<template lang="pug">
	div
		div.text-h6.text-sm-h5.text-md-h4.mb-4.primary--text
				| How to start using {{ $appTitle }}?
		div
			p
				| First, you have to be a resident of Biedersteiner Wohnheim.
			p
				| The contractual terms are irrelevant. It doesn't matter if you are a "permanent" resident, an exchange student or
				| a subletter (Untermieter).
			p
				| You need a chip to access the room and make purchases. You can order it and learn more about
				span.primary--text.mx-1 {{ $appTitle }}
				| on our home page.
		v-btn(
			to="/"
			color='primary'
			outlined
			).text-decoration-none.mt-2 Go to home page
</template>

<script>
export default {
	created() {},
	methods: {},
	props: ["headerSizes"]
};
</script>

<style scoped></style>
