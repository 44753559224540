<template lang="pug">
div
	div(:class="{'flex-wrap' : $vuetify.breakpoint.smAndDown}").d-flex.justify-center
		v-hover(
			v-for="theme in themes"
			v-slot="{ hover }"
			:key="theme.name")
			div(:class="{'display-row': row || accountSettingsDialog}")
				v-card(
					@click="selectTheme(theme)"
					:min-width='cardMinWidth'
					:min-height='cardMinHeight'
					:ripple='false'
					:style="{'background': designCardBackground(theme),\
					 				'border': `solid ${theme.primary} ${selectedThemeName == theme.name ? 3 : 2}px`}"
					:class="{'display-column': !row && !accountSettingsDialog,\
									'elevation-6':hover,\
					 				'tile-selected': selectedThemeName == theme.name,\
									'mx-2': landing}"
					flat
					outlined
				).remove-after-click-backround.color-card.mx-1.rounded-lg
					v-icon(
						v-if="selectedThemeName === theme.name"
						:class="row ? 'selected-design-tick-row-position' : 'selected-design-tick-column-position'"
						size="24"
						color="primary"
						) check_circle
					div(
						:class="[landing && !$vuetify.breakpoint.xs ? 'pb-2' : 'body-2 pb-1']"
						).font-weight-bold.white--text.title.text-center.fill-width.theme-name
						| {{ theme.name }}
	div(v-if='feedbackButton').text-right.mt-3
		a(
			v-if="!feedbackNoGoodColorsTriggered"
			@click="feedbackNoGoodColors"
			).primary--text I don't like any of those
		span(v-else) Thank you for sharing your opinion!
</template>

<script>
import { catchError } from "../../mixins/catchError";
import { setTheme } from "../../mixins/themes";
export default {
	props: [
		"row",
		"landing",
		"feedbackButton",
		"accountSettingsDialog",
		"preventSendingToServer"
	],
	mixins: [catchError, setTheme],
	data() {
		return {
			feedbackNoGoodColorsTriggered: false,
			selectedThemeName: "Equanimous Mint"
		};
	},
	mounted() {
		this.selectedThemeName = this.$store.state.settings.theme.name;
	},
	methods: {
		designCardBackground(theme) {
			return `linear-gradient(0deg, ${theme.primary} 30%, ${theme.complementary} 33%, ${theme.background} 74%)`;
			//return `linear-gradient(35deg, ${theme.primary} 15%,  ${theme.background} 85%)`;
		},
		selectTheme(theme) {
			this.selectedThemeName = theme.name;
			this.setTheme(this.selectedThemeName);
			if (!this.preventSendingToServer)
				this.$axios
					.post("/api/user_settings", {
						theme: theme.name
					})
					.then(() => {})
					.catch(error => catchError(error));
		},
		feedbackNoGoodColors() {
			const vm = this;
			this.$axios
				.put("/api/feedback", {
					text: "User doesn't like the offered UI color schemes",
					type: "Quick feedback",
					page: vm.$route.path,
					userAgent: window.navigator.userAgent
				})
				.then(() => {
					vm.feedbackNoGoodColorsTriggered = true;
				})
				.catch(error => catchError(error));
			this.showFeedbackDialog = false;
			this.selectedPurchaseId = null;
		}
	},
	computed: {
		cardMinWidth() {
			if (this.landing)
				if (this.$vuetify.breakpoint.smAndDown) return 100;
				else return 220;
			else return 0;
		},
		cardMinHeight() {
			if (this.landing)
				if (this.$vuetify.breakpoint.smAndDown) return 90;
				else return 100;
			else if (this.accountSettingsDialog) return 100;
			else return 0;
		}
	}
};
</script>

<style scoped>
.theme-name {
	position: absolute;
	width: 100%;
}

.title {
	bottom: 0;
}

.remove-after-click-backround:focus::before {
	opacity: 0 !important;
}

.tile-selected {
	border: 2px solid var(--v-primary-base);
}

.display-row {
	width: 85%;
	margin-top: 8px;
}

.display-column {
	padding-top: 120px;
	height: 180px;
}

.selected-design-tick-row-position {
	position: absolute;
	top: 24px;
	right: 12px;
}

.selected-design-tick-column-position {
	position: absolute;
	top: 6px;
	right: 6px;
}
</style>
