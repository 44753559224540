<template lang="pug">
v-container(fluid)#background
	div.pt-16.pb-6.px-sm-16
		v-card(
			outlined
			elevation='10'
			:color="`${hexToRgbA($vuetify.theme.themes.light['primary-light'], '0.82')}`"
			max-width='600px'
			).mx-auto.pa-6
			div.text-center.complementary--text
				div.text-h3 Stats
				div.subtitle-2 House 2
				div(v-if='!isObscure')
					div.d-flex.justify-center.mt-3.mx-2
						v-card(width='100%').text-center.complementary--text
							v-card-title
								v-icon(color='complementary').mr-1.mb-1 account_balance
								div Room balance
							div.pb-2.d-flex.justify-center
								v-skeleton-loader(
									v-if='roomBalance === null'
									type='heading').flex-grow-1.d-flex.justify-center.pb-3
								h1(v-else).complementary--text.text--darken-2 {{ formatMonetaryValue(roomBalance) }}
					div.d-sm-flex.justify-center
						v-card(
							v-for='(trend, index) in trends'
							:key='index'
							no-gutters
							).align-center.mx-2.pb-4.mt-3.complementary--text.text--darken-2.flex-grow-1
							v-card-title.complementary--text
								v-icon(color='complementary').mr-1.mb-1 {{ trend.icon }}
								div {{ trend.name }}
							div.justify-center.text-center
								div(v-for="(metric, _, i) in trend.metrics")
									v-card-text.pb-0
										v-skeleton-loader(
											v-if='metric.value === null'
											type='heading').flex-grow-1.d-flex.justify-center.pb-3
										h1(v-else).mb-0
											span(v-if="trend.name==='Revenue'") {{ formatMonetaryValue(metric.value) }}
											span(v-else) {{ metric.value }}
									v-card-subtitle.font-weight-thin.text--secondary.py-1
										| This {{metric.name}}
									v-divider(v-if='i === 0').mx-5.mb-2
				div(v-else).mt-4
					LogInToSee(
						entity='Stats'
						benefit='see how well our G-Raum is doing!')
		div(v-if='!isObscure').d-flex.justify-center
			OnlyVisibleToLoggedIn
</template>

<script>
import Vue from "vue";
import OnlyVisibleToLoggedIn from "./Elements/OnlyVisibleToLoggedIn";
import SignUpDialog from "./SignUp/SignUpDialog.vue";
import LogInToSee from "./Elements/LogInToSee.vue";
import { dataLoader } from "@/mixins/dataLoader";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
export default {
	props: { isObscure: Boolean },
	mixins: [dataLoader],
	components: { OnlyVisibleToLoggedIn, SignUpDialog, LogInToSee },
	data() {
		return {
			dataToLoadPreventLoadingOnMount: this.isObscure,
			dataToLoad: [
				{
					url: `dashboard/stats?from=${this.getDateDaysAgoRoundedDown(
						6
					)}&to=${this.getDateDaysAgo(0)}`,
					saveTo: "week",
					executeAfterLoading: ["mergeWeekData", "mergeBalance"]
				},
				{
					url: `dashboard/stats?from=${this.getDateDaysAgoRoundedDown(
						29
					)}&to=${this.getDateDaysAgo(0)}`,
					saveTo: "month",
					executeAfterLoading: "mergeMonthData"
				}
			],
			week: {},
			month: {},
			roomBalance: null,
			trends: {
				purchases: {
					name: "Purchases",
					icon: "storefront",
					metrics: {
						week: { name: "week", value: null },
						month: { name: "month", value: null }
					}
				},
				revenue: {
					name: "Revenue",
					icon: "monetization_on",
					metrics: {
						week: { name: "week", value: null },
						month: { name: "month", value: null }
					}
				}
			}
		};
	},
	methods: {
		mergeBalance() {
			Vue.set(this, "roomBalance", this.week.data[2].value);
		},
		mergeWeekData() {
			Vue.set(
				this.trends.purchases.metrics.week,
				"value",
				this.week.data[0].value
			);
			Vue.set(
				this.trends.revenue.metrics.week,
				"value",
				this.week.data[1].value
			);
		},
		mergeMonthData() {
			Vue.set(
				this.trends.purchases.metrics.month,
				"value",
				this.month.data[0].value
			);
			Vue.set(
				this.trends.revenue.metrics.month,
				"value",
				this.month.data[1].value
			);
		},
		getDateDaysAgo(numberOfDays) {
			return dayjs()
				.subtract(numberOfDays, "day")
				.format("YYYY-MM-DDTHH:mm:ss");
		},
		getDateDaysAgoRoundedDown(numberOfDays) {
			return dayjs()
				.subtract(numberOfDays, "day")
				.format("YYYY-MM-DDT00:00:00");
		}
	}
};
</script>

<style scoped>
#background {
	background-image: url("../../../public/img/stats-bg.webp");
	background-position: center;
	background-size: cover;
}
</style>
