<template lang="pug">
v-dialog(
	v-model='showAccountSettingsDialog'
	transition="dialog-bottom-transition"
	max-width="740"
	@click:outside='cancel'
	@keydown.esc='cancel')
	v-card
		div.account-card-background-comp
			v-card-title.text-h4.primary--text.py-5.d-flex.justify-space-between
				div Settings
				NameAndRoomBadge(
					:name='$store.state.user.name'
					:room="$store.state.user.room"
					size='s')
			//- alert(
			//- 	:content='content'
			//- 	ref='alert'
			//- 	:type='type'
			//- 	:backgroundColor='"red"')
			v-tabs(
				@change="resetUpdateStatuses"
				centered
				v-model="step"
				background-color="account-card-background-comp"
				color="primary")
				v-tab(
					v-for='(section, i) in settingsSections'
					:key='i'
					).justify-start
					v-icon(left) {{ section.icon }}
					| {{ section.text }}
			v-sheet(color='account-card-divider' height='2').divider
		v-tabs-items(v-model='step').pa-4.mt-4
			v-tab-item
				.text-sm-h6.subtitle-1.complementary--text.text-center
					| Choose design that suits you:
				v-card-text
					v-sheet(min-height='300')
						ThemePicker(
							:feedbackButton='true'
							:accountSettingsDialog='true')
			v-tab-item
				v-card(
					flat
					max-width='600').mx-auto
					v-card-text.pb-0
						v-form(
							v-model="contacts.valid"
							).d-flex.justify-center
							v-sheet(width='300').mr-4
								v-text-field(
									v-model="contacts.email"
									label="Email"
									:rules="[rules.required, rules.email]"
									:error="emailExists"
									:error-messages="emailExists ? 'Email already taken' : []"
									outlined
									clearable
									@input='emailUpdateStatus = null; emailExists = false')
							v-sheet(width='140')
								v-text-field(
									v-model="contacts.room"
									label="Room"
									hint="Or WG"
									:rules="[rules.required, rules.maxRoomLength]"
									outlined
									clearable
									@input='roomUpdateStatus = null')
						UpdateContactStatus(
							v-if="emailUpdateStatus"
							title='email'
							:status='emailUpdateStatus'
							:statuses='updateStatuses'
							successMessage='Follow the instructions we sent to your new address to confirm your email')
						UpdateContactStatus(
							v-if="roomUpdateStatus"
							title='room'
							:status='roomUpdateStatus'
							:statuses='updateStatuses'
							successMessage='Room number updated').mt-1
						EmailChangePending(v-if='this.$store.state.user.emailCandidate')
					v-card-actions.mt-6
						v-spacer
						v-btn(
							@click="saveContactInformation"
							color="primary"
							:disabled='disableSaveButton')
							span Save
						v-spacer
			v-tab-item
				.text-sm-h6.subtitle-1.complementary--text.text-center
					| Set new password
				v-card-text.pb-0
					PasswordInput(
						ref="newPassword"
						colorScheme="light"
						requireCurrentPassword='true'
						:accountSettingsDialog='true'
						alertBackgroundColor="white")
			v-tab-item
				EmailSettings
		v-sheet(color='account-card-divider' height='2')
		v-card-actions.primary-light
			v-spacer
			v-btn(
				color="primary"
				text
				@click='cancel')
				span close
</template>
<script>
import { mapGetters } from "vuex";
import { validators } from "@/mixins/validators";
import { catchError } from "@/mixins/catchError";
import Alert from "@/components/Alert.vue";
import PasswordInput from "@/components/Helpers/PasswordInput.vue";
import ThemePicker from "@/components/Helpers/ThemePicker.vue";
import EmailSettings from "@/components/Account/Elements/EmailSettings.vue";
import UpdateContactStatus from "./Elements/UpdateContactStatus.vue";
import NameAndRoomBadge from "../../Elements/NameAndRoomBadge.vue";
import EmailChangePending from "../EmailChangePending.vue";

export default {
	name: "account-settings-dialog",
	mixins: [validators, catchError],
	components: {
		Alert,
		PasswordInput,
		ThemePicker,
		EmailSettings,
		UpdateContactStatus,
		NameAndRoomBadge,
		EmailChangePending
	},
	props: ["settingsSections"],
	data() {
		return {
			selectedMailOptions: [],
			emailOptions: [
				{
					categoryName: "Admin",
					adminOnly: true,
					settings: [
						{
							name: "A new user registers",
							url: "mail_sub_user_registration"
						}
					]
				},
				{
					categoryName: "Friends",
					adminOnly: false,
					settings: [
						{
							name: "A Split or PayMe request from a friend",
							url: "mail_sub_split_request"
						},
						{
							name: "Your Split or PayMe request has finished",
							url: "mail_sub_split_finish"
						}
					]
				},
				{
					categoryName: "Balance",
					adminOnly: false,
					settings: [
						{
							name: "Your balance is negative",
							url: "mail_sub_debt_reminder"
						}
					]
				},
				{
					categoryName: "Misc",
					adminOnly: false,
					settings: [
						{
							name: "Significant updates to the service",
							url: "mail_sub_newsletter"
						}
					]
				}
			],
			step: 0,
			contacts: {
				email: this.$store.state.user.email,
				room: this.$store.state.user.room,
				valid: false
			},
			showAccountSettingsDialog: true,
			emailUpdateStatus: null,
			emailExists: false,
			roomUpdateStatus: null,
			updateStatuses: {
				loading: "loading",
				error: "error",
				success: "success"
			}
		};
	},
	mounted() {},
	methods: {
		async checkForExistingEmail() {
			const response = await this.$axios.get(
				`/api/email_exists/${this.contacts.email}`
			);
			this.emailExists = response.data.response;
		},
		resetUpdateStatuses() {
			this.emailUpdateStatus = null;
			this.roomUpdateStatus = null;
		},
		saveContactInformation() {
			if (this.contacts.email !== this.$store.state.user.email)
				this.saveNewEmail(this.contacts.email);
			if (this.contacts.room !== this.$store.state.user.room)
				this.saveNewRoom(this.contacts.room);
		},
		async saveNewEmail(email) {
			await this.checkForExistingEmail();
			if (this.emailExists) return;
			this.emailUpdateStatus = this.updateStatuses.loading;
			this.$axios
				.put(`/api/change_email?new_email=${email}`)
				.then(() => {
					this.emailUpdateStatus = this.updateStatuses.success;
					this.$store.commit("setUser", {
						...this.$store.state.user,
						emailCandidate: email
					});
				})
				.catch(() => {
					this.emailUpdateStatus = this.updateStatuses.error;
				});
		},
		saveNewRoom(room) {
			this.roomUpdateStatus = this.updateStatuses.loading;
			this.$axios
				.put(`/api/change_room?new_room=${room}`)
				.then(() => {
					this.roomUpdateStatus = this.updateStatuses.success;
					this.$store.commit("setUser", {
						...this.$store.state.user,
						room: room
					});
				})
				.catch(() => {
					this.roomUpdateStatus = this.updateStatuses.error;
				});
		},
		cancel() {
			this.$emit("cancel");
		}
	},
	computed: {
		...mapGetters(["user_is_admin"]),
		disableSaveButton() {
			return (
				!this.contacts.valid ||
				(this.contacts.email == this.$store.state.user.email &&
					this.contacts.room == this.$store.state.user.room) ||
				this.roomUpdateStatus === this.updateStatuses.loading ||
				this.emailUpdateStatus === this.updateStatuses.loading
			);
		}
	}
};
</script>
<style lang="scss" scoped>
.divider {
	z-index: 99999999;
}
</style>
