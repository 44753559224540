<template lang="pug">
v-dialog(v-model='showAddMoneyDialog'
				transition="dialog-bottom-transition"
				max-width="450"
				@click:outside='cancel'
				@keydown.esc='cancel')
	v-card
			v-card-title.d-flex.justify-space-between
				span.text-h6.font-weight-normal Top up {{ person.name }}
					span.text-secondary.font-weight-medium.h5.pl-2
						span ({{ formatRoomOrWGNumber(person.room, true) }})
			v-card-text
				v-container
					v-form(v-model="formIsValid")
						v-text-field(v-model='amountToAdd' type="number"  :rules="[rules.monetaryValue, rules.moneyLowerLimit, rules.moneyUpperLimit]" label='Amount' required outlined suffix="€")
					v-row
						v-col
							v-btn(elevation="3" raised @click="amountToAdd = 10" block text) 10&euro;
						v-col
							v-btn(elevation="3" raised @click="amountToAdd = 15" block text) 15&euro;
						v-col
							v-btn(elevation="3" raised @click="amountToAdd = 20" block text) 20&euro;
			v-divider
			v-card-actions
				v-container
					v-row
						v-col.d-flex.justify-content-center.pt-0
							v-btn(color='primary' @click='save' :disabled='!formIsValid')
								v-icon mdi-account-balance-wallet-outline
								span.pr-1 Top up
								span.font-weight-black {{ person.name }}
								template(v-if="formIsValid")
									span.px-1 for
									span.font-weight-black {{ amountToAdd }}&euro;
					v-row
						v-col.d-flex.justify-content-center.pb-1.pt-0
							v-btn(color='grey darken-1' text @click='cancel')
								| Cancel
</template>
<script>
import { validators } from "../../../mixins/validators";
export default {
	mixins: [validators],
	name: "add-money-dialog",
	props: ["person"],
	data() {
		return {
			formIsValid: false,
			showAddMoneyDialog: true,
			amountToAdd: 10
		};
	},
	methods: {
		save() {
			if (this.amountToAdd) {
				let amountToAddDouble = parseFloat(this.amountToAdd);
				this.$emit("addMoney", {
					personId: this.person.id,
					amount: amountToAddDouble + this.person.balance
				});
			} else {
				this.$emit("error", "Please enter a number");
				this.amountToAdd = 10;
			}
		},
		cancel() {
			this.$emit("cancel");
		}
	},
	watch: {
		content: {
			deep: true,
			handler: function(val) {
				this.form_data = Object.assign({}, this.form_data, val);
			}
		}
	}
};
</script>
