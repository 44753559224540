<template lang="pug">
div.pa-0.complementary.pb-6
	div.pt-md-14
	v-card(color='black' tile).align-center.text-center
		div.white--text
			div.py-5
				h1 Psst, want a drink?
				h4 All you need is a chip
	div.text-center.my-6
		v-img(
			height='562'
			position='center center'
			contain
			:src="require('../../../public/img/beers.svg')")
			div(v-if='$vuetify.breakpoint.mdAndUp').vertical-divider-110
			div(v-if='$vuetify.breakpoint.sm').vertical-divider-40
			.d-flex.justify-center.align-center
				v-sheet(
					color='rgba(0,0,0,0.88)'
					max-width='822'
					rounded).d-md-flex.justify-center.align-center.white--text
					div.px-5.pt-10
						SignUpButton(@click='signUpDialog.show = true')
						div.mt-3.body-2
							i Exclusively for Biedersteiners
					v-divider(v-if='$vuetify.breakpoint.smAndDown').my-5.complementary
					div.px-5.py-md-5
						h5.ma-0 For our guests
						div.body-1.text-left
							p.ma-0
								br
								| As of now, we don't give chips to guests of Biederstein
								br
								|It might change in future
								br
								br
							div.mb-2(v-if='!emailLoading') Would you like us to keep you posted?
						div(v-if='emailLoading').d-flex.justify-center.mb-6
							div
								v-card(max-width='320' color='primary-light' rounded='xl').d-flex.mr-6.rounded-tl-0
									div.text-left.py-2.px-4 Would you like us to keep you posted?
								div.d-flex.justify-end
									v-card(color='primary lighten-2' rounded='xl').my-2
										div.primary-light--text.py-2.px-4 Yes!
								div.d-flex
									v-card(
										color='primary-light'
										flat
										max-width='320'
										rounded='xl').d-flex.text-left.rounded-bl-0
										v-icon(v-if='!emailLoadingFinished' color='primary darken-2' size='30') pending
										div(v-if='emailLoadingFinished && !emailLoadingFailed').py-2.px-4 Got it, thanks! We'll get back to you
										div(v-if='emailLoadingFinished && emailLoadingFailed').py-2.px-4 This email has already been subscribed. We'll keep you posted ;)
									div
						div(v-else)
							v-form(ref='form' v-model='formIsValid').d-flex
								v-text-field(
									v-model='guestEmail'
									:rules='[rules.email]'
									@keyup="checkForExistingEmail"
									dark
									outlined
									label='E-mail')
								v-btn(
									:disabled='!formIsValid'
									@click='subscribeForGuests'
									x-large
									dark
									color='primary lighten-4').ml-3.mt-1
									span.primary--text.text--darken-2 YES!
						div.body-2
							v-icon.mr-1(color='white' size='22') gpp_good
							b No spam. Only relevant information
						.caption.text-center.px-8.mt-2.mb-4.primary-light--text.text--darken-2
							| By signing up you agree to our
							|
							router-link(to="/gdpr/terms-and-conditions").primary--text.text--lighten-3 Terms and Conditions
							|, and
							|
							router-link(to="/gdpr/privacy-policy").primary--text.text--lighten-3 Privacy Policy
	sign-up-dialog(
		v-if='signUpDialog.show'
		:showDialog.sync='signUpDialog.show'
		@cancel='signUpDialog.show = false'
		hide-overlay
		)
</template>

<script>
import { catchError } from "../../mixins/catchError";
import { validators } from "../../mixins/validators";
import SignUpDialog from "./SignUp/SignUpDialog.vue";
import SignUpButton from "./Elements/SignUpButton.vue";
export default {
	components: { SignUpDialog, SignUpButton },
	mixins: [validators, catchError],
	data() {
		return {
			emailLoading: false,
			emailLoadingFinished: false,
			emailLoadingFailed: false,
			formIsValid: false,
			guestEmail: "",
			signUpDialog: {
				show: false
			}
		};
	},
	methods: {
		checkForExistingEmail() {
			this.$refs.form.validate();
			if (this.formIsValid) {
				// send request to the server
			}
		},
		subscribeForGuests() {
			this.$refs.form.validate();
			if (this.formIsValid) {
				this.emailLoading = true;

				this.$axios
					.post("/api/announcement_register/" + this.guestEmail)
					.then(() => {
						this.emailLoadingFinished = true;
						this.emailLoadingFailed = false;
					})
					.catch(() => {
						this.emailLoadingFinished = true;
						this.emailLoadingFailed = true;
					});
			}
		}
	}
};
</script>

<style scoped>
#background {
	height: 100vh;
	background-image: url("../../../public/img/biederstein-login-background.jpg");
	background-size: cover;
}

.vertical-divider-110 {
	height: 110px;
}

.vertical-divider-40 {
	height: 80px;
}
</style>
