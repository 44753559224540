<template lang="pug">
div(
	v-if='$vuetify.breakpoint.smAndUp'
	).d-flex.mt-12.justify-center
	div
		v-img(
			width='80'
			:src='require("../../../../public/img/arrow.png")'
			).leftArrow
	div.text-h5.mx-16.pt-12 Hover
	div
		v-img(
			width='80'
			:src='require("../../../../public/img/arrow.png")'
			:class="rightArrowDown ? 'rightArrowDown' : 'rightArrow'"
			).rightArrow
</template>

<script>
export default {
	props: {
		rightArrowDown: Boolean
	},
	data() {
		return {};
	},
	created() {},
	methods: {},
	computed: {},
	components: { Request }
};
</script>

<style scoped>
.leftArrow {
	transform: scaleX(-1) rotate(40deg);
}

.rightArrow {
	transform: rotate(40deg);
}

.rightArrowDown {
	-moz-transform: scale(-1, 1);
	-webkit-transform: scale(-1, 1);
	-o-transform: scale(-1, 1);
	-ms-transform: scale(-1, 1);
	transform: scale(-1, 1) rotate(-150deg);
}
</style>
