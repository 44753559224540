<template lang="pug">
v-sheet(
	height='100vh'
	)#whats-new-first
	div.d-flex.justify-center.align-center
		v-sheet(
			height='70px'
			max-width='2000px'
			width="100%"
			).d-flex.justify-end.align-center.pr-8
			//- v-btn(
			//- 	to="/support"
			//- 	color="primary lighten-2"
			//- 	text
			//- 	).text-decoration-none.mr-2 Support
			//- v-btn(
			//- 	v-if='user_logged_in'
			//- 	:to="'/account'"
			//- 	outlined
			//- 	color='primary').text-decoration-none
			//- 	span.primary--text account
			//- v-btn(
			//- 	v-if='!user_logged_in'
			//- 	@click="scrollToLanding"
			//- 	outlined
			//- 	color='primary').text-decoration-none
			//- 	span.primary--text get started
	div.d-flex.justify-center.align-center.fill-height.mt-n16
		Title(
			:textTop='"Introducing"'
			:textBottom='"BiederDrinks 2"'
			textBottomClass="brand-text-logo"
			spread='true'
			:align='"center"')
			//ScrollDownArrow
</template>

<script>
import Title from "./Title.vue";
import ScrollDownArrow from "../Elements/ScrollDownArrow.vue";
import { mapGetters } from "vuex";
export default {
	components: { Title, ScrollDownArrow },
	methods: {
		scrollToLanding() {
			document.getElementById("background-landing-first").scrollIntoView({
				behavior: "smooth"
			});
		}
	},
	computed: {
		...mapGetters(["user_logged_in"])
	}
};
</script>
