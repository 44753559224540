<template lang="pug">
v-card(
	:min-width='$vuetify.breakpoint.xs ? 280 : 300'
	max-width='300'
	color="primary-light"
	:height="purchase.splitStatus || purchase.splitResponseStatus ? 314 : 220").rounded-lg
	//* Uncomment for debugging:
	//* .caption id {{purchase.id}}
	v-card-subtitle.pb-2.pt-2.text-center.px-0.account-card-text-light--text
		div.caption
			v-tooltip(
				top
				nudge-top='12'
				open-delay="200"
				color="primary darken-1")
				template(v-slot:activator="{ on, attrs }")
					span(
						v-bind="attrs"
						v-on="on")
						span(v-if='purchase.added_by') Entered by {{ purchase.added_by.name }}
						span.ml-1 {{ convertTimestampWithGMTPostfixToDaysAgo(purchase.timestamp) }}
				template
					span {{ convertTimestampToReadableDayOfTheWeekDateTime(purchase.timestamp) }}
	v-sheet(color='account-card-divider' height='2')
	div(
		v-if="purchase.splitStatus || purchase.splitResponseStatus"
		).text-center
		SplitStatus(
			:author='purchase.author'
			:status='purchase.author ? "responded" : purchase.splitStatus'
			:purchase="purchase")
	v-card-text.px-1.pb-0.pt-0.account-card-text--text
		v-container
			v-row
				v-container.pt-6
					div(v-if="!purchase.splitStatus && !purchase.splitResponseStatus")
					v-row(
						v-for="(drink, ownDrinkIndex) in drinksIPaidForPaginated(drinksIPaidFor(purchase))"
						:key='ownDrinkIndex'
						).body-1
						v-col(cols="8").py-0.pr-0
							v-tooltip(
								top
								open-delay="300"
								nudge-left='60')
								template(v-slot:activator="{ on, attrs }")
									div(
										v-bind="attrs"
										v-on="on"
										).text-truncate {{ drink.quantity }} {{ drink.drink }}
								span {{ drink.quantity }} {{ drink.drink }}
						v-col(cols="4").py-0.pt-0.pl-0.text-right
							div {{ formatMonetaryValue(drink.sum) }}
			v-row
				v-col(cols="12").pl-0.pt-2
					div(
						v-if="Math.ceil(drinksIPaidFor(purchase).length) > 4"
						).pl-5.body-2
						div.d-flex
							v-spacer
							v-btn(
								:disabled="purchase.currentPage <= 0"
								@click='$emit("goToPreviousPage")'
								color='primary'
								text
								small)
								v-icon(size='16') arrow_back_ios
							div.body-2.my-auto
								| {{ purchase.currentPage + 1 }} / {{ Math.ceil(drinksIPaidFor(purchase).length / 4) }}
							v-btn(
								:disabled="purchase.currentPage >= (Math.ceil(drinksIPaidFor(purchase).length) / 4 - 1)"
								@click='$emit("goToNextPage")'
								color='primary'
								text
								small)
								v-icon(size='16') arrow_forward_ios
							v-spacer
					// vertical spacer to make cards without Next/Prev buttons be the same height
					div(v-else).body-2.mt-10
					h5.text-right.mb-0.mt-3 {{ formatMonetaryValue(paidAmount()) }}
	v-card-actions.pa-0.d-inline
		div.d-flex.justify-space-between.align-center.card-footer
			div
				a(
					@click="$emit('reportPurchaseProblem', index)"
					).pl-3.caption.primary-light--text.text--darken-3
						| report a problem
			div
				template(v-if="purchase.splitStatus && !purchase.author")
					v-btn(
						@click="$emit('showRequestDetailsDialog', index)"
						color="primary"
						elevation='4'
						).rounded-tr-0.rounded-bl-0.rounded-lg
						v-icon(size="20") ballot
						span.ml-1 details
				template(v-else-if="purchase.isSplittable")
					v-btn(
						@click="$emit('showSplitDialog', index)"
						color="primary"
						elevation='4'
						).rounded-tr-0.rounded-bl-0.rounded-lg
						v-icon(size="20") person_add
						span.ml-1 Add friends
				template(v-else)
					div.pb-9

		//- v-container
		//- 	v-row
		//- 		v-col.pa-0.d-flex.align-center
		//- 			a(
		//- 				@click="$emit('reportPurchaseProblem', index)"
		//- 				).pl-3.caption.primary-light--text.text--darken-3
		//- 					| report a problem
		//- 		v-col(cols='3').d-flex.justify-end.pa-0
		//- 			template(v-if="purchase.splitStatus && !purchase.author")
		//- 				v-btn(
		//- 					@click="$emit('showRequestDetailsDialog', index)"
		//- 					color="primary"
		//- 					elevation='4'
		//- 					).rounded-tr-0.rounded-bl-0.rounded-lg
		//- 					v-icon(size="20") fact_check
		//- 					span.ml-1 details
		//- 			template(v-else-if="purchase.isSplittable")
		//- 				v-btn(
		//- 					@click="$emit('showSplitDialog', index)"
		//- 					color="primary"
		//- 					elevation='4'
		//- 					).rounded-tr-0.rounded-bl-0.rounded-lg
		//- 					v-icon(size="20") person_add
		//- 					span.ml-1 Add friends
		//- 			template(v-else)
		//- 				div.pb-9
</template>
<script>
import SplitStatus from "@/components/Account/Desktop/Purchase/SplitStatus.vue";
export default {
	props: ["purchase", "index"],
	methods: {
		drinksIPaidFor(purchase) {
			let drinks = [];
			if (purchase.ownDrinks) {
				drinks = purchase.ownDrinks;
				if (
					(purchase.splitStatus === "deadline reached" ||
						purchase.splitStatus === "everyone responded") &&
					!purchase.author
				)
					drinks = drinks.concat(purchase.unclaimedDrinks);
			} else if (purchase.drinks) drinks = purchase.drinks;
			return drinks;
		},
		drinksIPaidForPaginated(drinks) {
			return drinks.length > 0
				? drinks.slice(
						this.purchase.currentPage * 4,
						this.purchase.currentPage * 4 + 4
				  )
				: [];
		},
		paidAmount() {
			if (this.purchase.splitResponseStatus) return this.purchase.ownAmount;
			if (!this.purchase.splitStatus) return this.purchase.amount;
			if (
				(this.purchase.splitStatus === "everyone responded" ||
					this.purchase.splitStatus === "deadline reached") &&
				!this.purchase.author
			)
				return this.purchase.ownAmount + this.purchase.unclaimedAmount;
			return this.purchase.ownAmount;
		}
	},
	components: { SplitStatus }
};
</script>
<style scoped>
.card-footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	padding: 0;
	background-color: rgba(0, 0, 0, 0.04);
	background: none;
	border: 0;
}
</style>
