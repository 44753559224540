import First from "./First";
import ProjectHistory from "./ProjectHistory";
import Benefits from "./Benefits";
import Locations from "./Locations";
import SignUp from "./SignUp";
import Stats from "./Stats.vue";
import Responsibilities from "./Responsibilities.vue";
import WhatsNew from "./WhatsNew/WhatsNew.vue";
import H34 from "./H34.vue";
import GDPRLinks from "../GDPRLinks.vue";

export default {
	First,
	ProjectHistory,
	Benefits,
	Locations,
	SignUp,
	Stats,
	Responsibilities,
	WhatsNew,
	H34,
	GDPRLinks
};
