<template lang="pug">
div.background
	v-card(
		flat
		color='instant-refund-light'
		max-width='1200'
		).card.mx-auto
		//v-parallax
		v-img(
			:src="require('../../../../public/img/WhatsNew/instant-refund.webp')"
			:height='$vuetify.breakpoint.xs ? 500 : 800'
			).d-flex.align-center
			div
				div.d-flex.justify-center.mt-sm-16
					.instant-refund--text.d-flex.badge.py-3.align-center
						v-icon(
							color='instant-refund'
							:size='$vuetify.breakpoint.xs ? 30 : 44'
							).px-2 bolt
						div.text-sm-h3.pr-3.text-h5 Instant Refunds
				div.d-flex.justify-center
					div.text-sm-h4.text-h5.white--text.text-center.mt-10.title
						p
							| Get a refund the moment you initiate
						p SPLIT or PayMe
				div.d-flex.justify-center
					v-btn(
						:to="`/support/PendingBalance`"
						color='instant-refund-light'
						x-large
						absolute
						bottom
						).mb-sm-14.mb-6.text-decoration-none
						span.font-weight-bold Read more
	div.body-1.text-center.mt-3.background-text--text.mx-4.mx-sm-0
		| If drinks are not claimed within 2 weeks, your account will be charged
</template>

<script>
import InstantRefundBadge from "@/components/Elements/InstantRefundBadge.vue";
export default {
	components: { InstantRefundBadge }
};
</script>

<style scoped>
.card {
	border-top: solid var(--v-instant-refund-light-base) 20px;
	border-bottom: solid var(--v-instant-refund-light-base) 20px;
	border-radius: 100px;
}

/* .blur {
	opacity: 1;
	-webkit-filter: blur(1px);
	-moz-filter: blur(1px);
	-o-filter: blur(1px);
	-ms-filter: blur(1px);
	filter: blur(1px);
} */

/deep/ .img .v-image__image--cover {
	/* -webkit-filter: blur(5px);  */
	/* filter: blur(5px); */
	box-shadow: inset 0px 0px 13px 4px rgba(0, 0, 0, 0.25);
}

.button {
	border-radius: 13px;
}

.badge {
	border: solid var(--v-instant-refund-light-base) 4px;
	border-radius: 8px;
	background: rgba(255, 184, 118, 0.26);
}

.title {
	font-weight: 400;
	font-size: 32px;
	color: #ffffff;
	text-shadow: 0px 0px 8px rgba(255, 184, 118, 0.86);
}
</style>
