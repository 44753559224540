<template lang="pug">
v-card(
	v-if='requestCount > 0'
	color='primary-light'
	max-width='400'
	).align-center.py-2
	div.d-flex.justify-space-between.align-center
		div.d-flex
			v-icon(size='30' color='primary').ml-2.mr-1 account_box
			h5.ma-0.primary--text.text--darken-1.mr-8.pt-1 {{ requestCount }} chip request{{ requestCount > 1 ? 's' : '' }}
		v-btn(
			to='/persons'
			color='primary'
			dark
			rounded
			).my-2.mr-2.text-decoration-none
			span respond
</template>
<script>
export default {
	props: ["requestCount"]
};
</script>
