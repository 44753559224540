import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";

import Vue from "vue";
import VueCookies from "vue-cookies";
import VueRouter from "vue-router";
Vue.use(VueRouter);
Vue.use(VueCookies);

import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);

import Autocomplete from "@trevoreyre/autocomplete-vue";
import "@trevoreyre/autocomplete-vue/dist/style.css";

Vue.use(Autocomplete);

import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import routes from "./utils/routes";
import store from "./utils/store";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

Vue.config.productionTip = false;

import axios from "axios";
import axiosRetry from "axios-retry";

/*
function getCookie(name) {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2)
		return parts
			.pop()
			.split(";")
			.shift();
	else console.error("Could not retrieve CSRF-TOKEN cookie");
}

axios.defaults.headers.common["X-CSRFToken"] = getCookie("CSRF-TOKEN");
*/

if (process.env.VUE_APP_BACKEND_URL) axios.defaults.withCredentials = true;
const baseURL = process.env.VUE_APP_BACKEND_URL;
const axios_instance = axios.create({ baseURL });
const axios_instance_no_retry = axios.create({ baseURL });
const axios_instance_no_interceptors = axios.create({ baseURL });

axios_instance.interceptors.response.use(
	response => {
		return response;
	},
	error => {
		if (error.response.status === 401 || error.response.status === 403) {
			console.error("logged out from backend, resetting store");
			store.commit("reset");
			window.location.href = "/#/login";
		}
		return Promise.reject(error);
	}
);

// to listen: window.addEventListener("axiosRetry", (evt) => { evt.detail.count });
function retryDelay(retryCount) {
	const retryEvent = new CustomEvent("axiosRetry", {
		detail: { count: retryCount }
	});
	window.dispatchEvent(retryEvent);
	return 2000;
}

axiosRetry(axios_instance, {
	retries: 7,
	retryDelay: retryDelay,
	retryCondition: error => axiosRetry.isNetworkOrIdempotentRequestError(error)
});

axiosRetry(axios_instance_no_interceptors, {
	retries: 7,
	retryDelay: retryDelay,
	retryCondition: error => axiosRetry.isNetworkOrIdempotentRequestError(error)
});

Vue.prototype.$appTitle = process.env.VUE_APP_TITLE || "BiederDrinks";
Vue.prototype.$emailDomain =
	process.env.VUE_APP_EMAIL_DOMAIN || "biederdrinks.de";
Vue.prototype.$isBiederDrinks = Vue.prototype.$appTitle === "BiederDrinks";

Vue.prototype.$axios = axios_instance;
Vue.prototype.$axios_no_retry = axios_instance_no_retry;
Vue.prototype.$axios_instance_no_interceptors = axios_instance_no_interceptors;

const getLastUrlSegment = url => url.substring(url.lastIndexOf("/") + 1);

const router = new VueRouter({
	//mode: "history",
	base: __dirname,
	routes: routes,
	scrollBehavior(to, from /*, savedPosition */) {
		if (
			to?.name === "support" &&
			document.getElementsByClassName("router-supportPortal")[0]
		) {
			document
				.getElementsByClassName("router-supportPortal")[0]
				.scrollIntoView();
			//return { top: 0 };
		}
		if (to?.name === "gdpr") {
			document.getElementById("gdpr-terms-and-conditions")?.scrollIntoView();
		}
		if (to?.name?.startsWith("gdpr-")) {
			document.getElementById(to.name)?.scrollIntoView();
		}
		if (to?.path === "/") {
			if (
				document.getElementsByClassName(
					`router-${getLastUrlSegment(from.path)}`
				)[0]
			)
				document
					.getElementsByClassName(`router-${getLastUrlSegment(from.path)}`)[0]
					.scrollIntoView({ behavior: "smooth" });
		}
	}
});

router.beforeEach((to, from, next) => {
	// prevent user from seeing login page if they are logged in
	if (to.name === "login" && store.getters.user_logged_in)
		next({ name: store.getters.user_is_admin ? "dashboard" : "account" });

	if (to.matched.some(record => record.meta.requiresAdmin)) {
		// this route requires auth, check if logged in
		// if not, redirect to login page.
		if (!store.getters.user_is_admin) {
			next({ name: "login" });
		} else {
			next(); // go to wherever I'm going
		}
	} else if (to.matched.some(record => record.meta.requiresLogin)) {
		if (!store.getters.logged_in_to_personal_area) {
			next({ name: "login" });
		} else {
			next(); // go to wherever I'm going
		}
	} else {
		next(); // does not require auth, make sure to always call next()!
	}
});

Vue.filter("timestampToDDMMYY", function(timstamp) {
	if (!timstamp) return "";
	const timestampArray = timstamp.toString().split(/[- :]/);
	return timestampArray[2] + "." + timestampArray[1] + "." + timestampArray[0];
});

Vue.filter("timestampToDDMMYYHHMM", function(timstamp) {
	if (!timstamp) return "";
	const timestampArray = timstamp.toString().split(/[- :]/);
	return (
		timestampArray[2] +
		"." +
		timestampArray[1] +
		"." +
		timestampArray[0] +
		" " +
		timestampArray[3] +
		":" +
		timestampArray[4]
	);
});

Vue.mixin({
	methods: {
		formatToMultiplicativeNumber(number) {
			//if (!number) return;
			if (number === 1) return "once";
			else if (number === 2) return "twice";
			else if (number === 3) return "thrice";
			return number + " times";
		},
		addZeroes(value) {
			let valueStr = value.toString();
			if (valueStr[valueStr.length - 3] == ".") return valueStr;
			else if (valueStr[valueStr.length - 2] == ".") return valueStr + "0";
			else return valueStr + ".00";
		},
		formatRoomOrWGNumber(roomOrWGNumber, showCaption) {
			if (showCaption)
				return (roomOrWGNumber.length == "4" || roomOrWGNumber.length == "3"
					? "room"
					: "WG"
				).concat("\u00A0", roomOrWGNumber);
			return roomOrWGNumber;
		},
		formatMonetaryValue(input) {
			return `${this.addZeroes(Math.round(input * 100) / 100)}€`;
		},
		formatComment(input) {
			return input?.length > 20 ? input.substring(0, 20) + "..." : input;
		},
		cutGMTPostfix(timestamp) {
			return timestamp.substring(0, timestamp.length - 4);
		},
		convertTimestampWithGMTPostfixToDaysAgo(timestamp) {
			return dayjs(this.cutGMTPostfix(timestamp)).fromNow();
		},
		convertTimestampToDaysAgo(timestamp) {
			return dayjs(timestamp).fromNow();
		},
		// Add convertTimestampToReadableDayOfTheWeekDateTime / days ago (if less than 7 days)
		convertTimestampToReadableDayOfTheWeekDateTime(timestamp) {
			if (!timestamp) return null;
			return (
				dayjs(this.cutGMTPostfix(timestamp))
					.format("ddd, D MMMM YYYY")
					.toString() +
				" at " +
				dayjs(this.cutGMTPostfix(timestamp))
					.format("HH:mm")
					.toString()
			);
		},
		makeTimestampReadable(timestamp) {
			return (
				dayjs(this.cutGMTPostfix(timestamp))
					.format("D MMMM YYYY")
					.toString() +
				" at " +
				dayjs(this.cutGMTPostfix(timestamp))
					.format("HH:mm")
					.toString()
			);
		},
		dateDifference(timestamp1, timestamp2) {
			timestamp1 = dayjs(timestamp1);
			timestamp2 = dayjs(timestamp2);
			return timestamp2.from(timestamp1, true);
		},
		hexToRgbA(hex, opacity) {
			var c;
			if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
				c = hex.substring(1).split("");
				if (c.length == 3) {
					c = [c[0], c[0], c[1], c[1], c[2], c[2]];
				}
				c = "0x" + c.join("");
				return (
					"rgba(" +
					[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
					"," +
					opacity +
					")"
				);
			}
			throw new Error("Bad Hex");
		},
		hexToRgb(hex) {
			var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
			return result
				? `rgb(${parseInt(result[1], 16)}, ${parseInt(
						result[2],
						16
				  )}, ${parseInt(result[3], 16)})`
				: null;
		}
	}
});

new Vue({
	render: h => h(App),
	router,
	vuetify,
	store
}).$mount("#app");
