<template lang="pug">
	div.white
		alert(:content='content' ref='alert'  :type='type')
		div.text-center.primary--text.h1.brand-text-logo.pt-16
			| {{ $appTitle }}
		v-container(v-if="logged_in_to_personal_area" fluid).pa-0.fill-height.white
			v-row
				v-col
					h2.text-center.complementary--text Logging you out...
		v-container(
			v-else-if="user_logged_in_from_order_terminal"
			fluid
			).mt-20.text-h5.pa-0.primary-light
			v-row.white
				v-col.pb-2
					div(style='margin-top: 40px; padding-bottom: 58px')
						.mt-2.text-center.complementary--text
							NameAndRoomBadge(
							:name='this.$store.state.user.name'
							size='l')
							span &nbsp;thank you for your purchase!
						.text-center.text--secondary.mt-4 We'll log you out in {{timeTilLogOutLeft}} seconds
			GetAccountAd(
				v-if='showAccountAd'
				:admins='admins.data'
				:loading='admins.status===statuses.loading').mt-4
			v-row(v-else).primary-light.px-4.mt-16
				v-col.mb-6
					.text-h4.text-center.complementary--text  {{ feedbackQuestion }}
				v-col(style="padding-bottom: 170px")
					v-row
						v-col.mb-7(
							v-for="feedbackOption in feedbackOptions"
							:key="feedbackOption.id"
							cols="6")
							v-card(
								@click="logoutWithFeedback(feedbackOption.text)"
								outlined
								elevation="6"
								color='primary'
								ripple)
								v-card-text.pa-1
									v-img(:src="feedbackOption.src" height="250px" position="top center")
									div.text-center.mt-6.mb-5
										span.text-h4.primary-light--text.font-weight-medium {{ feedbackOption.text }}
</template>

<script>
import { mapGetters } from "vuex";
import { catchError } from "../../mixins/catchError";
import { dataLoader } from "../../mixins/dataLoader";
import Alert from "../Alert.vue";
import NameAndRoomBadge from "../Elements/NameAndRoomBadge.vue";
import GetAccountAd from "../Terminal/GetAccountAd.vue";

export default {
	mixins: [catchError, dataLoader],
	components: { Alert, NameAndRoomBadge, GetAccountAd },
	data() {
		return {
			dataToLoad: {
				url: "show_admins",
				saveTo: "admins"
			},
			admins: {},
			showAccountAd: false,
			logOutIntervalId: null,
			content: "",
			feedbackQuestion: "Liked the new colors?",
			feedbackOptions: [
				{
					id: 1,
					text: "Not really...",
					src: require("../../../public/img/feedback-bad.jpg")
				},
				{
					id: 2,
					text: "Absolutely!",
					src: require("../../../public/img/feedback-good.jpg")
				}
			],
			timeTilLogOut: 7,
			timeTilLogOutLeft: 7
		};
	},
	computed: {
		...mapGetters([
			"user_logged_in_from_order_terminal",
			"logged_in_to_personal_area",
			"user_is_admin"
		])
	},
	mounted() {
		if (this.logged_in_to_personal_area || this.$route.params.cancel) {
			this.logoutNoFeedback();
		} else {
			this.showAccountAd = !this.$store.state.user.email;
			if (this.showAccountAd) {
				this.timeTilLogOut = 18;
				this.timeTilLogOutLeft = 18;
			}
			const vm = this;
			this.logOutIntervalId = window.setInterval(function() {
				vm.timeTilLogOutLeft -= 1;
				if (vm.timeTilLogOutLeft <= 0) vm.logoutNoFeedback();
			}, 1000);
		}
	},
	watch: {
		logOutIntervalId: {
			handler: function(value) {
				console.log("Logout", value);
			}
		}
	},
	methods: {
		logoutNoFeedback() {
			clearInterval(this.logOutIntervalId);
			var vm = this;
			const loggedInFromTerminal = this.user_logged_in_from_order_terminal;
			vm.$axios
				.get("/api/logout")
				.then(() => {
					loggedInFromTerminal
						? (window.location.href = "/#/terminal")
						: (window.location.href = "/");
					vm.$store.commit("reset");

					if (loggedInFromTerminal) this.closeDoor();
				})
				.catch(error => {
					vm.catchError(error);
				});
		},
		logoutWithFeedback(feedbackText) {
			clearInterval(this.logOutIntervalId);
			const loggedInFromTerminal = this.user_logged_in_from_order_terminal;
			const vm = this;
			this.$axios
				.post("/api/logout", {
					feedbackType: this.feedbackQuestion,
					feedbackText: feedbackText
				})
				.then(() => {
					window.location.href = "/#/terminal";
					this.$store.commit("reset");

					if (loggedInFromTerminal) this.closeDoor();
				})
				.catch(error => {
					vm.catchError(error);
				});
		},
		async closeDoor() {
			await this.$axios_no_retry
				.get("http://localhost:5050/logout")
				.then(() => {
					console.log("Door closed");
				})
				.catch(() => {
					console.log("Door can't be closed");
				});
		}
	}
};
</script>

<style scoped>
.mt-20 {
	margin: 20px 0 0 0;
}

.invisible-text {
	color: rgba(0, 0, 0, 0);
	font-size: 0px;
}
</style>
