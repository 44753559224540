<template lang="pug">
v-card
	v-sheet(
		color="primary"
		).py-2.px-4.primary-light--text.rounded-t
		v-container
			v-row
				v-col(
					cols='12'
					sm='3'
					md='2'
					).align-center.d-flex.justify-center.justify-sm-end
					div.text-h6 Compare
				v-col(cols='6' sm='5' md='3' lg='2')
					v-select(
						v-model='type'
						:items='chart.parameters.type.items'
						@change='$emit("setChartParameter", {name: "Type", value: type})'
						background-color='primary-light'
						solo
						dense
						hide-details)
				v-col(cols='6' sm='4' md='3' lg='2').pl-0
					v-select(
						v-model='metric'
						:items='chart.parameters.metric.items'
						@change='$emit("setChartParameter", {name: "Metric", value: metric})'
						background-color='primary-light'
						solo
						dense
						hide-details)
				v-col(cols='6' sm='3' md='2').d-flex.align-center.justify-end
					div.text-h6.flex-shrink-0 to the last
				v-col(cols='6' sm='5' md='2').d-flex.align-center.justify-end
					v-select(
						v-model='timePeriod'
						:items='chart.parameters.timePeriod.items'
						item-text="title"
          	item-value="numberOfDays"
						@change='$emit("setChartParameter", {name: "TimePeriod", value: timePeriod})'
						background-color='primary-light'
						solo
						dense
						hide-details
						return-object)
	div(
		v-if='loading'
		:style="{ height: $vuetify.breakpoint.smAndDown ? '250px' : '200px' }"
		).d-flex.justify-center.align-center
		v-progress-circular(
			size='54'
			indeterminate
			color="primary")
	LineChartGenerator(
		v-show='!loading'
		:chart-data="chart.data"
		:chart-options="chart.options"
		:height='$vuetify.breakpoint.smAndDown ? 250 : 150')
</template>

<script>
import { Line as LineChartGenerator } from "vue-chartjs/legacy";
import {
	Chart as ChartJS,
	Title,
	Tooltip,
	Legend,
	LineElement,
	LinearScale,
	CategoryScale,
	PointElement
} from "chart.js";

ChartJS.register(
	Title,
	Tooltip,
	Legend,
	LineElement,
	LinearScale,
	CategoryScale,
	PointElement
);

export default {
	props: ["chart", "loading"],
	components: { LineChartGenerator },
	data() {
		return {
			type: this.$store.state.settings.chart.type,
			metric: this.$store.state.settings.chart.metric,
			timePeriod: this.chart.parameters.timePeriod.items.find(
				(item) => item.title === this.$store.state.settings.chart.timePeriod
			)
		};
	}
};
</script>
