<template lang="pug">
v-card(flat).py-3.body-1.primary--text.text--darken-3.white.px-sm-6
	div.body-1.text-sm-h6.text-center.pt-sm-10.pt-3 Paying with friends is simple
	div(
		v-for='(instruction, i) in instructions'
		:index="i"
		).mt-5.mb-3
		div.d-flex.justify-center
			NameAndRoomBadge(:name="instruction.title")
		div.d-flex.flex-wrap.justify-space-around.pt-2
			v-card(
				v-for='(rule, i) in instruction.rules'
				:key='i'
				min-width='100'
				max-width='130'
				flat
				style='border: solid var(--v-primary-lighten3) 1px'
				:class="{'body-2' : $vuetify.breakpoint.xs}"
				).primary--text.text--darken-3.pt-sm-4.pb-sm-3.py-2.mx-sm-3.white.body-2.px-4
				div.d-flex.justify-center.mb-sm-3.mb-1
					v-icon(
						color='primary lighten-2'
						size='30') {{ rule.icon }}
				.text-center.primary--text.caption.text-sm-body-2.text--lighten-2
					| {{ rule.text }}
	div.d-flex.justify-center.mt-sm-8.mt-4.pb-6.pt-2
		v-btn(
			@click="$emit('close')"
			color='primary')
			span Alright
</template>
<script>
import NameAndRoomBadge from "../../../Elements/NameAndRoomBadge.vue";
export default {
	name: "request-instruction",
	data() {
		return {
			instructions: [
				{
					title: "You",
					rules: [
						{
							icon: "schedule",
							text: "Can add friends to purchases up to 2 weeks old"
						},
						{
							icon: "bolt",
							text: "Get refunded instantly"
						},
						{
							icon: "paid",
							text: "Will be charged for unclaimed drinks"
						}
					]
				},
				{
					title: "Your friends",
					rules: [
						{ icon: "mail", text: "Receive an email notification" },
						{ icon: "schedule", text: "Have 2 weeks to respond" }
					]
				}
			]
		};
	},
	components: { NameAndRoomBadge }
};
</script>
