<template lang="pug">
v-container(fluid).pa-0.primary.primary-light--text
	v-row(no-gutters)
		v-col(cols='0' lg='1')
		v-col(cols='12' lg='10').text-center.mt-12.mb-4
			div.text-h5 What we help with
			//div.text-h6 Know who to ask
			div.d-sm-flex.justify-center.mt-8.mx-2.mx-sm-0
				v-card(
					v-for="(card, index) in cards"
					:key='index'
					).mx-3.mt-6
					div.text-h6.primary.lighten-3.white--text.break-word.py-3.px-4
						div.mt-2.mb-1
							div(
								:class="{'': card.logoFont}"
								) {{ card.caption }}
							//- div.subtitle-1.primary--text.text--lighten-5
							//- 	| {{ card.subcaption }}
					div.pr-2
						v-icon(
							color='primary lighten-3'
							size='80'
							).mt-n9 arrow_drop_down
						div.text-left.px-2.d-flex.justify-center.mt-n5.pb-5
							div
								div(v-for='responsibility in card.responsibilities').d-flex
									div
										v-icon(
											size='28'
											color='primary'
											).mx-3.my-3 {{ responsibility.icon }}
									div
										.mt-4.mb-1.primary--text.flex-grow-1.body-1
											| {{ responsibility.title }}
										.text--text.body-2
											| {{ responsibility.text }}
			v-col(cols='0' lg='1')
</template>

<script>
import OnlyVisibleToLoggedIn from "../../Landing/Elements/OnlyVisibleToLoggedIn";
import NameAndRoomBadge from "../../Elements/NameAndRoomBadge.vue";
export default {
	components: { OnlyVisibleToLoggedIn, NameAndRoomBadge },
	data() {
		return {
			cards: [
				{
					caption: "Goods2gether",
					logoFont: true,
					subcaption: "Platform",
					responsibilities: [
						{
							title: "Handle Purchases",
							text:
								"Members of your community can shop from their computer or mobile device",
							icon: "shopping_cart"
						},
						{
							title: "Keep Track of Everything",
							text: "Balances, purchases, top-ups",
							icon: "receipt_long"
						},
						{
							title: "Remind on Debts",
							text:
								"We'll remind everyone whose balance goes below the limit you set",
							icon: "notifications"
						}
					]
				},
				{
					caption: "You",
					subcaption: "Seller",
					responsibilities: [
						{
							title: "Provide Products",
							text:
								"From packaged goods to self-baked cookies. Offer anything you want",
							icon: "store"
						},
						{
							title: "Collect payments (if needed)",
							text:
								"You are not tied up to any payment system and there are no fees. Collect funds any way you want",
							icon: "price_check"
						}
					]
				}
			]
		};
	}
};
[];
</script>

<style scoped>
.rotate-90 {
	transform: rotate(90deg);
}
</style>
