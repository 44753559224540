<template lang="pug">
component(
	:is='$vuetify.breakpoint.smAndUp ? "v-dialog" : "v-bottom-sheet"'
	v-model='showRequestResponseDialog'
	transition="dialog-bottom-transition"
	:max-width="$vuetify.breakpoint.smAndUp ? '570' : ''"
	scrollable
	@click:outside='cancel'
	@keydown.esc='cancel')
	v-card(
		min-height="450"
		v-if='!requestStatus')
		alert(
			:content='content'
			ref='alert'
			:type='type')
		v-overlay(
			v-if='showInstruction && $vuetify.breakpoint.smAndUp'
			:absolute="true"
			:z-index='99999'
			:value="showInstruction"
			opacity='0.9'
			color='primary-light')
			v-card(
				color='primary-light'
				flat
			 	outlined
				).pb-0.mt-2.body-2.primary--text
				ResponseInstruction(
					:initiatorName='requestInitiator.name'
					@close='showInstruction=false')
		div.position-sticky.account-card-background-comp
			div.complementary-text--text.pl-4.pl-sm-6.d-flex.flex-nowrap.mt-4
				.text-h6.text-sm-h5.mt-sm-3.pr-4 What drinks are you paying for?
				div.d-flex.justify-end.align-center.flex-grow-1.mr-4
					v-btn(
						v-if='$vuetify.breakpoint.xs'
						@click="cancel"
						color='primary'
						outlined
						x-small
						fab)
						v-icon close
			v-card-subtitle.subtitle-1.text--text.pb-0.pl-sm-6.pt-2
				NameAndRoomBadge(
					:name='requestInitiator.name'
					:room='requestInitiator.room'
					size='s')
				span.ml-2 is asking
			div.mx-4.ml-sm-6.mt-6.pb-1
				div.d-flex.justify-space-between.text-secondary.text-sm-subtitle-2.caption
					div Drink
					div.mr-13 Quantity
		v-sheet(color='account-card-divider' height='2')
		v-card-text.pb-0.px-4.px-sm-6
			DrinkPicker(
				:drinks='selectedDrinks'
				:limits='limits'
				@reduceQuantityByOne="selectedDrinks.find(el => el.id === $event).quantity--"
				@increaseQuantityByOne="selectedDrinks.find(el => el.id === $event).quantity++"
				isMobile)
		v-sheet(color='account-card-divider' height='2')
		div.position-sticky-bottom.primary-light
			div(
				:class='{"justify-center": $vuetify.breakpoint.xs, "d-flex":!declineExpanded}'
				).mt-3.mb-5.align-center.justify-space-between
				div.d-flex.py-2.mx-0.ml-sm-3.body-2
					v-icon(color="complementary" :size='$vuetify.breakpoint.xs ? 20 : 24').ma-0.px-2 info
					div(
						:class='{"caption" : $vuetify.breakpoint.xs, "pt-1": $vuetify.breakpoint.smAndUp}'
						).d-flex.align-center.pr-2.complementary--text.text--darken-1.mb-1
						span(v-if='noDrinksSelected') Pick at least one drink
						span(v-else) You can't make changes once you confirm
				div.mr-5
					div(v-if='$vuetify.breakpoint.smAndUp')
						div(v-if="!declineExpanded").text-center.d-flex.align-center
							a(
								style='padding-top: 2px'
								@click='declineExpanded = true'
								).account-card-text-comp--text.body-2 Refuse to pay...
						v-card(
							v-else
							outlined
							icon='lightbulb'
							).mb-6.d-flex.justify-space-between.align-center.pa-3.ml-3
							div
								div.body-1 You can refuse to pay for this {{ request.askedToPay ? "PayMe" : "Split" }}
								div.text-secondary.body-2 We'll let {{ requestInitiator.name }} know
							v-btn(
								@click='decline'
								style='padding-top: 1px'
								color='primary'
								outlined
								small)
								v-icon(small).mr-1 block
								span(style='padding-top: 1px') refuse to pay
			v-card-actions.justify-center.mt-3
				div
					a(
						v-if='$vuetify.breakpoint.smAndUp'
						@click="showInstruction = !showInstruction")
						v-btn(
							color='text'
							text
							).pl-2.ma-0.pr-3.py-1
								div.d-flex
									v-icon(
										size='18'
										color='text') help
									div.caption.pl-1 What is going to happen?
				v-spacer(v-if='$vuetify.breakpoint.smAndUp')
				v-btn(
					v-if='$vuetify.breakpoint.smAndUp'
					@click="cancel"
					color="primary"
					text)
					span cancel
				.d-flex
					v-btn(
						:disabled='noDrinksSelected'
						:class="{ 'rounded-r-0' : $vuetify.breakpoint.xs }"
						@click='confirm'
						color="primary")
						v-icon check
						span Confirm
					v-btn(
						v-if='$vuetify.breakpoint.xs'
						@click="showDeclineRequestConfirmationDialog=true"
						color="primary"
						outlined).rounded-l-0
						v-icon block
						span Decline
			div(v-if='$vuetify.breakpoint.xs').d-flex.justify-center.mt-3.pb-4
				a(
					@click="showInstruction = true"
					).caption
					span.primary--text What is going to happen?
				v-bottom-sheet(
					v-model='showInstruction'
					transition="dialog-bottom-transition"
					@click:outside='showInstruction=false'
					@keydown.esc='showInstruction=false')
					ResponseInstruction(
						:initiatorName='requestInitiator.name'
						@close='showInstruction=false')
				v-bottom-sheet(
					v-model='showDeclineRequestConfirmationDialog'
					transition="dialog-bottom-transition"
					@click:outside='showDeclineRequestConfirmationDialog=false'
					@keydown.esc='showDeclineRequestConfirmationDialog=false')
					DeclineRequestConfirmationDialog(
						:requestInitiator='requestInitiator'
						@close='showDeclineRequestConfirmationDialog=false'
						@decline='showDeclineRequestConfirmationDialog=false; decline()')
	RequestSender(
		v-else
		:status='requestStatus'
		:statuses="requestStatuses"
		@cancel='cancel')
</template>
<script>
import NameAndRoomBadge from "../../../Elements/NameAndRoomBadge.vue";
import ResponseInstruction from "./ResponseInstruction.vue";
import DeclineRequestConfirmationDialog from "./DeclineRequestConfirmationDialog.vue";
import RequestSender from "../../../Helpers/RequestSender.vue";
import Alert from "../../../Alert.vue";
import { catchError } from "../../../../mixins/catchError";
import DrinkPicker from "../Helpers/DrinkPicker.vue";
import { VDialog, VBottomSheet } from "vuetify/lib";

export default {
	name: "request-response-dialog",
	props: ["request", "requestInitiator"],
	mixins: [catchError],
	components: {
		Alert,
		RequestSender,
		ResponseInstruction,
		NameAndRoomBadge,
		DrinkPicker,
		VDialog,
		VBottomSheet,
		DeclineRequestConfirmationDialog
	},
	data() {
		return {
			declineExpanded: false,
			requestStatus: null,
			requestStatuses: {
				sendingRequest: {
					title: "Hold tight...",
					text: "Responding to a split"
				},
				requestCompleted: {
					title: "Done",
					text: "Thank you for being a responsible friend!"
				},
				requestFailed: {
					title: "Oups...",
					text: "Error occured",
					error: null,
					supportInformation: {
						title: "You can always reach out to us at:",
						link: {
							text: `support@${this.$emailDomain}`,
							url: `mailto:support@${this.$emailDomain}?subject=Error%20with%20Split%20Creation`
						}
					}
				}
			},
			selectedDrinks: [],
			showRequestResponseDialog: true,
			limits: [],
			showInstruction: false,
			showDeclineRequestConfirmationDialog: false
		};
	},
	computed: {
		noDrinksSelected() {
			return !this.selectedDrinks.some(drink => drink.quantity > 0);
		}
	},
	methods: {
		confirm() {
			this.requestStatus = this.requestStatuses.sendingRequest;
			let data = {
				transaction_id: this.request.id,
				drinks: this.selectedDrinks
					.filter(drink => drink.quantity > 0)
					.map(drink => ({ id: drink.id, quantity: drink.quantity }))
			};
			this.sendResponse(data);
		},
		decline() {
			this.requestStatus = this.requestStatuses.sendingRequest;
			let data = {
				transaction_id: this.request.id,
				drinks: []
			};
			this.sendResponse(data);
		},
		sendResponse(data) {
			this.$axios
				.post("/api/pay_split", data)
				.then(() => {
					this.requestStatus = this.requestStatuses.requestCompleted;
					this.$emit("reloadAllData");
				})
				.catch(error => {
					this.requestStatuses.requestFailed.error = error.response.data.error;
					this.requestStatus = this.requestStatuses.requestFailed;
				});
		},
		reset(request) {
			this.request.unclaimedDrinks.forEach(drink => {
				this.limits.push(drink.quantity);
			});
			this.selectedDrinks = [];
			request.unclaimedDrinks.forEach(drink =>
				this.selectedDrinks.push({ ...drink, quantity: 0 })
			);
		},
		cancel() {
			this.$emit("cancel");
		}
	},
	mounted() {
		this.reset(this.request);
	}
};
</script>
<style lang="scss" scoped>
.position-sticky {
	position: sticky;
	top: 0;
	z-index: 999;
}

.position-sticky-bottom {
	position: sticky;
	bottom: 0;
	z-index: 999;
}

.table-fixed {
	table-layout: fixed;
}
</style>
