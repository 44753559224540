<template lang="pug">
	div
		.text-h6.text-sm-h5.text-md-h4.mb-4.primary--text
				| Where to get drinks?
		div
			p
				| We store drinks in a room called Getränkeraum (G-Raum).
			p
				| There are currently two G-Raums in Biederstein but only one is equipped with
				span.primary--text.ml-1 BiederDrinks
				|.
				| It's located on the underground floor in House 2. It's the first room you see when you go down the staircase.
</template>

<script>
export default {
	created() {},
	methods: {}
};
</script>

<style scoped></style>
