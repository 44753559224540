<template lang="pug">
div
	//v-btn(absolute x-small).caption {{ $vuetify.breakpoint.name }}
	div.mb-10.mt-3
		v-btn(
			v-if='settingsPage > 0'
			@click='settingsPage = 0'
			color='primary'
			fab
			small
			outlined
			absolute).ml-4.mt-n1
			v-icon chevron_left
		h4.background--text.text--darken-3.brand-text-logo.text-center {{ $appTitle }}
	.d-flex.justify-center.background
		Home(
			v-if='page === 0'
			:balances='balances'
			:statuses='statuses'
			@openFeedbackDialog='openFeedbackDialog'
			@reloadData='$emit("reloadData")'
		)
		RequestsMobile(
			v-if='page === 1'
			:requests='requests'
			:captions='captions.requests'
			:requestsReady='requestsReady'
			:showNoRequestsCard='showNoRequestsCard'
			:requestsWithLoadMoreCard='requestsWithLoadMoreCard'
			:statuses='statuses'
			:height='requestCardHeight'
			@showRequestResponseDialog='showRequestResponseDialog'
			@loadMoreRequests='$emit("loadMoreRequests")'
			@reloadRequests='$emit("reloadRequests")')
		PurchasesMobile(
			v-if='page === 2'
			:purchases='purchases'
			:purchasesWithLoadMoreCard='purchasesWithLoadMoreCard'
			:purchasesReady='purchasesReady'
			:showNoPurchasesCard='showNoPurchasesCard'
			:statuses='statuses'
			@showSplitDialog='showSplitDialog'
			@reportPurchaseProblem='reportPurchaseProblem'
			@showRequestDetailsDialog='showRequestDetailsDialog'
			@loadMorePurchases='$emit("loadMorePurchases")'
			@reloadPurchases='$emit("reloadPurchases")')
		Settings(
			v-else-if='page === 3'
			:settingsPage='settingsPage'
			:settingsSections='settingsSections'
			@setSettingsPage='setSettingsPage($event)'
			@saveContactInformation='saveContactInformation($event)')
	v-bottom-navigation(
		v-model="page"
		background-color="primary"
		dark
		fixed
		grow)
		v-btn(
			v-for='(item, i) in bottomNavigation'
			:key='i'
			color='primary-light'
			text)
			span(
				:class="{'primary-light--text' : page === i,\
								'primary--text text--lighten-4' : page !== i,}"
			) {{ item.text }}
			v-badge(
				v-if='item.text === "Requests" && requests.data && requests.data.length > 0'
				offset-x='-10'
				offset-y='-17'
				dot
				color='primary-light')
			v-icon(:color="page === i ? 'primary-light' : 'primary lighten-4'") {{ item.icon }}
</template>
<script>
import { catchError } from "@/mixins/catchError";
import NameAndRoomBadge from "../../Elements/NameAndRoomBadge.vue";
import Pages from ".";

export default {
	components: {
		...Pages,
		NameAndRoomBadge
	},
	mixins: [catchError],
	props: [
		"loading",
		"purchases",
		"requests",
		"persons",
		"settingsSections",
		"balances",
		"selectedPurchase",
		"captions",
		"statuses",
		"requestsWithLoadMoreCard",
		"purchasesWithLoadMoreCard",
		"showNoPurchasesCard",
		"showNoRequestsCard",
		"purchasesReady",
		"requestsReady"
	],
	data() {
		return {
			requestCardHeight: 196,
			page: 0,
			bottomNavigation: [
				{ text: "Home", icon: "info" },
				{ text: "Requests", icon: "people" },
				{ text: "Purchases", icon: "local_mall" },
				{ text: "More", icon: "more_horiz" }
			],
			settingsPage: 0
		};
	},
	watch: {
		page: {
			handler: function() {
				this.settingsPage = 0;
			}
		}
	},
	methods: {
		saveContactInformation(contacts) {
			let contactsToSave = {};
			if (contacts.email != this.$store.state.user.email)
				contactsToSave.email = contacts.email;
			if (contacts.room != this.$store.state.user.room)
				contactsToSave.room = contacts.room;
			this.$emit("saveContactInformation", contactsToSave);
		},
		showSplitDialog(index) {
			this.$emit("showSplitDialog", index);
		},
		showRequestDetailsDialog(index) {
			this.$emit("showRequestDetailsDialog", index);
		},
		showRequestResponseDialog(request) {
			this.$emit("showRequestResponseDialog", request);
		},
		reportPurchaseProblem(purchaseId) {
			this.$emit("reportPurchaseProblem", purchaseId);
		},
		openFeedbackDialog() {
			this.$emit("openFeedbackDialog");
		},
		setSettingsPage(pageIndex) {
			this.settingsPage = pageIndex;
		}
	}
};
</script>
<style lang="scss"></style>
