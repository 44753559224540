<template lang="pug">
v-sheet(
	height='90vh'
	min-height='800px'
	style='position: relative;'
	).d-flex.justify-center.align-center.bg-animation
	div
		Title(
			:textTop='"Pick"'
			:textBottom='"Your style"'
			:align='"left"'
			:class="{'extra-margin': $vuetify.breakpoint.md}"
			).ml-7.ml-sm-0
		PurchaseCard(
			v-if='$vuetify.breakpoint.mdAndUp'
			:purchase="purchase").purchase-card
		ThemePicker(
			landing='true'
			:row='$vuetify.breakpoint.smAndDown'
			:preventSendingToServer='!user_logged_in')
		Sidebar(
			v-if='$vuetify.breakpoint.mdAndUp'
			:height="700"
			:balances='balances'
			:statuses='statuses'
			).sidebar
</template>

<script>
import Title from "./Title.vue";
import ThemePicker from "@/components/Helpers/ThemePicker.vue";
import PurchaseCard from "@/components/Account/Desktop/Purchase/PurchaseCard.vue";
import Sidebar from "@/components/Account/Desktop/Sidebar/Sidebar.vue";
import AccountSettingsDialog from "@/components/Account/Desktop/AccountSettingsDialog.vue";
import AdminSettings from "@/components/Admin/Settings/AdminSettings.vue";
import { mapGetters } from "vuex";

export default {
	data() {
		return {
			settingsSections: [
				{
					text: "Interface",
					icon: "brush",
					caption: "Choose design that suits you"
				},
				{
					text: "Contacts",
					icon: "badge",
					caption: "Make sure those are up to date"
				},
				{ text: "Password", icon: "password", caption: "Set new password" },
				{ text: "Emails", icon: "email", caption: "" }
			],
			statuses: {
				loading: "loading",
				success: "finished",
				error: "error"
			},
			balances: {
				data: [
					{
						amount: 24.3,
						disabled: false,
						groupId: 1,
						groupName: "Biederstein Haus 1/2",
						keyLocation: "TODO",
						pending_amount: -2.8
					}
				],
				status: "finished"
			},
			purchase: {
				askedToPay: false,
				author: { id: 2, name: "Martin Luther", room: "1211" },
				deadline: "Fri, 15 Jul 2022 18:44:05 GMT",
				location: "Biederstein Haus 1/2",
				ownAmount: 1.5,
				ownDrinks: [
					{
						drink: "Tilmans die Weiße",
						id: 8,
						price: 1.5,
						quantity: 1,
						sum: 1.5
					},
					{ drink: "Clubmate", id: 11, price: 0.8, quantity: 1, sum: 0.8 }
				],
				paidTimestamp: "Wed, 06 Jul 2022 08:36:40 GMT",
				splitFinishedTimestamp: "Wed, 06 Jul 2022 08:36:40 GMT",
				splitInitiatedTimestamp: "Fri, 01 Jul 2022 18:44:05 GMT",
				splitResponseStatus: "paid",
				timestamp: new Date().toUTCString(),
				currentPage: 0
			}
		};
	},
	computed: {
		...mapGetters(["user_logged_in"])
	},
	components: {
		Title,
		ThemePicker,
		PurchaseCard,
		Sidebar,
		AccountSettingsDialog,
		AdminSettings
	}
};
</script>

<style scoped>
.extra-margin {
	margin-top: 400px;
}

.purchase-card {
	position: absolute;
	right: 15px;
	top: 10px;
	transform: rotate(-20deg);
}

.sidebar {
	position: absolute;
	left: -174px !important;
	top: 10vh !important;
	transform: rotate(30deg);
}

.bg-animation {
	background-image: url(bg.svg);
	background-size: cover;
}
</style>
