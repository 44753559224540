<template lang="pug">
	div.background.fill-height
		alert(:content='content' ref='alert'  :type='type')
		v-row.px-10
			v-col.d-flex.justify-center
				v-data-table(
					v-if='drinks.tableOptions'
					:headers="headers"
					:items="drinks.data"
					:options.sync="drinks.tableOptions"
					:loading="!drinks || drinks.status === statuses.loading"
					:loading-text="messages.loadingText"
					item-key="id"
					:search="drinks.tableOptions.search"
					:server-items-length="drinks.totalItems"
					).pt-1.maxWidth.elevation-5
					template(v-slot:top)
						v-toolbar(flat).mb-6
							v-container
								v-row.mt-3
									v-col(cols='6' sm='7' md='8').d-flex.align-center
										v-btn(
											@click='showAddAndEditDialog({})'
											color='primary'
											rounded
											big)
											v-icon mdi-plus
											span Add drink
									v-col(cols='6' sm='5' md='4')
										v-text-field(
											v-model='drinks.tableOptions.search'
											:append-icon='drinks.tableOptions.search ? "mdi-magnify" : undefined'
											:append-outer-icon='drinks.tableOptions.search ? "mdi-close-circle-outline" : undefined'
											@click:append-outer='drinks.tableOptions.search = ""; updateEntityAndLoadData(drinks, "drinks")'
											@click:append='updateEntityAndLoadData(drinks, "drinks")'
											@keyup='startSearchTimer(drinks, "drinks")'
											label='Search'
											single-line
											flat
											solo
											hide-details)
					template(v-slot:item.price='item')
						span {{ formatMonetaryValue(item.value) }}
					template(v-slot:item.actions='{ item }')
						v-icon.mr-2(@click='showAddAndEditDialog(item)')
							| mdi-pencil
					template(v-slot:no-data)
						| {{ messages.nothingFound }}
		edit-drink-dialog(
			v-if='addAndEditDialog.show'
			:showDialog.sync='addAndEditDialog.show'
			:drink='addAndEditDialog.selectedItem'
			@save='saveEdit($event)'
			@put='putEntity($event)'
			@cancel='addAndEditDialog.show = false'
			hide-overlay)
</template>

<script>
import Alert from "@/components/Alert.vue";
import EditDrinkDialog from "./EditDrinkDialog.vue";
import { dataLoader } from "@/mixins/dataLoader";
import { catchError } from "@/mixins/catchError";
import { showAddEditDialog } from "@/mixins/showAddEditDialog";
import { addAndUpdateEntity } from "@/mixins/addAndUpdateEntity";

export default {
	components: {
		Alert,
		EditDrinkDialog
	},
	mixins: [dataLoader, catchError, showAddEditDialog, addAndUpdateEntity],
	data() {
		return {
			dataToLoad: [
				{
					url: "drinks",
					table: true,
					tableOptions: {
						itemsPerPage: 10
					}
				}
			],
			drinks: {},
			headers: [
				{
					text: "Name",
					align: "center",
					value: "name",
					sortable: true,
					groupable: false
				},
				{
					text: "Barcode",
					align: "center",
					value: "barcode",
					sortable: true,
					groupable: false
				},
				{
					text: "Price",
					align: "center",
					value: "price",
					sortable: true,
					groupable: false
				},
				{
					text: "",
					align: "center",
					value: "actions",
					sortable: false,
					groupable: false
				}
			],
			entityName: "drinks",
			messages: {
				addSuccess: "Drink added successfully",
				editSuccess: "Drink updated successfully",
				loadingText: "Loading list of drinks... Please wait",
				nothingFound:
					"There are no drinks yet. You can use the button above to add one."
			}
		};
	}
};
</script>
<style lang="scss" scoped></style>
