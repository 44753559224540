<template lang="pug">
v-card(
	color='primary-light').request-card-mobile.mx-2
	v-sheet(
		color='primary-light'
		rounded
		style='border: solid var(--v-primary-light-darken1) 0px !important'
		).primary--text.pa-3
		div.d-flex.justify-end
			//- v-tooltip(
			//- 	top
			//- 	nudge-right="50"
			//- 	open-delay="200"
			//- 	color="complementary lighten-1")
			//- 	template(v-slot:activator="{ on, attrs }")
			//- 		span(
			//- 			v-bind="attrs"
			//- 			v-on="on"
			//- 			).text-secondary.caption
			//- 			| Purchased {{ convertTimestampToDaysAgo(request.timestamp) }}
				template
					span {{ convertTimestampToReadableDayOfTheWeekDateTime(request.timestamp) }}

		div.mt-1.d-flex.align-center.justify-center
			PayMeBadge(v-if="request.askedToPay")
			SplitBadge(v-else)
			div.body-1.mx-1.text--text by
			NameAndRoomBadge(
				:name='request.author.name'
				:room='request.author.room'
				size='s')
		v-sheet(
			style='border: solid var(--v-primary-light-darken1) 1px !important'
			color='primary-light'
			rounded).primary--text.pa-2.fill-height.my-3
			template(v-if="request.friends.paid.length > 0")
				div.body-2.ml-1 Friends who already paid:
				div.d-flex
					div(
						v-for="(friend, index) in request.friends.paid.slice(0, 2)"
						:key='index'
						).my-2
						NameAndRoomBadge(
							:name='friend.person.name'
							:room='friend.person.room'
							size='xs').pr-1
				div.ma-1(
					v-if="request.friends.paid.length > 2"
					color="primary lighten-2"
					).caption +{{ request.friends.paid.length - 2 }} more
			div(v-else).body-2.pt-2.mb-3.primary-light--text.text--darken-4
				div {{ captions.title }}
				div {{ request.author.name }} {{ captions.subtitle }}
			v-card-actions.primary-light.justify-center
				v-btn(
					@click="showRequestResponseDialog(request)"
					color="primary"
					large
					) Respond
		div.caption.text-center.primary-light--text.text--darken-3.d-flex.align-center.justify-center
			v-icon(
					size='17'
					color='primary-light darken-3'
					).mr-1 schedule
			div(style='padding-bottom: 1px') {{ request.timeLeftToPay.topLine }} {{ request.timeLeftToPay.bottomLine }}
</template>
<script>
import NameAndRoomBadge from "@/components/Elements/NameAndRoomBadge.vue";
import Badges from "@/components/Elements/Badges/payMeAndSplitBadges.js";
export default {
	components: { ...Badges, NameAndRoomBadge },

	props: ["request", "captions"],
	data() {
		return {};
	},
	methods: {
		showRequestResponseDialog(request) {
			this.$emit("showRequestResponseDialog", request);
		}
	},
	computed: {}
};
</script>
<style lang="scss">
.request-card-mobile {
	max-width: 380px;
	min-width: 280px;
}
</style>
