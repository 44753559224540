<template lang="pug">
.d-flex.justify-center.mt-16.pb-16.pb-sm-0.white.pt-6
	div.text-center.pa-2
		div.text-sm-h5.text-h6 Something missing?
		div.text-sm-subtitle-1.subtitle-2.text--text
			|
			| If there is anything we can do to better suit your needs,
			|
			| please let us know. We are very responsive to your feedback.
		.d-flex.justify-center
			v-btn(
				@click='$emit("showGetStartedDialog")'
				color="primary"
				).mt-4 contact us
</template>
<script>
export default {
	components: {},
	props: [],
	data() {
		return {};
	},
	methods: {},
	computed: {}
};
</script>
<style lang="scss"></style>
