<template lang="pug">
v-sheet(
	min-width='312'
	max-width='500'
	color='background').mx-auto
	div.d-flex.justify-center
		LoadingFailedCard(
			v-if='purchases.status === statuses.error'
			:error='purchases.error'
			:entityName='"purchases"'
			@reload='$emit("reloadPurchases")')
		PurchaseSkeleton(v-else-if='purchases.status === statuses.loading')
		NoPurchasesCard(v-else-if='showNoPurchasesCard')
	RecycleScroller(
		v-show='purchasesReady'
		class="scroller"
		:items="purchasesWithLoadMoreCard"
		key-field="id"
		v-slot="{ item, index }").pb-14
		template(v-if='purchases.data')
			div.px-2
				PurchaseCard(
					v-if='index <= purchases.data.length - 2'
					:purchase='item'
					:index="index"
					@goToPreviousPage="item.currentPage--"
					@goToNextPage="item.currentPage++"
					@showRequestDetailsDialog='$emit("showRequestDetailsDialog", index)'
					@showSplitDialog='$emit("showSplitDialog", index)'
					@reportPurchaseProblem='$emit("reportPurchaseProblem", index)')
				//div(v-else-if='purchases.itemsPerLoad && purchases.data.length < purchases.totalItems') {{ index }}
				LoadMoreCard(
					v-else-if='purchases.itemsPerLoad && purchases.data.length < purchases.totalItems'
					:statuses='statuses'
					:status='purchases.loadMoreStatus'
					:entityName='"purchases"'
					@loadMore='$emit("loadMorePurchases")')
</template>
<script>
import PurchaseCard from "@/components/Account/Desktop/Purchase/PurchaseCard.vue";
import LoadMoreCard from "@/components/Account/Desktop/Elements/LoadMoreCard.vue";
import LoadingFailedCard from "@/components/Account/Desktop/Elements/LoadingFailedCard.vue";
import PurchaseSkeleton from "@/components/Account/Desktop/Purchase/PurchaseSkeleton.vue";
import NoPurchasesCard from "@/components/Account/Desktop/Purchase/NoPurchasesCard.vue";
export default {
	props: [
		"purchases",
		"captions",
		"purchasesWithLoadMoreCard",
		"statuses",
		"purchasesReady",
		"showNoPurchasesCard"
	],
	data() {
		return {};
	},
	methods: {
		showSplitDialog(request) {
			this.$emit("showSplitDialog", request);
		},
		reportPurchaseProblem(purchaseId) {
			this.$emit("reportPurchaseProblem", purchaseId);
		},
		showRequestDetailsDialog(index) {
			this.$emit("showRequestDetailsDialog", index);
		}
	},
	computed: {},
	components: {
		PurchaseCard,
		LoadMoreCard,
		LoadingFailedCard,
		PurchaseSkeleton,
		NoPurchasesCard
	}
};
</script>
<style lang="scss"></style>
