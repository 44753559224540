<template lang="pug">
v-alert(
	:type='isMobile ? "" : "info"'
	color="info"
	text
	).mt-5
	div.text-h6 Email change is pending
	v-card-text
		| You've requested to change your Account email to:
		|
		b {{ this.$store.state.user.emailCandidate }}.
		|
		div
			br
			| Go to your mailbox to confirm your new email. Until you confirm, the old address (
			b {{this.$store.state.user.email}}
			| ) will be used
</template>
<script>
export default {
	props: ["isMobile"]
};
</script>
