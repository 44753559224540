<template lang="pug">
v-sheet(
	:max-width='$vuetify.breakpoint.width <= tabletBreakpointSize ? 350 : 700'
	:min-width='$vuetify.breakpoint.width <= tabletBreakpointSize ? 300 : 600'
	color='background').mx-auto
	div.d-flex.justify-center
		LoadingFailedCard(
			v-if='requests.status === statuses.error'
			:error='requests.error'
			:entityName='"requests"'
			@reload='$emit("reloadRequests")')
		RequestSkeletonMobile(v-else-if='requests.status === statuses.loading')
		NoRequestsCard(
			v-else-if='showNoRequestsCard'
			:height='height')
	RecycleScroller(
		v-show='requestsReady'
		class="scroller"
		:items="requestsWithLoadMoreCard"
		:item-size="$vuetify.breakpoint.width <= tabletBreakpointSize ? 280 : 210"
		key-field="id"
		v-slot="{ item, index }"
		).pb-10
		template(v-if='requests.data')
			div(
				v-if='index <= requests.data.length - 1'
				).px-6.d-flex.justify-center
				RequestCardMobile(
					v-if='$vuetify.breakpoint.width <= tabletBreakpointSize'
					:captions='captions.firstToPay'
					:request='item'
					@showRequestResponseDialog='$emit("showRequestResponseDialog", item)')
				RequestCardTablet(
					v-else
					:captions='captions.firstToPay'
					:request='item'
					@showRequestResponseDialog='$emit("showRequestResponseDialog", item)')
			LoadMoreCard(
				v-else-if='requests.itemsPerLoad && requests.data.length < requests.totalItems'
				:statuses='statuses'
				:status='requests.loadMoreStatus'
				:entityName='"requests"'
				@loadMore='$emit("loadMoreRequests")')
</template>
<script>
import NameAndRoomBadge from "@/components/Elements/NameAndRoomBadge.vue";
import RequestCardMobile from "./RequestCardMobile";
import RequestCardTablet from "./RequestCardTablet";
import LoadMoreCard from "@/components/Account/Desktop/Elements/LoadMoreCard.vue";
import LoadingFailedCard from "@/components/Account/Desktop/Elements/LoadingFailedCard.vue";
import NoRequestsCard from "@/components/Account/Desktop/Request/NoRequestsCard.vue";
import RequestSkeletonMobile from "@/components/Account/Mobile/Request/RequestSkeletonMobile.vue";

export default {
	components: {
		NameAndRoomBadge,
		RequestCardMobile,
		RequestCardTablet,
		LoadMoreCard,
		LoadingFailedCard,
		NoRequestsCard,
		RequestSkeletonMobile
	},
	props: [
		"requests",
		"requestsWithLoadMoreCard",
		"showNoRequestsCard",
		"requestsReady",
		"captions",
		"statuses",
		"height"
	],
	data() {
		return {
			tabletBreakpointSize: 624
		};
	},
	methods: {
		showRequestResponseDialog(request) {
			this.$emit("showRequestResponseDialog", request);
		}
	},
	computed: {}
};
</script>
<style lang="scss"></style>
