<template lang="pug">
div.pa-8
	Buttons
	h4.text-h4(id="gdpr-terms-and-conditions") Terms and Conditions
	p
		| Please read these Terms and Conditions (“Terms”, “Terms and Conditions”) carefully before using the website {{ websiteURL }} (the “Service”) operated by Aleksandr Cherkashin and Vadim Goryainov (“us”, “we”, or “our”).
	p
		| Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.
	p
		| By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.
	h5.text-h5 Links To Other Web Sites
	p
		| Our Service may contain links to third-party web sites or services that are not owned or controlled by us.
	p
		| We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.
	p
		| We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.
	h5.text-h5 Termination
	p
		| We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
	p
		| All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
	h5.text-h5 Governing Law
	p
		| These Terms shall be governed and construed in accordance with the laws of Germany, without regard to its conflict of law provisions.
	p
		| Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.
	h5.text-h5 Changes
	p
		| We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
	p
		| By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.
	h4.text-h4(id="gdpr-privacy-policy") Privacy Policy for {{ $appTitle }}
	p
		| At {{ websiteURL }}, accessible from {{ websiteURL }}, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by {{ websiteURL }} and how we use it.
	p
		| If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us at support@{{ $emailDomain }}. Our Privacy Policy was generated with the help of 
		a(href='https://www.gdprprivacypolicy.net/') GDPR Privacy Policy Generator
	h5.text-h5 General Data Protection Regulation (GDPR)
	p We are a Data Controller of your information.
	p
		| {{ $appTitle }} legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Information we collect and the specific context in which we collect the information:
	ul
		li {{ $appTitle }} needs to perform a contract with you
		li You have given {{ $appTitle }} permission to do so
		li Processing your personal information is in {{ $appTitle }} legitimate interests
		li {{ $appTitle }} needs to comply with the law
	p
		| {{ $appTitle }} will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.
	p
		| If you are a resident of the European Economic Area (EEA), you have certain data protection rights. If you wish to be informed what Personal Information we hold about you and if you want it to be removed from our systems, please contact us.
	p In certain circumstances, you have the following data protection rights:
	ul
		li The right to access, update or to delete the information we have on you.
		li The right of rectification.
		li The right to object.
		li The right of restriction.
		li The right to data portability
		li The right to withdraw consent
	h5.text-h5 Log Files
	p
		| {{ websiteURL }} follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services&apos; analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, and tracking users&apos; movement on the website.
	h5.text-h5 Cookies and Web Beacons
	p
		| Like any other website, {{ websiteURL }} uses &apos;cookies&apos;. These cookies are used to store information including visitors&apos; preferences, and the pages on the website that the visitor accessed or visited. We use the following types of cookies:
	p
		b Session Cookies
	p
		| Session cookies are neccessary to keep you logged in and thus crucial for the functionality of the website. They are deleted when you log out. They help to keep track of your progress so you don&apos;t have to enter the same information more than once.
	h5.text-h5 Children&apos;s Information
	p
		| Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
	p
		| If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately at suppoert@biedrstinks.de and we will do our best efforts to promptly remove such information from our records.
	h5.text-h5 Online Privacy Policy Only
	p
		| Our Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in {{ websiteURL }}. This policy is not applicable to any information collected offline or via channels other than this website.
	h5.text-h5 Consent
	p
		| By using our website, you hereby consent to our Privacy Policy and agree to its terms.
	br
	p
		i
			| Generated using 
		a(href='https://www.gdprprivacypolicy.net') GDPR Privacy Policy
	hr
	br
	h4.text-h4(id="gdpr-legal-notice") Legal Notice for {{ $appTitle }}
	h5.text-h5 Address
	p
		| Aleksandr Cherkashin, Vadim Goryainov
		br
		| Biedersteiner Straße 24
		br
		| 80805 München
		br
		| Germany
	h5.text-h5 Contact
	p
		| support@{{ $emailDomain }}
	h5.text-h5 Disclaimer
	p
		| Despite careful content control of our website we assume no liability on the content of external links. The originator of the linked pages is exclusively responsible for the content of these pages.
	hr
	Buttons


</template>
<script>
import Buttons from "./Buttons.vue";
export default {
	components: { Buttons },
	computed: {
		websiteURL() {
			return this.isBiederDrinks ? "biederdrinks.digital" : "goods2gether.com";
		}
	}
};
</script>
