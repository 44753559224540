<template lang="pug">
	div
		.text-h6.text-sm-h5.text-md-h4.mb-4.primary--text Why do I need a complicated password?
		div
			| It's not to annoy you, we promise.
		br
		div
			span.primary--text.mr-1 {{ $appTitle }}
			| deals with your personal and financial information and it's in everyone's
			|	best interest to keep it private.
		br
		div
			| A password that is too simple makes
			| your account vulnerable.
</template>

<script>
export default {
	created() {},
	methods: {}
};
</script>

<style scoped></style>
