<template lang="pug">
div.white
	v-card(tile flat max-width='400').pa-4.py-8.mx-auto
		div(v-for='functionality in functionalities')
			.d-flex.my-4
				v-sheet(min-width="120").primary--text.text-h6.d-flex.align-center
					div {{  functionality.title }}
				div.text--text {{  functionality.text }}


</template>
<script>
export default {
	components: {},
	props: [],
	data() {
		return {
			functionalities: [
				{
					title: "You",
					text: "Run your local 'Shop', offering anything you want"
				},
				{
					title: "«Clients»",
					text: "Can puchase things with their computer or a mobile device"
				}
			]
		};
	},
	methods: {},
	computed: {}
};
</script>
<style lang="scss"></style>
