export const showAddEditDialog = {
	data() {
		return {
			selectedItem: {},
			isAddDialog: false,
			show: false
		};
	},
	methods: {
		edit(item) {
			this.selectedItem = item;
			this.isAddDialog = false;
			this.show = true;
		},
		showNewItemDialog() {
			this.selectedItem = { active: "Yes" };
			this.isAddDialog = true;
			this.show = true;
		}
	}
};
