<template lang="pug">
	v-row.mt-0
		v-col.d-flex.justify-center
			v-card.pb-2
				v-card-title Last to use the box
				v-card-subtitle We only show three last users
				v-card(
					v-for='loginEntry in loginHistory.data'
					:key='loginEntry.id'
					).mx-6.px-4.py-3.my-2
					h6.my-2.pr-2.font-weight-bold
						| {{ loginEntry.name }} ({{ formatRoomOrWGNumber(loginEntry.room, true) }})
						| – {{ loginEntry.timestamps.length }}
						| chip scan{{ loginEntry.timestamps.length > 1 ? 's' : '' }}
					ul.mb-2
						li(
							v-for='(timestamp, i) in loginEntry.timestamps'
							:key='i')
							| {{ convertTimestampToReadableDayOfTheWeekDateTime(timestamp) }}
</template>
<script>
import { dataLoader } from "@/mixins/dataLoader";
export default {
	mixins: [dataLoader],
	data() {
		return {
			dataToLoad: { url: "login_history", saveTo: "loginHistory" },
			loginHistory: {}
		};
	}
};
</script>
