<template lang="pug">
	div
		.text-h6.text-sm-h5.text-md-h4.mb-4.primary--text Why do you need my email?
		div
			p
				| 1. To manage your account.
				| With email you can easily recover access to your account if you forget your password
			p
				| 2. Since
				span.primary--text.mx-1 {{ $appTitle }}
				| deals with finances, it's important to have away to get in touch with you.
			p.mb-0
				| 3.
				span.primary--text.mx-1 {{ $appTitle }}
				| is a quickly-developing platform and we would love to update you on the new features and changes to the service
			div.mt-1.mb-4.body-2.text--text This is optional and can be disabled in Account Settings
		v-alert(
			outlined
			text
			type='info'
			icon='verified_user'
			).mb-0
			span.text-body-2.text-sm-body-1 We don't share your email nor any other personal information with anyone
</template>

<script>
export default {
	created() {},
	methods: {}
};
</script>

<style scoped></style>
