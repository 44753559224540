export const addAndUpdateEntity = {
	data() {
		return {
			addAndEditDialog: {
				show: false,
				selectedItem: {}
			}
		};
	},
	methods: {
		showAddAndEditDialog(item) {
			this.addAndEditDialog.selectedItem = item;
			this.addAndEditDialog.show = true;
		},
		saveEdit(obj, functionsToExecuteOnFinish) {
			if (this.requestStatuses)
				this.requestStatus = this.requestStatuses.sendingRequest;
			this.addAndEditDialog.show = false;
			this.loading = true;
			const vm = this;
			this.$axios
				.post("/api/" + vm.entityName + "/" + obj.id, obj)
				.then(response => {
					obj = Object.assign({}, obj, response.data);
					var index = vm[vm.entityName].data.findIndex(
						element => element.id == obj.id
					);
					// trigger reactivity
					vm.$set(vm[vm.entityName].data, index, obj);
					vm.content = vm.messages.editSuccess;
					vm.type = "success";
					this.$refs.alert.showAlert();
					if (this.requestStatuses)
						this.requestStatus = this.requestStatuses.requestCompleted;
				})
				.catch(error => {
					vm.catchError(error);
					if (this.requestStatuses) {
						this.requestStatuses.requestFailed.error =
							error.response.data.error;
						this.requestStatus = this.requestStatuses.requestFailed;
					}
				})
				.finally(() => {
					vm.loading = false;
					if (functionsToExecuteOnFinish) {
						if (!Array.isArray(functionsToExecuteOnFinish))
							functionsToExecuteOnFinish = [functionsToExecuteOnFinish];
						functionsToExecuteOnFinish.forEach(func => func());
					}
				});
		},
		putEntity(obj) {
			this.addAndEditDialog.show = false;
			this.loading = true;
			const vm = this;
			this.$axios
				.put("/api/" + vm.entityName, obj)
				.then(response => {
					obj = Object.assign({}, obj, response.data);
					vm[vm.entityName].data.push(obj);
					vm.content = vm.messages.addSuccess;
					vm.type = "success";
					this.$refs.alert.showAlert();
				})
				.catch(error => {
					vm.catchError(error);
				})
				.finally(() => (vm.loading = false));
		}
	}
};
