<template lang="pug">
div.white.d-flex.justify-center
	v-sheet(
		max-width='1300'
		).white.d-flex.justify-center
		div.py-sm-16
			Title(
				:textBottom='"Also..."'
				:align='"center"')
			div.mx-8
				.d-flex.justify-center
					.d-lg-flex.justify-center.mt-lg-12.mt-6.d-inline-block
						v-card(
							v-for="(card, i) in cards"
							:index="i"
							:min-width="$vuetify.breakpoint.smAndDown ? '90%' : '25%'"
							:max-width="$vuetify.breakpoint.mdAndDown ? '500px' : ''"
							rounded="xl"
							color="primary-light"
							).text-center.pa-6.shadow.my-6.my-lg-0.account-card-text--text.mx-md-2.mx-lg-4
								.text-sm-h5.text-h6 {{ card.title }}
								hr.my-2.my-sm-4
								.text-sm-body-1.body-2(v-html='card.text').mt-4
								.text-sm-body-2.caption.mt-4.mb-1.account-card-text-comp--text
									span {{card.subtext ?? "&nbsp;"}}
								v-btn(
									v-if="card.button"
									:to="card.button.to"
									color='primary'
									text
									).text-decoration-none.mt-4.mb-n2 {{ card.button.label }}
			.d-flex.justify-center.mt-16.pb-16.pb-sm-0
				div.text-center
					div.text-sm-h5.text-h6 Something missing?
					div.text-sm-subtitle-1.subtitle-2.text--text Tell us without leaving this page
					.d-flex.justify-center
						v-btn(
							@click='$emit("openFeedbackDialog")'
							color="primary"
							large).mt-4 right here
</template>

<script>
import Title from "./Title.vue";
export default {
	data() {
		return {
			cards: [
				{
					title: "Support Portal",
					text: "Our concise, yet extensive knowledge base",
					button: {
						label: "check it out",
						to: "/support"
					}
				},
				{
					title: "Email Notifications",
					text: "You can specify when you want us to get in touch",
					subtext: "Account ⇢ Settings ⇢ Emails",
					button: {
						label: "go to account",
						to: "/account"
					}
				},
				{
					title: "One Chip Access",
					text:
						"Use your Chip in every room equipped with BiederDrinks. We keep a separate balance for each"
				}
			]
		};
	},
	created() {},
	methods: {},
	computed: {},
	components: { Title }
};
</script>

<style scoped>
.shadow {
	-webkit-box-shadow: 0px 4px 22px -1px #8b103d;
	box-shadow: 0px 4px 22px -1px #8b103d;
}
</style>
