<template lang="pug">
v-sheet(
	:width='$vuetify.breakpoint.sm ? "500px" : ""'
	:height='$vuetify.breakpoint.sm ? "500px" : ""'
	).d-flex.justify-center.pa-2
	v-hover(
		v-slot="{ hover }")
		v-img(
			:src='pic'
			aspect-ratio='1'
			).elevation-8.rounded-circle
			v-sheet(
				:height='$vuetify.breakpoint.smAndDown ? "100%" : ""'
				:color="`rgba(${bgColor}, ${hover || $vuetify.breakpoint.sm ? 0.85 : 0.85})`"
				).ma-auto.rounded-circle.justify-center.ma-2.pt-sm-16.pt-14
				div.text-center
					div
						v-icon(
							:color='iconColor'
							:size='$vuetify.breakpoint.smAndDown ? 60 : 110'
							).mb-2 {{ icon }}
					v-scroll-y-transition(mode='in-out' appear)
						div(v-show='hover || $vuetify.breakpoint.smAndDown')
							div(
								:class="`${colorName}--text`"
								).text-sm-h3.text-h4 {{ title }}
							div.text--text.text--lighten-5.mt-6.mt-sm-4.text-sm-h6.text-center.body-1
								div {{ text1 }}
								div {{ text2 }}
							div.d-flex.justify-center.pt-5.pt-sm-0.mt-8
								v-btn(
									:to="`/support/${link}`"
									:color='iconColor'
									dark
									large
									).text-decoration-none
									span.mx-3.black--text Read more
</template>

<script>
export default {
	props: {
		swap: Boolean,
		icon: String,
		title: String,
		iconColor: String,
		buttonTextColor: String,
		colorName: String,
		text1: String,
		text2: String,
		pic: String,
		link: String,
		bgColor: String
	}
};
</script>
<style>
.ab {
	position: absolute;
	top: 0;
}
</style>
