export const validators = {
	data() {
		return {
			rules: {
				number: value => {
					const pattern = /^[0-9]*$/;
					return pattern.test(value) || "Must be a number";
				},
				emailReminderTimeIntervalDays: value => {
					const pattern = /^[0-9]*$/;
					return (
						(pattern.test(value) && value > 1 && value < 61) || "2 to 60 days"
					);
				},
				monetaryValue: value => {
					const pattern = /^(?![.0]*$)\d+(?:\.\d{1,2})?$/;
					return (
						pattern.test(value) || "Invalid value. Must be a positive number"
					);
				},
				required: value => !!value || "Required",
				moneyLowerLimit: value =>
					(value && value >= 0.01) || "Value has to be positive",
				moneyUpperLimit: value =>
					(value && value < 1000) || "Value cannot be that big",
				rfid: value => {
					const pattern = /^[A-Z0-9]{2} [A-Z0-9]{2} [A-Z0-9]{2} [A-Z0-9]{2}$/;
					return pattern.test(value) || "Invalid RFID";
				},
				email: value => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return pattern.test(value) || "Invalid e-mail";
				},
				url: value => {
					const pattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
					return pattern.test(value) || "Invalid link";
				},
				room: value => {
					const pattern = /^[0-9]{4}$/;
					return pattern.test(value) || "4 digits";
				},
				password: value => {
					const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/;
					return (
						pattern.test(value) ||
						"New password has to follow all rules listed below"
					);
				},
				noTmobile: value => {
					return (
						!value.endsWith("t-mobile.de") ||
						"Sorry, we can't send emails to T-Mobile"
					);
				},
				maxRoomLength: value => value.length <= 8 || "Up to 8 characters",
				maxNameLength: value => value.length <= 17 || "Up to 17 characters"
			}
		};
	}
};
