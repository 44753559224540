<template lang="pug">
div.background.d-flex.justify-center.py-16
	v-sheet(
		max-width='1300'
		).background
		Title(
			:textTop='"House 3&4,"'
			:textBottom='"Want all of that too?"'
			:align='"center"'
			useCompementary="true")
		div
			.d-flex.justify-center
				.d-md-flex.justify-center.mt-lg-12.mt-6.d-inline-block
					v-card(
						v-for="(card, i) in cards"
						:index="i"
						:min-width="$vuetify.breakpoint.smAndDown ? '90%' : '25%'"
						).mx-md-2.mx-lg-4.pa-6.shadow.my-8.my-md-0
						.text-h6.primary--text.text-center
							| «{{ card.title }}»
						hr
						div(
							:class="card.alignLeft ? 'text-start' : 'text-center'")
							div(v-for='point in card.points').mb-3
								.text-sm-body-1.body-2 {{ point.text }}
								.text-sm-body-2.caption.text--text.mt-1 {{ point.subText }}
		.d-flex.justify-center.mt-16
			div
				div.text-center.text-h5
					i Why
					span.brand-text-logo.px-2.primary--text BiederDrinks
					i ?
				.d-flex.justify-center
					v-btn(
						@click="scrollToBenefits"
						color="primary"
						large).mt-4 see benefits
</template>

<script>
import Title from "./WhatsNew/Title.vue";
export default {
	data() {
		return {
			cards: [
				{
					title: "Why don't we have it?",
					points: [
						{
							text: "The project didn't get majority of the votes",
							subText: "Vollversammlung Spring 2022"
						}
					]
				},
				{
					title: "Can we still get in?",
					points: [
						{
							text: "Yes, everything from our side is ready for it"
						}
					]
				},
				{
					title: "What's missing?",
					points: [
						{
							text: "A volunteer responsible for the equipment",
							subText: "Setup, occasional maintenance"
						},
						{
							text: "Budget",
							subText: "The amount depends on what equipment is already there"
						}
					],
					alignLeft: true
				}
			]
		};
	},
	created() {},
	methods: {
		scrollToBenefits() {
			document.getElementById("benefits-background").scrollIntoView({
				behavior: "smooth"
			});
		}
	},
	computed: {},
	components: { Title }
};
</script>

<style scoped>
.shadow {
	-webkit-box-shadow: 0px 4px 22px -1px #8b103d;
	box-shadow: 0px 4px 22px -1px #8b103d;
}
</style>
