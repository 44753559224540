<template lang="pug">
div.d-flex.justify-center
	v-card(
		max-width='600'
		min-width='350'
		color='primary-light lighten-1')
		div.text-h5.text-center.primary--text.text--lighten-1.py-4
			| Demand
		div.d-flex.justify-center
			v-sheet(
				width='200'
				elevation='2'
				rounded
				).text-h5.text-center.primary.py-1
				div.subtitle-1.d-flex.justify-center.py-1
					v-btn(
						@click='selectedTimePeriod = 0; $emit("getDemand", 7)'
						small
						dark
						text)
						div(
							:style='{border: selectedTimePeriod == 0 ? "solid var(--v-primary-light-base) 1px" : "solid var(--v-primary-base) 1px"}'
						).py-2.px-4.rounded.primary-light--text week
					v-btn(
						@click='selectedTimePeriod = 1; $emit("getDemand", 30)'
						small
						dark
						text)
						div(
							:style='{border: selectedTimePeriod == 1 ? "solid var(--v-primary-light-base) 1px" : "solid var(--v-primary-base) 1px"}'
						).py-2.px-4.rounded.primary-light--text month
		div(v-if='loading').d-flex
			div(
				v-for='i in 2'
				:key='i'
				style='width: 50%')
				v-skeleton-loader(type='article')
		div(v-else-if='demandLoadingFailed')
			.body-1.text-center.text--text.my-5
				| We couldn't load Demand
		div(v-else).d-flex.justify-center.mt-4.pb-4
			div(
				v-for='(drinks, categoryName, index) in demand'
				:key='index'
				).primary--text.px-6.primary-light.lighten-1
				div.title.text-capitalize.mb-3 {{ categoryName }}
				div(v-if='drinks.length === 0').text-center.text--secondary
					span We don't have enough data on that. Come back later
				div(
					v-for='(drink, index) in drinks'
					:key='index')
					div {{ drink.name }}
					div.text-secondary.subtitle-2.font-weight-normal
						| Bought {{ formatToMultiplicativeNumber(drink.purchases) }}
</template>
<script>
export default {
	props: ["demand", "loading", "demandLoadingFailed"],
	data() {
		return {
			selectedTimePeriod: 0
		};
	}
};
</script>
