import { render, staticRenderFns } from "./WhyEmail.vue?vue&type=template&id=2a49ca00&scoped=true&lang=pug&"
import script from "./WhyEmail.vue?vue&type=script&lang=js&"
export * from "./WhyEmail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a49ca00",
  null
  
)

export default component.exports