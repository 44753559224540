<template lang="pug">
v-dialog(
	:is='$vuetify.breakpoint.smAndUp ? "v-dialog" : "v-bottom-sheet"'
	v-model='showRequestDetailsDialog'
	transition="dialog-bottom-transition"
	:max-width="$vuetify.breakpoint.smAndUp ? '500' : ''"
	@click:outside='cancel'
	@keydown.esc='cancel')
	v-card
		div.pa-4
			div.d-sm-flex.justify-space-between.mb-sm-3.mt-sm-1
				.d-flex.text-h6.text-sm-h5.primary--text
					div.pr-4 {{ getType() }} details
					// mobile close button
					div.d-flex.justify-end.align-center.flex-grow-1
						v-btn(
							v-if='$vuetify.breakpoint.xs'
							@click="cancel"
							color='primary'
							x-small
							outlined
							fab)
							v-icon close
				// split status
				div.mt-sm-2
					div.d-flex.mr-3
						v-icon(
							:size='$vuetify.breakpoint.xs ? 14 : 18'
							color='grey darken-1'
							).mr-1 {{ splitStatuses[purchase.splitStatus].status.icon }}
						div.text-sm-body-2.caption.grey--text.text--darken-1
							| {{ splitStatuses[purchase.splitStatus].status.text }}
			// lists of friends
			v-card-text.pb-0.black--text.px-0.px-sm-3
				ListOfPeople(
					v-if='purchase.friends.pending.length > 0'
					:title='purchase.splitStatus === "initiated" ? "Waiting for" : "Didn\'t respond"'
					:friends='purchase.friends.pending')
				ListOfPeople(
					v-if="purchase.friends.paid.length > 0"
					:title='purchase.splitStatus === "initiated" ? "Already paid" : "Paid"'
					:friends='purchase.friends.paid')
				ListOfPeople(
					v-if="purchase.friends.declined.length > 0"
					title='Refused to pay'
					:friends='purchase.friends.declined')
				template(v-if="purchase.unclaimedDrinks.length > 0")
					.text-sm-h6.subtitle-1.mt-sm-5.primary--text.mb-2 Unclaimed Drinks
					div.ml-1(
						v-for="(drink, index) in purchase.unclaimedDrinks"
						).d-inline-block.text-sm-body-1.body-2.text--text
						span {{drink.quantity}} {{drink.drink}}
						span(v-if="index < purchase.unclaimedDrinks.length - 1") ,
		// info field
		div.primary-light.rounded.mt-sm-6.d-flex.py-2.mx-0.mx-sm-6
			v-icon(color="complementary lighten-1").ma-0.px-3 info
			div(
				:class='{"caption":$vuetify.breakpoint.xs}'
				).align-center.pr-2.complementary--text.text--darken-1.body-2
				div(v-if='paidByFriends').mt-1 Your friends paid for all drinks
				template(v-else)
					div {{ splitStatuses[purchase.splitStatus].topLine }}
					div {{ splitStatuses[purchase.splitStatus].bottomLine }}
		// footer
		v-card-actions(v-if='$vuetify.breakpoint.smAndUp')
			v-spacer
			v-btn(
				@click="cancel"
				color="primary"
				text) Close
</template>
<script>
import ListOfPeople from "./ListOfPeople.vue";
import { VDialog, VBottomSheet } from "vuetify/lib";
export default {
	components: { ListOfPeople, VDialog, VBottomSheet },
	name: "request-details-dialog",
	props: ["purchase"],
	data() {
		return {
			showRequestDetailsDialog: true,
			paidByFriends:
				this.purchase.splitStatus === "everyone responded" &&
				this.purchase.ownAmount === 0 &&
				this.purchase.unclaimedAmount === 0,
			splitStatuses: {
				initiated: {
					status: {
						icon: "hourglass_empty",
						text: `in progress`
					},
					topLine: "You will be charged for the unlcaimed drinks on:",
					bottomLine: this.convertTimestampToReadableDayOfTheWeekDateTime(
						this.purchase.deadline
					)
				},
				"everyone responded": {
					status: { icon: "done", text: `Finished` },
					topLine: "All of your friends responded",
					bottomLine:
						this.purchase.unclaimedAmount > 0
							? `You were charged ${this.formatMonetaryValue(
									this.purchase.unclaimedAmount
							  )} for the unclaimed drinks`
							: "All drinks you split are paid for"
				},
				"deadline reached": {
					status: { icon: "done", text: `Finished` },
					topLine: `This ${this.getType()} was closed because deadline was reached`,
					bottomLine: `You were charged ${this.formatMonetaryValue(
						this.purchase.unclaimedAmount
					)} for the unclaimed drinks`
				}
			}
		};
	},
	methods: {
		getType() {
			return this.purchase.askedToPay ? "PayMe" : "Split";
		},
		unclaimedDrinks() {
			if (this.purchase.friends.pending.length > 0)
				return this.purchase.friends.pending;
			else if (this.purchase.unclaimedDrinks.length > 0)
				return this.purchase.unclaimedDrinks;
			else return null;
		},
		cancel() {
			this.$emit("cancel");
		}
	}
};
</script>
