<template lang="pug">
div.mb-sm-6.mb-4
	div.d-flex
		div.primary--text
			.text-sm-h6.subtitle-1.mb-1 {{ title }}
		div(v-if='friends[0].drinks')
			v-btn(
				@click="showAlreadyPaidDetails = !showAlreadyPaidDetails"
				x-small
				plain
				).mt-1 {{ showAlreadyPaidDetails ? 'hide' : 'show' }} drinks
	div(
		v-for="(purchase, index) in friends"
		:key='index'
		).d-inline-block.align-top.mr-1.mt-sm-1
		NameAndRoomBadge(
			:name='purchase.person.name'
			:room='purchase.person.room'
			:size='$vuetify.breakpoint.xs ? "s" : "m"').mr-1.mb-2
		div(v-if='purchase.drinks && showAlreadyPaidDetails')
			div(v-for="drink in purchase.drinks")
				div.ml-3.text--text {{drink.quantity}} {{drink.drink}}
</template>
<script>
import NameAndRoomBadge from "../../../Elements/NameAndRoomBadge.vue";
export default {
	components: { NameAndRoomBadge },
	name: "list-of-people-dialog",
	props: ["title", "friends"],
	data() {
		return {
			showAlreadyPaidDetails: false
		};
	}
};
</script>
