<template lang="pug">
div
	alert(:content='content' ref='alert'  :type='type')
	h1 Redirecting
	h5.red to {{ $route.params.groupId }}
	v-btn(
		to='/'
		color='primary'
	).text-decoration-none Go to main page

</template>

<script>
import { mapGetters } from "vuex";
import Alert from "./Alert.vue";
import { catchError } from "../mixins/catchError";

export default {
	components: { Alert },
	mixins: [catchError],
	data() {
		return {};
	},
	methods: {},
	mounted() {
		var vm = this;
		if (this.user_logged_in) {
			this.$axios
				.get(`/api/group_page/${this.$route.params.groupId}`)
				.then(response => {
					vm.loading = false;
					window.location.href = response.data.redirect_to;
				})
				.catch(error => {
					vm.catchError(error);
				});
		} else
			this.$router.push({
				name: `LoginTransferToPayment`,
				params: { groupId: this.$route.params.groupId }
			});
	},
	computed: {
		...mapGetters(["user_logged_in"])
	}
};
</script>

<style scoped>
.large-alert-text {
	font-size: 35px;
}
</style>
