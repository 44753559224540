<template lang="pug">
v-card(
	color='primary-light lighten-1'
	).d-flex.align-center.py-4
	div.primary--text.text--lighten-1.text-center.text-h5.mt-7.mx-7
		| TRENDS
	v-sheet(
		color='primary-light lighten-1').pr-4.flex-grow-1
		div(v-if='loading')
			div.d-flex
				v-skeleton-loader(
					max-height='100'
					type='image').flex-grow-1.mr-4
				v-skeleton-loader(
					max-height='100'
					type='image').flex-grow-1
			div.d-flex.mt-4
				v-skeleton-loader(
					type='image'
					max-height='100'
					).flex-grow-1.mr-4
				v-skeleton-loader(
					type='image'
					max-height='100').flex-grow-1
		div(v-else-if='trends.length > 0')
			v-row(no-gutters).text-left.pl-1.text--text
				v-col
					div This week
				v-col
					div This month
			v-row(no-gutters)
				v-col(
					v-for='(trend, i) in trendsToFetch'
					:key='i'
					cols='6').pa-1
					TrendTile(
						:metric='filterTrendByTypeAndTimePeriod(trend.timePeriod, trend.type)'
						:title='trend.type'
						:isMonetaryValue='trend.type === "revenue"')
	</template>
<script>
import TrendTile from "./TrendTile.vue";
export default {
	props: ["loading", "trends"],
	components: { TrendTile },
	data() {
		return {
			filtered: null,
			trendsToFetch: [
				{ timePeriod: "week", type: "purchases" },
				{ timePeriod: "month", type: "purchases" },
				{ timePeriod: "week", type: "revenue" },
				{ timePeriod: "month", type: "revenue" }
			]
		};
	},
	mounted() {},
	methods: {
		filterTrendByTypeAndTimePeriod(type, timePeriod) {
			return this.trends
				.find(trend => trend.name === type)
				.metrics.find(metric => metric.name === timePeriod);
		}
	}
};
</script>
<style lang="scss" scoped>
.trends-title {
	position: absolute;
	left: -40px;
	width: 40px;
	top: 0;
}
</style>
