<template lang="pug">
div(:class="{ 'mb-6' : paragraph.text }")
	div(
		:class="$vuetify.breakpoint.xs ? 'text-h6': 'text-h5'"
		).mb-4.primary--text.mt-0 {{ paragraph.title }}
	div(v-if='paragraph.text')
		p(v-html="paragraph.text")
</template>

<script>
export default {
	props: ["paragraph"]
};
</script>

<style scoped></style>
