<template lang="pug">
v-container(fluid fill-height).pa-0#background
	v-row(no-gutters)
		v-col.d-flex.justify-center.align-centers
			v-card(
				color='primary'
				:min-width='$vuetify.breakpoint.smAndUp ? 550 : 345'
				).pb-16
				alert(:content='content' ref='alert' :type='type')
				div(v-if='tokenValid')
					CompletedSteps(
						v-if="currentStepIndex > 0"
						:steps="steps"
						:currentStepIndex="currentStepIndex")
					component(
						:is="currentStep.step.component"
						v-on="currentStep.step.on")
					div.primary-light.bottom
						div.d-flex.justify-center
							v-btn.ma-4(
								:disabled="buttonIsDisabled"
								:loading="loading"
								@click="goToNextStepOrFinish"
								color="primary"
								large)
								span {{ currentStep.step.button.text }}
								v-icon {{ currentStep.step.button.icon }}
				LoadingOrError(
					v-if='loading || !tokenValid'
					:loading="loading")
	div.footer-logo
		h5.text-center.primary-light--text.brand-text-logo {{ $appTitle }}
</template>
<script>
import { validators } from "../../../mixins/validators";
import { catchError } from "../../../mixins/catchError";
import Alert from "../../Alert.vue";
import First from "./Steps/First.vue";
import CreatePassword from "./Steps/CreatePassword.vue";
import ChooseDesign from "./Steps/ChooseDesign.vue";
import CompletedSteps from "./Elements/CompletedSteps.vue";
import LoadingOrError from "./Elements/LoadingOrError.vue";
import SignUpForm from "../../Landing/SignUp/SignUpForm.vue";

export default {
	mixins: [validators, catchError],
	components: {
		Alert,
		First,
		CreatePassword,
		ChooseDesign,
		CompletedSteps,
		LoadingOrError,
		SignUpForm
	},
	name: "welcome-dialog",
	data() {
		let allSteps = {
			first: {
				component: First,
				button: {
					text: "Get started",
					icon: "chevron_right"
				}
			},
			contacts: {
				component: SignUpForm,
				on: {
					input: formData => (this.contacts = formData)
				},
				button: {
					text: "Confirm",
					icon: "chevron_right",
					disabled: () => !this.contacts
				}
			},
			password: {
				component: CreatePassword,
				on: {
					enterPressed: () =>
						this.$store.state.newPasswordIsValid
							? (this.currentStep = this.steps[++this.currentStepIndex])
							: ""
				},
				button: {
					text: "To the last step",
					icon: "chevron_right",
					disabled: () => !this.$store.state.newPasswordIsValid
				}
			},
			theme: {
				component: ChooseDesign,
				button: {
					text: "Go to " + this.$appTitle,
					icon: "rocket_launch"
				}
			}
		};

		return {
			loading: false,
			tokenValid: false,
			currentStep: null,
			currentStepIndex: 0,
			steps: [],
			contacts: null,
			isInviteLink: null,
			stepsForInviteEmail: [
				{
					step: allSteps.first,
					completionText: "You've already provided your email"
				},
				{
					step: allSteps.password,
					completionText: "You've created a secure password"
				},
				{ step: allSteps.theme, completionText: null }
			],
			stepsForInviteLink: [
				{ step: allSteps.first, completionText: null },
				{
					step: allSteps.contacts,
					completionText: "You've specified contact information"
				},
				{
					step: allSteps.password,
					completionText: "You've created a secure password"
				},
				{ step: allSteps.theme, completionText: null }
			]
		};
	},
	mounted() {
		this.loading = true;
		const vm = this;
		this.isInviteLink = this.$route.meta.isInviteLink;
		const url = `/api/verify_token/invite_${
			this.isInviteLink ? "link" : "email"
		}/${this.$route.params.token}`;
		this.$axios
			.get(url)
			.then(() => {
				vm.tokenValid = true;
				if (this.isInviteLink) {
					this.steps = this.stepsForInviteLink;
					this.currentStep = this.stepsForInviteLink[0];
				} else {
					this.steps = this.stepsForInviteEmail;
					this.currentStep = this.stepsForInviteEmail[0];
				}
			})
			.finally(() => (vm.loading = false));
	},
	computed: {
		buttonIsDisabled() {
			if (!this.currentStep.step.button.disabled) return false;
			return this.currentStep.step.button.disabled();
		}
	},
	methods: {
		goToNextStep() {
			this.currentStep = this.steps[++this.currentStepIndex];
		},
		goToNextStepOrFinish() {
			const isLastStep = this.currentStep == this.steps[this.steps.length - 1];
			isLastStep ? this.finishSetup() : this.goToNextStep();
		},
		finishSetup() {
			this.loading = true;
			const vm = this;
			let preferences = {
				password: this.$store.state.newPassword,
				settings: { theme: this.$store.state.settings.theme.name }
			};

			if (this.steps == this.stepsForInviteLink)
				preferences = {
					...preferences,
					name: this.contacts.name,
					email: this.contacts.email,
					room: this.contacts.room,
					mails_enabled: this.contacts.emailConsent
				};

			const url = `/api/create_account/invite_${
				this.isInviteLink ? "link" : "email"
			}/${this.$route.params.token}`;

			this.$axios
				.post(url, preferences)
				.then(response => {
					var user = response.data.user ?? {};
					var adminGroups = response.data.admin_groups ?? [];
					//theme is already installed. There aren't any other settings as of now
					//var settings = response.data.settings ?? {};
					var balances = response.data.balances ?? [];
					this.$store.commit("setBalances", balances);
					this.$store.commit("setLoginMethod", "password");
					this.$store.commit("setUser", user);
					this.$store.commit("setAdminGroups", adminGroups);
					window.location.href = "/#/account";
				})
				.catch(error => {
					vm.catchError(error);
				})
				.finally(() => (vm.loading = false));
		}
	}
};
</script>
<style lang="scss" scoped>
#background {
	background-image: url("../../../../public/img/biederstein-login-background.jpg");
	background-size: cover;
}
.bottom {
	width: 100%;
	position: absolute;
	bottom: 0;
	clear: both;
}
</style>
