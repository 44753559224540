import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import store from "../utils/store";

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: store.state.settings.theme.primary,
				"primary-light": store.state.settings.theme["primary-light"],
				complementary: store.state.settings.theme.complementary,
				background: store.state.settings.theme.background,
				"terminal-footer-top":
					store.state.settings.theme["terminal-footer-top"],
				"terminal-footer-bottom":
					store.state.settings.theme["terminal-footer-bottom"],
				"user-badge": store.state.settings.theme["user-badge"],
				"user-badge-text": store.state.settings.theme["user-badge-text"],
				"user-badge-room": store.state.settings.theme["user-badge-room"],
				"account-card-text": store.state.settings.theme["account-card-text"],
				"account-card-divider":
					store.state.settings.theme["account-card-divider"],
				"account-card-text-comp":
					store.state.settings.theme["account-card-text-comp"],
				"account-card-background-comp":
					store.state.settings.theme["account-card-background-comp"],
				"account-card-text-light":
					store.state.settings.theme["account-card-text-light"],
				"complementary-text-comp":
					store.state.settings.theme["complementary-text-comp"],
				"complementary-text": store.state.settings.theme["complementary-text"],
				"complementary-text-light":
					store.state.settings.theme["complementary-text-light"],

				// "complementary-text-comp": "#477e91",
				// "complementary-text": "#8c661b",
				// "complementary-text-light": "#cee6e8",

				"rising-trend": "#fbfff9",
				"rising-trend-text": "#56c1a3",
				"rising-trend-border": "#bcf9c2",
				"rising-trend-dim": "#e7fde8",

				"falling-trend": "#fffcfd",
				"falling-trend-text": "#d4698c",
				"falling-trend-border": "#ffcad5",
				"falling-trend-dim": "#fde7f0",

				"flat-trend": "#fefefe",
				"flat-trend-text": "#909090",
				"flat-trend-border": "#dcdcdc",
				"flat-trend-dim": "#ebe8e8",

				chart1: "#90A4AE",
				chart2: "#BCAAA4",

				//split: "#0a96cd",
				split: "#86b5ff",
				"split-text": "#5496ff",
				//"pay-me": "#cd0a65",
				//"pay-me": "#ff86b4",
				//"pay-me": "#ff86e6",
				"pay-me": "#eca6ec",
				"pay-me-text": "#d980d9",
				"instant-refund": "#ef9d18",
				"instant-refund-light": "#ffdc21",
				text: "#6f6f6f",

				"background-text": "#81a3d9",
				"background-inset": "#c7d2e6",
				"background-text-dark": "#5e6571",

				"dark-grey": "#444343",
				secondary: "#b0bec5",
				"secondary-text": "#6c757d",
				accent: "#8c9eff",
				error: "#b71c1c",
				success: "#088729"
			}
		},
		options: {
			customProperties: true
		}
	}
});
