<template lang="pug">
div.primary--text.ml-5.pb-3
	.text-h6.mb-0
		v-icon(
			:size="iconSize"
			color="primary"
			).mr-1.pb-1 {{ captions.icon }}
		span {{ captions.title }}
	.subtitle-2.my-0.text--text.mt-n1 {{ captions.subtitle }}
</template>
<script>
export default {
	props: ["captions", "iconSize"]
};
</script>
