<template lang="pug">
div.router-supportPortal
	div.d-flex.justify-center.white
		v-sheet(max-width='800').d-sm-flex.mx-sm-4.flex-md-grow-1.justify-center
			//- v-btn(
			//- 	class="md-5 mr-3 elevation-21"
			//- 	dark
			//- 	fab
			//- 	button
			//- 	right
			//- 	bottom
			//- 	small
			//- 	color="primary lighten-1"
			//- 	fixed
			//- 	@click="scrollToTop")
			//- 	v-icon arrow_circle_up
			v-app-bar(
				v-if='$vuetify.breakpoint.smAndDown'
				dense
				fixed
				flat
				tile
				color='primary-light'
				).align-center.justify-center
				v-btn(
					@click='drawer = true'
					text
					absolute
					x-small
					left).py-4
					v-icon(color="primary") menu
				v-btn(
					color='primary'
					:to='user_logged_in ? "/account" : "/login"'
					text
					absolute
					x-small
					right).text-decoration-none.py-4
					v-icon.mr-1(color='primary') account_circle
					span  {{ user_logged_in ? "account" : "login" }}
			v-navigation-drawer(
				v-if='$vuetify.breakpoint.smAndDown'
				v-model='drawer'
				width='280'
				fixed
				temporary).pr-2
				div.d-flex.justify-space-between
					v-btn(
						@click='drawer = false'
						color='grey darken-1'
						text).mt-2
						v-icon close
					v-btn(
						to='/support'
						color='primary'
						outlined
						small).mt-3.d-flex.text-decoration-none
						span support start page
				Menu(
					:categories='categories'
					:selectedArticle='selectedArticle'
					@navigate="navigate($event);")
			div.d-sm-flex.py-4.mt-12.mt-md-0.d-block
				div
					h1.brand-text-logo.primary--text
						a(href='/').text-decoration-none {{ $appTitle }}
				div(v-if='$vuetify.breakpoint.smAndUp').py-2
					v-divider.ml-4.mr-3(vertical)
				div.d-flex.align-center.justify-center.mr-4
					h4.pt-2.text--secondary Support Center
			template(v-if='$vuetify.breakpoint.mdAndUp')
				v-spacer
				div.d-flex.align-center.justify-center
					v-btn(
						color='primary'
						:to='user_logged_in ? "/account" : "/login"'
						:small="$vuetify.breakpoint.smAndDown"
						).text-decoration-none
						| {{ user_logged_in ? "go to account" : "login" }}
	div(
		:class='searchSectionHeightClass'
		)#background.d-flex.justify-center.px-5
		v-sheet(max-width='450' color='rgba(0, 0, 0, 0)')
			.text-h6.text-sm-h5.text-sm-h4.text-center.primary-light--text.mb-3
				| How can we help you?
			autocomplete(
				:search="search"
				@submit="searchSubmit")
	div(v-if='!selectedArticle').mx-3.mt-5.pb-6
		div.d-md-flex.justify-center
			v-card(
				v-for='category in categories'
				:key='category.caption'
				:color='category.bgColor'
				rounded='xl'
			).mx-2.py-4.px-6.my-4.my-md-0
				div.d-flex.mb-4.mt-2.align-center
					v-icon(:color='category.textAndIconColor').mr-2 {{ category.icon }}
					div(:style="{color: category.textAndIconColor}").mb-0.text-h6.text-sm-h5 {{ category.caption }}
				div(:style="{color: category.textAndIconColor}").subtitle-1
					div(v-for='link in category.links').my-2
						a(@click='goToArticle(link.componentName)') {{ link.text }}
		div(v-if='$vuetify.breakpoint.smAndDown')
			div.mt-6.px-10.text-center
				v-btn(
					color='primary'
					:to='user_logged_in ? "/account" : "/login"'
				).text-decoration-none.my-2
					| {{ user_logged_in ? "go to account" : "login" }}
				v-btn(
					color='primary'
					outlined
					to='/'
				).text-decoration-none.mt-4 homepage
			div.d-flex.align-center.justify-center.mt-8
				h4.brand-text-logo.primary--text
					a(href='/').text-decoration-none {{ $appTitle }}
	div.d-flex.mt-sm-5.justify-center.flex-shrink-1.pb-md-6.mx-0.mx-sm-2.mx-lg-6
		v-card(
			v-if='$vuetify.breakpoint.mdAndUp && selectedArticle'
			max-width='300'
			style='height: 100%').mr-5
			v-btn(
				to='/support'
				color='primary'
				outlined
				small).mx-10.mt-3.d-flex.text-decoration-none
				span support start page
			Menu(
				:categories='categories'
				:selectedArticle='selectedArticle'
				@navigate="navigate($event)")
		v-card(
			v-if="selectedArticle"
			:max-width='$vuetify.breakpoint.mdAndUp ? 640 : ""'
			:tile='$vuetify.breakpoint.smAndDown'
			:flat='$vuetify.breakpoint.smAndDown'
			color='white'
			:class='{"pa-6 flex-grow-1": selectedArticle !== "NotFound"}'
			).mb-sm-10
			component(
				:is='selectedArticle'
				:contactPerson="{name: 'Alex', room: '0101'}"
				@openFeedbackDialog='openFeedbackDialog'
				).text-body-2.text-sm-body-1
		v-footer(bottom padless absolute)
				v-card.pa-0.ma-0.pb-1.text-center(flat tile color="#FFFFFF" width="100%")
					GDPRLinks(:isWhiteBackground="true")
</template>

<script>
import { catchError } from "@/mixins/catchError";
import { validators } from "@/mixins/validators";
import { mapGetters } from "vuex";
import Articles from "./Articles";
import Menu from "./Elements/Menu.vue";
import GDPRLinks from "../GDPRLinks.vue";

export default {
	components: { ...Articles, Menu, GDPRLinks },
	mixins: [catchError, validators],
	data() {
		return {
			showScrollUpButtonAt: 250,
			group: null,
			drawer: false,
			selectedArticle: null
		};
	},
	watch: {
		group() {
			this.drawer = false;
		}
	},
	mounted() {
		if (this.$route.params.article) {
			var articleCategory = this.categories.find(category =>
				category.links.find(
					link => link.componentName === this.$route.params.article
				)
			);
			if (articleCategory) {
				this.selectedArticle = this.$route.params.article;
				this.expandCategoryCollapseOthers(articleCategory);
			} else this.selectedArticle = "NotFound";
		}
		window.addEventListener("scroll", this.showOrHideGoUpButton, {
			passive: true
		});
	},
	destroyed() {
		window.removeEventListener("scroll", this.showOrHideGoUpButton, {
			passive: true
		});
	},
	methods: {
		scrollToTop() {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth"
			});
		},
		navigate({ componentName }) {
			this.goToArticle(componentName);
			this.scrollToTop();
		},
		search(input) {
			if (input.length < 1) {
				return [];
			}
			return this.articles
				.filter(article => {
					return (
						article.text.toLowerCase().startsWith(input.toLowerCase()) ||
						article.componentName
							.toLowerCase()
							.startsWith(input.toLowerCase()) ||
						article.aliases?.some(alias =>
							alias.toLowerCase().startsWith(input.toLowerCase())
						)
					);
				})
				.map(article => article.text);
		},
		searchSubmit(result) {
			let article = this.articles.find(article => article.text === result);
			this.goToArticle(article.componentName);
		},
		goToArticle(componentName) {
			const path = `/support/${componentName}`;
			if (this.$route.path !== path) {
				this.$router.push({
					name: "support",
					params: { article: componentName }
				});
			}
		},
		goHome() {
			this.$router.push({
				name: "support"
			});
		},
		openFeedbackDialog(feedbackType, purchaseId, text) {
			this.$emit("openFeedbackDialog", feedbackType, purchaseId, text);
		},
		expandCategoryCollapseOthers(categoryToExpand) {
			this.categories.forEach(
				category =>
					(category.active = category.caption === categoryToExpand.caption)
			);
		}
	},
	computed: {
		// showScrollUpButton() {
		// 	return window.pageYOffset > this.showScrollUpButtonAt;
		// },
		searchSectionHeightClass() {
			let className = "search-section-";
			className = className.concat(
				this.selectedArticle ? "article-height" : "main-height"
			);
			if (this.$vuetify.breakpoint.smAndDown)
				className = className.concat("-mobile");
			return className;
		},
		...mapGetters(["user_logged_in"]),
		articles() {
			let articles = [];
			this.categories.forEach(category =>
				category.links.forEach(link => articles.push(link))
			);
			return articles;
		},
		categories() {
			return [
				{
					active: false,
					bgColor: "#CDEAFF",
					textAndIconColor: "#2E648F",
					icon: "storefront",
					caption: "Usage",
					links: [
						this.$isBiederDrinks
							? {
									text: "I took drinks without scanning",
									componentName: "ForgotToScan",
									aliases: [
										"forgot",
										"scan",
										"take",
										"took",
										"pay",
										"didn't",
										"debt",
										"owe",
										"forget"
									]
							  }
							: null,
						{
							text: "Splits",
							componentName: "Split",
							aliases: [
								"split",
								"splits",
								"friend",
								"friends",
								"for",
								"buy",
								"share",
								"add"
							]
						},
						{
							text: "PayMe",
							componentName: "PayMe",
							aliases: [
								"split",
								"splits",
								"friend",
								"friends",
								"for",
								"buy",
								"share",
								"add",
								"get",
								"swing",
								"by",
								"bring",
								"pay",
								"present"
							]
						},
						{
							text: "I have a suggestion",
							componentName: "Suggestion",
							aliases: ["help", "tip", "share", "idea"]
						},
						{
							text: "Pending balance",
							componentName: "PendingBalance",
							aliases: [
								"balance",
								"account",
								"pending",
								"money",
								"funds",
								"split"
							]
						},
						this.$isBiederDrinks
							? {
									text: "How to start?",
									componentName: "HowTo",
									aliases: ["begin", "start", "use"]
							  }
							: null,
						this.$isBiederDrinks
							? {
									text: "Where to get drinks?",
									componentName: "WhereToFind",
									aliases: [
										"find",
										"room",
										"getränkeraum",
										"g-raum",
										"graum",
										"buy",
										"purchase",
										"drink",
										"drinks"
									]
							  }
							: null
					].filter(e => e)
				},
				{
					active: false,
					bgColor: "#fff8b2",
					textAndIconColor: "#8B7505",
					icon: "security",
					caption: "Security & Privacy",
					links: [
						{
							text: "Why do you need my email?",
							componentName: "WhyEmail",
							aliases: [
								"data",
								"email",
								"privacy",
								"security",
								"personal",
								"information"
							]
						},
						{
							text: "I forgot my password",
							componentName: "ForgotPassword",
							aliases: [
								"password",
								"forget",
								"forgot",
								"restore",
								"access",
								"can't",
								"login"
							]
						},
						{
							text: "Why do I need a complicated password?",
							componentName: "PasswordRules",
							aliases: ["password", "security"]
						}
					]
				},
				{
					active: false,
					bgColor: "#FCC",
					textAndIconColor: "#A62E2B",
					icon: "bug_report",
					caption: "Issues",
					links: [
						{
							text: "I found a bug",
							componentName: "BugReport",
							aliases: ["bug", "problem", "error", "wrong"]
						},
						{
							text: "I hear a system error",
							componentName: "SystemError",
							aliases: ["error", "fault", "problem"]
						},
						this.$isBiederDrinks
							? {
									text: "Nothing happens when I present a chip",
									componentName: "NothingHappens",
									aliases: ["chip", "error", "fault", "problem"]
							  }
							: null
					].filter(e => e)
				}
			];
		}
	}
};
</script>

<style scoped>
#background {
	background-image: url("../../../public/img/biederstein-login-background.jpg");
	background-size: cover;
}

.search-section-main-height {
	padding-top: 150px;
	padding-bottom: 130px;
}

.search-section-article-height {
	margin-top: 0;
	padding-top: 50px;
	padding-bottom: 50px;
}

.search-section-main-height-mobile {
	padding-top: 40px;
	padding-bottom: 30px;
}

.search-section-article-height-mobile {
	margin-top: 0;
	padding-top: 40px;
	padding-bottom: 20px;
}
</style>
