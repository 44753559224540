import MeetAccount from "./MeetAccount.vue";
import Requests from "./Requests.vue";
import InstantRefund from "./InstantRefund.vue";
import PickYourStyle from "./PickYourStyle.vue";
import Also from "./Also.vue";
import Devices from "./Devices.vue";

export default {
	MeetAccount,
	Requests,
	InstantRefund,
	PickYourStyle,
	Also,
	Devices
};
