<template lang="pug">
	div
		.text-h6.text-sm-h5.text-md-h4.mb-4.primary--text I found a bug
		div
			p
				| Well done!
			div Let us know by pressing the button below and we'll make sure to fix it
			v-btn(
				@click='$emit("openFeedbackDialog", "Bug")'
				color='primary'
			).mt-3.mb-6 Make the bug go away!
			ContactInformation(
				v-if="$isBiederDrinks"
				:contactPerson="contactPerson"
				:subject="'Bug%20Report'")
</template>

<script>
import ContactInformation from "../Elements/ContactInformation.vue";
import { catchError } from "../../../mixins/catchError";
export default {
	components: { ContactInformation },
	mixins: [catchError],
	props: ["contactPerson"],
	data() {
		return {};
	},
	created() {},
	methods: {}
};
</script>

<style scoped></style>
