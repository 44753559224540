<template lang="pug">
div.mb-5
	v-card(color='primary-light lighten-1' style='z-index:99;').primary--text.text--lighten-1
		div(v-if='loadingFailed').py-6.px-10
			h3 Sorry, we couldn't load information
			h5.dark-grey--text Check your Internet connection and try again
			h6.dark-grey--text Or contact us at
				a.ml-1(href=`mailto:support@${$emailDomain}?subject=Dashboard%20data%20is%20missing`) support@{{ $emailDomain }}
		div(v-else-if='loading').d-flex
				div(
					v-for='i in 3'
					:key='i'
					style='width: 33%')
					v-skeleton-loader(type='article')
		div(v-else)
			div.px-6.d-flex.pt-4
				div(v-for='stat in stats').mx-4
					div.d-flex.mb-1
						v-icon(
							size='20'
							color="background darken-3").mr-1 {{ stat.icon }}
						div.text-h6.background--text.text--darken-3 {{ stat.name }}
					div.pl-7.text-h4.stat-value
						span(v-if='stat.name === "purchases"')
							| {{ stat.value }}
						span(v-else) {{ formatMonetaryValue(stat.value) }}
			div.d-flex.justify-space-between.caption.background--text.text--darken-2.pb-1.px-6
				div(style='width: 63%').strike.mx-3
					span Past 24h
				div(style='width: 37%').strike.mx-3
					span Now
	div(
		v-if='chipRequestCount > 0'
		).d-flex.justify-center
		v-card(color='primary' rounded).rounded-t-0
			div.mx-4
				div.d-flex.align-center
					v-icon(color="primary-light").mr-1 account_box
					div.text-h6.my-3.primary-light--text {{chipRequestCount}} Chip Request{{ chipRequestCount.length > 1 ? 's' : '' }}
					div.d-flex.pl-5
						v-btn(
							to='/persons/expandChipRequests'
							color='primary-light'
							rounded
							).text-decoration-none
							span.primary--text.text--darken-1 respond
</template>
<script>
export default {
	props: ["loading", "loadingFailed", "stats", "chipRequestCount"]
};
</script>
<style scoped>
.stat-value {
	color: transparent;
	background-color: var(--v-primary-base);
	text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.45);
	-webkit-background-clip: text;
	-moz-background-clip: text;
	background-clip: text;
}

.strike {
	display: block;
	text-align: center;
	overflow: hidden;
	white-space: nowrap;
}

.strike > span {
	position: relative;
	display: inline-block;
}

.strike > span:before,
.strike > span:after {
	content: "";
	position: absolute;
	top: 50%;
	width: 9999px;
	height: 0.3px;
	background: var(--v-background-darken2);
}

.strike > span:before {
	right: 100%;
	margin-right: 15px;
}

.strike > span:after {
	left: 100%;
	margin-left: 15px;
}
</style>
