<template lang="pug">
v-card(flat rounded="lg" height='100vh').page-title.mx-2
	v-card(flat).mx-2.rounded-t-0.pa-5
		v-img(
			max-width="160"
			:src="require(`../../../../public/img/new/logo.png`)").mx-auto
	div.text-center.mt-10
		div.py-6.px-4.mx-3
			div.h3.primary--text
				div Offering goods within
				div.d-flex.justify-center
					div your
					div.ml-2.text-left
						div community?
						div.body-2.text--text Office | Verein | Dorm
			div.h5.mt-8.mb-0
				div.text--text
					| We handle purchases and funds, saving your time and effort.
			div.mt-10
				v-btn(
					@click="$emit('showGetStartedDialog')"
					color="primary"
					) get started
				div.caption.mt-2.text--text Completely free. No strings attached
	v-row.pt-10
		v-col.d-flex.justify-center
			ScrollDownArrow
</template>

<script>
import { mapGetters } from "vuex";
import ScrollDownArrow from "../../Landing/Elements/ScrollDownArrow.vue";
export default {
	components: { ScrollDownArrow },
	data() {
		return {};
	},
	computed: {
		...mapGetters(["user_logged_in"])
	},
	mounted() {},
	methods: {}
};
</script>

<style scoped>
.page-title {
	background: rgba(255, 255, 255, 0.9) !important;
}
</style>
