<template lang="pug">
	div.fill-height.background.px-10.d-flex.justify-center
		div
			alert(:content='content' ref='alert'  :type='type')
			div.d-flex.justify-center
				v-card(
					v-if='pending.data && pending.data.length > 0'
					color='primary-light lighten-1'
					min-width='600'
					).mb-3.mt-1.align-center.flex-grow-1.flex-shrink-0
					div.d-flex.justify-space-between.align-center
						div.d-flex
							v-icon(size='30' color='primary').ml-2.mr-1 account_box
							h5.ma-0.primary--text.text--darken-1.mr-8.pt-1
								| {{ pending.data.length }} chip request{{ pending.data.length > 1 ? 's' : '' }}
						v-btn(
							@click='showPending=!showPending'
							color='primary'
							dark
							rounded
							).my-2.mr-2
							div(v-if='!showPending')
								span respond
							div(v-else)
								span close
					div(v-if='showPending').fill-height
						v-divider.ma-0
						v-virtual-scroll(
							:items="pending.data"
							:item-height="45"
							:height="pending.data.length >= 3 ? 150 : pending.data.length * 60"
							bench='3')
							template(v-slot:default="{ item }")
								v-list-item
									v-list-item-content
										NameAndRoomBadge(:name='item.name' :room="item.room")
									v-list-item-content.px-2.d-flex.flex-shrink-1
										div.body-2.text-truncate {{ item.email }}
									div.d-flex
										v-btn(
											@click='addAndEditDialog.type="approval"; showDialog("addAndEditDialog", item)'
											color='green lighten-5'
											depressed
											small).rounded-r-0
											v-icon(
												size='20'
												color='green') done
											span.green--text.text--darken-4 approve
										v-btn(
											@click='showDialog("declineChipRequestDialog", item)'
											color='red lighten-5'
											depressed
											small).rounded-l-0
											v-icon(
												size='20'
												color='red') block
											span.red--text.text--darken-4 decline
			v-data-table(
				v-if='persons.tableOptions'
				:headers="headers"
				:items="persons.data"
				:options.sync="persons.tableOptions"
				:loading="!persons || persons.status === statuses.loading"
				:loading-text="messages.loadingText"
				item-key="id"
				:search="persons.tableOptions.search"
				:server-items-length="persons.totalItems"
				).pt-1.maxWidth.mb-4.elevation-5
				template(v-slot:top)
					div.mb-6.d-flex.justify-space-between.pt-6.px-6
						div
							v-btn(
								@click='showDialog("addAndEditDialog", {})'
								color="primary"
								rounded)
								v-icon mdi-plus
								span Add person
						div
							v-text-field(
								v-model="persons.tableOptions.search"
								:append-icon='persons.tableOptions.search ? "mdi-magnify" : undefined'
								:append-outer-icon='persons.tableOptions.search ? "mdi-close-circle-outline" : undefined'
								@click:append-outer='persons.tableOptions.search = ""; updateEntityAndLoadData(persons, "persons")'
								@click:append='updateEntityAndLoadData(persons, "persons")'
								@keyup='startSearchTimer(persons, "persons")'
								label='Search'
								single-line
								flat
								solo
								hide-details)
				template(v-slot:item.active='item')
					v-tooltip(
						top
						open-delay="600"
						max-width='300'
						color='complementary')
						template(v-slot:activator="{ on, attrs }")
							v-chip(
								v-bind="attrs"
								v-on="on"
								:color='statusChipColor(item)'
								outlined
								small)
								span(style='margin-top: 1px') {{ statusChipText(item) }}
						span {{ statusChipTooltip(item) }}
				template(v-slot:item.balance='item')
					span(:class="item.value < 0 ? 'error--text' : 'normal--text'")
						| {{ formatMonetaryValue(item.value) }}
				template(v-slot:item.comment='item')
					span
						| {{ formatComment(item.value) }}
				template(v-slot:item.actions='{ item }')
					v-tooltip(
						v-for="button in actionButtons"
						:key="button.action"
						:right="button.tooltipPosition == 'right'"
						:top="button.tooltipPosition == 'top'"
						:left="button.tooltipPosition == 'left'"
						:bottom="button.tooltipPosition == 'bottom'"
						open-delay="300"
						color='complementary')
						template(v-slot:activator="{ on, attrs }")
							v-icon.mr-2(
								@click='showDialog(button.dialogName, item)'
								v-bind="attrs"
								v-on="on")
								| {{ button.icon }}
						span {{ button.tooltipText }}
				template(v-slot:footer.prepend)
					p.body-2.text--secondary.mt-4.ml-4 Combined balance:
						span.ml-2(v-if="combinedBalance.status === statuses.loading")
							v-progress-circular(width='1' size='12' indeterminate)
						span.ml-1(v-else-if='combinedBalance.status === statuses.success') {{ formatMonetaryValue(combinedBalance.data["combined-balance"]) }}
						v-tooltip(
							bottom
							color='complementary')
							template(v-slot:activator='{ on, attrs }')
								v-icon(
									color='grey'
									size='18'
									dark
									v-bind='attrs'
									v-on='on'
									).ml-1.pb-1.cursor-pointer
									| mdi-help-circle-outline
							span Excluding Admins' Chips
				template(v-slot:no-data)
					| {{ persons.status === statuses.error ? messages.loadingError : messages.nothingFound }}
			edit-person-dialog(
				v-if='addAndEditDialog.show'
				:showDialog.sync='addAndEditDialog.show'
				:selectedPerson='addAndEditDialog.selectedPerson.email == "None" ?\
												{...addAndEditDialog.selectedPerson, email: ""} :\
												addAndEditDialog.selectedPerson'
				:type='addAndEditDialog.type'
				:terminalEnabled='terminalEnabled'
				@save='personEmpty ? putEntity($event) : save($event); addAndEditDialog.type=null'
				@cancel='addAndEditDialog.show = false; addAndEditDialog.type=null'
				@approve='saveEditAndReloadData($event); addAndEditDialog.type=null'
				hide-overlay
				)
			decline-chip-request-dialog(
				v-if='declineChipRequestDialog.show'
				:requestStatus='requestStatus'
				:requestStatuses='requestStatuses'
				:showDialog.sync='declineChipRequestDialog.show'
				:person='declineChipRequestDialog.selectedPerson'
				@decline='declineChipRequest($event)'
				@cancel='closeDeclineChipRequestDialog'
			)
			add-money-dialog(
				v-if='addMoneyDialog.show'
				:showDialog.sync='addMoneyDialog.show'
				@addMoney='addMoney($event)'
				@cancel='addMoneyDialog.show = false'
				:person='addMoneyDialog.selectedPerson'
				hide-overlay
				)
			person-purchases-dialog(
				v-if='personPurchasesDialog.show'
				:showDialog.sync='personPurchasesDialog.show'
				@cancel='personPurchasesDialog.show = false'
				:person='personPurchasesDialog.selectedPerson'
				hide-overlay
				)
</template>

<script>
import Alert from "../../Alert.vue";
import NameAndRoomBadge from "../../Elements/NameAndRoomBadge.vue";
import AddMoneyDialog from "./AddMoneyDialog.vue";
import DeclineChipRequestDialog from "./DeclineChipRequestDialog.vue";
import EditPersonDialog from "./EditPersonDialog.vue";
import PersonPurchasesDialog from "./PersonPurchasesDialog.vue";

import { addAndUpdateEntity } from "@/mixins/addAndUpdateEntity";
import { catchError } from "@/mixins/catchError";
import { dataLoader } from "@/mixins/dataLoader";
import { showAddEditDialog } from "@/mixins/showAddEditDialog";

export default {
	components: {
		Alert,
		AddMoneyDialog,
		EditPersonDialog,
		PersonPurchasesDialog,
		DeclineChipRequestDialog,
		NameAndRoomBadge
	},
	mixins: [dataLoader, catchError, showAddEditDialog, addAndUpdateEntity],
	data() {
		return {
			requestStatus: null,
			requestStatuses: {
				sendingRequest: { title: "Hold tight", text: "Loading" },
				requestCompleted: { title: "Done!", text: "You declined chip request" },
				requestFailed: {
					title: "Oups...",
					text: "Error occured",
					error: null,
					supportInformation: {
						title: "You can always reach out to us at:",
						link: {
							text: `support@${this.$emailDomain}`,
							url: `mailto:support@${this.$emailDomain}?subject=Error%20with%20declining%20chip%20request`
						}
					}
				}
			},
			showPending: false,
			entityName: "persons",
			dataToLoad: [
				{
					url: "persons",
					table: true,
					tableOptions: {
						itemsPerPage: 10
					}
				},
				{
					url: "persons/pending",
					saveTo: "pending"
				},
				{
					url: "persons/combined-balance",
					saveTo: "combinedBalance"
				},
				{
					url: "admin_settings",
					saveTo: "groupSettings"
				}
			],
			persons: {},
			pending: {},
			combinedBalance: {},
			groupSettings: [],
			actionButtons: [
				{
					dialogName: "addAndEditDialog",
					icon: "mdi-pencil",
					tooltipText: "Edit user",
					tooltipPosition: "left"
				},
				{
					dialogName: "personPurchasesDialog",
					icon: "mdi-receipt",
					tooltipText: "User's purchase history",
					tooltipPosition: "top"
				},
				{
					dialogName: "addMoneyDialog",
					icon: "mdi-plus-circle-multiple-outline",
					tooltipText: "Top up balance",
					tooltipPosition: "top"
				}
			],
			messages: {
				topUpSuccess: "Balance topped up successfully",
				addSuccess: "User added successfully",
				editSuccess: "User information updated successfully",
				loadingText: "Loading list of people... Please wait",
				nothingFound:
					"No one is registered in the system yet. You can use the button above to add one.",
				loadingError: "We couldn't load users"
			},
			addAndEditDialog: {
				show: false,
				selectedPerson: {},
				type: null
			},
			addMoneyDialog: {
				show: false,
				selectedPerson: {}
			},
			personPurchasesDialog: {
				show: false,
				selectedPerson: {}
			},
			declineChipRequestDialog: {
				show: false,
				selectedPerson: {}
			}
		};
	},
	mounted() {
		if (this.$route.params.expandChipRequests) this.showPending = true;
	},
	computed: {
		personEmpty() {
			return Object.keys(this.addAndEditDialog.selectedPerson).length === 0;
		},
		terminalEnabled() {
			return (
				this.groupSettings?.status === "finished" &&
				this.groupSettings?.data?.terminalCode
			);
		},
		headers() {
			const headers = [
				{
					text: "Name",
					align: "center",
					value: "name",
					sortable: true,
					groupable: false
				},
				{
					text: "Room (WG)",
					align: "center",
					value: "room",
					sortable: true,
					groupable: false
				},
				{
					text: "Status",
					align: "center",
					value: "active",
					sortable: true
				},
				{
					text: "Balance",
					align: "center",
					value: "balance",
					sortable: true,
					groupable: false
				},
				{
					text: "Comment",
					align: "center",
					value: "comment",
					sortable: true,
					groupable: false
				},
				{
					text: "",
					align: "center",
					value: "actions",
					sortable: false,
					groupable: false
				}
			];

			if (this.terminalEnabled) {
				headers.splice(2, 0, {
					text: "Chip Code",
					align: "center",
					value: "rfid-code",
					sortable: true,
					groupable: false
				});
			}

			return headers;
		}
	},
	methods: {
		statusChipColor(item) {
			if (item.item.confirmed === "No") return "yellow darken-4";
			else if (item.value == "Yes") return "success";
			else return "grey";
		},
		statusChipText(item) {
			if (item.item.confirmed === "No") return "Pending";
			else if (item.value == "Yes") return "Active";
			else return "Disabled";
		},
		statusChipTooltip(item) {
			if (item.item.confirmed === "No")
				return (
					"User hasn't completed registration yet.\
				 	They will be able to use " +
					this.$appTitle +
					" as soon as they do.\
					You can send the registration link again in Edit Dialog →"
				);
			else if (item.value == "Yes") return "User can make purchases";
			else return "User cannot make purchases";
		},
		saveEditAndReloadData(data) {
			this.saveEdit(data, this.loadAll);
		},
		save(data) {
			this.saveEdit(data);
		},
		showDialog(dialogName, item) {
			this[dialogName].selectedPerson = item;
			this[dialogName].show = true;
		},
		declineChipRequest(data) {
			this.saveEdit(data, this.loadAll);
		},
		reloadCombinedBalance() {
			this.loadEntitiesAndSave(this.dataToLoad[2]);
		},
		addMoney(obj) {
			this.addMoneyDialog.show = false;
			const vm = this;
			this.$axios
				.put("/api/persons/" + obj.personId + "/update_balance", obj)
				.then(() => {
					let ind = vm.persons.data.findIndex(el => el.id === obj.personId);
					vm.persons.data[ind].balance = obj.amount;
					vm.content = this.messages.topUpSuccess;
					vm.type = "success";
					this.reloadCombinedBalance();
					this.$refs.alert.showAlert();
				})
				.catch(error => {
					vm.catchError(error);
				});
		},
		closeDeclineChipRequestDialog() {
			this.declineChipRequestDialog.show = false;
			this.selectedPerson = null;
			this.requestStatus = null;
		}
	}
};
</script>
<style lang="scss" scoped></style>
