<template lang="pug">
	div
		.text-h6.text-sm-h5.text-md-h4.mb-4.primary--text I forgot my password
		div
			p
				| Click on the "Forgot password?" link on the login page
			v-btn(
				to='/login'
				color='primary'
			).text-decoration-none go to login
</template>

<script>
export default {
	created() {},
	methods: {}
};
</script>

<style scoped></style>
