<template lang="pug">
v-alert(
	outlined
	icon="admin_panel_settings"
	color='primary-light'
	).d-flex.mt-6.py-3.background-transparent.body-2.text-sm-body-1
	div.pr-2.font-weight-medium.text-center Only visible to logged in users
</template>

<script>
export default {
	data() {
		return {};
	},
	methods: {}
};
</script>

<style scoped></style>
