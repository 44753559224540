<template lang="pug">
div
	v-card.rounded-l-0.pa-2.text-center
		div.caption.primary--text.pt-1
			| {{ balance.groupName }}
		div.mt-3
			div(
				:class="[balance.balance < 0 ? 'error--text' : 'complementary--text']"
			).d-flex.align-center
				.text-h6(style='width: 50%') Balance
				h4.ma-0(style='width: 50%') {{ formatMonetaryValue(balance.amount) }}
			div.text-end.mt-2
				a(
					:href="`${host}/api/payment_page/${balance.groupId}`"
					target="_blank")
					v-btn(
						color="primary"
						small
						depressed
						).text-decoration-none
						v-icon.pl-1(size="18" color='primary-light')
							| add
						span(style='padding-top: 1px').pr-2.primary-light--text Top Up
			div.mt-6.complementary--text.text--lighten-1
				div.d-flex.align-center
					.body-2(style='width: 50%') Pending
					h5.ma-0(style='width: 50%')
						span(
							v-if='balance.pending_amount < 0'
							) −{{ formatMonetaryValue(balance.pending_amount * -1) }}
						span(
							v-else='balance.pending_amount < 0'
							) {{ formatMonetaryValue(balance.pending_amount) }}
				.mt-1.caption.d-flex.justify-end.mr-1.text-secondary
					div(style='width: 50%')
					div(style='width: 50%')
						a(href='#/support/PendingBalance').grey--text.text--darken-1
							v-icon(
								color='grey'
								size='14').mr-1 help
							span What is this?
	div(
		v-if="balance.disabled"
	).pt-3.pb-2.px-2.mr-1.d-flex.complementary.lighten-2.rounded.rounded-tr-0.rounded-l-0
		div.d-flex.body-2.complementary--text.text--darken-3
			v-icon.mr-2(
				size="23"
				color="complementary darken-2") production_quantity_limits
			div You are blocked from making purchases here
</template>
<script>
export default {
	props: ["balance"],
	name: "welcome-dialog",
	computed: {
		host() {
			return window.location.protocol + "//" + window.location.host;
		}
	}
};
</script>
