<template lang="pug">
div.background.fill-height.pb-3
	alert(:content='content' ref='alert'  :type='type')
	v-row.px-10
		v-col.d-flex.justify-center
			v-card(min-width="550" max-width="720" elevation="5")
				v-progress-linear(v-if="loading" indeterminate)
				v-row.text-center.mt-0.pt-0.pt-2(v-if="maxDebt.maxDebtSwitch !== undefined")
					v-col(cols='5').pa-0
						v-container
							v-row.mt-2
								v-col(cols='5').d-flex.justify-end.pa-0
									v-switch(switch :disabled="loading" v-model='maxDebt.maxDebtSwitch' inset)
								v-col.d-flex.justify-start
									span.d-flex.align-self-center.font-weight-medium Debt Limit
					v-col(cols='7').pt-0
						v-container
							v-row
								v-col(cols="5").pa-0
									v-form(v-model='maxDebtIsValid')
										v-text-field(
											:disabled='!maxDebt.maxDebtSwitch || loading'
											v-model='maxDebt.maxDebtValue'
											:rules="[rules.required, rules.moneyLowerLimit, rules.moneyUpperLimit, rules.monetaryValue]"
											outlined
											suffix="€"
											).mt-6
								v-col.pa-0(cols="5")
						v-container.pb-0
							v-row
								v-col.pa-0.pr-5
									span(
										:style="{ color: maxDebt.maxDebtSwitch && !loading ? 'var(--v-primary-base)' : 'rgba(0, 0, 0, 0.38)'}"
									).settings-subheader.d-flex.justify-start.text-subtitle-1 Policy on reaching the limit:
									v-hover(
										v-slot="{ hover }"
										v-for="option in maxDebt.debtOptions"
										:disabled="!maxDebt.maxDebtSwitch || loading"
										:key="option.caption")
										v-card(
											@click="maxDebt.maxDebtSwitch ? maxDebt.selectedDebtOption = option.value : 1"
											:ripple='false'
											:style="{ 'border-color': maxDebt.maxDebtSwitch && !loading ? '' : 'rgba(0, 0, 0, 0.12)', 'cursor' : maxDebt.maxDebtSwitch ? 'pointer' : 'default'}"
											:class="{ 'tile-hover':hover, 'tile-selected': maxDebt.selectedDebtOption == option.value}"
											flat
											outlined
											).remove-after-click-backround.pa-4.mb-3
											div.d-flex.justify-start
												v-icon(
													v-if="option.value === maxDebt.selectedDebtOption"
													:color="maxDebt.maxDebtSwitch && !loading ? 'var(--v-primary-base)' : 'rgba(0, 0, 0, 0.20)'") radio_button_checked
												v-icon(v-else :color="maxDebt.maxDebtSwitch && !loading ? 'var(--v-primary-base)' : 'rgba(0, 0, 0, 0.20)'") radio_button_unchecked
												span(
													:style="{ color: maxDebt.maxDebtSwitch && !loading ? 'var(--v-primary-base)' : 'rgba(0, 0, 0, 0.20)'}"
												).mt-1.mb-0.h6.pl-2 {{ option.caption }}
											div(
												:style="{ color: maxDebt.maxDebtSwitch && !loading ? 'var(--v-secondary-text-base)' : 'rgba(0, 0, 0, 0.20)'}"
											).pl-8.my-3.text-left {{ option.text }}
				v-row.text-center.mt-0.pt-0.pt-2
					v-col(cols='5').pa-0
						v-container
							v-row.mt-3
								v-col(cols='5').d-flex.justify-end.pa-0
									v-switch(switch :disabled="loading" v-model='emailReminder.switch' inset)
								v-col.d-flex.justify-start
									span.d-flex.align-self-center.font-weight-medium Debt Reminders
					v-col(cols='7').pt-0
						v-container
							v-row
								v-col(cols="5").d-flex.justify-start.align-center.pa-0
									span.pr-0 Send email every
								v-col(cols="4").pa-0.mt-7.d-flex.justify-start.align-center
									v-form(v-model='debtRemindersIsValid')
										v-text-field(
											v-model="emailReminder.timeIntervalDays"
											:disabled="!emailReminder.switch"
											outlined
											type="number"
											suffix='days'
											:rules="[rules.emailReminderTimeIntervalDays, rules.required]")
								v-col.d-flex.justify-start.align-center
									span.pl-2.pb-0 at 8 PM
				v-row.text-center.mt-0.pt-0.pt-2
					v-col(cols='5').pa-0
						v-container
							v-row.mt-3
								v-col(cols='5')
								v-col.d-flex.justify-start.align-center.mt-2
									span.d-flex.align-self-center.font-weight-medium Payment link
					v-col.pt-0
						v-form(v-model='paymentLinkIsValid')
							v-container
								v-row
									v-col.pa-0.pr-5.mt-7.d-flex.justify-start.align-center
										v-text-field(
											v-model="paymentLink"
											outlined
											:rules="[rules.required, rules.url]"
											label="Url used to accept payments")
				v-row(v-if="this.terminalCode")
					v-col(cols='5')
						v-container
							v-row
								v-col(cols='5')
								v-col.d-flex.justify-start.align-center
									div
										div.d-flex.align-self-center.font-weight-medium Terminal Code
										div.text--secondary.caption {{ this.$store.state.admin_groups[0].group_name }}
					v-col.pr-5.d-flex.justify-start.align-center
						div.mt-3
							div.font-weight-bold
								| {{ this.terminalCode }}
							div.text--secondary.caption
								| You need to enter this code on the order terminal in the room every time you restart it.
								| The code might change automatically in the future. You can't change the code yourself
				v-row(v-if="this.inviteLink")
					v-col(cols='5')
						v-container
							v-row
								v-col(cols='5')
								v-col.d-flex.justify-start.align-center
									div
										div.d-flex.align-self-center.font-weight-medium Invite Link
										div.text--secondary.caption {{ this.$store.state.admin_groups[0].group_name }}
					v-col.pr-5.d-flex.justify-start.align-center
						div.mt-3
							div.font-weight-bold.invite-link-container
								a(:href="inviteLink" target="_blank")
									| {{ this.inviteLink }}
							div.text--secondary.caption
								| Share this link with your friends! They can create an account after clicking the link 
								| and will automatically join {{ this.$store.state.admin_groups[0].group_name }}
				v-divider
				v-row
					v-col(cols='3')
					v-col(cols='6' align='center')
						v-btn(
							@click='saveSettings()'
							:disabled="!formIsValid || loading" color="primary"
						) Save Settings
						p.smallInfoText
							| in order to discard changes, just leave the page
					v-col(cols='3')
</template>

<script>
import { catchError } from "../../../mixins/catchError";
import { validators } from "../../../mixins/validators";
import Alert from "../../Alert.vue";
export default {
	mixins: [catchError, validators],
	data() {
		return {
			maxDebtIsValid: false,
			debtRemindersIsValid: false,
			paymentLinkIsValid: false,
			loading: false,
			messages: {
				saveSuccess: "New settings have been saved"
			},
			maxDebt: {
				maxDebtSwitch: undefined,
				maxDebtValue: 10,
				selectedDebtOption: 0,
				debtOptions: [
					{
						value: 0,
						caption: "Lenient",
						text: "Users will be reminded to pay their bill."
					},
					{
						value: 2,
						caption: "Semi-Strict",
						text:
							"Users won't be given the room key. If they go in debt in the middle of a purchase, they can still complete the purchase."
					},
					{
						value: 1,
						caption: "Strict",
						text:
							"Users won't be given the room key. They won't be able to complete a purchase where they would go below debt limit."
					}
				]
			},
			emailReminder: {
				switch: true,
				timeIntervalDays: 10
			},
			paymentLink: "",
			terminalCode: "",
			inviteLink: ""
		};
	},
	components: { Alert },
	mounted() {
		this.loading = true;
		const vm = this;
		this.$axios
			.get("/api/admin_settings")
			.then(response => {
				if (response.data?.maxDebtSwitch !== undefined) {
					vm.maxDebt.maxDebtSwitch =
						response.data.maxDebtSwitch == "true" ? true : false;
				}
				vm.maxDebt.maxDebtValue = parseInt(response.data.maxDebtValue);
				vm.maxDebt.selectedDebtOption =
					response.data.selectedDebtOption == "warn"
						? 0
						: response.data.selectedDebtOption == "block"
						? 1
						: 2;
				vm.emailReminder.switch =
					response.data.emailReminderSwitch == "true" ? true : false;
				vm.emailReminder.timeIntervalDays = parseInt(
					response.data.emailReminderTimeIntervalDays
				);
				vm.paymentLink = response.data.groupPage;
				vm.terminalCode = response.data.terminalCode;
				vm.inviteLink = response.data.inviteLink;
				vm.loading = false;
			})
			.catch(error => {
				vm.catchError(error);
			});
	},
	computed: {
		formIsValid() {
			return (
				(this.maxDebt.maxDebtSwitch === undefined ||
					!this.maxDebt.maxDebtSwitch ||
					this.maxDebtIsValid) &&
				(!this.emailReminder.switch || this.debtRemindersIsValid) &&
				this.paymentLinkIsValid
			);
		}
	},
	methods: {
		saveSettings() {
			this.loading = true;
			const vm = this;
			var newSettings = {
				maxDebtSwitch:
					this.maxDebt.maxDebtSwitch !== undefined
						? this.maxDebt.maxDebtSwitch.toString()
						: undefined,
				emailReminderSwitch:
					this.emailReminder.switch !== undefined
						? this.emailReminder.switch.toString()
						: undefined,
				groupPage: this.paymentLink
			};

			// only send settings when user toggled a corresponding switch on
			if (this.maxDebt?.maxDebtSwitch) {
				newSettings["maxDebtValue"] = this.maxDebt.maxDebtValue;
				newSettings["selectedDebtOption"] =
					this.maxDebt.selectedDebtOption == 0
						? "warn"
						: this.maxDebt.selectedDebtOption == 1
						? "block"
						: "semi";
			}

			if (this.emailReminder?.switch)
				newSettings[
					"emailReminderTimeIntervalDays"
				] = this.emailReminder.timeIntervalDays;

			// ------------------------------

			this.$axios
				.post("/api/admin_settings", newSettings)
				.then(() => {
					vm.content = this.messages.saveSuccess;
					vm.type = "success";
					this.$refs.alert.showAlert();
				})
				.catch(error => {
					vm.catchError(error);
				})
				.finally(() => {
					this.loading = false;
				});
		}
	}
};
</script>

<style lang="sass" scoped>
.v-card.on-hover.theme--dark
  background-color: rgba(#FFF, 0.8)
  >.v-card__text
    color: #000
</style>

<style lang="scss" scoped>
.remove-after-click-backround:focus::before {
	opacity: 0 !important;
}

.tile-hover {
	border: 1px solid var(--v-primary-base);
}

.tile-selected {
	border: 2px solid var(--v-primary-base);
}

.smallInfoText {
	margin-top: 10px;
	font-size: 12px;
	color: grey;
}

.settings-subheader {
	font-size: 17px !important;
}

.invite-link-container {
	overflow-wrap: break-word;
	word-wrap: break-word;
	word-break: break-word;
}
</style>
