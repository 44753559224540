/*
	Loads data using parameters from parent's "dataToLoad" on mounted.
	"dataToLoad" can be an object or an array.

	Data is saved in outputObject that has to be declared beforehand (because of how Vue reactivity works)
	outputObject's name can be specified in saveTo property. If not, url is used as a name
	Loading status is saved in outputObject.status, and uses values from the "statuses"

	There are 3 options for data to be loaded:
		1) Basic.
				– Default option. Saves the response to outputObject
		2) Paginated.
				– enabled with "paginate: true"
				– provides loadMore(outputObject) to get the next chunk
				– creates an additional loading status – "loadMoreStatus" in the output object
		3) Tabled
				– enabled with "table: true"
				– creates a watcher that reloads data once outputObject.tableOptions is changed
				– lets you stop the watcher by calling outputObject.unwatchFunction()

	What it does:
	For each object(obj) in dataToLoad:
		– makes a requests to api/{obj.url}
		– if addGroupId is set: api/{obj.url}?groupId={this.groupId}
		– creates an object consisting of metadata properties and data{} (or data[])
		– if obj.dataProcessor string or array is given, executes the corresponding function(s) on the data, replacing data with its output
		– saves resulting object
		– if obj.executeAfterLoading is given, executes the corresponding function

	Parent component data example:
	dataToLoad: [
		{
			url: "balances",
		},
		{
			url: "persons/list",
			saveTo: "friends"
		},
		{
			url: "account/purchases",
			saveTo: "purchases",
			dataProcessor: ["fillCurrentPages", "renameElements"],
			executeAfterLoading: "displayStatus"
			paginate: true,
			itemsPerLoad: 2
		},
		{
			url: "persons",
			table: true,
			dataProcessor: "addTags",
			tableOptions: {
				page: 3
				itemsPerPage: 10,
				selectedPerson: this.selectedPerson
			}
		},
		{
			url: "drinks",
			addGroupId: true
		}
	],
	balances: {},
	friends: {},
	purhcases: {},
	persons: {}
*/

import Vue from "vue";
import { catchError } from "./catchError";
export const dataLoader = {
	mixins: [catchError],
	data() {
		return {
			searchTimeout: null,
			statuses: {
				loading: "loading",
				success: "finished",
				error: "error"
			}
		};
	},
	methods: {
		getTableParams(tableOptions) {
			let params = {};
			params.page = tableOptions.page;
			params.per_page =
				tableOptions.itemsPerPage === -1
					? "all"
					: tableOptions.itemsPerPage ?? 10; // 10 is arbitrary
			if (tableOptions.sortBy)
				params.sort_by = tableOptions.sortBy[0] || "None";
			if (tableOptions.sortDesc)
				params.sort_desc = tableOptions.sortDesc[0] || "false";
			if (tableOptions.selectedPerson)
				params.person_id = tableOptions.selectedPerson.id;
			if (tableOptions.search) params.search = tableOptions.search;
			return params;
		},
		getParams(entityToLoad) {
			let params = {};

			if (!entityToLoad.paginate && !entityToLoad.table)
				params.per_page = "all";

			if (entityToLoad.tableOptions)
				params = this.getTableParams(entityToLoad.tableOptions);
			else if (entityToLoad.itemsPerLoad)
				params.per_page = entityToLoad.itemsPerLoad;

			if (entityToLoad.addGroupId) params.groupId = this.selectedGroupId;

			return params;
		},
		loadData(url, params) {
			return this.$axios.get(`/api/${url}`, {
				params
			});
		},
		loadEntityData(entityToLoad) {
			const params = this.getParams(entityToLoad);
			return this.loadData(entityToLoad.url, params);
		},
		hasMetaData(data) {
			return data.pageInfo?.total !== undefined && data.data !== undefined;
		},
		formatData(data) {
			if (this.hasMetaData(data)) return data.data;
			else return data;
		},
		getMetaDataForPaginate(itemsPerLoad) {
			return {
				itemsPerLoad: itemsPerLoad ?? 10, // 10 is arbitrary
				sequencesLoaded: 1,
				loadMoreStatus: null
			};
		},
		getMetaDataForTable(entityToLoad) {
			return {
				page: entityToLoad.tableOptions?.page ?? 1,
				itemsPerPage: entityToLoad?.tableOptions?.itemsPerPage ?? 10,
				selectedPerson: entityToLoad.tableOptions?.selectedPerson ?? null,
				sortBy: entityToLoad?.tableOptions?.sortBy ?? [],
				sortDesc: entityToLoad?.tableOptions?.sortDesc ?? [],
				search: entityToLoad?.tableOptions?.search ?? ""
			};
		},
		getMetaData(data, entityToLoad) {
			let meta = {};

			if (this.hasMetaData(data)) meta.totalItems = data.pageInfo.total;
			meta.loadedFromUrl = entityToLoad.url;

			if (entityToLoad.paginate)
				meta = {
					...meta,
					...this.getMetaDataForPaginate(entityToLoad.itemsPerLoad)
				};
			else if (entityToLoad.table)
				meta = {
					...meta,
					tableOptions: this.getMetaDataForTable(entityToLoad)
				};
			return meta;
		},
		composeEntity(entityData, entityToLoad) {
			return entityData
				.then(resonse => {
					let entity = {};

					let rawData = resonse.data;
					if (entityToLoad.paginate || entityToLoad.table)
						entity = { ...this.getMetaData(rawData, entityToLoad) };

					//ToDO: Why paginate condition also here?
					if (entityToLoad.paginate && entityToLoad.dataProcessor)
						entity = {
							...entity,
							dataProcessor: entityToLoad.dataProcessor
						};

					let formattedData = this.formatData(rawData);
					if (entityToLoad.dataProcessor)
						formattedData = this.applyDataProcessors(
							entityToLoad,
							formattedData
						);

					entity.data = formattedData;
					entity.status = this.statuses.success;
					entity.loadedFromUrl = entityToLoad.loadedFromUrl ?? entityToLoad.url;
					entity.addGroupId = entityToLoad.addGroupId;
					return entity;
				})
				.catch(error => {
					let entity = {
						status: this.statuses.error,
						error: error?.response?.data.error ?? "Failed to compose entity",
						data: []
					};
					if (entityToLoad.table)
						entity.tableOptions = this.getMetaDataForTable({});
					return entity;
				});
		},
		async updateEntityAndLoadData(entity, saveTo) {
			await Vue.set(this[saveTo], "status", this.statuses.loading);
			const params = this.getParams(entity);
			const data = await this.loadData(entity.loadedFromUrl, params);
			await Vue.set(this[saveTo], "totalItems", data.data.pageInfo.total);
			await Vue.set(this[saveTo], "data", data.data.data);
			await Vue.set(this[saveTo], "status", this.statuses.success);
		},
		async startSearchTimer(entity, saveTo) {
			await Vue.set(this[saveTo], "status", this.statuses.loading);
			if (this.searchTimeout) clearTimeout(this.searchTimeout);
			this.searchTimeout = setTimeout(() => {
				this.updateEntityAndLoadData(entity, saveTo);
			}, 1000);
		},
		addTableWatcher(entity, saveTo) {
			entity.unwatchFunction = this.$watch(
				`${saveTo}.tableOptions`,
				async (newValue, oldValue) => {
					// don't load if only search has been changed
					let abort = true;
					for (const key in newValue) {
						if (
							newValue[key] !== oldValue[key] &&
							key !== "search" &&
							oldValue[key] !== undefined
						) {
							abort = false;
							break;
						}
					}
					if (!abort) await this.updateEntityAndLoadData(entity, saveTo);
				},
				{ deep: true }
			);
		},
		addGroupIdWatcher(entity, saveTo) {
			this.$watch("selectedGroupId", async newValue => {
				if (newValue !== 0) await this.updateEntityAndLoadData(entity, saveTo);
			});
		},
		createLoadingEntityObject(addTableMetaData) {
			let obj = { status: this.statuses.loading, data: [] };
			if (addTableMetaData) obj.tableOptions = this.getMetaDataForTable({});
			return obj;
		},
		createAllLoadingEntityObjects(entitiesToLoad) {
			const vm = this;
			for (const entityToLoad of entitiesToLoad) {
				const saveTo = entityToLoad.saveTo ?? entityToLoad.url;
				Vue.set(vm, saveTo, this.createLoadingEntityObject(entityToLoad.table));
			}
		},
		async loadEntitiesAndSave(entitiesToLoad) {
			if (!entitiesToLoad) return;
			if (!Array.isArray(entitiesToLoad)) entitiesToLoad = [entitiesToLoad];
			if (entitiesToLoad.length === 0) return;

			this.createAllLoadingEntityObjects(entitiesToLoad);

			const vm = this;
			for (const entityToLoad of entitiesToLoad) {
				const saveTo = entityToLoad.saveTo ?? entityToLoad.url;
				if (vm[saveTo].unwatchFunction) vm[saveTo].unwatchFunction();
				const entityData = this.loadEntityData(entityToLoad);
				const entity = await this.composeEntity(entityData, entityToLoad);
				await Vue.set(vm, saveTo, entity);

				if (entityToLoad.executeAfterLoading) {
					if (!Array.isArray(entityToLoad.executeAfterLoading))
						entityToLoad.executeAfterLoading = [
							entityToLoad.executeAfterLoading
						];
					entityToLoad.executeAfterLoading.forEach(func => {
						if (!this[func])
							console.error(
								`Can't executeAfterLoading - "${func}()" not found`
							);
						else this[func]();
					});
				}

				if (entityToLoad.table && entity.status === this.statuses.success) {
					this.addTableWatcher(entity, saveTo);
				}

				if (entityToLoad.addGroupId) {
					this.addGroupIdWatcher(entity, saveTo);
				}
			}
		},
		applyDataProcessors(entity, newItems) {
			let entityCopy = { ...entity };
			if (!Array.isArray(entityCopy.dataProcessor))
				entityCopy.dataProcessor = [entityCopy.dataProcessor];
			entityCopy.dataProcessor.forEach(
				processor => (newItems = this[processor](newItems))
			);
			return newItems;
		},
		async loadMore(entity) {
			Vue.set(entity, "loadMoreStatus", this.statuses.loading);
			await this.loadData(entity.loadedFromUrl, {
				per_page: entity.itemsPerLoad,
				page: entity.sequencesLoaded + 1
			})
				.then(response => {
					Vue.delete(entity, "loadMoreError");
					Vue.set(entity, "loadMoreStatus", this.statuses.success);
					let newItems = this.formatData(response.data);
					if (entity.dataProcessor)
						newItems = this.applyDataProcessors(entity, newItems);
					Vue.set(entity, "data", entity.data.concat(newItems));
					Vue.set(entity, "sequencesLoaded", ++entity.sequencesLoaded);
				})
				.catch(error => {
					Vue.set(entity, "loadMoreStatus", this.statuses.error);
					Vue.set(entity, "loadMoreError", error.response.data.error);
				});
		},
		loadAll() {
			this.loadEntitiesAndSave(this.dataToLoad);
		}
	},
	mounted() {
		if (!this.dataToLoadPreventLoadingOnMount) this.loadAll();
	}
};
