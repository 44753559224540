<template lang="pug">
div.white
	div.ma-0.pt-0(v-if='$store.getters.user_logged_in_from_order_terminal')
		alert(
			:content='content'
			ref='alert'
			@resetAlert='resetAlert'
			:alertImage='alertImage'
			:type='type')
		input#drink-input(
			ref='drinkinput'
			v-model='drink_input'
			@keydown.enter='requestDrinkInfo()'
			type='text')
		v-snackbar.inactivityReminder(
			v-model='inactivityReminder'
			centered
			vertical
			color="primary lighten-5"
			absolute
			rounded="l"
			:timeout='showLogOutReminderAt * 1000'
			height='400'
			width='100%')
			div.text-center.mt-3
				h1.primary--text.text--darken-1 Due to inactivity, your order will be aborted in:
				p.mt-16.time-til-auto-logout.primary--text.text--darken-2 {{ timeTilLogOutLeft }} sec
			template(v-slot:action='{ attrs }')
				v-btn.mb-4.mr-15(
					color='primary darken-1'
					height="90px"
					width="450px"
					elevation="4"
					v-bind='attrs'
					@click='resetInactivityTimer'
					timeTilLogOutLeft = timeTilLogOut).mr-3.hide-cursor
					span.h2.ma-0 I'm still here!
		ScanInstruction(
			v-if='orderIsEmpty'
			@cancel='cancelOrder')
		div(v-else)
			v-scroll-y-transition
				v-overlay(:value="loading" opacity="0.8").px-5
					v-card(color="white" elevation="20")
						v-card-text.pa-8
							div(v-if="loading && !orderCompleted").pa-3
								div.mb-10.complementary--text.text-h3 Loading
								v-progress-linear(rounded height="25" indeterminate color="complementary")
							div(v-else)
								div.mx-10
									v-img.mx-auto(src="../../../public/img/thank-you.gif"
																contain width="400px"
																position="center center")
									div.mb-4.mt-8.complementary--text.text-h3.text-center All set!
			v-container.ma-0.pa-0(fluid)
				v-img(:src="backgroundImage")
					v-virtual-scroll(height="976"
													bench="3"
													:item-height="250"
													:items="getOrderAsArray").hide-scrollbar-y.pb-7
						template(v-slot:default="{ index, item }")
							div(v-if='index="1"' style="height: 20px")
							v-card(
								color="primary-light"
								elevation="15"
								).pt-2.mx-6.primary--text
								v-row.pt-2
									v-col(cols='8')
										div(
											style="max-width: 360px"
										).d-inline-block.pl-8.text-truncate.drink-name {{ item.name }}
									v-col.d-flex.align-center.justify-center.text-center
										div.drink-price {{ formatMonetaryValue(item.price) }}
								v-row.pl-5
									v-col(cols="10").d-flex.align-center
										v-btn(
											@click='decrease(item.id); resetInactivityTimer()'
											:disabled='item.quantity <= 1'
											color="primary"
											fab
											text
											:height="dimensions.quantityButtons.y"
											:width="dimensions.quantityButtons.x"
											elevation="3"
											).primary-dark--text.hide-cursor
											span.text-h3.quantity-button.pb-1 –
										div(
											style="width: 120px"
										).d-inline-block.text-h2.text-center {{ item.quantity }}
										v-btn(
											@click='increaseBy(item.id, 1); resetInactivityTimer()'
											color="primary"
											fab
											text
											:height="dimensions.quantityButtons.y"
											:width="dimensions.quantityButtons.x"
											elevation="3"
											).primary-dark--text.hide-cursor
											span.text-h3.quantity-button +
										span.mx-2
										v-btn(
											@click='increaseBy(item.id, 5); resetInactivityTimer()'
											color="primary"
											fab
											text
											:height="dimensions.quantityButtons.y"
											:width="dimensions.quantityButtons.x"
											elevation="3"
											).hide-cursor
											span.text-h5.quantity-button.plus-five-button +5
									v-col.d-flex.align-center
										v-btn(
											@click='deleteItem(item.id); resetInactivityTimer()'
											x-large
											icon
											elevation="0").pr-2.hide-cursor
											v-icon(size="36") mdi-close
							div(v-if='index = getIndexOfLastItemInOrderArray' style="height: 188px")
					div#action-buttons
						v-card(
							elevation="25"
							style="border-radius: 80px"
							:style='footerColor'
							).px-8.rounded-b-0.pb-2
							div.d-flex.text-h4.hide-cursor.px-4.primary-light--text.text--lighten-2.justify-center.rounded-t-0.mx-16.pt-4
								span.mr-4 {{ this.$store.state.user.name }}
								span {{ formatMonetaryValue(this.$store.state.balance) }}
							v-card-text.white--text.text-center.pb-3
								v-chip(
									color='terminal-footer-top'
									v-if='showWarning' large).mb-4.px-4
									v-icon(color="primary-light" size='30') error
									span.h4.ma-0.primary-light--text.text--center.mx-2
										span(v-if="maxDebtExceeded") You can&apos;t go that much in debt
										span(v-else) This purchase will get you in debt
								v-row.pa-1
									v-col(cols='4').d-flex.align-center.justify-center
										v-btn(
											height="90px"
											elevation="8"
											width="100%"
											color="primary"
											@click='cancelOrder'
											:disabled='buttons_blocked'
											outlined
											).hide-cursor
											v-icon(color="primary" size="42") mdi-logout
									v-col(cols='8' v-if='!orderIsEmpty').d-flex.align-center.justify-center
										v-btn(
											height="90px"
											elevation="8"
											width="100%"
											color="primary"
											@click='finishOrder'
											:disabled='maxDebtExceeded || buttons_blocked'
											).hide-cursor
											span.text-h4.primary-dark--text
												span Pay
												span.pl-2.font-weight-medium {{ formatMonetaryValue(total) }}
	v-footer(bottom fixed padless)
		v-card(
			flat
			tile
			:color="'terminal-footer-bottom'"
			width="100%"
			).text-center.d-flex.justify-center.py-2.text-h6.primary--text.text--darken-2
				div.mr-2.d-inline-block Follow us on Slack:
				div.font-weight-bold.d-inline-block
					span #BiederDrinks
</template>

<script>
import Vue from "vue";
import { catchError } from "../../mixins/catchError";
import { validators } from "../../mixins/validators";
import Alert from "../Alert";
import ScanInstruction from "./ScanInstruction.vue";

export default {
	mixins: [catchError, validators],
	components: { Alert, ScanInstruction },
	name: "TerminalOrder",
	data() {
		return {
			backgrounds: [
				{
					name: "Mint",
					img: require("../../../public/img/order-background-Mint.jpg")
				},
				{
					name: "Magenta",
					img: require("../../../public/img/order-background-Magenta.jpg")
				},
				{
					name: "Orange",
					img: require("../../../public/img/order-background-Orange.jpg")
				}
			],
			alertImage: "",
			inactivityReminder: false,
			showLogOutReminderAt: 20,
			timeTilLogOut: 180,
			timeTilLogOutLeft: 180,
			logOutIntervalId: null,
			orderInfo: {},
			drink_input: "",
			loading: false,
			orderCompleted: false,
			buttons_blocked: false,
			dimensions: {
				quantityButtons: { x: "100px", y: "100px" }
			},
			rules: {
				required: value => !!value || "Required",
				rfid: value => {
					const pattern = /^[A-Z0-9]{2} [A-Z0-9]{2} [A-Z0-9]{2} [A-Z0-9]{2}$/;
					return pattern.test(value);
				}
			}
		};
	},
	methods: {
		resetAlert() {
			this.alertImage = "";
		},
		resetInactivityTimer() {
			let vm = this;
			this.inactivityReminder = false;
			this.timeTilLogOutLeft = this.timeTilLogOut;
			clearInterval(this.logOutIntervalId);
			this.logOutIntervalId = window.setInterval(function() {
				vm.timeTilLogOutLeft -= 1;
				if (vm.timeTilLogOutLeft == 0) vm.cancelOrder();
				if (vm.timeTilLogOutLeft - vm.showLogOutReminderAt <= 0)
					vm.inactivityReminder = true;
			}, 1000);
		},
		increaseBy(key, amount) {
			this.orderInfo[key].quantity += amount;
		},
		decrease(key) {
			if (this.orderInfo[key].quantity == 1) return;
			this.orderInfo[key].quantity -= 1;
		},
		deleteItem(key) {
			Vue.delete(this.orderInfo, key);
		},
		requestDrinkInfo() {
			this.resetInactivityTimer();
			const vm = this;
			const drink_input = String(this.drink_input);

			if (this.rules.rfid(drink_input)) {
				this.drink_input = "";
				let audio = new Audio(
					require("../../../public/sounds/someone_in_the_room.mp3")
				);
				audio.play();
				return;
			}

			this.loading = true;

			this.$axios_instance_no_interceptors
				.get("api/drinks/" + drink_input, {})
				.then(response => {
					if (response.data.data.length === 0) {
						this.content = "We don't recognize this drink";
						this.type = "warning";
						this.alertImage = "eyes";
						this.$refs.alert.showAlert();
						return;
					}
					var drink = response.data.data[0];
					if (drink.id in vm.orderInfo) {
						vm.orderInfo[drink.id].quantity += 1;
						return;
					}
					vm.$set(vm.orderInfo, drink.id, {});
					for (var key in drink) {
						vm.$set(vm.orderInfo[drink.id], key, drink[key]);
					}
					vm.$set(vm.orderInfo[drink.id], "quantity", 1);
				})
				.catch(error => {
					vm.catchError(error);
				})
				.finally(() => {
					vm.loading = false;
					vm.drink_input = "";
				});
		},
		finishOrder() {
			const vm = this;
			this.buttons_blocked = true;
			this.loading = true;

			// this is awkward. Think about keeping orders in an array instead of the orederInfo object
			var orderToSend = { drinks: [], terminal: true };
			for (const key in this.orderInfo) {
				orderToSend.drinks.push({
					drinkId: vm.orderInfo[key]["id"],
					quantity: vm.orderInfo[key]["quantity"]
				});
			}

			this.$axios_instance_no_interceptors
				.post("api/order", orderToSend)
				.then(response => {
					vm.$store.commit("setBalance", response.data.balance);
				})
				.catch(error => {
					vm.catchError(error);
				})
				.finally(() => {
					vm.orderCompleted = true;
					setTimeout(() => {
						vm.loading = false;
						vm.orderCompleted = false;
						clearInterval(this.logOutIntervalId);
						window.location.href = "/#/logout";
					}, 2800);
				});
		},
		cancelOrder() {
			clearInterval(this.logOutIntervalId);
			window.location.href = "/#/logout/cancel";
		}
	},
	computed: {
		backgroundImage() {
			return this.backgrounds.find(
				bg => bg.name === this.$store.state.settings.theme.name.split(" ")[1]
			).img;
		},
		getOrderAsArray() {
			return Object.values(this.orderInfo);
		},
		getIndexOfLastItemInOrderArray() {
			return this.getOrderAsArray.length - 1;
		},
		orderIsEmpty() {
			return Object.keys(this.orderInfo).length === 0;
		},
		total() {
			var total = 0;
			for (var key in this.orderInfo) {
				total += this.orderInfo[key].price * this.orderInfo[key].quantity;
			}
			return Math.round(total * 100) / 100;
		},
		showWarning() {
			return (
				this.$store.state.balance - this.total <
					-this.$store.state.maxDebtValue && this.$store.state.maxDebtSwitch
			);
		},
		maxDebtExceeded() {
			return (
				this.$store.state.balance - this.total <
					-this.$store.state.maxDebtValue &&
				this.$store.state.maxDebtSwitch &&
				this.$store.state.maxDebtSelectedOption === "block"
			);
		},
		footerColor() {
			return {
				"background-color": this.hexToRgbA(
					this.$store.state.settings.theme["terminal-footer-top"],
					0.5
				),
				"background-image": `linear-gradient(to bottom, ${this.hexToRgbA(
					this.$store.state.settings.theme["terminal-footer-top"],
					0.65
				)},
		${this.hexToRgbA(this.$store.state.settings.theme["terminal-footer-bottom"], 1)}
	)`
			};
		}
	},
	mounted() {
		const vm = this;
		window.setInterval(function() {
			if (vm.$refs.drinkinput) vm.$refs.drinkinput.focus();
		}, 300);
		this.resetInactivityTimer();
	}
};
</script>

<style scoped>
#drink-input {
	position: absolute;
	top: -500px;
}

#action-buttons {
	position: absolute;
	width: 100%;
	bottom: 0;
	z-index: 999;
}

.action-buttons-background {
	background-color: rgba(255, 83, 25, 0.5);
	background-image: linear-gradient(
		rgba(255, 83, 25, 0.65),
		rgb(255, 230, 170)
	);
}

.quantity-button {
	font-size: 70px !important;
	padding-top: 6px;
}

.plus-five-button {
	font-size: 56px !important;
}

#footer-slack-img {
	vertical-align: bottom;
}

.inactivityReminder {
	z-index: 3;
}

.time-til-auto-logout {
	font-size: 80px;
}

:root {
	--inactive-gray: #961212;
}

.drink-name {
	font-size: 42px;
}

.drink-price {
	font-size: 36px;
}
</style>
