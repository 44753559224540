<template lang="pug">
.text-center
	v-dialog(
		v-model='showDialog'
		width='400'
		@click:outside='cancel')
		v-card
			template(v-if='!requestStatus')
				v-card-title(style="word-break: break-word").text-h5.primary--text.text-center
					| Are you sure you want to decline chip request?
				div.pa-5
					div
						| We will send an email to {{ person.name }} from {{ formatRoomOrWGNumber(person.room, true) }}
						| saying you decided not to give them a chip.
					div.mt-4
						| {{ person.name }} will be able to apply again
				v-divider
				v-card-actions
					v-spacer
					v-btn(color='primary' text @click='cancel').mb-2.mr-3
						| No, go back
					v-btn(color='primary' @click='decline').mb-2.px-6
						| Decline
					v-spacer
			RequestSender(
				v-else
				:status='requestStatus'
				:statuses="requestStatuses"
				@cancel='cancel')
</template>

<script>
import RequestSender from "../../Helpers/RequestSender.vue";
export default {
	name: "decline-chip-request-dialog",
	props: ["person", "showDialog", "requestStatus", "requestStatuses"],
	data() {
		return {};
	},
	methods: {
		decline() {
			const person = Object.assign({}, this.person, { approve: false });
			this.$emit("decline", person);
		},
		cancel() {
			this.$emit("cancel");
		}
	},
	components: { RequestSender }
};
</script>
