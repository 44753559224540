<template lang="pug">
	div
		.text-h6.text-sm-h5.text-md-h4.mb-4.primary--text
				| I took drinks but didn't scan them
		div
			p
				| Shame on you!
			p
				| Kidding :)
				span.primary--text.mr-1.ml-2 {{ $appTitle }}
				| lets admins manually enter your purchase. Just contact one of them:
		div.pb-4
			div.mr-1.px-4
				span(v-for='(admin, index) in admins.data')
					NameAndRoomBadge(
						:name='admin.name'
						:room='admin.room'
						).ma-1
</template>

<script>
import { dataLoader } from "@/mixins/dataLoader";
import NameAndRoomBadge from "../../Elements/NameAndRoomBadge.vue";
export default {
	mixins: [dataLoader],
	data() {
		return {
			dataToLoad: {
				url: "show_admins",
				saveTo: "admins"
			},
			admins: {}
		};
	},
	components: { NameAndRoomBadge }
};
</script>

<style scoped></style>
