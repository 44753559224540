<template lang="pug">
BadgeBase(
	:size='size'
	text='SPLIT'
	borderColorClass='split'
	textColorClass='split-text'
	addLetterSpacing='true'
)
</template>
<script>
import BadgeBase from "./BadgeBase.vue";
export default {
	props: ["size"],
	components: { BadgeBase }
};
</script>
