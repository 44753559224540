<template lang="pug">
div
	.d-sm-flex.justify-center.my-3.my-sm-6.px-3.mx-1
		v-card(
			v-for='(option, i) in options'
			:key='i'
			:disabled='!splitIsPossible && i === 0'
			@click='$emit("setAskToPay", option.name)'
			:width='$vuetify.breakpoint.xs ? "" : "50%"'
			v-ripple="{ class: `${option.color}--text` }"
			).px-4.py-sm-6.py-1.mx-3.text-center.mb-3.mb-sm-0.d-flex.d-sm-inline-block
			div.mr-4.mr-sm-0.my-2.my-sm-0
				v-icon(
					:size='$vuetify.breakpoint.smAndUp ? 50 : 34'
					:color='option.color').my-1.my-sm-0 {{ option.icon }}
				.my-sm-4.d-sm-flex.justify-center
					div(
						v-if='option.preTitle'
						).mr-sm-2.primary--text.text-sm-h5.body-2.my-0 {{ option.preTitle }}
					component(
						:is='option.component'
						:size='$vuetify.breakpoint.smAndUp ? "l" : "m"')
			div.d-flex.align-center
				div(v-html='option.text').text-secondary
	.d-flex.justify-center.mt-6.mt-sm-8.mb-4
		.text-secondary.caption.text-sm-body-1.text-center
			span.mr-2 We offer
			InstantRefundBadge(:small='$vuetify.breakpoint.xs')
			span.ml-2 for either option
</template>
<script>
import InstantRefundBadge from "../../../Elements/InstantRefundBadge.vue";
import Badges from "@/components/Elements/Badges/payMeAndSplitBadges.js";
export default {
	props: ["splitIsPossible"],
	name: "request-type-selector",
	data() {
		return {
			options: [
				{
					name: "split",
					component: "SplitBadge",
					color: "split",
					icon: "diversity_3",
					title: "SPLIT",
					applyLetterSpacing: true,
					text: "Split a bill for this purchase with your friend(s)"
				},
				{
					name: "askToPay",
					component: "PayMeBadge",
					color: "pay-me",
					icon: "volunteer_activism",
					//preTitle: "Ask for",
					title: "PayMe",
					applyLetterSpacing: false,
					text: "Ask your friend(s) to pay for <b>the entire</b> purchase"
				}
			]
		};
	},
	components: { ...Badges, InstantRefundBadge }
};
</script>
