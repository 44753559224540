<template lang="pug">
v-container(fluid).pa-0
	v-sheet(
		color="primary-light"
		).pt-6.pb-10
		v-row(no-gutters)
			v-col(cols='0' lg='1' xl='2')
			v-col(cols='12' lg='10' xl='8')
				div.my-7.text-center
					div.complementary--text.text--lighten-2.text-h4
						| Locations
					div.text--text.text--lighten-1.mt-1
						| (G-Raums)
				div.d-flex.justify-center.flex-wrap.mt-3
					v-card(
						:tile='$vuetify.breakpoint.xs'
						v-for='(raum, index) in gRaums'
						:key='index'
						:style="[$vuetify.breakpoint.smAndUp ? {'max-width': '500px'} : {}]"
						).my-6.mx-4.location-card
						v-img(
							:src="raum.pic"
							height='300'
							).align-end
							v-sheet(
								:color="'rgba(0, 0, 0, 0.85)'"
								).py-1.pl-2.align-center.white--text.justify-center.d-sm-flex
								div.text-center
									div.text-h5.pt-3.pb-1.mt-sm-0
										| {{ raum.title }}
									div.d-flex.justify-center.py-2
										v-chip(
											:color='raum.color'
											outlined
											small) {{ raum.text }}
									div.d-flex.justify-center.pb-2.body-2.grey--text
										| {{ raum.text2 }}
				div.text-center.mt-7
					CheckItOutButton(
						text="Where to find"
						page="support/WhereToFind")
			v-col(cols='0' lg='1' xl='2')
</template>

<script>
import CheckItOutButton from "./Elements/CheckItOutButton.vue";
export default {
	data() {
		return {
			gRaums: [
				{
					pic: require("../../../public/img/haus2.webp"),
					title: "House 2",
					color: "success lighten-3",
					text: "In service since 17 May 2021"
				},
				{
					pic: require("../../../public/img/haus4.webp"),
					title: "House 4",
					color: "success lighten-3",
					text: "In service since 13 June 2023"
				}
			]
		};
	},
	components: { CheckItOutButton }
};
</script>

<style scoped>
#background {
	height: 100vh;
	background-image: url("../../../public/img/biederstein-login-background.jpg");
	background-size: cover;
}

.location-card {
	-webkit-box-shadow: 1px 1px 15px 3px var(--v-complementary-lighten3) !important;
	box-shadow: 1px 1px 15px 3px var(--v-complementary-lighten3) !important;
}
</style>
