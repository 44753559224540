<template lang="pug">
v-card(flat tile)
	v-img(:src="data.img" :height='$vuetify.breakpoint.xs ? "150px" : "250px"')
		.text-h6.data-text.white--text
			| {{ data.text }}
</template>
<script>
export default {
	components: {},
	props: ["data"],
	data() {
		return {};
	},
	methods: {},
	computed: {}
};
</script>
<style lang="scss">
.data-text {
	padding: 6px 6px 2px 8px;
	position: absolute;
	bottom: 0;
	width: 100%;
	background: rgb(255, 255, 255);
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0) 6%,
		rgba(0, 0, 0, 0.7) 67%
	);
}
</style>
