<template lang="pug">
div(:class="{'body-2' : isMobile, 'white' : isWhiteBackground, 'background' : !isWhiteBackground}").d-flex.justify-center.py-6.text-center
	div.background--text.text--darken-2
		router-link(to="/gdpr").background--text.text--darken-2 Terms & Conditions
		|
		| •
		|
		router-link(to="/gdpr/privacy-policy").background--text.text--darken-2 Privacy Policy
		|
		| •
		|
		router-link(to="/gdpr/legal-notice").background--text.text--darken-2 Legal Notice
</template>
<script>
export default {
	props: ["isMobile", "isWhiteBackground"]
};
</script>
