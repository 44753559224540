<template lang="pug">
v-list
	v-list-group(
		v-for='(category, i) in categories'
		:key='i'
		v-model='category.active'
		:prepend-icon="category.icon"
		:color='category.textAndIconColor'
		no-action)
		template(v-slot:activator)
			v-list-item-content
				v-list-item-title(v-text='category.caption')
		v-list-item(
			v-for='link in category.links'
			@click='$emit("navigate", {componentName: link.componentName, category: category})'
			).text-decoration-none
			v-list-item-content
				div(
					:style="{color: selectedArticle === link.componentName ? category.textAndIconColor : 'black'}"
					) {{ link.text }}
</template>
<script>
export default {
	props: ["categories", "selectedArticle"]
};
</script>

<style lang="scss" scoped></style>
