<template lang="pug">
div.white
	WhatsNewFirst
	v-sheet(
		max-width='1900px').mx-auto
		MeetAccount
	div.background.pt-16
		v-sheet(
			max-width='1100'
			).mx-auto.rounded-xl.background.pb-8
			Requests.my-16.py-16.rounded-xl.router-Split.router-PayMe
		v-sheet(
			color="background"
			height='40')
		Devices
		v-sheet(
			color="background"
			height='90')
		PickYourStyle.mt-sm-16.mx-sm-10.primary-light
		InstantRefund.py-16.rounded-xl.router-PendingBalance
		v-sheet(
			max-width='1200'
			).mx-auto.rounded-xl.background.mb-10
	Also(@openFeedbackDialog='$emit("openFeedbackDialog")')
</template>

<script>
import Features from "/";
import WhatsNewFirst from "./WhatsNewFirst.vue";
export default {
	data() {
		return {};
	},
	created() {},
	methods: {},
	computed: {},
	components: { ...Features, WhatsNewFirst }
};
</script>

<style scoped>
.over {
	position: absolute;
	z-index: 10;
	width: 100%;
	/* top: 100vh; */
}

.st {
	z-index: -1;
	position: fixed;
	width: 100%;
	top: 0px;
}

.mt {
	margin-top: 30vh;
}
</style>
