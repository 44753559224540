<template lang="pug">
.d-flex.justify-center
	div(v-if='status===statuses.success').d-flex
		v-icon(
			size='20'
			color='success'
			).mr-1 check
		div {{ successMessage }}
	div(v-if='status===statuses.error').d-flex
		v-icon(
			size='20'
			color='error'
			).mr-1 close
		div Couldn't update {{ title }}
	.d-flex(v-if='status===statuses.loading')
		v-progress-circular(
			size='18'
			width="2"
			indeterminate
			).mr-2
		div Updating {{ title }}
</template>
<script>
export default {
	props: {
		statuses: [],
		status: String,
		title: String,
		successMessage: String
	},
	mixins: [],
	data() {
		return {};
	},
	methods: {},
	computed: {},
	components: {}
};
</script>

<style lang="scss" scoped></style>
