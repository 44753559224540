<template lang="pug">
v-card(flat).body-1.primary--text.text--darken-3.white.pa-6
	.text-h6.text-center Are you sure you want to decline this request?
	.d-flex.flex-wrap.justify-center.mt-4
		| We'll let {{ requestInitiator.name }} know
	.pb-2
		.d-flex.justify-center.mt-10.mb-6
			v-btn(
				@click="$emit('decline')"
				color='primary')
				v-icon.mr-1 block
				| Yes, Decline
		.d-flex.justify-center
			v-btn(
				@click="$emit('close')"
				color='primary'
				text)
				| No, I changed my mind
</template>
<script>
export default {
	props: ["requestInitiator"],
	name: "decline-request-confirmation-dialog",
	data() {
		return {};
	}
};
</script>
