<template lang="pug">
	div.background.fill-height
		v-row
			v-col.d-flex.justify-center.pb-0
				v-btn-toggle(v-model='historyToggle' background-color="primary darken-1" color="primary lighten-4" borderless mandatory rounded)
					v-btn(v-for="(button, index) in items" :key='index' :value='button.value' small)
						v-icon(color="primary darken-2" :size="iconSize")
							| {{button.icon}}
						span.pl-1.primary--text.text--darken-2 {{button.text}}
		v-row
			v-col.pt-0
				AdminActionHistory(v-if='historyToggle == "AdminActionHistory"')
				PriceHistory(v-else-if='historyToggle == "PriceHistory"')
				KeyFinder(v-else-if='historyToggle == "KeyFinder"')
</template>

<script>
import PriceHistory from "./PriceHistory.vue";
import AdminActionHistory from "./AdminActionHistory.vue";
import KeyFinder from "./KeyFinder.vue";

export default {
	components: { PriceHistory, AdminActionHistory, KeyFinder },
	data() {
		return {
			historyToggle: "AdminActionHistory",
			iconSize: "18",
			items: [
				{
					value: "AdminActionHistory",
					icon: "pending_actions",
					text: "Admins' actions"
				},
				{ value: "PriceHistory", icon: "request_quote", text: "Price history" },
				{ value: "KeyFinder", icon: "key", text: "Key finder" }
			]
		};
	}
};
</script>

<style scoped></style>
