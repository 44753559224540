export const setTheme = {
	data() {
		return {
			themes: [
				{
					name: "Equanimous Mint",
					primary: "#107a8b",
					"primary-light": "#fff5e5",
					complementary: "#a8493e",
					background: "#ffe3ed",
					"background-text": "#81a3d9",
					"user-badge": "#bff6ff",
					"user-badge-text": "#316a73",
					"user-badge-room": "#4890a6",
					"account-card-background-comp": "#fffef6",
					"account-card-text": "#916a39",
					"account-card-text-light": "#a8a077",
					"account-card-divider": "#fbeed9",
					"account-card-text-comp": "#978251",
					"complementary-text-comp": "#477e91",
					"complementary-text": "#8c661b",
					"complementary-text-light": "#ffd1cb",
					"terminal-footer-top": "#ff5319",
					"terminal-footer-bottom": "#ffe6aa"
				},
				{
					name: "Exquisite Magenta",
					primary: "#8b103d",
					"primary-light": "#fffbe6",
					complementary: "#428da6",
					background: "#d5deec",
					"background-text": "#81a3d9",

					"user-badge": "#f9c98b",
					"user-badge-text": "#864e06",
					"user-badge-room": "#a67d48",

					"account-card-background-comp": "#fffef6",
					"account-card-text": "#845518",
					"account-card-text-light": "#a8a077",
					"account-card-divider": "#f2eccd",
					"account-card-text-comp": "#777151",
					"complementary-text-comp": "#477e91",
					"complementary-text": "#8c661b",
					"complementary-text-light": "#cee6e8",
					"terminal-footer-top": "#ff5319",
					"terminal-footer-bottom": "#ffe6aa"
				}
			]
		};
	},
	methods: {
		setTheme(themeName) {
			let selectedTheme;
			selectedTheme =
				this.themes.find(element => element.name === themeName) ??
				this.themes[this.themes.length - 1];

			Object.keys(selectedTheme).forEach(color => {
				if (color !== "name")
					this.$vuetify.theme.themes.light[color] = selectedTheme[color];
			});

			this.$store.commit("setTheme", selectedTheme);
		}
	}
};
