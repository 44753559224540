<template lang="pug">
v-card(flat tile color="account-card-background-comp").account-card-text-comp--text
	div.pt-4.px-2.pb-2
		div.d-flex.justify-center.align-center
			PayMeBadge(v-if="purchase.askedToPay" size='s')
			SplitBadge(v-else size='s')
			div.d-flex.justify-center.align-center.body-2
				div
					span.mx-1 by
					NameAndRoomBadge(
						v-if='purchase.author'
						:name='purchase.author.name'
						:room='purchase.author.room'
						size='s')
					span(v-else) you
		div.body-2.pb-2.pt-4
			v-icon(size="16" color="account-card-text-comp").mr-1 {{ statuses[purchase.splitStatus ? "initiator" : "responder"][status].icon }}
			span {{ statuses[purchase.splitStatus ? "initiator" : "responder"][status].text }}
	v-sheet(color='account-card-divider' height='2')
</template>
<script>
import NameAndRoomBadge from "@/components/Elements/NameAndRoomBadge.vue";
import Badges from "@/components/Elements/Badges/payMeAndSplitBadges.js";
export default {
	props: ["purchase", "status"],
	data() {
		//splitResponseStatus
		//splitStatus
		return {
			statuses: {
				initiator: {
					// initiated can only be the case when I am the request's author
					// when I am not, "initiated" Splits are in my requests[], not in purchases[]
					initiated: {
						icon: "hourglass_empty",
						text: "Waiting for friends to respond"
					},
					"everyone responded": {
						icon: "how_to_reg",
						text: "All friends responded"
					},
					"deadline reached": {
						icon: "timer",
						text: "Deadline reached"
					}
				},
				responder: {
					responded: {
						icon: "task_alt",
						text: !this.purchase.ownAmount
							? "You refused to contribute"
							: "You paid your part"
					},
					"deadline reached": {
						icon: "timer",
						text: "Deadline reached. You didn't respond"
					}
				}
			}
		};
	},
	components: { ...Badges, NameAndRoomBadge }
};
</script>
