<template lang="pug">
v-btn(
	x-large
	@click='$emit("click")'
	:color="isDark ? darkColor : brightColor"
	elevation='10')
	div(:class="{'black--text' : !isDark}")
		div Sign Up
		div.caption We'll order a chip for you
</template>
<script>
export default {
	components: {},
	props: { isDark: Boolean },
	data() {
		return {
			brightColor: "primary lighten-4",
			darkColor: "primary"
		};
	}
};
</script>
<style lang="scss"></style>
