import Home from "./Home/Home";
import RequestsMobile from "./Request/RequestsMobile.vue";
import PurchasesMobile from "./Purchase/PurchasesMobile";
import Settings from "./Settings/Settings";

export default {
	Home,
	RequestsMobile,
	PurchasesMobile,
	Settings
};
