<template lang="pug">
div(
	@click='!preventExpansion ? expanded = true : $emit("click")'
	:class="{'cursor-pointer' : !expanded && !preventExpansion}"
	).d-inline-block
	div.d-flex
		v-sheet(
			rounded='xl'
			color='user-badge'
			).d-flex.align-center
			div(
				:class="getClassesForName()"
				).user-badge-text--text.ma-0 {{ name }}
			div(
				v-if='deletable'
				style='cursor:pointer').pr-2
				v-icon(
					size='17'
					color='user-badge-text darken-1') close
			div(
				v-else-if='room'
				).rounded-l-0.d-flex.align-center
				div(
					:class="getClassesForRoom()"
					).user-badge-room--text {{ formatRoomOrWGNumber(room, expanded) }}
</template>

<script>
export default {
	props: ["name", "room", "size", "preventExpansion", "deletable"],
	data() {
		return {
			expanded: false
		};
	},
	methods: {
		getClassesForName() {
			switch (this.size) {
				case "xs":
					return "caption py-05 pr-1 pl-2";
				case "s":
					return "body-2 py-1 pl-2 pr-1";
				case "l":
					return "h5 py-1 pl-3 " + (!this.room ? "pr-3" : "pr-2");
				default:
					return "body-1 py-1 pl-3 " + (!this.room ? "pr-3" : "pr-1");
			}
		},
		getClassesForRoom() {
			switch (this.size) {
				case "xs":
					return "caption pr-1";
				case "s":
					return "caption pr-2";
				case "l":
					return "body-1";
				default:
					return "body-2 pr-3";
			}
		}
	}
};
</script>

<style scoped>
.py-05 {
	padding-top: 1px;
	padding-bottom: 1px;
}
</style>
