<template lang="pug">
v-card(
	max-width='380'
	:height='entityName==="purchases" ? 248 : 196'
	:rounded='entityName==="requests" ? "xl" : ""'
	:class='entityName==="purchases" ? "purchase-card" : "requestCard"'
	outlined
	style='border:  1px dashed var(--v-primary-light-darken2); background-color: var(--v-primary-light-base);'
	).pa-4.ml-sm-4.d-flex.justify-center.align-center
	div.text-center
		v-icon(color='primary' size='38').mr-1.mb-4 wifi_off
		div.text-secondary.text-sm-h6.subtitle-1 Couldn't load {{ entityName }}
		div.pt-2.primary--text
			a(@click='$emit("reload")') Try again
</template>
<script>
export default {
	props: ["error", "entityName"],
	mixins: [],
	data() {
		return {};
	},
	methods: {},
	computed: {},
	components: {}
};
</script>

<style lang="scss" scoped></style>
