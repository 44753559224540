<template lang="pug">
v-container(fluid).pa-0.complementary.lighten-1
	v-row(no-gutters)
		v-col
			div.white--text.py-11.text-center.px-1
				div.text-h4 Our concise history
				div.subtitle-1
					| {{ items.length }} Milestones
	v-row(no-gutters)
		v-col(cols='0' md='1' lg='2' xl='3')
		v-col(cols='12' md='10' lg='8' xl='6')
			LogInToSee(
				v-if='isObscure'
				entity='history'
				benefit='see how our project has been progressing').pb-14
			v-timeline(
				v-else
				align-top
				:dense='$vuetify.breakpoint.xs'
				).mr-5.mx-sm-5
				v-timeline-item(
					v-for='(item, i) in items'
					:key='i'
					:color='item.color'
					:icon='item.icon'
					fill-dot)
					template(v-slot:icon)
						v-icon(:color="item.iconColor") {{ item.icon }}
					v-card(:color='item.color' dark)
						v-card-title(
							v-html='item.title'
							).body-1.text-sm-h6.break-word
						v-card-subtitle.pb-2.white--text.caption.text-sm-body-2
							div {{ convertTimestampToDaysAgo(item.timestamp) }}
						v-card-text(
							:style='{color: item.color}'
							).white.pt-5
							p(
								v-html="item.text"
								).mb-1.body-2.text-sm-body-1
							div(
								v-if='item.credit'
								).mt-3
								span Props to
								NameAndRoomBadge(
									:name='item.credit.name'
									:room='item.credit.room'
									size='s').px-1
								span for building the box!
							div(
								v-if="i === items.length - 1"
								).d-flex.justify-center
								div.d-flex.flex-wrap.justify-center.flex-grow-1
									v-card(
										v-for='point in whatsNewPoints'
										:key="point.text"
										color='complementary lighten-1'
										).pa-2.ma-1.flex-grow-1.d-flex.align-center.justify-center
										div.d-flex.justify-center.my-1.mr-3
											v-icon(size='28') {{ point.icon }}
										div(
											v-html='point.text'
											).text-center.body-2.pt-1
		v-col(cols='0' md='1' lg='2' xl='4')
	div(v-if='!isObscure').d-flex.justify-center
		OnlyVisibleToLoggedIn
</template>

<script>
import OnlyVisibleToLoggedIn from "./Elements/OnlyVisibleToLoggedIn";
import NameAndRoomBadge from "../Elements/NameAndRoomBadge.vue";
import LogInToSee from "./Elements/LogInToSee";
export default {
	components: { OnlyVisibleToLoggedIn, NameAndRoomBadge, LogInToSee },
	props: { isObscure: Boolean },
	data() {
		return {
			items: [
				{
					title: "Takeoff",
					timestamp: "31 August 2020",
					text: "Start of conceptualization",
					color: "#e6a100",
					icon: "lightbulb",
					iconColor: "warning lighten-4"
				},
				{
					title: "The first investment",
					timestamp: "12 Nov 2020",
					text:
						"<h2 class='text-center '>326&euro;</h2>\
						<div class='text-center'>Allocated from\
						 the budget of H1/2 as a result of a public vote",
					color: "#08b966",
					icon: "euro",
					iconColor: "#d5ffcd"
				},
				{
					title: "Processing first orders",
					timestamp: "17 May 2021",
					text: "The first 10 users get to enjoy BiederDrinks",
					color: "#EC5192",
					icon: "mdi-airballoon",
					iconColor: "#ffe5e5"
				},
				{
					title: "The second investment",
					timestamp: "07 June 2021",
					text:
						"<h2 class='text-center '>200&euro;</h2>\
						<div class='text-center'>Allocated from\
						the budget of H1/2 as a result of a public vote",
					color: "#08b966",
					icon: "euro",
					iconColor: "#d5ffcd"
				},
				{
					title: "Public Launch",
					timestamp: "21 July 2021",
					text: "Every resident can use BiederDrinks",
					color: "#EC5192",
					icon: "rocket_launch",
					iconColor: "#ffe5e5"
				},
				{
					title: "Keybox",
					timestamp: "17 January 2022",
					text: "Stepping up the convenience. Room access straight at the door",
					color: "#1271c6",
					icon: "vpn_key",
					iconColor: "#d0e9ff",
					credit: { name: "Konsti", room: "0602" }
				},
				{
					title: "First 1000 purchases",
					timestamp: "25 February 2022",
					text: "Only 7 months after the public launch",
					color: "#e6a100",
					icon: "celebration",
					iconColor: "warning lighten-4"
				},
				{
					title: "2000 purchases",
					timestamp: "29 June 2022",
					text: "Next 1000 in just 4 months!",
					color: "#e6a100",
					icon: "celebration",
					iconColor: "warning lighten-4"
				},
				{
					title: "<span class='brand-text-logo'>BiederDrinks 2</span>",
					timestamp: "01 October 2022",
					text: "Our biggest update yet includes:",
					color: "#EC5192",
					icon: "rocket_launch",
					iconColor: "#ffe5e5"
				}
			],
			whatsNewPoints: [
				{ text: "Account Page", icon: "account_circle" },
				{ text: "Splits", icon: "diversity_3" },
				{
					text: "PayMe",
					icon: "volunteer_activism"
				},
				{ text: "Instant<br />Refunds", icon: "bolt" },
				{ text: "Admin<br />Dashboard", icon: "space_dashboard" },
				{ text: "Support<br />Center", icon: "contact_support" },
				{ text: "Themes", icon: "brush" },
				{
					text: "One chip access to multiple G-Raums",
					icon: "holiday_village"
				}
			]
		};
	}
};
</script>
<style scoped></style>
