<template lang="pug">
div.primary-light--text
	div.pa-4.pb-4
		i(:style='welcomeFontSize') Welcome
			div(:style="{'margin-left': '30%'}")
				span(:style="toFontSize") to
		div.text-center
			span(
				:style='biederdrinksFontSize'
				).brand-text-logo.brand-text-size.primary-light--text
					| {{ $appTitle }}
			i(:style='welcomeFontSize').ml-1 !
	v-container(fluid).py-10.primary.px-md-8
		v-row(no-gutters).primary-light--text
			v-col(v-for="(feature, index) in features" :key='index' cols='4')
				div.d-flex.justify-center
					v-icon(
						:size="$vuetify.breakpoint.smAndUp ? 60 : 40"
						color="primary-light"
					)  {{ feature.icon }}
				div(
					:style='{"font-size": $vuetify.breakpoint.smAndUp ? "20px" : "16px"}'
					v-html='feature.text'
				).text-center.mt-3
</template>
<script>
export default {
	data() {
		return {
			features: [
				{ icon: "account_balance_wallet", text: "Check<br />balance" },
				{ icon: "fact_check", text: "Track<br />purchases" },
				{ icon: "groups", text: "Share with<br />friends" }
			]
		};
	},
	computed: {
		welcomeFontSize: function() {
			return this.$vuetify.breakpoint.smAndUp
				? { fontSize: "38px" }
				: { fontSize: "28px" };
		},
		toFontSize: function() {
			return this.$vuetify.breakpoint.smAndUp
				? { fontSize: "32px" }
				: { fontSize: "22px" };
		},
		biederdrinksFontSize: function() {
			return this.$vuetify.breakpoint.smAndUp
				? { fontSize: "54px" }
				: { fontSize: "44px" };
		}
	}
};
</script>
<style lang="scss" scoped></style>
