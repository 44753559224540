<template lang="pug">
v-dialog(v-model='showEditDrinkDialog'
				transition="dialog-bottom-transition"
        max-width="600"
				@click:outside='cancel'
				@keydown.esc='cancel')
	v-card
		v-card-title.d-flex.justify-space-between.mb-8
			span(v-if="drinkEmpty").text-h6.font-weight-normal Add a new drink
			span.text-h6.font-weight-normal(v-else) Edit
				span.pl-2.text-h6.font-weight-normal(v-if='!drinkEmpty') {{ drink.name }}
		v-card-text
			v-container
				v-form(v-model='formIsValid')
					v-row
						v-col(cols='5')
							v-text-field(v-model='formData.name' :rules="[rules.required]" label='Name' required outlined)
						v-col(cols='4')
							v-text-field(v-model='formData.barcode' :rules="[rules.required]" label='Barcode' required outlined)
						v-col(cols='3')
							v-text-field(v-model='formData.price' :rules="[rules.required, rules.moneyLowerLimit, rules.moneyUpperLimit, rules.monetaryValue]" label='Price' suffix="€" required outlined)
		v-card-actions
			small.text-secondary(v-if='!drinkEmpty') Added by {{ formData.admin_id }} on {{ formData.timestamp | timestampToDDMMYY }}
			v-spacer
			v-btn(color='grey darken-1' text @click='cancel')
				| cancel
			v-btn(color='primary' @click='save' :disabled='!formIsValid')
					| {{ drinkEmpty ? 'add drink' : 'save changes'}}
</template>
<script>
import { validators } from "../../../mixins/validators";
export default {
	mixins: [validators],
	name: "edit-drink-dialog",
	props: ["drink"],
	data() {
		return {
			showEditDrinkDialog: true,
			formIsValid: false,
			formData: {}
		};
	},
	methods: {
		save() {
			const formDataCopy = Object.assign({}, this.formData);
			this.formData = {};

			if (this.drinkEmpty) {
				this.$emit("put", formDataCopy);
			} else this.$emit("save", formDataCopy);
		},
		cancel() {
			this.$emit("cancel");
		},
		reset(drink) {
			this.formData = Object.assign({}, this.formData, drink);
		}
	},
	mounted() {
		this.reset(this.drink);
	},
	computed: {
		drinkEmpty() {
			return Object.keys(this.drink).length === 0;
		}
	},
	watch: {
		drink: {
			deep: true,
			handler: function(val) {
				this.formData = Object.assign({}, this.formData, val);
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.active-checkbox {
	margin-bottom: 0;
}
</style>
