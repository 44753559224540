<template lang="pug">
v-container(
	fluid
	)#benefits-background.min-height.d-flex.align-center.justify-center
	div
		v-row
			v-col(cols='0' md='1' lg='2' xl='3')
			v-col(cols='12' sm='12' md='10' lg='8' xl='6')
				div.pt-4.pb-7.darken-4.px-2.px-sm-10
					div.text-h4.text-center.complementary--text.text--lighten-5.text-glow
						| Everyone benefits
			v-col(cols='0' md='1' lg='2' xl='3')
		v-row(v-if='$vuetify.breakpoint.mdAndUp' no-gutters).mt-14
			v-col(cols='0' sm='0' md='1' lg='2' xl='3')
			v-col(
				cols='12' sm='12' md='10' lg='8' xl='6'
				).d-flex.justify-middle
				v-card.background-transparent
					div(v-for="(benefit, index) in benefits" :key='index')
						div(v-if='index == 1').separator
						div.d-flex
							div.benefit-caption.d-flex.align-center.text-h5.pl-5.white--text
								| {{ benefit.caption }}
							v-sheet(
								v-for="(card, index) in benefit.cards"
								:color="'rgba(0, 0, 0, 0.85)'"
								:key='index'
								).pa-4.mx-2.white--text.rounded
								h5.font-weight-bold.primary-light--text.mb-3
									| {{ card.title }}
								div(
									v-html='card.text'
									).text--text.text--lighten-3.body-1
			v-col(cols='0' sm='0' md='1' lg='2' xl='3')
		v-row(v-else no-gutters)
			v-col(cols='0' sm='2')
			v-col(cols='12' sm='8').d-flex.justify-middle
				v-card.background-transparent
					div(v-for="(benefit, index) in benefits")
						div(v-if='index == 1').separator.mt-5
						div.text-h5.white--text.text-center.my-6
							| {{ benefit.caption }}
						div(v-for="(card, index) in benefit.cards")
							div(v-if='index % 2 === 0')
								v-sheet(
									:color="'rgba(0, 0, 0, 0.85)'"
									).pa-3.ma-1.white--text.rounded
									h5.font-weight-bold.primary-light--text
										| {{ card.title }}
									div(
										v-html='card.text'
										).text--text.text--lighten-3.body-1
								v-sheet(
									v-if='index < benefit.cards.length - 1'
									:color="'rgba(0, 0, 0, 0.85)'"
									).pa-3.ma-1.white--text.rounded
									h5.font-weight-bold.primary-light--text
										| {{ benefit.cards[index + 1].title }}
									div(
										v-html='benefit.cards[index + 1].text'
										).text--text.text--lighten-3.body-1
			v-col(cols='0' sm='2')
</template>

<script>
export default {
	data() {
		return {
			benefits: [
				{
					caption: "For You",
					cards: [
						{
							title: "Convenience",
							text: "G-Raum 24/7.<br/>Just wave your chip and you're&nbsp;in"
						},
						{
							title: "Awareness",
							text: "Balance, purchase history, payment link and everything\
								else you need. All in one place"
						},
						{
							title: "Pay with/for friends",
							text: "Split the bill or ask your friends to chip in.\
								 All in a few clicks"
						}
					]
				},
				{
					caption: "For Biederstein",
					cards: [
						{
							title: "Time and mind saver",
							text: "Automates boring, tedious and repetitive work"
						},
						{
							title: "Also a money saver",
							text: "Reduces losses and provides flexible instruments for dealing with debts"
						}
					]
				}
			]
		};
	},
	methods: {}
};
</script>

<style scoped>
.text-glow {
	text-shadow: 0px 0px 15px #ffffff;
}

#benefits-background {
	padding-bottom: 60px;
	background-image: url("../../../public/img/g-raum.webp");
	background-size: cover;
}

.benefit-caption {
	padding-right: 20px;
	width: 180px;
}

.separator {
	height: 3px;
	width: 100%;
	background-color: var(--v-primary-light-darken1);
}

.min-height {
	min-height: 800px;
}
</style>
