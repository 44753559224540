<template lang="pug">
v-container(
	fluid
	fill-height
	:style="{'min-height': $vuetify.breakpoint.xs ? '568px' : '700px'}"
	)#background-landing-first
	v-row.mb-10.mt-8.mt-sm-0
		v-col
			div.complementary--text.text--lighten-1
				div.d-flex.justify-center.text-center
					div
						div.page-title.brand-text-logo.primary--text.text--lighten-4 {{ $appTitle }}
						div.page-subtitle.complementary--text.text--lighten-4.mx-2.text-glow
								| The most advanced project in Biederstein
				div.d-sm-flex.justify-center.mt-6.mt-sm-0
					div(v-if='user_logged_in').d-flex.justify-center
						v-btn(
							to='account'
							large
							:x-large="$vuetify.breakpoint.smAndUp"
							color='primary lighten-5'
						).primary--text.text--darken-1.text-decoration-none.mt-15.mt-sm-0
							| go to account
					div(v-else).d-md-flex
						div.d-flex.justify-center
							div.mt-5.mt-xs-0
								SignUpButton(@click='signUpDialog.show = true')
								div.mt-3.body-2.text-center.white--text
									i Exclusively for Biedersteiners
						div.d-flex.justify-center.mt-xs-0.mt-5
							v-btn(
								to='login'
								outlined
								large
								:x-large='$vuetify.breakpoint.smAndUp'
								color='primary lighten-4').text-decoration-none.ml-sm-3 Log in
	v-row
		v-col.d-flex.justify-center
			ScrollDownArrow(:isLightened="true")
	sign-up-dialog(
		v-if='signUpDialog.show'
		:showDialog.sync='signUpDialog.show'
		@cancel='signUpDialog.show = false'
		hide-overlay)
</template>

<script>
import SignUpDialog from "./SignUp/SignUpDialog.vue";
import { mapGetters } from "vuex";
import ScrollDownArrow from "./Elements/ScrollDownArrow.vue";
import SignUpButton from "./Elements/SignUpButton.vue";
export default {
	props: {
		openFeedbackDialogOnMounted: Boolean
	},
	components: { SignUpDialog, ScrollDownArrow, SignUpButton },
	data() {
		return {
			signUpDialog: {
				show: false
			}
		};
	},
	computed: {
		...mapGetters(["user_logged_in"])
	},
	mounted() {
		if (this.openFeedbackDialogOnMounted) this.signUpDialog.show = true;
	}
};
</script>

<style scoped>
.biederstein {
	font-size: 5vw;
	font-weight: lighter;
}

.page-title {
	font-size: clamp(48px, 8vw, 90px);
	margin-top: 10vw;
	margin-bottom: 4vw;
}

.page-subtitle {
	margin-bottom: 10vw;
	font-size: clamp(23px, 4vw, 35px);
	line-height: clamp(30px, 6vw, 50px);
}

.text-glow {
	text-shadow: 0px 0px 15px #ffffff;
}

#background-landing-first {
	height: 100vh;
	background-image: url("../../../public/img/biederstein-login-background.jpg");
	background-size: cover;
}

.scroll-down-text {
	font-size: clamp(20px, 3vw, 32px);
}
</style>
