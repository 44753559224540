<template lang="pug">
v-container(fluid).pa-0.primary.primary-light--text
	v-row(no-gutters)
		v-col(cols='0' lg='2' xl='3')
		v-col(cols='12' lg='8' xl='6').text-center.mt-12.mb-4
			div.text-h4 Want to get in touch?
			div.text-h6 Know who to ask
			div.d-sm-flex.justify-center.mt-6.mx-6.mx-sm-0
				v-card(
					v-for="(card, index) in cards"
					:key='index'
					).mx-3.mt-6
					div.text-h6.primary.lighten-3.white--text.break-word.py-3.px-4
						div.mt-2.mb-1
							div(
								:class="{'brand-text-logo': card.logoFont}"
								) {{ card.caption }}
							div.subtitle-1.primary--text.text--lighten-5
								| {{ card.subcaption }}
					v-icon(
						color='primary lighten-3'
						size='80'
						).mt-n9 arrow_drop_down
					div.text-left.px-8.d-flex.justify-center.mt-n5
						div.mt-3
							div(v-for='responsibility in card.responsibilities').d-flex
								div
									v-icon(
										size='22'
										color='complementary'
										).mx-3.my-3 {{ responsibility.icon }}
								div.my-3.complementary--text.px-1.flex-grow-1.body-1
									| {{ responsibility.text }}
					div.mt-5.pb-2
						div(
							v-if='index===0'
							).justify-center.align-center.mt-7.py-4
							v-btn(
								color='primary'
								@click='$emit("openFeedbackDialog")'
								) Contact us
						div(v-else-if='admins.data && admins.data.length > 0')
							div.subtitle-2.primary--text
								| Contact Admins
							div.mr-1.px-4
								span(v-for='(admin, index) in admins.data')
									NameAndRoomBadge(
										:name='admin.name'
										:room='admin.room').ma-1
			div.d-flex.justify-center.mt-8.mb-4
		v-col(cols='0' lg='2' xl='3')

</template>

<script>
import { dataLoader } from "@/mixins/dataLoader";
import OnlyVisibleToLoggedIn from "./Elements/OnlyVisibleToLoggedIn";
import NameAndRoomBadge from "../Elements/NameAndRoomBadge.vue";
export default {
	props: { hideAdmins: Boolean },
	mixins: [dataLoader],
	components: { OnlyVisibleToLoggedIn, NameAndRoomBadge },
	data() {
		let preventLoadingAdmins = false;
		if (this.hideAdmins) preventLoadingAdmins = true;
		return {
			dataToLoadPreventLoadingOnMount: preventLoadingAdmins,
			dataToLoad: {
				url: "show_admins",
				saveTo: "admins"
			},
			admins: {},
			cards: [
				{
					caption: "BiederDrinks",
					logoFont: true,
					subcaption: "Platform",
					responsibilities: [
						{ text: "New Features", icon: "star_rate" },
						{ text: "Design and Usability", icon: "brush" },
						{ text: "Accessibility", icon: "cloud" }
					]
				},
				{
					caption: "Biederstein",
					subcaption: "Seller",
					responsibilities: [
						{ text: "Chip Distribution", icon: "key" },
						{ text: "Assortment and Pricing", icon: "shopping_cart" },
						{ text: "Funds and Debts", icon: "paid" }
					]
				}
			]
		};
	}
};
[];
</script>

<style scoped>
.rotate-90 {
	transform: rotate(90deg);
}
</style>
