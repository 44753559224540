<template lang="pug">
div
	v-card(:height='height + 30').ml-12.rounded-r-0.white
		Sidebar(
			:height="height"
			:balances='balances'
			:statuses='statuses'
			@reloadData='$emit("reloadData")'
			@showAccountSettingsDialog='$emit("showAccountSettingsDialog")'
			@openFeedbackDialog='$emit("openFeedbackDialog")')
		div.scrollers-col.pt-5
			Heading(
				:captions="captions.requests"
				:iconSize="26")
			div.requests
				div.ml-4
					LoadingFailedCard(
						v-if='requests.status === statuses.error'
						:error='requests.error'
						:entityName='"requests"'
						@reload='$emit("reloadRequests")')
					RequestSkeleton(v-else-if='requests.status === statuses.loading')
					NoRequestsCard(
						v-else-if='showNoRequestsCard'
						:height='requestCardHeight')
				div.d-flex
					RecycleScroller(
						v-show='requestsReady'
						class="scroller"
						:items="requestsWithLoadMoreCard"
						:item-size="440"
						key-field="id"
						direction='horizontal'
						ref='requestsScroller'
						v-slot="{ item, index }"
						:emitUpdate='true'
						).requests.pr-4
						template(v-if='requests.data')
							div.ml-4
								RequestCard(
									v-if='item.friends && index <= requests.data.length - 1'
									:captions='captions.requests.firstToPay'
									:request='item'
									:height='requestCardHeight'
									@showRequestResponseDialog='$emit("showRequestResponseDialog", item)')
								LoadMoreCard(
									v-else-if='requests.itemsPerLoad && requests.data.length < requests.totalItems'
									:statuses='statuses'
									:status='requests.loadMoreStatus'
									:entityName='"requests"'
									@loadMore='$emit("loadMoreRequests")')
			Heading(
				:captions="captions.purchases"
				:iconSize="24").mt-2
			div.purchases
				div.ml-4
					LoadingFailedCard(
						v-if='purchases.status === statuses.error'
						:error='purchases.error'
						:entityName='"purchases"'
						@reload='$emit("reloadPurchases")')
					PurchaseSkeleton(v-else-if='purchases.status === statuses.loading')
					NoPurchasesCard(v-else-if='showNoPurchasesCard')
				div.d-flex
					RecycleScroller(
						v-show='purchasesReady'
						class="scroller"
						:items="purchasesWithLoadMoreCard"
						:item-size="330"
						key-field="id"
						direction='horizontal'
						ref='purchasesScroller'
						v-slot="{ item, index }").purchases.pr-4
						template(v-if='purchases.data')
							div.ml-4
								PurchaseCard(
									v-if='index <= purchases.data.length - 1'
									:purchase='item'
									:index="index"
									@goToPreviousPage="item.currentPage--"
									@goToNextPage="item.currentPage++"
									@showRequestDetailsDialog='$emit("showRequestDetailsDialog", index)'
									@showSplitDialog='$emit("showSplitDialog", index)'
									@reportPurchaseProblem='$emit("reportPurchaseProblem", index)')
								LoadMoreCard(
									v-else-if='purchases.itemsPerLoad && purchases.data.length < purchases.totalItems'
									:statuses='statuses'
									:status='purchases.loadMoreStatus'
									:entityName='"purchases"'
									@loadMore='$emit("loadMorePurchases")')
</template>
<script>
import { catchError } from "@/mixins/catchError";
import AccountDesktopComponents from "/";

export default {
	components: {
		...AccountDesktopComponents
	},
	mixins: [catchError],
	props: [
		"loading",
		"purchases",
		"requests",
		"persons",
		"settingsSections",
		"balances",
		"captions",
		"statuses",
		"requestsWithLoadMoreCard",
		"purchasesWithLoadMoreCard",
		"showNoPurchasesCard",
		"showNoRequestsCard",
		"purchasesReady",
		"requestsReady"
	],
	data() {
		return {
			height: 770,
			requestCardHeight: 176
		};
	},
	methods: {
		addHorizontalScrollingForRequestsAndPurchases() {
			const refs = ["requestsScroller", "purchasesScroller"];
			refs.forEach((ref) => {
				this.$refs[ref].$el.addEventListener("wheel", (ev) => {
					if (!ev.shiftKey) {
						ev.preventDefault();
						this.$refs[ref].$el.scrollLeft += ev.deltaY + ev.deltaX;
					}
				});
			});
		}
	},
	computed: {},
	mounted() {
		this.addHorizontalScrollingForRequestsAndPurchases();
	}
};
</script>
<style lang="scss">
.scrollers-col {
	margin-left: 220px;
	margin-top: 12px;
}

.requests {
	height: 220px;
}

.purchases {
	height: 396px;
}
</style>
