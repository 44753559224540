<template lang="pug">
div
	div(
		v-if='step.title'
		style='height: 30px'
		).body-2.d-flex.align-center.justify-start.pl-1
		div
			i {{ step.title }}
	v-card(
		color='primary-light'
		outlined).text-center.py-2.ma-1.px-2.flex-grow-1
		.body-2.mt-1(v-html='step.text')
		div.d-flex.text-center.justify-center.mt-3
			v-card(outlined).py-1.px-2.mr-1
				div.text-secondary Balance
				div.text-h5 {{ step.accountBalance }}€
			v-card(outlined).py-1.px-2
					div.text-secondary Pending
					div.text-h5 {{ step.pendingBalance }}€
</template>
<script>
export default {
	props: ["step"],
	data() {
		return {};
	}
};
</script>

<style scoped></style>
