<template lang="pug">
v-container(fluid fill-height).pa-0#background
	v-row(no-gutters)
		v-col.d-flex.justify-center
			v-card(v-if='!success' color='primary-light').pb-6
				alert(:content='content' ref='alert'  :type='type')
				h2.text-center.mt-6 Create a new password
				PasswordInput.my-8
				div.d-flex.justify-center
					v-btn(
						@click='savePassword'
						:disabled='!$store.state.newPasswordIsValid || loading'
						:loading='loading'
						color='primary') save password
			v-card(v-else color='primary-light').pa-6
				h3.mb-6 Your password is successfully updated
				div.d-flex.justify-center
					v-btn(to='/account' color='primary').text-decoration-none go to your account
	div.footer-logo
		h5.text-center.primary-light--text.brand-text-logo {{ $appTitle }}
</template>

<script>
import PasswordInput from "../Helpers/PasswordInput.vue";
import { catchError } from "../../mixins/catchError";
import Alert from "../Alert.vue";
export default {
	components: { PasswordInput, Alert },
	mixins: [catchError],
	data() {
		return {
			loading: false,
			success: false
		};
	},
	methods: {
		savePassword() {
			this.loading = true;
			const vm = this;
			this.$axios
				.post(`/api/change_password/${this.$route.params.token}`, {
					new_password: this.$store.state.newPassword
				})
				.then(() => {
					this.success = true;
				})
				.catch(error => {
					vm.catchError(error);
				});
			vm.loading = false;
		}
	}
};
</script>

<style lang="scss" scoped>
#background {
	background-image: url("../../../public/img/biederstein-login-background.jpg");
	background-size: cover;
}
</style>
