<template lang="pug">
v-container
	div.primary-light--text.title.text-center.mb-3 Choose Design
	div.primary.d-flex.justify-center
		div
			ThemePicker(
				:row='$vuetify.breakpoint.xs'
				:feedbackButton='false'
				preventSendingToServer='true'
				row='true'
				landing='true')
			h6.my-4.text-center.primary-light--text.body-2 Of course, you can always change it later
</template>
<script>
import ThemePicker from "../../../Helpers/ThemePicker.vue";
export default {
	components: { ThemePicker }
};
</script>
<style lang="scss" scoped></style>
