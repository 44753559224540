<template lang="pug">
	v-container(fill-height)
		v-row
			v-col.d-flex.justify-center
				v-data-table(
					v-if='actions.tableOptions'
					:headers='headers'
					:items="actions.data"
					:options.sync="actions.tableOptions"
					:loading="!actions || actions.status === statuses.loading"
					:loading-text="messages.loading"
					:no-data-text="actions.tableOptions.search ? messages.nothingFound : messages.noData"
					item-key="id"
					:search="actions.tableOptions.search"
					:server-items-length="actions.totalItems"
					).pt-1.maxWidth.elevation-5
					template(v-slot:top)
						v-toolbar(flat).mb-6
							v-container
								v-row.mt-3
									v-col(cols='6' sm='7' md='8').d-flex.align-center
									v-col(cols='6' sm='5' md='4')
										v-text-field(
											v-model="actions.tableOptions.search"
											:append-icon='actions.tableOptions.search ? "mdi-magnify" : undefined'
											:append-outer-icon='actions.tableOptions.search ? "mdi-close-circle-outline" : undefined'
											@click:append-outer='actions.tableOptions.search = ""; updateEntityAndLoadData(actions, "actions")'
											@click:append='updateEntityAndLoadData(actions, "actions")'
											@keyup='startSearchTimer(actions, "actions")'
											label='Search'
											single-line
											flat
											solo
											hide-details)
					template(v-slot:item.type='item')
						v-chip(
							:color="badge(item).color"
							label
							small
							).mr-4.py-5.px-3.operation-chip.justify-center.white--text.body-2
							v-icon(size='18')
								| {{ badge(item).icon }}
							span.pl-1.mr-3.ml-1
								| {{ badge(item).text }}
							span.font-weight-bold
								| {{ formatMonetaryValue(item.item.amount) }}
					template(v-slot:item.person='item')
						span {{item.value.name}}&nbsp;
						span.text-secondary
							| {{ formatRoomOrWGNumber(item.value.room, false) }}
					template(v-slot:item.timestamp="item")
						span {{ makeTimestampReadable(item.value) }}
</template>

<script>
import { catchError } from "@/mixins/catchError";
import { dataLoader } from "@/mixins/dataLoader";
export default {
	mixins: [catchError, dataLoader],
	data() {
		return {
			dataToLoad: {
				url: "admin_transactions",
				saveTo: "actions",
				table: true,
				tableOptions: {
					sortBy: ["timestamp"],
					sortDesc: [true]
				}
			},
			actions: {},
			operationTypeBadges: {
				admin: {
					color: "#3c9d0bBF",
					icon: "mdi-plus-circle-multiple-outline",
					text: "Top Up"
				},
				admin_purchase: {
					color: "#e17d27E3",
					icon: "mdi-receipt",
					text: "Purchase"
				},
				admin_substraction: {
					color: "#e17d27E3",
					icon: "remove_circle_outline",
					text: "Deduct"
				}
			},
			headers: [
				{
					text: "Person",
					align: "right",
					value: "person",
					sortable: true,
					groupable: false,
					filterable: true
				},
				{
					text: "Operation",
					align: "center",
					value: "type",
					sortable: true,
					groupable: false,
					filterable: true
				},
				{
					text: "Entered by",
					align: "center",
					value: "name",
					sortable: true,
					groupable: false,
					filterable: true
				},
				{
					text: "Entered on",
					align: "center",
					value: "timestamp",
					sortable: true,
					groupable: false,
					filterable: true
				}
			],
			messages: {
				loading: "Loading action history... Please wait",
				noData: "Admins haven't performed any actions yet",
				nothingFound: "Nothing found"
			}
		};
	},
	methods: {
		badge(item) {
			return item.value == "admin" && item.item.amount < 0
				? this.operationTypeBadges.admin_substraction
				: this.operationTypeBadges[item.value];
		},
		textColor(type) {
			if (type == "admin") return this.operationTypeBadges.admin.color;
			else return this.operationTypeBadges.admin_purchase.color;
		}
	}
};
</script>

<style scoped>
.operation-chip {
	width: 170px;
}
</style>
