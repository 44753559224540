<template lang="pug">
v-sheet(max-width='900').mx-auto
	alert(:content='content' ref='alert' :type='type' backgroundColor="white" class="contact-alert")
	.content
		First(@showGetStartedDialog="doShowGetStartedDialog = true").cta-card
		WhyUs
		UseCases.useCases
		Functionality
		SignUpExplanation(@showGetStartedDialog='doShowGetStartedDialog = true').mt-10
		SomethingMissing(@showGetStartedDialog="doShowGetStartedDialog = true")
	v-footer(bottom padless).footer
		v-card.pa-0.ma-0.pb-1.text-center(flat tile width="100%")
			GDPRLinks(isWhiteBackground='true' isMobile='true')
	v-dialog(
		v-model='doShowGetStartedDialog'
		transition="dialog-bottom-transition"
		max-width="500"
		@click:outside='doShowGetStartedDialog = false'
		@keydown.esc='doShowGetStartedDialog = false')
		v-card.pa-4
			h5.primary--text We appreciate your interest!
			h6 How shall we get in touch?
			v-textarea.mt-3(
					v-model='getStartedUserText'
					:rules="[rules.required]"
					label='E-mail, phone, WhatsApp'
					rows='3'
					outlined
					no-resize)
			.text-center
				v-btn(
					color="primary"
					@click="contact()"
					) contact me
			div.mt-5
				.body-2
					| You can also send us a message:
					|
					a(href="mailto:contact@goods2gether.com") contact@goods2gether.com
</template>

<script>
import Alert from "../Alert.vue";
import { mapGetters } from "vuex";
import First from "./First/First.vue";
import UseCases from "./UseCases/UseCases.vue";
import WhyUs from "./WhyUs/WhyUs.vue";
import Functionality from "./Functionality/Functionality.vue";
import SomethingMissing from "./SomethingMissing/SomethingMissing";
import GDPRLinks from "../GDPRLinks.vue";
import SignUpExplanation from "./SignUpExplanation/SignUpExplanation.vue";
import { validators } from "@/mixins/validators";
import { catchError } from "@/mixins/catchError";

export default {
	components: {
		Alert,
		First,
		UseCases,
		WhyUs,
		Functionality,
		SomethingMissing,
		GDPRLinks,
		SignUpExplanation
	},
	mixins: [validators, catchError],
	data() {
		return {
			imgsMobile: [],
			imgsDesktop: [],
			doShowGetStartedDialog: false,
			getStartedUserText: ""
		};
	},
	created() {},
	mounted() {
		this.fillImgsForMobile();
		this.fillImgsForDesktop();
	},
	methods: {
		fillImgsForMobile() {
			for (let i = 1; i <= 4; i++)
				this.imgsMobile.push(require(`./img/${i}.jpg`));
		},
		fillImgsForDesktop() {
			for (let i = 1; i <= 6; i++)
				this.imgsDesktop.push(require(`./img/${i}.jpg`));
		},
		contact() {
			this.$axios
				.put("/api/new-client-request", {
					text: this.getStartedUserText,
					page: this.$route.path,
					userAgent: window.navigator.userAgent
				})
				.then(() => {
					this.content =
						"Your message has been sent. We will get in touch with you!";
					this.type = "success";
					this.$refs.alert.showAlert();
				})
				.catch(error => {
					this.type = "error";
					this.catchError(error);
				})
				.finally(() => {
					this.getStartedUserText = "";
					this.doShowGetStartedDialog = false;
				});
		}
	},
	computed: {
		...mapGetters(["user_logged_in"])
	}
};
</script>

<style scoped>
.footer {
	z-index: 98;
}

.imgs {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	z-index: 1;
}

.img {
	filter: brightness(60%);
}

.img-mobile {
	flex: 1 0 50%;
}

.img-desktop {
	flex: 1 0 33%;
}

.content {
	position: relative;
	z-index: 2 !important;
}

.first {
	height: 70%;
}

.cta-card {
	padding-top: 20%;
}

.contact-alert {
	position: absolute;
	top: 50px;
	z-index: 3 !important;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}
</style>
