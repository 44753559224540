<template lang="pug">
v-sheet
	h5.primary--text.mx-2.mb-4.text-center
		| What others usually share:
	.d-flex.flex-wrap
		v-sheet(
			v-for="useCase in useCases"
			:key="useCase"
			:width='$vuetify.breakpoint.xs ? "50%" : "25%"'
			height='100%')
			UseCaseCard(
				:data="useCase")
</template>
<script>
import UseCaseCard from "./Elements/UseCaseCard.vue";
export default {
	components: { UseCaseCard },
	props: [],
	data() {
		return {
			useCases: [
				{ img: require("../img/1.jpg"), text: "Drinks" },
				{ img: require("../img/2.jpg"), text: "Self-baked goods" },
				{ img: require("../img/7.jpg"), text: "Snacks" },
				{ img: require("../img/8.jpg"), text: "Candy" }
			]
		};
	},
	methods: {},
	computed: {}
};
</script>
<style lang="scss"></style>
