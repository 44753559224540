<template lang="pug">
div.rounded-xl.white.mb-sm-16.mb-0
	Title(
		:textTop='"Good old habits"'
		:textBottom='"Modernized Accounting"'
		:align='"center"').my-10
	v-sheet
		div.d-inline.d-md-flex.justify-center
			Request(
				:class="{'width left-margin': $vuetify.breakpoint.smAndUp}"
				:icon='"diversity_3"'
				:title='"Split"'
				:iconColor='splitRgb'
				buttonTextColor="primary"
				colorName='split'
				:text1='"Buy all drinks with one chip"'
				:text2='"Let everyone contribute"'
				:pic='require("../../../../public/img/WhatsNew/Split.jpg")'
				:link='"Split"'
				:bgColor="'29, 64, 119'"
				).max-size.mt-10.mx-auto
			Request(
				:class="{'width both-margins': $vuetify.breakpoint.smAndUp}"
				:icon='"volunteer_activism"'
				:title='"PayMe"'
				:iconColor='payMeRgb'
				buttonTextColor="primary"
				colorName='pay-me'
				:text1='"Get drinks for someone"'
				:text2='"Let them pay you back"'
				:pic='require("../../../../public/img/WhatsNew/PayMe.webp")'
				:link='"PayMe"'
				:bgColor="'94, 32, 94'"
				swap
				).max-size.mt-10.mx-auto
	// add attribution: <a href="https://www.freepik.com/free-vector/hand-drawn-arrow-set_15961277.htm#query=arrow&position=1&from_view=keyword">Image by alicia_mb</a> on Freepik
	Hover(v-if='$vuetify.breakpoint.mdAndUp')

</template>

<script>
import Title from "./Title.vue";
import Request from "./Request.vue";
import Hover from "./Hover.vue";
export default {
	data() {
		return {
			splitRgb: this.hexToRgb(this.$vuetify.theme.themes.light["split"]),
			payMeRgb: this.hexToRgb(this.$vuetify.theme.themes.light["pay-me"])
		};
	},
	created() {},
	methods: {},
	computed: {},
	components: { Title, Request, Hover }
};
</script>

<style scoped>
.leftArrow {
	transform: scaleX(-1) rotate(40deg);
}

.rightArrow {
	transform: rotate(40deg);
}

.width {
	width: 40%;
}

.max-size {
	max-width: 400px;
	max-height: 400px;
}

.left-margin {
	margin-left: 6vw;
}

.both-margins {
	margin-left: 6vw;
	margin-right: 6vw;
}
</style>
