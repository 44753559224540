<template lang="pug">
v-dialog(
	v-model='showPersonPurchasesDialog'
	transition="dialog-bottom-transition"
	max-width="750"
	@click:outside='cancel'
	@keydown.esc='cancel')
	v-container(fluid).ma-0.pa-0.background
		v-row.ma-0
			v-col(cols='12').justify-start.pa-0
				v-btn(
					@click="cancel"
					pill
					color='background lighten-4'
					outlined
					rounded
					small).mt-3.mb-5.ml-3
					v-icon.mr-1(size="16") cancel
					span(style='padding-top: 1px') Close
		v-row.ma-0.pa-0
			v-col.ma-0.pa-0.d-flex.justify-center
				Purchases.px-6(
					:selectedPerson="person"
					:headers="headers")
</template>

<script>
import Purchases from "../Purchases/Purchases.vue";
export default {
	name: "person-purchases-dialog",
	props: ["person"],
	components: {
		Purchases
	},
	data() {
		return {
			loading: false,
			showPersonPurchasesDialog: true,
			headers: [
				{
					text: "ID",
					align: "center",
					value: "id",
					sortable: true,
					groupable: false
				},
				{
					text: "Time of purchase",
					align: "center",
					value: "timestamp",
					sortable: true,
					groupable: false
				},
				{
					text: "Amount",
					align: "center",
					value: "amount",
					sortable: true,
					groupable: false
				}
			]
		};
	},
	methods: {
		cancel() {
			this.$emit("cancel");
		}
	}
};
</script>

<style scoped></style>
