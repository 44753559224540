<template lang="pug">
v-tooltip(bottom color='instant-refund darken-1' internal-activator)
	template(v-slot:activator="{ on, attrs }")
		span(
			v-bind="attrs"
      v-on="on").pr-3.caption.badge
			v-icon(
				:size='small ? 16 : 22'
				color='instant-refund'
				:style='`margin-bottom: ${small ? 1 : 2}px`') bolt
			span(
				:style='`margin-top: ${small ? 0 : 3}px`'
			).instant-refund--text.font-weight-bold Instant Refund
	div
		div We'll issue a refund for you as soon as you make a request
		div No need to wait until your friends respond!
</template>
<script>
export default {
	props: {
		small: Boolean
	},
	data() {
		return {};
	},
	methods: {}
};
</script>

<style scoped>
.badge {
	border: solid var(--v-instant-refund-base) 1px;
	border-radius: 10px;
}

.badge v-icon,
span {
	display: inline-block;
}
</style>
