<template lang="pug">
v-card(
	min-width='296'
	color='primary-light'
	).request-card-tablet.d-flex.mx-2
	v-sheet(
		rounded='lg'
		color='primary'
		).fill-height.primary-light--text.pa-5.rounded-l-sm
		v-tooltip(
			top
			nudge-right="50"
			open-delay="200"
			color="complementary lighten-1")
			template(v-slot:activator="{ on, attrs }")
				span(
					v-bind="attrs"
					v-on="on").primary-light--text.text--darken-1.subtitle-2
					| Purchased {{ convertTimestampToDaysAgo(request.timestamp) }}
			template
				span {{ convertTimestampToReadableDayOfTheWeekDateTime(request.timestamp) }}
		div.mt-3
			div.text-h5 From
			NameAndRoomBadge(
				:name='request.author.name'
				:room='request.author.room'
				:large='true').mt-1
		div.caption.text-center.primary--text.text--lighten-5.d-flex.align-center.justify-center.mt-4
			v-icon(
				size='17'
				color='primary lighten-5'
				).mr-1 schedule
			div(
				style='padding-bottom: 1px'
				) {{ request.timeLeftToPay.topLine }} {{ request.timeLeftToPay.bottomLine }}
	v-sheet(
		color='primary-light'
		rounded
		).primary--text.pa-4.primary-light.fill-height.my-10
		template(v-if="request.friends.paid && request.friends.paid.length > 0")
			div.text-h6 Friends who already paid:
			div.d-flex.flex-wrap
				div(
					v-for="(friend, index) in request.friends.paid.slice(0, 2)"
					:key='index').my-2
						NameAndRoomBadge(
							:name='friend.person.name'
							:room='friend.person.room').ml-1
			div(
				v-if="request.friends.paid.length > 2"
				color="primary lighten-2").ma-1
				| +{{ request.friends.paid.length - 2 }} more
		div(v-else).text-h6.pr-2
			div {{ captions.title }}
			div.pb-1.subtitle-1.primary-light--text.text--darken-4
				| {{ request.author.name }} {{ captions.subtitle }}
		v-card-actions.primary-light.justify-center
			v-btn(
				absolute
				bottom
				@click="showRequestResponseDialog(request)"
				color="primary")
				v-icon.mr-1 toc
				| Respond
</template>
<script>
import NameAndRoomBadge from "@/components/Elements/NameAndRoomBadge.vue";
export default {
	props: ["request", "captions"],
	methods: {
		showRequestResponseDialog(request) {
			this.$emit("showRequestResponseDialog", request);
		}
	},
	components: { NameAndRoomBadge }
};
</script>
<style lang="scss">
.request-card-tablet {
	height: 180px;
	align-items: center;
}
</style>
