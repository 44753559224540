<template lang="pug">
	div
		.text-h6.text-sm-h5.text-md-h4.mb-4.primary--text I hear a system error
		div
			p
				| There can be many reasons for it.
			div We kindly ask you to let us know about all malfunctions
			ContactInformation(
				:contactPerson="contactPerson"
				:subject="'Error%20Report%20–%20System%20error'")
</template>

<script>
import ContactInformation from "../Elements/ContactInformation.vue";
export default {
	components: { ContactInformation },
	props: ["contactPerson"]
};
</script>

<style scoped></style>
