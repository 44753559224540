import PurchaseSkeleton from "@/components/Account/Desktop/Purchase/PurchaseSkeleton.vue";
import RequestSkeleton from "@/components/Account/Desktop/Request/RequestSkeleton.vue";
import RequestCard from "@/components/Account/Desktop/Request/RequestCard.vue";
import PurchaseCard from "@/components/Account/Desktop/Purchase/PurchaseCard.vue";
import NoRequestsCard from "@/components/Account/Desktop/Request/NoRequestsCard.vue";
import Heading from "./Heading.vue";
import NoPurchasesCard from "@/components/Account/Desktop/Purchase/NoPurchasesCard.vue";
import LoadMoreCard from "@/components/Account/Desktop/Elements/LoadMoreCard";
import Sidebar from "./Sidebar/Sidebar.vue";
import LoadingFailedCard from "@/components/Account/Desktop/Elements/LoadingFailedCard";

export default {
	PurchaseSkeleton,
	RequestSkeleton,
	RequestCard,
	PurchaseCard,
	NoRequestsCard,
	Heading,
	NoPurchasesCard,
	LoadMoreCard,
	Sidebar,
	LoadingFailedCard
};
