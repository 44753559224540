<template lang="pug">
v-card(
	color='primary-light'
	min-width='180'
	).pa-4.mr-3.fill-height
	div.pb-4.pb-sm-0
		div.d-flex.justify-start.align-center
			v-sheet(
				:height='$vuetify.breakpoint.xs ? 28 : 36'
				:width='$vuetify.breakpoint.xs ? 28 : 36'
				outlined
				color='primary'
				style='border-left: none'
				rounded='pill').d-flex.inner-shadow.d-flex.justify-center
				v-icon(
					color='primary-light'
					:size='$vuetify.breakpoint.xs ? 18 : 22'
					).icon-shadow {{ step.icon }}
			div.primary--text.ml-2.text-h6 {{ step.title }}
		div(v-html='step.text').d-flex.align-center.mt-3.body-2.mb-3
	div.text-center.links
		div(
			v-for='(link, i) in step.links'
			:key='i')
			a(:href='link.link').caption {{ link.text }}
</template>

<script>
export default {
	props: ["step"]
};
</script>

<style scoped>
.inner-shadow {
	-webkit-box-shadow: inset 0px 2px 4px -1px #000000;
	box-shadow: inset 2px 3px 4px -2px #000000;
}

.icon-shadow {
	filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.4));
}

.links {
	position: absolute;
	bottom: 10px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
}
</style>
