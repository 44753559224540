<template lang="pug">
v-card(
	style="border: 3px solid var(--v-account-card-divider-base) !important"
	flat).body-1.white.pa-6.rounded-xl
	div.body-1.text-sm-h6.text-center.pt-sm-10.complementary--text.text--darken-4
		| Once you confirm your selection, we will:
	div.d-flex.flex-wrap.justify-center.mb-4.mb-sm-10.mt-sm-4
		v-card(
			v-for='(step, i) in explanationSteps'
			:key='i'
			min-width='100'
			max-width='150'
			outlined
			color='white'
			:class="{'body-2' : $vuetify.breakpoint.xs}"
		).pa-1.mx-1.my-1.primary--text.text--darken-3.body-2
			div.d-flex.justify-center.mb-2.pt-4
				v-icon(
					color='complementary'
					size='30') {{ step.icon }}
			div.text-center.text--secondary {{ step.text }}
	div.d-flex.justify-center.pb-2
		v-btn(
			@click="$emit('close')"
			color='primary')
			//v-icon expand_more
			span Alright
</template>
<script>
export default {
	props: ["initiatorName"],
	name: "request-instruction",
	data() {
		return {
			explanationSteps: [
				{
					icon: "attach_money",
					text: "Charge you for the drinks you selected"
				},
				{
					icon: "shopping_bag",
					text: "Add a purchase to your account"
				},
				{
					icon: "sentiment_very_satisfied",
					text: `Show ${this.initiatorName} that you responded`
				}
			]
		};
	}
};
</script>
