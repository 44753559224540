import { render, staticRenderFns } from "./PendingBalance.vue?vue&type=template&id=01ae71cc&scoped=true&lang=pug&"
import script from "./PendingBalance.vue?vue&type=script&lang=js&"
export * from "./PendingBalance.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01ae71cc",
  null
  
)

export default component.exports