<template lang="pug">
	div
		.text-h6.text-sm-h5.text-md-h4.mb-4.primary--text I have a suggestion
		div
			p
				| Awesome, we appreciate you sharing it with us!
			v-btn(
				@click='$emit("openFeedbackDialog", "Tip")'
				color='primary'
				).mb-6 Share your idea
			ContactInformation(
				:contactPerson="contactPerson"
				subject="I%20have%20a%20suggestion")
</template>

<script>
import ContactInformation from "../Elements/ContactInformation.vue";
import { catchError } from "../../../mixins/catchError";
export default {
	components: { ContactInformation },
	mixins: [catchError],
	props: ["contactPerson"],
	methods: {
		openFeedbackDialog(feedbackType) {
			this.$emit("openFeedbackDialog", feedbackType);
		}
	}
};
</script>

<style scoped></style>
