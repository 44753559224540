<template lang="pug">
BadgeBase(
	:size='size'
	text='PayMe'
	borderColorClass='pay-me'
	textColorClass='pay-me-text'
)
</template>
<script>
import BadgeBase from "./BadgeBase.vue";
export default {
	props: ["size"],
	components: { BadgeBase }
};
</script>
