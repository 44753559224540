<template lang="pug">
	v-data-table(
		:headers="headers"
		:items="items"
		hide-default-footer
		dense).elevation-1
		template(v-slot:item.price='item')
			span {{ formatMonetaryValue(item.value) }}
		template(v-slot:item.quantity='item')
			span ×{{ item.value }}
		template(v-slot:item.sum='item')
			span {{ formatMonetaryValue(item.value) }}
		template(v-slot:no-data)
			| {{ messages.nothingFound }}
</template>
<script>
export default {
	name: "purchase-details",
	props: ["items", "messages"],
	data() {
		return {
			headers: [
				{
					text: "Drink",
					align: "start",
					value: "drink"
				},
				{
					text: "Price",
					align: "start",
					value: "price"
				},
				{
					text: "Quantity",
					align: "center",
					value: "quantity"
				},
				{
					text: "Sum",
					align: "start",
					value: "sum"
				}
			]
		};
	}
};
</script>
