<template lang="pug">
v-card(
	:to="link.to"
	color='primary'
	target="_blank").pa-3.primary-light--text.text-decoration-none
	div.d-flex.justify-space-between.align-center
		div
			div.subtitle-1.font-weight-bold {{ link.title }}
			div.subtitle-2 {{ link.text }}
		v-icon(size='20' color='primary-light').mr-1 open_in_new
</template>
<script>
export default {
	props: ["link"]
};
</script>
