import HowTo from "./HowTo.vue";
import WhereToFind from "./WhereToFind.vue";
import BugReport from "./BugReport.vue";
import ForgotPassword from "./ForgotPassword.vue";
import NothingHappens from "./NothingHappens.vue";
import PasswordRules from "./PasswordRules.vue";
import Suggestion from "./Suggestion.vue";
import SystemError from "./SystemError.vue";
import WhyEmail from "./WhyEmail.vue";
import Split from "./Split.vue";
import PendingBalance from "./PendingBalance.vue";
import NotFound from "./NotFound.vue";
import PayMe from "./PayMe.vue";
import ForgotToScan from "./ForgotToScan";

export default {
	HowTo,
	WhereToFind,
	BugReport,
	ForgotPassword,
	NothingHappens,
	PasswordRules,
	Suggestion,
	SystemError,
	WhyEmail,
	Split,
	PendingBalance,
	NotFound,
	PayMe,
	ForgotToScan
};
