<template lang="pug">
v-row.mx-4
	v-col.py-0
		v-sheet(color='background' max-width='700').mx-auto.pb-12
			div(v-if='settingsPage === 0')
				div(v-for='link in links')
					Link(:link='link').mb-4
				.text-h5.background-text-dark--text.mt-6 Settings
				v-card(
					v-for='(item, i) in settingsSections'
					:key='i'
					@click='setSettingsPage(i + 1)'
					color='primary'
					min-height='70'
					max-width='400'
					).d-flex.my-4.mx-auto
					div.d-flex.align-center.pa-4
						v-icon(
							size='28'
							color='primary-light'
							) {{ item.icon }}
					div.d-flex.align-center.ml-2.primary-light--text {{ item.text }}
					div.d-flex.align-center.justify-end.pa-4.flex-grow-1
						v-icon(
							size='32'
							color='primary-light') chevron_right
				div.d-flex.justify-center.mt-6
					v-btn(
						to='logout'
						color='primary'
						outlined).mt-2.text-decoration-none
						v-icon.mr-1 logout
						span log out
				GDPRLinks(:isMobile="true")
			div(v-else-if='settingsPage === 1')
				ThemePicker(landing='true' row='false')
			v-sheet(
				v-else-if='settingsPage === 2'
				color='background'
				max-width='300').mx-auto
				v-form(v-model="contacts.valid")
					v-text-field(
						v-model="contacts.email"
						label="Email"
						:rules="[rules.required, rules.email]"
						:error="emailExists"
						:error-messages="emailExists ? 'Email already taken' : []"
						filled
						@input='emailUpdateStatus = null; emailExists = false')
					v-text-field(
						v-model="contacts.room"
						label="Room number"
						hint="Or WG"
						:rules="[rules.required, rules.maxRoomLength]"
						filled)
				div.d-flex.justify-center
					v-btn(
						@click='saveContactInformation'
						:disabled='disableSaveButton'
						color='primary') Save
				UpdateContactStatus(
					v-if="emailUpdateStatus"
					title='email'
					:status='emailUpdateStatus'
					:statuses='updateStatuses'
					successMessage='Follow the instructions we sent to your new address to update your email').mt-5.body-2
				UpdateContactStatus(
					v-if="roomUpdateStatus"
					title='room'
					:status='roomUpdateStatus'
					:statuses='updateStatuses'
					successMessage='Room number updated').mt-5.body-2
				EmailChangePending(
					v-if='this.$store.state.user.emailCandidate'
					:isMobile="true")
			div(v-else-if='settingsPage === 3')
				PasswordInput(requireCurrentPassword = 'true').pb-16
			div(v-else-if='settingsPage === 4').pb-16
				EmailSettings.pb-1
</template>
<script>
import { validators } from "@/mixins/validators";
import ThemePicker from "../../../Helpers/ThemePicker.vue";
import PasswordInput from "../../../Helpers/PasswordInput.vue";
import EmailSettings from "@/components/Account/Elements/EmailSettings.vue";
import UpdateContactStatus from "../Elements/UpdateContactStatus.vue";
import EmailChangePending from "../../EmailChangePending.vue";
import GDPRLinks from "../../../GDPRLinks.vue";
import Link from "./Link.vue";

export default {
	components: {
		ThemePicker,
		PasswordInput,
		EmailSettings,
		UpdateContactStatus,
		EmailChangePending,
		GDPRLinks,
		Link
	},
	mixins: [validators],
	props: ["settingsPage", "settingsSections"],
	data() {
		return {
			links: [
				{ title: "Questions?", text: "Visit Support Portal", to: "support" },
				{ title: "About us", text: "Everything you need to know", to: "/" }
			],
			contacts: {
				email: this.$store.state.user.email,
				room: this.$store.state.user.room,
				valid: false
			},
			emailUpdateStatus: null,
			emailExists: false,
			roomUpdateStatus: null,
			updateStatuses: {
				loading: "loading",
				error: "error",
				success: "success"
			}
		};
	},
	methods: {
		async checkForExistingEmail() {
			const response = await this.$axios.get(
				`/api/email_exists/${this.contacts.email}`
			);
			this.emailExists = response.data.response;
		},
		setSettingsPage(pageIndex) {
			this.$emit("setSettingsPage", pageIndex);
		},
		saveContactInformation() {
			if (this.contacts.email !== this.$store.state.user.email)
				this.saveNewEmail(this.contacts.email);
			if (this.contacts.room !== this.$store.state.user.room)
				this.saveNewRoom(this.contacts.room);
		},
		async saveNewEmail(email) {
			await this.checkForExistingEmail();
			if (this.emailExists) return;
			this.emailUpdateStatus = this.updateStatuses.loading;
			this.$axios
				.put(`/api/change_email?new_email=${email}`)
				.then(() => {
					this.emailUpdateStatus = this.updateStatuses.success;
					this.$store.commit("setUser", {
						...this.$store.state.user,
						email: email
					});
				})
				.catch(() => {
					this.emailUpdateStatus = this.updateStatuses.error;
				});
		},
		saveNewRoom(room) {
			this.roomUpdateStatus = this.updateStatuses.loading;
			this.$axios
				.put(`/api/change_room?new_room=${room}`)
				.then(() => {
					this.roomUpdateStatus = this.updateStatuses.success;
					this.$store.commit("setUser", {
						...this.$store.state.user,
						room: room
					});
				})
				.catch(() => {
					this.roomUpdateStatus = this.updateStatuses.error;
				});
		}
	},
	computed: {
		disableSaveButton() {
			return (
				!this.contacts.valid ||
				(this.contacts.email == this.$store.state.user.email &&
					this.contacts.room == this.$store.state.user.room) ||
				this.roomUpdateStatus === this.updateStatuses.loading ||
				this.emailUpdateStatus === this.updateStatuses.loading
			);
		}
	}
};
</script>
<style lang="scss"></style>
