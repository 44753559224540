<template lang="pug">
div
	div.mt-3
		span.mr-1 You can contact
		span(v-if="$isBiederDrinks")
			NameAndRoomBadge(
				:name='contactPerson.name'
				:room='contactPerson.room'
				:size='$vuetify.breakpoint.xs ? "s" : ""')
			div.mt-2
				span.mr-1 or
				a(:href="`mailto:support@{{ $emailDomain }}?subject=${subject}`")
					| support@{{ $emailDomain }}
		span(v-else)
			a(:href="`mailto:support@{{ $emailDomain }}?subject=${subject}`")
				| support@{{ $emailDomain }}
</template>

<script>
import NameAndRoomBadge from "../../Elements/NameAndRoomBadge.vue";
export default {
	components: { NameAndRoomBadge },
	props: ["subject", "contactPerson"]
};
</script>

<style scoped></style>
