<template lang="pug">
v-row.primary-light
	v-col
		.text-h4.text-center.complementary--text.mt-3 Did you know?
		.text-h5.text-center.complementary--text.my-1 You can get an account, and:
		div.d-flex.justify-center.text-center.my-6.complementary--text
			div
				div.d-flex.justify-space-around.flex-wrap.px-6
					v-card(
						v-for="(feature, index) in features"
						:key='index'
						elevation='10'
						color='complementary'
						:max-width='index === 1 || index === 0 ? "260" : ""'
						).ma-2.primary-light--text.text--lighten-1
						div.py-4.px-6.align-center.text-start
							div.d-flex.justify-center
								v-icon(
									:size="42"
									color="primary-light lighten-1"
								)  {{ feature.icon }}
								div.ml-2.d-flex.align-center.my-3
									div.text-h4 {{ feature.heading }}
							div(
								v-html='feature.text'
							).text-h5.text-center.complementary--text.text--lighten-5
				div.text-h4.mt-10 Interested?
				div.mt-4.text-h5.complementary--text.text--lighten-1 Just give your email to one of the admins:
				div(v-if='loading').d-flex.justify-center
					v-skeleton-loader(v-for='i in 2' :key='i' type="chip").mx-2.mt-5
				div(v-else).d-flex.justify-center.mt-2
					NameAndRoomBadge(
						v-for='(admin, i) in admins'
						:key='i'
						:name='admin.name'
						:room='admin.room'
						size='l')

</template>
<script>
import NameAndRoomBadge from "../Elements/NameAndRoomBadge.vue";
export default {
	components: { NameAndRoomBadge },
	props: ["admins", "loading"],
	name: "GetAccountAd",
	data() {
		return {
			features: [
				{
					icon: "account_balance_wallet",
					heading: "Check",
					text: "your account balance"
				},
				{ icon: "fact_check", heading: "See", text: "what you bought before" },
				{
					icon: "groups",
					heading: "Split the bill!",
					text:
						"Buy drinks with your chip and invite your friends to pay for their beverages"
				}
			]
		};
	}
};
</script>
<style scoped></style>
