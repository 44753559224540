<template lang="pug">
v-sheet.pt-4.white.add-scroll
	.text-h6.text-center.primary--text Purchase
	.mx-6.mt-10
		v-select(
			v-model="selectedGroupId"
			:items="balances.data"
			:disabled="balances.data.length < 2"
			label="Group"
			item-text="groupName"
			item-value="groupId"
			outlined).mb-n4
		.caption.complementary-text--text(v-if="balances.data.length < 2 && this.isBiederDrinks")
			| You can only make purchases in {{ balances.data.length > 0 ? balances.data.find(group => group.groupId === this.groupId).groupName : ""}}.
			| For other groups you have access to, make at least one purchase
			| at the terminal inside the room with your Chip
	alert(
			:content='content'
			ref='alert'
			@resetAlert='resetAlert'
			:alertImage='alertImage'
			:type='type')
	v-form(v-model="purchaseIsValid").pt-2
		Instruction(v-if='purchase.length === 0')
		v-simple-table(
			v-else
			dense
			fixed-header
			style=" max-width: 100%; overflow: hidden; table-layout: fixed;  white-space: normal; ")
			template(v-slot:default)
				thead
					tr
						th.text-left
							| Name
						th.text-left
							| Price
						th.text-center
							| Quantity
				tbody
					tr(
						v-for='drink in purchase'
						:key='drink.barcode').drink-row
						td(style='word-wrap:break-word; white-space: normal; max-width: 90px')
							| {{ drink.name }}
						td.px-0.text-center {{ formatMonetaryValue(drink.price) }}
						td(style="min-width: 160px")
							div.d-flex.justify-center
								v-btn(
									color="primary"
									rounded
									outlined
									small
									@click="drink.quantity > 1 ? drink.quantity-- : removeFromPurchase(drink)")
									v-icon(v-if='drink.quantity > 1') mdi-minus
									v-icon(v-else size='18') cancel
								div.body-1.drink-quantity.mx-3 {{ drink.quantity }}
								v-btn(
									color="primary"
									rounded
									outlined
									small
									@click="drink.quantity++")
									v-icon mdi-plus
		//v-sheet(v-if='purchase.length < 6' height='300')
		v-footer(bottom fixed).primary-light.pt-3
			v-autocomplete(
			v-if="drinks"
			@change="addToPurchase"
			ref="drink"
			v-model="drinkInput"
			:items="drinks.data"
			:filter="filterDrink"
			:loading='drinks.status === statuses.loading'
			:disabled='drinks.status === statuses.loading'
			item-text="drink"
			prepend-inner-icon="search"
			label="Choose drinks..."
			placeholder="Type name of a drink"
			outlined
			required
			no-data-text="Loading drinks..."
			hide-details
			).mx-4
				template(v-slot:item="{item}")
					span {{ item.name }}
				template(v-slot:selection="{item}")
					span {{ item.name }}
			v-sheet(
				width='100%'
				color='primary-light'
				).d-flex.justify-space-around.pb-2.pt-3
				div
					v-btn(
						large
						@click="clearPurchase(); $emit('close')"
						outlined
						color="primary") cancel
				div
					v-btn(
						large
						@click="finishOrder()"
						:disabled="purchase.length === 0"
						color="primary") pay {{ formatMonetaryValue(purchaseSum) }}
	v-dialog(
		v-model='showRequestStatusDialog'
		transition="dialog-bottom-transition"
		max-width="420"
		@click:outside='cancel'
		@keydown.esc='cancel'
		:fullscreen='true')
		RequestSender(
			:status='requestStatus'
			:statuses="requestStatuses"
			@cancel='cancel')
</template>
<script>
import RequestSender from "@/components/Helpers/RequestSender.vue";
import { catchError } from "@/mixins/catchError";
import { dataLoader } from "@/mixins/dataLoader";
import Alert from "../../../Alert";
import Instruction from "./Instruction.vue";

export default {
	props: { groupId: Number, balances: Object },
	components: { Instruction, Alert, RequestSender },
	mixins: [dataLoader, catchError],
	data() {
		return {
			dataToLoad: [
				{
					url: "drinks",
					addGroupId: true
				}
			],
			selectedGroupId:
				this.balances.data.length > 0
					? this.balances.data.find(group => group.groupId === this.groupId)
							.groupId
					: null,
			drinks: [],
			purchase: [],
			purchaseIsValid: false,
			drinkInput: "",
			page: 1,
			alertImage: "",
			status: null,
			showRequestStatusDialog: false,
			requestStatus: null,
			requestStatuses: {
				sendingRequest: {
					title: "Hold tight",
					text: "Making a purchase..."
				},
				requestCompleted: {
					title: "Done!",
					text:
						"You've successfully paid for the drink(s). If you paid in advance, you can now pick them up in the G-Raum or ask someone else to do it for you. Don't scan them - you've already paid for them."
				},
				requestFailed: {
					title: "Oups...",
					text: "We couldn't make a purchase",
					error: null,
					supportInformation: {
						title: "You can always reach out to us at:",
						link: {
							text: `support@${this.$emailDomain}`,
							url: `mailto:support@${this.$emailDomain}?subject=Error%20with%20MobileOrder`
						}
					}
				}
			}
		};
	},
	watch: {
		groupId: {
			handler: function(value) {
				// I don't know why data array is empty after completing an order
				// that's why there is this check here, in data() and in the text under v-select
				if (this.balances.data.length > 0)
					this.selectedGroup = this.balances.data.find(
						group => group.groupId === value
					).groupId;
			}
		}
	},
	methods: {
		cancel() {
			this.$emit("close");
			this.requestStatus = null;
			this.showRequestStatusDialog = false;
			this.requestStatuses.requestFailed.error = null;
		},
		resetAlert() {
			this.alertImage = "";
		},
		roundedpurchaseSum(purchaseSum) {
			return this.addZeroes(
				Math.round((purchaseSum + Number.EPSILON) * 100) / 100
			);
		},
		filterDrink(item, queryText) {
			return item.name
				.toLocaleLowerCase()
				.includes(queryText.toLocaleLowerCase());
		},
		addToPurchase() {
			this.purchase.push({ ...this.drinkInput, quantity: 1 });
			this.drinks.data.splice(
				this.drinks.data.findIndex(drink => drink.id == this.drinkInput.id),
				1
			);
		},
		removeFromPurchase(drink) {
			delete drink.quantity;
			this.drinks.data.push(drink);
			this.purchase.splice(
				this.purchase.findIndex(
					drinkInPurchase => drinkInPurchase.id == drink.id
				),
				1
			);
			this.drinkInput = "";
		},
		clearPurchase() {
			for (; this.purchase.length > 0; )
				this.removeFromPurchase(this.purchase[0]);
		},
		finishOrder() {
			if (!this.groupId) {
				this.content = "groupId not set";
				this.$refs.alert.showAlert();
			}
			this.buttons_blocked = true;
			this.requestStatus = this.requestStatuses.sendingRequest;
			this.showRequestStatusDialog = true;

			var orderToSend = {
				groupId: this.selectedGroupId,
				drinks: [],
				terminal: false
			};

			// this is awkward. Think about keeping orders in an array instead of the orederInfo object

			for (const drink of this.purchase) {
				orderToSend.drinks.push({
					drinkId: drink.id,
					quantity: drink.quantity
				});
			}

			this.$axios
				.post("api/order", orderToSend)
				.then(response => {
					this.requestStatus = this.requestStatuses.requestCompleted;
					this.$store.commit("setBalance", response.data.balance);
					this.$emit("reloadData");
				})
				.catch(error => {
					this.requestStatuses.requestFailed.error = error.response.data.error;
					this.requestStatus = this.requestStatuses.requestFailed;
					this.catchError(error);
				})
				.finally(() => {
					this.purchase = [];
				});
		}
	},
	computed: {
		purchaseSum() {
			return this.roundedpurchaseSum(
				Object.values(this.purchase).reduce(
					(total, currentValue) =>
						total +
						parseFloat(currentValue.price) * parseInt(currentValue.quantity),
					0
				)
			);
		}
	}
};
</script>

<style lang="scss" scoped>
.drink-row {
	height: 70px !important;
}

.drink-quantity {
	padding-top: 2px;
}

.add-scroll {
	overflow: auto;
	padding-bottom: 140px;
}
</style>
