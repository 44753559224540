<template lang="pug">
table(
	style='width: 100%'
	).table-fixed.white
	tbody
		tr(
			v-for='(drink, index) in drinks'
			:key='index')
			td(
				:class="isMobile ? 'body-2' : 'body-1'"
				).pr-2.py-5.black--text {{ drink.drink }}
			td(style="width: 135px")
				div.d-flex
					v-btn(
						@click='$emit("reduceQuantityByOne", drink.id)'
						:disabled="drink.quantity < 1"
						icon)
						v-icon(color="primary") mdi-minus
					v-text-field(
						v-model.number="drink.quantity"
						:rules="maxQuantity(drink.quantity, index)"
						hide-details="auto"
						single-line
						rounded
						filled
						full-width
						dense
						).pa-0
					v-btn(
						@click='$emit("increaseQuantityByOne", drink.id)'
						:disabled="drink.quantity >= limits[index]"
						icon)
						v-icon(color="primary") mdi-plus
</template>
<script>
export default {
	props: { drinks: Array, limits: Array, isMobile: Boolean },
	name: "drink-picker",
	data() {
		return {
			globalRules: [v => v >= 0 || "Not valid", v => v !== "" || "Required"]
		};
	},
	methods: {
		maxQuantity(v, drinkId) {
			return [...this.globalRules, v <= this.limits[drinkId] || "Too many"];
		}
	}
};
</script>
<style lang="scss" scoped>
.table-fixed {
	table-layout: fixed;
}
</style>
