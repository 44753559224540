<template lang="pug">
v-card#sidebar(color="complementary" :height='height').rounded.pt-5
	v-card(v-if='balances.status === statuses.error').rounded-l-0.px-2.text-center.mr-3.py-4
		v-icon(color='primary' size='32').mr-1.mb-4 wifi_off
		div.text-secondary.body-1 Couldn't load Balances
		div.pt-2.primary--text.body-2
			a(@click='$emit("reloadData")') Try again
	div(v-else-if='balances.status === statuses.success')
		div(
			v-for="(balance, index) in balances.data"
			:key='index').mr-3
			BalanceCard(:balance="balance").mb-4
	BalanceCardSkeleton(v-else)
	div#footer
		v-card(
			tile
			flat
			color="complementary darken-1"
			).pl-6
			v-row.pb-1
				v-col(cols='7').pl-0.pb-0.complementary-text-light--text.d-flex.align-center
					span {{ $store.state.user.name }}
				v-col.pb-0.d-flex.align-center.justify-center
					v-tooltip(
						top
						open-delay="300"
						color="primary")
						template(v-slot:activator="{ on, attrs }")
							v-btn(
								icon
								to='logout'
								v-bind="attrs"
								v-on="on").text-decoration-none
								v-icon(color="complementary-text-light") logout
						span Logout
					v-tooltip(
						top
						open-delay='300'
						color="primary"
						nudge-left='22')
						template(v-slot:activator="{ attrs, on }")
							v-btn(
								icon
								@click='$emit("showAccountSettingsDialog")'
								v-bind="attrs"
								v-on="on")
								v-icon(color="complementary-text-light") settings
						span Settings
			v-row
				v-col.pl-0.pt-0
					v-btn(
						v-if='user_is_admin'
						to="dashboard"
						color="complementary-text-light"
						x-small
						outlined).text-decoration-none admin panel
		div(
			@mouseover='underlineSupportLink = true'
			@mouseleave='underlineSupportLink = false'
			).d-flex.justify-center.my-8
			a(href='/#/support')
				v-icon(
					color='complementary-text-light'
					size='24') contact_support
				span(
					:class="{'text-decoration-underline' : underlineSupportLink}"
					).d-inline-block.ml-1.complementary-text-light--text
					| Support Center
		div.d-flex.justify-center.my-3
			v-btn(
				@click="$emit('openFeedbackDialog')"
				small
				color='account')
				span.complementary--text.text--darken-3 give Feedback
		div.h5.mb-0.mt-10.d-flex.justify-center.brand-text-logo.logo-shadow
			a(
				href='/'
				).text-decoration-none.complementary--text.text--lighten-3
				| {{ $appTitle }}
</template>
<script>
import { mapGetters } from "vuex";
import BalanceCard from "./BalanceCard/index.js";
export default {
	props: ["height", "balances", "statuses"],
	data() {
		return {
			underlineSupportLink: false
		};
	},
	computed: { ...mapGetters(["user_is_admin"]) },
	components: { ...BalanceCard }
};
</script>

<style lang="scss" scoped>
#sidebar {
	position: absolute;
	width: 250px;
	z-index: 4;
	left: -30px;
	top: 14px;
	overflow: hidden;
}

#footer {
	width: 100%;
	position: absolute;
	bottom: 20px;
}

.logo-shadow {
	text-shadow: 0px 0.25px 0px var(--v-complementary-darken4);
}
</style>
