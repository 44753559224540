import { render, staticRenderFns } from "./NothingHappens.vue?vue&type=template&id=72d14ebf&scoped=true&lang=pug&"
import script from "./NothingHappens.vue?vue&type=script&lang=js&"
export * from "./NothingHappens.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72d14ebf",
  null
  
)

export default component.exports