<template lang="pug">
div(
	:style='`border: 1px var(--v-${borderColorClass}-base) solid`'
	:class='`${textColorClass}--text`'
	).rounded.ma-0.d-flex.align-center.text-h6
	div(
		v-if='size === "l"'
		style='padding-top: 1px;'
		:class="{ 'letter-spacing' : addLetterSpacing}").px-3
		| {{text}}
	div(
		v-else-if='size === "s"'
		style='padding-top: 2px; padding-bottom: 2px;'
		:class="{ 'letter-spacing' : addLetterSpacing}"
		).body-2.px-2 {{text}}
	div(
		v-else
		:class="{ 'letter-spacing' : addLetterSpacing}").h6.px-2.ma-0.py-1
		| {{text}}
</template>
<script>
export default {
	props: [
		"size",
		"text",
		"borderColorClass",
		"textColorClass",
		"addLetterSpacing"
	]
};
</script>
<style scoped>
.pt-1px {
	padding-top: 1px;
}
.letter-spacing {
	letter-spacing: 1.5px !important;
}
</style>
