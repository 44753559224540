<template lang="pug">
v-card(
 	max-width='1000'
	color='background-inset'
	).shadow
	div.py-3
		v-container(fluid).d-flex.justify-center.align-center.fill-height
			v-row.px-4.pb-0
				v-col(
					:cols='vertical || $vuetify.breakpoint.xs ? 12 : 8'
					).d-flex.align-center.justify-center
					div
						div(
							:class="{'my-2' : vertical}"
							).text-h4.background-text-dark--text.font-weight-light.text-shadow.text-center
							| Something missing?
						div(
							:class="vertical || $vuetify.breakpoint.xs ? 'text-center' : 'text-left'"
							).background-text-dark--text
							| You are the best person to know! Mind sharing?
				v-col(
					:cols='vertical || $vuetify.breakpoint.xs ? 12 : 4'
					).d-flex.justify-center.align-center
					div
						v-btn(
							@click='requestWidget'
							large
							plain
							color='primary')
							| suggest widget
</template>
<script>
export default {
	props: ["vertical"],
	methods: {
		requestWidget() {
			this.$emit("requestWidget");
		}
	}
};
</script>
<style lang="scss" scoped>
.shadow {
	-webkit-box-shadow: inset 1px 3px 8px -4px #000000 !important;
	box-shadow: inset 0px 3px 8px -4px #000000 !important;
}

.text-shadow {
	text-shadow: 1px 1px 1.5px #ffffff;
}
</style>
