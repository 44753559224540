<template lang="pug">
v-container(fluid).ma-0.pa-0
	v-row.pl-sm-8.ma-0.pa-0
		v-col(cols='12' md='4' lg='4').d-flex.justify-center.align-center.pa-0
			Title(
				:textTop='"Meet your"'
				:textTopClass="'text-h4'"
				:textBottom='"Account"'
				:align='"center"'
				squeeze='true')
		v-col(cols='12' md='8' lg='8').pa-0.pl-sm-8
			div.d-flex.justify-sm-end.justify-center
				v-sheet(
					rounded="l"
					elevation='12').d-lg-flex.rounded-r-0.my-10.pl-sm-8.py-sm-6
					img(
						:src='getImageForScreenSize'
						:class='$vuetify.breakpoint.xs ? "mw-mobile" : "mw-desktop"')
				v-sheet(
					v-if='$vuetify.breakpoint.xl'
					min-width='1').xx.my-7
				v-sheet(
					v-if='$vuetify.breakpoint.xl'
					color='white'
					min-width='30px')
	v-row.ma-0.pb-6
		v-col.d-flex.justify-center.mt-6
			CheckItOutButton(
				text="Check it out"
				page="account")
</template>

<script>
import Title from "./Title.vue";
import CheckItOutButton from "../Elements/CheckItOutButton.vue";
export default {
	data() {
		return {};
	},
	created() {},
	methods: {},
	computed: {
		getImageForScreenSize() {
			if (this.$vuetify.breakpoint.xs)
				return require("../../../../public/img/WhatsNew/Account-mobile.png");
			if (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md)
				return require("../../../../public/img/WhatsNew/Account-small.png");
			else return require("../../../../public/img/WhatsNew/Account.png");
		}
	},
	components: { Title, CheckItOutButton }
};
</script>

<style scoped>
.zzz {
	width: calc(100% - 12px) !important;
}

.xx {
	z-index: 9999;
	/* -webkit-box-shadow: -12px -1px 11px -11px #000000;
	box-shadow: -12px -1px 11px -11px #000000; */
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.75);
	clip-path: inset(0px 0px 0px -40px);
}

.mw-desktop {
	width: 100%;
	max-width: 1200px !important;
}

.mw-mobile {
	width: 100%;
	max-width: 300px !important;
}
</style>
