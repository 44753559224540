<template lang="pug">
	div.white
		v-overlay(:value="overlay && !$store.state.group_name" opacity='0.93')
			v-container
				alert.pt-16(
					:content='content'
					ref='roomCodeOverlayAlert'
					:type='type')
				div.text-h2.text-center.mb-4 Room Code
				div(style='height:120px').black.text-h1.text-right.pt-3.mr-1.pr-3 {{ roomCodeInput }}
				v-btn(v-for='i in 9' :key='i' width='170' height='140' @click='digitPressed(i)').text-h2 {{ i }}
				div.d-flex.justify-center
					div(style='width:170px').d-flex.justify-center.align-center
						v-btn(icon @click='roomCodeInput = ""')
							v-icon(size='76' color='red') close
					div(style='width:170px')
						v-btn(width='170' height='140' @click='digitPressed(0)').text-h2 0
					div(style='width:170px').d-flex.justify-center.align-center
						v-btn(icon @click='submitRoomCode' color='green')
							v-icon(size='76') done
		v-overlay(:value="loading")
			v-card(color='primary-light').px-16.py-10
				div.d-flex.justify-center
					v-progress-circular(
						color="primary"
						:size="70"
						indeterminate)
				div.primary--text.my-10
					div Trying to connect...
					div(v-if='retryCount > 0') Retried {{retryCount+""}} time{{retryCount>1 ? "s":""}}
				div.d-flex.justify-center
					v-btn(
						@click="loading = false"
						color='primary'
						large)
						| Abort
		div(v-if='group').text-h5.primary.text-center.py-3.white--text {{ group }}
		h1.brand-text-logo#header.primary--text.text-center.pb-16 {{ $appTitle }}
		alert.pt-16(
			:content='alertContent'
			ref='alert'
			:alertImage='alertImage'
			@resetAlert='resetAlert'
			:type='type')
		div(v-if='!$store.getters.user_logged_in && !$store.getters.user_is_admin')
			v-form(inline)
				v-container(fluid)
					v-row
						v-text-field.hidden-roomCodeInput(
							ref='drinkinput'
							v-mask="rfidMask"
							v-model='rfid'
							@keyup="submitWhenReady")
						button(type='submit' style='display:none') Submit
			div.h6.text-center.text-secondary
				v-container
					v-row
						v-col(cols="2")
						v-col.h4
							v-row
								v-col
									div.text-start.primary--text.font-weight-bold Want to buy drinks?
							v-row
								v-col.pa-0.pt-1(cols="2")
									v-icon(size="50" color="complementary") sensors
								v-col.pa-0
									div.text-left.ml-3.pt-1
										span Scan your chip with the reader
										span.complementary--text.font-weight-bold &nbsp;on the key box outside
							v-row
								v-col.mt-10
									div.text-start.primary--text.font-weight-bold Finished shopping?
							v-row
								v-col.pa-0.mt-2.pr-1(cols="2").d-flex.justify-end.align-start
									v-icon(size="50" color="complementary") key
								v-col.pa-0.text-left.ml-3.pt-1
										div
											span Lock the room and drop the key into the
											span.complementary--text.font-weight-bold &nbsp;hole on the top
											span &nbsp;of the key box
						v-col(cols="1")
</template>

<script>
import Alert from "../Alert";
import { catchError } from "../../mixins/catchError";
import { setTheme } from "../../mixins/themes";

export default {
	mixins: [catchError, setTheme],
	data() {
		return {
			overlay: true,
			roomCodeInput: "",
			group: "Login Required",
			alertImage: "",
			rfid: "",
			alertContent: "",
			loading: false,
			audios: {
				returnKey: new Audio(
					require("../../../public/sounds/dont_forget_to_return_key.mp3")
				),
				systemError: new Audio(
					require("../../../public/sounds/system_error.mp3")
				),
				debt: new Audio(require("../../../public/sounds/too_much_debt.mp3")),
				noKeyFound: new Audio(
					require("../../../public/sounds/no_key_found.mp3")
				),
				accountDeactivated: new Audio(
					require("../../../public/sounds/account_deactivated.mp3")
				),
				keyboxNotWorking: new Audio(
					require("../../../public/sounds/key_box_not_working.mp3")
				),
				tryingToConnect: new Audio(
					require("../../../public/sounds/trying_to_connect.mp3")
				),
				noInternet: new Audio(
					require("../../../public/sounds/no_internet_error.mp3")
				)
			},
			selectedAudio: null,
			delayAudio: false,
			retryCount: 0,
			rfidMask: {
				mask: "FF FF FF FF",
				tokens: {
					F: {
						pattern: /[0-9a-fA-F]/,
						transform: v => v.toLocaleUpperCase()
					}
				}
			}
		};
	},
	components: { Alert },
	mounted() {
		this.group = this.$store.state.group_name ?? "";
		const vm = this;
		window.setInterval(function() {
			if (vm.$refs.drinkinput) vm.$refs.drinkinput.focus();
		}, 300);
		window.addEventListener("axiosRetry", evt => {
			this.retryCount = evt.detail.count;
		});
	},
	watch: {
		retryCount: function(val) {
			if (val === 2) this.audios.tryingToConnect.play();
			if (val === 5) {
				this.audios.noInternet.play();
				setTimeout(function() {}, 8500);
			}
		}
	},
	methods: {
		in1() {
			this.rfid = "69 F1 28 B9";
			this.login();
		},
		in2() {
			this.rfid = "A1 3A D9 3D";
			this.login();
		},
		submitRoomCode() {
			this.loading = true;
			var vm = this;
			this.$axios_instance_no_interceptors
				.get(`/api/login_terminal/${this.roomCodeInput}`)
				.then(async response => {
					this.loading = false;
					this.overlay = false;
					this.group = response.data.groupName;
					this.$store.commit("setGroupName", response.data.groupName);
				})
				.catch(error => {
					vm.roomCodeInput = "";
					const response = error.response ? error.response : { data: error };
					vm.content = response.data.error
						? response.data.error
						: response.data;
					vm.type = "error";
					vm.$refs.roomCodeOverlayAlert.showAlert();
					vm.loading = false;
				});
		},
		digitPressed(digit) {
			this.roomCodeInput += digit;
		},
		resetAlert() {
			this.alertImage = "";
		},
		submitWhenReady() {
			if (this.rfid.length === 11) this.login();
		},
		saveConfigurationToStore(config) {
			this.$store.commit("setLoginMethod", "rfid");
			for (const [key, value] of Object.entries(config)) {
				//? if any of value are null, abort with an eror audio?
				if (key === "warning") continue;
				this.$store.commit(
					`set${key[0].toUpperCase() + key.substring(1)}`,
					value
				);
			}
		},
		async openDoor() {
			const vm = this;
			await this.$axios_no_retry
				.get("http://localhost:5050/login")
				.then(() => {
					vm.delayAudio = true;
					vm.selectedAudio = vm.audios.returnKey;
					window.location.href = "/#/order";
				})
				.catch(() => {
					console.log("Door can't be opened");
					vm.selectedAudio = vm.audios.keyboxNotWorking;
					window.location.href = "/#/order";
				});
		},
		login() {
			const vm = this;
			vm.loading = true;
			vm.retryCount = 0;
			this.$axios_instance_no_interceptors
				.get("/api/login_rfid", {
					params: { rfid: this.rfid }
				})
				.then(async response => {
					vm.saveConfigurationToStore(response.data);
					await this.setTheme(response.data.settings.theme);
					await vm.openDoor();
				})
				.catch(error => {
					// check if selectedAudio is already playing
					if (vm.selectedAudio) {
						vm.selectedAudio.pause();
						vm.selectedAudio.currentTime = 0;
					}

					var responseError = error.response.data.error;
					vm.alertContent = responseError;
					if (
						!responseError ||
						responseError.includes("MySQL server has gone away")
					) {
						vm.selectedAudio = vm.audios.systemError;
						vm.alertContent = "Could not connect to backend.";
					} else if (responseError.includes("pay your debts"))
						vm.selectedAudio = vm.audios.debt;
					else if (responseError.includes("No RFID")) {
						vm.alertImage = "eyes";
						vm.selectedAudio = vm.audios.noKeyFound;
					} else if (responseError.includes("account is deactivated"))
						vm.selectedAudio = vm.audios.accountDeactivated;
					else vm.selectedAudio = vm.audios.systemError;
					vm.$refs.alert.showAlert();
				})
				.finally(() => {
					if (vm.delayAudio)
						setTimeout(function() {
							vm.selectedAudio.play();
							vm.delayAudio = false;
							vm.selectedAudio = null;
						}, 2000);
					else vm.selectedAudio.play();
					vm.rfid = "";
					vm.loading = false;
					vm.retryCount = 0;
				});
		}
	}
};
</script>

<style scoped>
#header {
	margin-top: 180px;
	font-size: 72px;
}

.hidden-roomCodeInput {
	position: absolute;
	top: -900px;
}
</style>
