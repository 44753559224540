<template lang="pug">
div(style='max-width:750px').px-6.px-sm-10.pt-16
	div(v-if='loading')
		div.d-flex.justify-center
			v-progress-circular(
				indeterminate
				color="primary lighten-4")
		h3.primary--text.text--lighten-4.mt-4 Loading
	div(v-else).primary--text.text--lighten-4.mt-4
		h3
			| There is a problem with registering your account. Please ask to send you a registration link again.
			br
			br
			| Sorry for the invonvenience
		v-btn.mt-5(
			to='/#/landing'
			color='primary-light'
		).text-decoration-none Go to homepage
</template>
<script>
export default {
	props: ["loading"]
};
</script>
