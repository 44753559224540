<template lang="pug">
v-expand-transition
	v-container(:class="backgroundColor ? backgroundColor : 'background'" fluid v-show='alert')
		v-row.justify-center
			v-col(:cols='large ? 12 : 8')
				v-alert(:type='type' colored-border border="left" elevation='1' fade).mb-0
					v-img.mx-auto(v-if="large && alertImage == 'eyes'" src="../../public/img/not-found.png" height="120" width="130")
					div.complementary--text.text-center(:class="{ 'large-alert-text' : large }" v-html="content")
</template>

<script>
export default {
	props: ["content", "type", "alertImage", "backgroundColor"],
	data() {
		return {
			alert: false
		};
	},
	computed: {
		large() {
			return this.$route.path == "/order" || this.$route.path == "/";
		}
	},
	methods: {
		showAlert() {
			this.alert = true;
			var vm = this;
			window.setTimeout(() => {
				vm.alert = false;
				// Only used for resetting a picture on TerminalLogin and OrderView
				vm.$emit("resetAlert");
			}, 5000);
		}
	}
};
</script>

<style scoped>
.large-alert-text {
	font-size: 35px;
}
</style>
