<template lang="pug">
	div
		.text-h6.text-sm-h5.text-md-h4.mb-4.primary--text Nothing happens when I present a chip
		div
			p.font-weight-bold Is BiederLAN working?
			p
				span.primary--text {{ $appTitle }}
				|
				| requires Internet connection.
			p
				span.font-weight-bold Do you hear a short "beep" when you present a chip?
			p
				| If not, make sure USB cable is connected to the black RFID reader (the port is at the bottom).
			div
				p.font-weight-bold Is there a mechanical sound coming from the box after the "beep"?
				p If not, there might be a problem with the box.
				ContactInformation(
				:contactPerson="contactPerson"
				subject="I%20Have%20A%20Suggestion")
</template>

<script>
import ContactInformation from "../Elements/ContactInformation.vue";
export default {
	components: { ContactInformation },
	props: ["contactPerson"]
};
</script>

<style scoped></style>
