<template lang="pug">
v-layout.fill-height.fill-width.pa-0.ma-0.primary-light--text.background
	v-container(fluid fill-width).fill-width.px-0.py-0
		alert(:content='content' ref='alert'  :type='type')
		AccountMobile(
			v-if='$vuetify.breakpoint.smAndDown'
			:purchases='purchases'
			:statuses='statuses'
			:requests='requests'
			:settingsSections='settingsSections'
			:balances='balances'
			:selectedPurchase='selectedPurchase'
			:captions='captions'
			:requestsWithLoadMoreCard='requestsWithLoadMoreCard'
			:purchasesWithLoadMoreCard='purchasesWithLoadMoreCard'
			:showNoPurchasesCard='showNoPurchasesCard'
			:showNoRequestsCard='showNoRequestsCard'
			:purchasesReady='purchasesReady'
			:requestsReady='requestsReady'
			@reloadData='reloadData'
			@loadMorePurchases="loadMorePurchases"
			@loadMoreRequests="loadMoreRequests"
			@reloadRequests='loadEntitiesAndSave(dataToLoad[0])'
			@reloadPurchases='loadEntitiesAndSave(dataToLoad[1])'
			@showAccountSettingsDialog='AccountSettingsDialog.show=true'
			@openFeedbackDialog='openFeedbackDialog'
			@reportPurchaseProblem='reportPurchaseProblem($event)'
			@showSplitDialog='showSplitDialog($event)'
			@showRequestDetailsDialog='showRequestDetailsDialog($event)'
			@showRequestResponseDialog='showRequestResponseDialog($event)'
		)
		div(v-else)
			AccountDesktop(
				:purchases='purchases'
				:statuses='statuses'
				:requests='requests'
				:settingsSections='settingsSections'
				:balances='balances'
				:selectedPurchase='selectedPurchase'
				:captions='captions'
				:requestsWithLoadMoreCard='requestsWithLoadMoreCard'
				:purchasesWithLoadMoreCard='purchasesWithLoadMoreCard'
				:showNoPurchasesCard='showNoPurchasesCard'
				:showNoRequestsCard='showNoRequestsCard'
				:purchasesReady='purchasesReady'
				:requestsReady='requestsReady'
				@reloadData='reloadData'
				@loadMorePurchases="loadMorePurchases"
				@loadMoreRequests="loadMoreRequests"
				@reloadRequests='loadEntitiesAndSave(dataToLoad[0])'
				@reloadPurchases='loadEntitiesAndSave(dataToLoad[1])'
				@showAccountSettingsDialog='AccountSettingsDialog.show=true'
				@openFeedbackDialog='openFeedbackDialog'
				@reportPurchaseProblem='reportPurchaseProblem($event)'
				@showSplitDialog='showSplitDialog($event)'
				@showRequestDetailsDialog='showRequestDetailsDialog($event)'
				@showRequestResponseDialog='showRequestResponseDialog($event)')
			v-footer(bottom padless absolute)
				v-card.pa-0.ma-0.pb-1.text-center(flat tile color="background" width="100%")
					GDPRLinks
		// We need to add selectedPurchase to the v-if to be able to access it in the dialog's mounted(), where it's passed to as a prop
		request-dialog(
			v-if='RequestDialog.show && selectedPurchase'
			:showDialog.sync='RequestDialog.show'
			:purchase='purchases.data ? purchases.data[selectedPurchaseId] : {}'
			:friends="friends"
			:loadingStatuses="statuses"
			@put='putEntity($event)'
			@cancel='RequestDialog.show = false'
			@reloadData='loadEntitiesAndSave(dataToLoad)'
			hide-overlay
			)
		request-response-dialog(
			v-if='RequestResponseDialog.show && RequestResponseDialog.selectedRequest'
			:showDialog.sync='RequestResponseDialog.show'
			:request='RequestResponseDialog.selectedRequest'
			:requestInitiator='RequestResponseDialog.requestInitiator'
			@cancel='RequestResponseDialog.show = false'
			@reloadAllData='loadEntitiesAndSave(dataToLoad)'
			hide-overlay
			@put='putEntity($event)'
			)
		request-details-dialog(
			v-if='RequestDetailsDialog.show && selectedPurchase'
			:showDialog.sync='RequestDetailsDialog.show'
			:purchase='selectedPurchase'
			@cancel='RequestDetailsDialog.show = false'
			hide-overlay
			)
		account-settings-dialog(
			v-if='AccountSettingsDialog.show'
			:showDialog.sync='AccountSettingsDialog.show'
			:settingsSections='settingsSections'
			@cancel='AccountSettingsDialog.show = false'
			hide-overlay
			)

</template>
<script>
import { catchError } from "@/mixins/catchError";
import { dataLoader } from "@/mixins/dataLoader";
import AccountMobile from "./Mobile/AccountMobile.vue";
import AccountDesktop from "./Desktop/AccountDesktop.vue";
import RequestDialogs from "./RequestDialogs";
import AccountSettingsDialog from "./Desktop/AccountSettingsDialog.vue";
import Alert from "../Alert.vue";
import GDPRLinks from "../GDPRLinks.vue";
export default {
	components: {
		...RequestDialogs,
		AccountMobile,
		AccountDesktop,
		Alert,
		AccountSettingsDialog,
		GDPRLinks
	},
	mixins: [catchError, dataLoader],
	data() {
		return {
			dataToLoad: [
				{
					url: "account/requests",
					saveTo: "requests",
					dataProcessor: "addTimeLeftToPayToRequests",
					paginate: true,
					itemsPerLoad: 10
				},
				{
					url: "account/purchases",
					saveTo: "purchases",
					dataProcessor: ["fillCurrentPages", "addHeightToPurchases"],
					paginate: true,
					itemsPerLoad: 10
				},
				{
					url: "account/balances",
					saveTo: "balances"
				},
				{
					url: "persons/list",
					saveTo: "allUsers"
				}
			],
			purchases: {},
			requests: {},
			allUsers: {},
			balances: {},
			RequestDialog: {
				show: false
			},
			RequestResponseDialog: {
				show: false,
				requestInitiator: null,
				selectedRequest: null
			},
			RequestDetailsDialog: {
				show: false
			},
			AccountSettingsDialog: {
				show: false
			},
			selectedPurchase: null,
			selectedPurchaseId: null,
			settingsSections: [
				{
					text: "Interface",
					icon: "brush",
					caption: "Choose design that suits you"
				},
				{
					text: "Contacts",
					icon: "badge",
					caption: "Make sure those are up to date"
				},
				{ text: "Password", icon: "password", caption: "Set new password" },
				{ text: "Notifications", icon: "email", caption: "" }
			],
			captions: {
				requests: {
					icon: "people",
					title: "Pending Requests",
					subtitle: "Split and PayMe requests from friends",
					firstToPay: {
						title: "Be the first to pay back!",
						subtitle: " will appreciate it :)"
					}
				},
				purchases: {
					icon: "shopping_bag",
					title: "Purchases",
					subtitle:
						"Including Split and PayMe requests you initiated and responded to"
				}
			}
		};
	},
	methods: {
		addTimeLeftToPayToRequests(requests) {
			let requestsWithTime = [];
			requests.forEach(request =>
				requestsWithTime.push({
					...request,
					timeLeftToPay: this.timeLeftToPay(request.deadline)
				})
			);
			return requestsWithTime;
		},
		fillCurrentPages(purchases) {
			let purchasesWithPages = [];
			purchases.forEach(purchase =>
				purchasesWithPages.push({ ...purchase, currentPage: 0 })
			);
			return purchasesWithPages;
		},
		addHeightToPurchases(purchases) {
			let purchasesWithHeight = [];
			purchases.forEach(purchase => {
				const height =
					purchase.splitStatus || purchase.splitResponseStatus ? 340 : 245;
				purchasesWithHeight.push({ ...purchase, size: height });
			});
			return purchasesWithHeight;
		},
		reloadData() {
			this.loadAll();
		},
		loadMorePurchases() {
			this.loadMore(this.purchases);
		},
		loadMoreRequests() {
			this.loadMore(this.requests);
		},
		timeLeftToPay(date) {
			var today = new Date(Date.now());
			var secondDate = new Date(date);
			var differenceInMs = secondDate.getTime() - today;
			var differenceInDays = Math.round(differenceInMs / (1000 * 3600 * 24));
			var differenceTexts = {};
			if (differenceInDays < 1) {
				differenceTexts.topLine = "Deadline is today";
				differenceTexts.bottomLine = "today";
			} else {
				differenceTexts.topLine = "Deadline in";
				differenceTexts.bottomLine = `${differenceInDays} day${
					differenceInDays === 1 ? "" : "s"
				} left`;
			}
			return differenceTexts;
		},
		showSplitDialog(index) {
			this.selectedPurchaseId = index;
			this.selectedPurchase = this.purchases.data[index].drinks;
			this.RequestDialog.show = true;
		},
		showRequestDetailsDialog(index) {
			this.selectedPurchase = this.purchases.data[index];
			this.RequestDetailsDialog.show = true;
		},
		showRequestResponseDialog(request) {
			this.RequestResponseDialog.requestInitiator = request.author;
			this.RequestResponseDialog.selectedRequest = request;
			this.RequestResponseDialog.show = true;
		},
		reportPurchaseProblem(purchaseId) {
			this.$emit("openFeedbackDialog", "purchase_problem", purchaseId);
		},
		openFeedbackDialog() {
			this.$emit("openFeedbackDialog");
		},
		entityHasNoData(entity) {
			if (!this[entity]) return true;
			if (!this[entity].data) return true;
			return (
				this[entity].status === this.statuses.success &&
				this[entity].data.length === 0
			);
		},
		entityIsReady(entity) {
			if (!this[entity] || !this[entity].data) return false;
			return (
				this[entity].status === this.statuses.success &&
				this[entity].data.length > 0
			);
		},
		makeSpaceForLoadMoreCard(entity) {
			if (!this[entity].data || this[entity].data.length === 0) return [];
			if (this[entity].data.length === this[entity].totalItems)
				return this[entity].data;
			else return [...this[entity].data, { id: -1 }];
		}
	},
	computed: {
		requestsWithLoadMoreCard() {
			return this.makeSpaceForLoadMoreCard("requests");
		},
		purchasesWithLoadMoreCard() {
			return this.makeSpaceForLoadMoreCard("purchases");
		},
		showNoPurchasesCard() {
			return this.entityHasNoData("purchases");
		},
		showNoRequestsCard() {
			return this.entityHasNoData("requests");
		},
		purchasesReady() {
			return this.entityIsReady("purchases");
		},
		requestsReady() {
			return this.entityIsReady("requests");
		},
		friends() {
			return {
				...this.allUsers,
				data: this.allUsers.data.filter(
					person => person.id !== this.$store.state.user.id
				)
			};
		}
	}
};
</script>
<style lang="scss"></style>
