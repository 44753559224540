<template lang="pug">
v-container(v-if='!user_logged_in' fluid).pa-0
	First(:openFeedbackDialogOnMounted="Boolean(this.$route.params.signUp)")
	Stats(:isObscure='!user_logged_in')
	Benefits
	Responsibilities(@openFeedbackDialog='$emit("openFeedbackDialog")' :hideAdmins='!user_logged_in')
	ProjectHistory(:isObscure='!user_logged_in')
	Locations.router-WhereToFind
	SignUp
	WhatsNew(@openFeedbackDialog='$emit("openFeedbackDialog")')
	GDPRLinks(:isWhiteBackground='true')
v-container(v-else fluid).pa-0
	First(:openFeedbackDialogOnMounted="Boolean(this.$route.params.signUp)")
	Stats(:isObscure='!user_logged_in')
	Benefits
	Responsibilities(@openFeedbackDialog='$emit("openFeedbackDialog")' :hideAdmins='!user_logged_in')
	ProjectHistory(:isObscure='!user_logged_in')
	Locations.router-WhereToFind
	WhatsNew(@openFeedbackDialog='$emit("openFeedbackDialog")')
	GDPRLinks(:isWhiteBackground='true')
</template>

<script>
import { catchError } from "@/mixins/catchError";
import { validators } from "@/mixins/validators";
import { mapGetters } from "vuex";
import Alert from "../Alert";
import ThemePicker from "../Helpers/ThemePicker.vue";
import Sections from "/";
export default {
	components: {
		...Sections,
		Alert,
		ThemePicker
	},
	mixins: [catchError, validators],
	data() {
		return {};
	},
	created() {},
	methods: {},
	computed: {
		...mapGetters(["user_logged_in"])
	}
};
</script>

<style scoped></style>
