<template lang="pug">
component(
	:is='$vuetify.breakpoint.smAndUp ? "v-dialog" : "v-bottom-sheet"'
	v-model='showSplitDialog'
	transition="dialog-bottom-transition"
	:max-width="$vuetify.breakpoint.smAndUp ? '600' : ''"
	scrollable
	@click:outside='cancel'
	@keydown.esc='cancel')
	alert(:content='content' ref='splitDialogAlert' :type='type')
	v-card(
		:min-height="minHeight")
		v-overlay(
			v-if='$vuetify.breakpoint.smAndUp'
			:absolute="true"
			:z-index='99999'
			:value="showInstruction"
			opacity='0.9'
			color='white')
			RequestInstruction(@close='showInstruction=false')
		template(v-if='currentStep && !requestStatus')
			.position-sticky.white
				div.primary--text.pl-4.pl-sm-6.d-flex.flex-nowrap.my-6.my-sm-0.align-center
					.text-h6.text-sm-h5.mt-sm-3.pr-4.pt-sm-2 {{ currentStep.title }}
					div.d-flex.justify-end.align-center.flex-grow-1.mr-4
						v-btn(
							v-if='$vuetify.breakpoint.xs'
							@click="cancel"
							color='primary'
							x-small
							outlined
							fab)
							v-icon close
				SplitTypeSelector(
					v-if='currentStep.showTypeSelector'
					:splitIsPossible='purchase.drinks.length > 1 || purchase.drinks[0].quantity > 1'
					@setAskToPay='currentStep = steps[$event]')
				v-card-subtitle.subtitle-1.primary--text.pb-0.pl-sm-6.pt-0
					template
						v-autocomplete(
							v-if='currentStep.friendsPicker'
							v-model="selectedFriends"
							item-value="id"
							:items="friends.data"
							:loading="friends.status === loadingStatuses.loading"
				 			:filter='filterFriends'
							:menu-props="{ maxHeight: $vuetify.breakpoint.smAndUp ? 300 : 140, closeOnContentClick: false,   transition: true}"
							:search-input='filterValue'
							@update:search-input='filterValue = $event'
							@change="filterValue = ''"
							item-text="name"
							prepend-inner-icon="person_add"
							clearable
							chips
							hide-no-data
							hide-details
							deletable-chips
							:placeholder="$vuetify.breakpoint.xs ? 'Search by name or room' : 'You can search by name or room number'"
							:label="friends.status === loadingStatuses.loading ? 'Loading friends...' : currentStep.friendsPicker.label"
							multiple
							color="primary darken-2"
							required).my-5
							template(v-slot:item="{item}")
								span.primary--text {{ item.name }}
								span.pl-1.primary--text.text--lighten-3
									| {{ formatRoomOrWGNumber(item.room, true) }}
							template(v-slot:selection="{item}")
								NameAndRoomBadge(
								:name='item.name'
								:room='item.room'
								preventExpansion='true'
								deletable='true'
								@click='selectedFriends = selectedFriends.filter(e => e !== item.id)'
								size='s').my-1.mx-1
						div(v-if='currentStep.showDrinksPicker').mt-8
							div.text-sm-h6.subtitle-1
								| Want to help friends pick?
							div.text-secondary.body-2
								div.mt-sm-2.mt-1
									| If you pick your drinks now,
									| we won't offer your friends to pay for them
								//- div.my-2
								//- 	| Or just leave the zeroes and you'll be charged for the drinks no one claims
							div.d-flex.justify-space-between.mr-16.text-secondary.text-sm-subtitle-2.caption.mt-8
								div Drink
								div.mr-n2 Quantity
							v-divider.mb-0.mt-2
			v-card-text(v-if="currentStep.showDrinksPicker").pa-0.px-sm-6.white.d-flex
				DrinkPicker(
					:drinks='ownDrinks'
					:limits='limits'
					@reduceQuantityByOne="ownDrinks.find(el => el.id === $event).quantity--"
					@increaseQuantityByOne="ownDrinks.find(el => el.id === $event).quantity++"
					isMobile).mx-4
			v-card-actions.position-sticky-bottom.d-inline.pt-0
				div
					v-divider(v-if="currentStep.showDivider").mt-0.ml-2
					div(
						v-if="allDrinksAreClaimedAsOwn"
						:class='{"caption" : $vuetify.breakpoint.xs}'
						).primary-light.rounded.mb-3.d-flex.py-2.mx-0.body-2.rounded-t-0
							v-icon(
								color="complementary lighten-2"
							).ma-0.px-2 info
							div.d-flex.align-center.pr-2.complementary--text.text--darken-1
								| If you want to pay for all drinks, there is no reason to Split
					div.d-flex
						div
							a(
								v-if='$vuetify.breakpoint.smAndUp && currentStep.showInstructionButton'
								@click="showInstruction = !showInstruction")
								v-alert(
									color='primary lighten-3'
									outlined
									text
									).py-1.pl-2.ma-0.pr-3
										div.d-flex
											v-icon(color='primary lighten-2') expand_less
											div.primary--text.text--lighten-1.body-2.py-1 How does this work?
						v-spacer(v-if='$vuetify.breakpoint.smAndUp')
						div.mx-auto
							v-btn(
								v-if='$vuetify.breakpoint.smAndUp'
								@click="cancel"
								color="primary"
								text)
								span cancel
							v-btn(
								v-if='currentStep.actionButton'
								@click="currentStep.actionButton.function()"
								:large='$vuetify.breakpoint.xs'
								:disabled="allDrinksAreClaimedAsOwn || selectedFriends.length < 1"
								color="primary"
								).mb-sm-0.mb-1
								v-icon.mr-1 {{ currentStep.actionButton.icon }}
								span {{ currentStep.actionButton.text }}
					div(v-if='$vuetify.breakpoint.xs').d-flex.justify-center.my-2
						a(@click="showInstruction = true")
							span.primary--text.caption How does this work?
						v-bottom-sheet(
							v-model='showInstruction'
							transition="dialog-bottom-transition"
							@click:outside='showInstruction=false'
							@keydown.esc='showInstruction=false')
							RequestInstruction(@close='showInstruction = false')
		// v-show isn't working with d-flex, so there is a workaround
		RequestSender(
			v-if='requestStatus'
			:status='requestStatus'
			:statuses="requestStatuses"
			@cancel='cancel')
</template>
<script>
import NameAndRoomBadge from "../../../Elements/NameAndRoomBadge.vue";
import Alert from "../../../Alert.vue";
import { catchError } from "../../../../mixins/catchError";
import RequestInstruction from "./RequestInstruction.vue";
import RequestSender from "../../../Helpers/RequestSender.vue";
import SplitTypeSelector from "./RequestTypeSelectorDialog.vue";
import DrinkPicker from "../Helpers/DrinkPicker.vue";
import { VDialog, VBottomSheet } from "vuetify/lib";

export default {
	mixins: [catchError],
	components: {
		RequestInstruction,
		Alert,
		RequestSender,
		NameAndRoomBadge,
		SplitTypeSelector,
		DrinkPicker,
		VDialog,
		VBottomSheet
	},
	name: "request-dialog",
	props: ["purchase", "friends", "loadingStatuses"],
	data() {
		return {
			isAskToPay: false,
			currentStep: null,
			steps: {
				selectType: {
					title: "What would you like to do?",
					showTypeSelector: true,
					friendsPicker: null,
					showDrinksPicker: false,
					showDivider: false,
					actionButton: null,
					minDialogHeight: 380
				},
				split: {
					title: "Split purchase",
					showTypeSelector: false,
					friendsPicker: {
						label: "Select friends"
					},
					showDrinksPicker: true,
					showDivider: true,
					actionButton: {
						function: this.split,
						text: "Split",
						icon: "diversity_3"
					},
					showInstructionButton: true,
					minDialogHeight: null
				},
				askToPay: {
					title: "Ask friend(s) to pay",
					showTypeSelector: false,
					friendsPicker: {
						label: "Select friends"
					},
					showDrinksPicker: false,
					showDivider: false,
					actionButton: {
						function: this.askToPay,
						text: "Ask to PayMe",
						icon: "volunteer_activism"
					},
					showInstructionButton: true,
					minDialogHeight: null
				}
			},
			requestStatus: null,
			requestStatuses: {
				sendingRequest: { title: "Hold tight", text: "Loading" },
				requestCompleted: {
					title: "Done!",
					text:
						"We've issued a refund for you. We'll notify your friends about your request"
				},
				requestFailed: {
					title: "Oups...",
					text: "Error occured",
					error: null,
					supportInformation: {
						title: "You can always reach out to us at:",
						link: {
							text: `support@${this.$emailDomain}`,
							url: `mailto:support@${this.$emailDomain}?subject=Error%20with%20Split%20Creation`
						}
					}
				}
			},
			selectedFriends: [],
			ownDrinks: [],
			showSplitDialog: true,
			limits: [],
			showInstruction: false,
			filterValue: ""
		};
	},
	methods: {
		split() {
			this.requestStatus = this.requestStatuses.sendingRequest;
			let data = {
				persons: this.selectedFriends,
				transaction_id: this.purchase.id,
				drinks: this.ownDrinks
					.filter(drink => drink.quantity > 0)
					.map(drink => ({ id: drink.id, quantity: drink.quantity }))
			};
			this.sendRequest(data);
		},
		askToPay() {
			this.requestStatus = this.requestStatuses.sendingRequest;
			let data = {
				persons: this.selectedFriends,
				transaction_id: this.purchase.id,
				askToPay: true
			};
			this.sendRequest(data);
		},
		sendRequest(data) {
			this.$axios
				.post("/api/split_order", data)
				.then(() => {
					this.requestStatus = this.requestStatuses.requestCompleted;
					this.$emit("reloadData");
				})
				.catch(error => {
					this.requestStatuses.requestFailed.error = error.response.data.error;
					this.requestStatus = this.requestStatuses.requestFailed;
				});
		},
		filterFriends(item, queryText) {
			return (
				item.name.toLocaleLowerCase().includes(queryText.toLocaleLowerCase()) ||
				item.room.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
			);
		},
		reset(drinks) {
			this.purchase.drinks.forEach(drink => {
				this.limits.push(drink.quantity);
			});
			//let text = Array.from(drinks, drink => {...drink, quantity: 0});
			drinks.forEach(drink => this.ownDrinks.push({ ...drink, quantity: 0 }));
		},
		cancel() {
			this.$emit("cancel");
		}
	},
	// watch: {
	// 	loading: {
	// 		handler: function(loading) {
	// 			if (!loading)
	// 				this.friends = [...this.friends].filter(
	// 					person => person.id != this.$store.state.user.id
	// 				);
	// 		}
	// 	}
	// },
	computed: {
		minHeight() {
			return this.currentStep
				? this.showInstruction
					? 600
					: this.currentStep.minDialogHeight
				: null;
		},
		allDrinksAreClaimedAsOwn() {
			return (
				JSON.stringify(this.purchase.drinks) === JSON.stringify(this.ownDrinks)
			);
		}
	},
	mounted() {
		this.currentStep = this.steps.selectType;
		this.reset(this.purchase.drinks);
	}
};
</script>
<style lang="scss">
.position-sticky {
	position: sticky;
	top: 0;
	z-index: 999;
}

.position-sticky-bottom {
	position: -webkit-sticky;
	position: sticky;
	bottom: 0;
	z-index: 999;
}
</style>
