<template lang="pug">
div
	v-card(:style='getTrendCardStyle(metric.change)' flat).fill-height.text-center
		div
			div.text-h4.pt-4.pb-1
				span(v-if='metric.change').mr-1 {{ Math.trunc(metric.change) + "%" }}
				span(v-else).mr-1.caption No change

				v-icon(
					v-if='metric.change'
					:color='getTrendTextColor(metric.change)'
					) {{ getTrendIcon(metric.change) }}
			div(
				:style='{ "background-color": getTrendValueBackgroundColor(metric.change) }'
				).lighten-5.rounded.rounded-t-0.mt-1.subtitle-1
				div
					span {{ isMonetaryValue ? formatMonetaryValue(metric.value) : metric.value }}
					span {{ isMonetaryValue ? '' : '' }}
				div.caption.mt-n2 {{ title }}
</template>

<script>
export default {
	props: ["metric", "title", "isMonetaryValue"],
	mounted() {},
	methods: {
		getTrendCardStyle(change) {
			return {
				backgroundColor: this.getTrendBackgroundColor(change),
				color: this.getTrendTextColor(change),
				border: `solid ${this.getTrendBorderColor(change)} 1px`
			};
		},
		getTrendTextColor(change) {
			if (change < 0) return "var(--v-falling-trend-text-base)";
			else if (change > 0) return "var(--v-rising-trend-text-base)";
			else return "var(--v-flat-trend-text-base)";
		},
		getTrendBackgroundColor(change) {
			if (change < 0) return "var(--v-falling-trend-base)";
			else if (change > 0) return "var(--v-rising-trend-base)";
			else return "var(--v-flat-trend-base)";
		},
		getTrendBorderColor(change) {
			if (change < 0) return "var(--v-falling-trend-border-base)";
			else if (change > 0) return "var(--v-rising-trend-border-base)";
			else return "var(--v-flat-trend-border-base)";
		},
		getTrendValueBackgroundColor(change) {
			if (change < 0) return "var(--v-falling-trend-dim-base)";
			else if (change > 0) return "var(--v-rising-trend-dim-base)";
			else return "var(--v-flat-trend-dim-base)";
		},
		getTrendIcon(change) {
			if (change < 0) return "trending_down";
			else if (change > 0) return "trending_up";
			else return "trending_flat";
		}
	}
};
</script>

<style></style>
