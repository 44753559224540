<template lang="pug">
v-card(
	:width='$vuetify.breakpoint.xs ? 270 : 400'
	:height='height'
	color='primary-light'
	rounded='xl').account-card-text--text
	.text-sm-h5.text-h6.account-card-background-comp.px-4.pt-4.pb-3
		| All clear!
	v-sheet(color='account-card-divider' height='2')
	.px-4.pt-2.pb-4
		.text-sm-body-1.body-2.account-card-text-comp--text.mt-1
			| You have no pending requests
		div.request-links.d-sm-flex.flex-wrap
			div(
				v-for='(btn, i) in buttons'
				:key='i'
				).d-flex.justify-start
				v-btn(
					:to='btn.to'
					text
					small
					).body-2.primary--text.text-decoration-none.mt-3.text-none.pl-0.mr-1
					span {{ btn.captionLeftPart }}
					component(:is="btn.middleBadge" size='s').ml-2
</template>
<script>
import Badges from "@/components/Elements/Badges/payMeAndSplitBadges.js";
export default {
	props: {
		height: Number
	},
	data() {
		return {
			buttons: [
				{
					to: "/support/Split",
					captionLeftPart: "What is",
					middleBadge: "SplitBadge"
				},
				{
					to: "/support/PayMe",
					captionLeftPart: "What is",
					middleBadge: "PayMeBadge"
				}
			]
		};
	},
	components: { ...Badges }
};
</script>
<style lang="scss">
.request-links {
	position: absolute;
	bottom: 10px;
}
</style>
