<template lang="pug">
v-container(v-if='status' fluid fill-height).pa-0.align-stretch
	v-card.fill-width.text-center.d-flex.align-center.justify-center.primary--text.text--darken-1.pa-4
		div
			div.mt-8
				div.text-h4 {{ status.title }}
				div.subtitle-1.mt-1 {{ status.text }}
			div.d-flex.justify-center.fill-width
				div
					v-progress-circular(
						v-if='status === statuses.sendingRequest'
						:size='90'
						color='primary darken-2'
						indeterminate).ma-10
					div(v-else)
						v-icon.my-8(
							size='80'
							color='primary'
							) {{ status === statuses.requestCompleted ? 'check_circle' : 'sentiment_dissatisfied' }}
						div(v-if='status === statuses.requestFailed')
							div.subtitle-1.mt-1 {{ statuses.requestFailed.error }}
							div.caption.text--secondary.mt-3 {{ statuses.requestFailed.supportInformation.title }}
							div
								a(
									:href='statuses.requestFailed.supportInformation.link.url'
								).body-2 {{ statuses.requestFailed.supportInformation.link.text }}
					div.my-4
						v-btn(
							color='primary'
							@click='cancel'
							) {{ status === statuses.sendingRequest ? "Cancel" : "Ok" }}
</template>
<script>
import { catchError } from "../../mixins/catchError";
import { validators } from "../../mixins/validators";
export default {
	props: ["statuses", "status"],
	mixins: [validators, catchError],
	name: "request-sender",
	data() {
		return {};
	},
	methods: {
		cancel() {
			this.$emit("cancel");
		}
	}
};
</script>
<style lang="scss" scoped>
.fill-width {
	width: 100%;
}
</style>
