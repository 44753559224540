<template lang="pug">
	v-container(fill-height)
		v-row
			v-col
				div.d-flex.justify-center.mx-6.mb-4
					v-data-table(
						v-if='prices.tableOptions'
						:headers="headers"
						:items="prices.data"
						:options.sync="prices.tableOptions"
						:loading="!prices || prices.status === statuses.loading"
						:loading-text="messages.loadingText"
						item-key="id"
						:search="prices.tableOptions.search"
						:server-items-length="prices.totalItems"
						).pt-1.maxWidth.elevation-5
						template(v-slot:top)
							v-toolbar(flat).mb-6
								v-container
									v-row.mt-3
										v-col(cols='6' sm='7' md='8').d-flex.align-center
										v-col(cols='6' sm='5' md='4')
											v-text-field(
												v-model="prices.tableOptions.search"
												:append-icon='prices.tableOptions.search ? "mdi-magnify" : undefined'
												:append-outer-icon='prices.tableOptions.search ? "mdi-close-circle-outline" : undefined'
												@click:append-outer='prices.tableOptions.search = ""; updateEntityAndLoadData(prices, "prices")'
												@click:append='updateEntityAndLoadData(prices, "prices")'
												@keyup='startSearchTimer(prices, "prices")'
												label='Search'
												single-line
												flat
												solo
												hide-details)
						template(v-slot:item.price='item')
							span {{ formatMonetaryValue(item.value) }}
						template(v-slot:item.timestamp="item")
							span {{ makeTimestampReadable(item.value) }}
						template(v-slot:no-data)
							| {{ messages.nothingFound }}
</template>

<script>
import { dataLoader } from "@/mixins/dataLoader";
import { catchError } from "@/mixins/catchError";
export default {
	mixins: [dataLoader, catchError],
	data() {
		return {
			dataToLoad: {
				url: "prices",
				table: true,
				tableOptions: {
					sortBy: ["timestamp"],
					sortDesc: [true]
				}
			},
			prices: {},
			headers: [
				{
					text: "Drink",
					align: "center",
					value: "drink",
					sortable: true,
					groupable: false
				},
				{
					text: "Time of change",
					align: "center",
					value: "timestamp",
					sortable: true,
					groupable: false
				},
				{
					text: "Set by",
					align: "center",
					value: "admin",
					sortable: true,
					groupable: false
				},
				{
					text: "Price",
					align: "center",
					value: "price",
					sortable: false,
					groupable: false
				}
			],
			messages: {
				loadingText: "Loading price history... Please wait",
				nothingFound:
					"There are no drinks in the system yet. As soon as you add a first drink, its price will be displayed here."
			}
		};
	}
};
</script>
