<template lang="pug">
	v-container(fluid).background
		v-row
			v-col.d-flex.justify-center
				v-card(
					color="primary"
					max-width="720px")
					v-tabs(
						v-model="selectedPage"
						@change="onTabChange"
						background-color="primary"
						active-class="primary-light--text"
						center
						dark
						fixed-tabs)
						v-tabs-slider(color="primary-light")
						// the problem is the div (container)
						div(
							v-for="(button, index) in menuButtons"
							:key="button.id"
							).d-flex.align-center
							v-menu(
								style='z-index:99999;'
								v-if='button.children'
								offset-y
								color='primary')
								template(v-slot:activator="{on, attrs}")
									v-tab(
										v-bind="attrs"
										v-on="on"
										).py-3.px-2
										v-icon {{ button.icon }}
										div.d-block.mx-2
											div {{ button.title }}
											div(
												v-if='selectedPage === 1 && selectedSubPage != null'
												).selected-manage-page-title.text-left.pt-1.primary--text.text--lighten-3
												| {{ menuButtons[1].children[selectedSubPage].title }}
										v-icon expand_more
								v-list(
									v-for='(child, index) in button.children'
									:key='index'
									nav
									color='primary'
									dark)
									v-list-item(:to='child.to' @click='selectedPage = 1; selectedSubPage = index').text-decoration-none
										v-list-item-icon.mr-3
											v-icon {{ child.icon }}
										v-list-item-title {{ child.title }}
							v-tab.mx-1.py-2(
								v-else
								@click='selectedSubPage = null'
								:value="button.id"
								:to="button.to"
								).text-decoration-none
								v-icon.pr-2 {{ button.icon }}
								span.text-button.font-primary {{ button.title }}
						v-menu(bottom right)
							template(v-slot:activator='{ on, attrs }')
								div.d-flex.align-center.pr-1
									v-btn(dark icon v-bind='attrs' v-on='on')
										v-icon(color='rgba(255, 255, 255, 0.6)') mdi-dots-vertical
							v-list(color='primary' dark nav)
								v-list-item(v-for='(item, i) in extraMenuButtons' :key='i' :to='item.to').text-decoration-none
									v-list-item-icon.mr-3
										v-icon {{ item.icon }}
									v-list-item-title {{ item.title }}
</template>

<script>
export default {
	name: "Navbar",
	data() {
		return {
			selectedPage: null,
			selectedSubPage: null,
			menuButtons: [
				{
					id: 0,
					title: "Dashboard",
					icon: "space_dashboard",
					to: "/dashboard"
				},
				{
					id: 1,
					title: "Manage",
					icon: "edit_note",
					children: [
						{ id: 11, title: "People", icon: "face", to: "/persons" },
						{ id: 12, title: "Drinks", icon: "sports_bar", to: "/drinks" },
						{ id: 13, title: "History", icon: "mdi-history", to: "/history" }
					]
				},
				{ id: 4, title: "Purchases", icon: "receipt_long", to: "/purchases" },
				{ id: 5, title: "Settings", icon: "settings", to: "/admin_settings" }
			],
			extraMenuButtons: [
				{
					id: 1,
					title: "Personal Area",
					icon: "account_circle",
					to: "/account"
				},
				{ id: 2, title: "Log out", icon: "logout", to: "/logout" }
			]
		};
	},
	methods: {
		async onTabChange(clickedTab) {
			this.selectedPage = clickedTab;
			await this.$nextTick();
			// tabs that have dropdown submenues are indexed with an int
			// simple tabs have page name (i.e. 'admin_settings') as their value
			if (typeof clickedTab !== "string" && this.selectedSubPage == null)
				this.selectedPage = this.previousTab;
			else this.previousTab = this.selectedPage;
		}
	}
};
</script>

<style lang="scss" scoped>
.hello-message {
	margin: 10px 0 5px 20px;
}

.balance {
	font-size: 25px;
}
.input-low-balance {
	color: rgb(204, 25, 25);
}

.input-normal-balance {
	color: black;
}

#header-img {
	margin-left: 10px;
}

.inactive-page-button {
	color: rgba(255, 255, 255, 0.6);
}

.selected-manage-page-title {
	font-size: 0.8em;
}
</style>
