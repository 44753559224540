import Account from "../components/Account/Account";
import ConfirmEmail from "../components/Account/ConfirmEmail";
import Login from "../components/Account/Login";
import Logout from "../components/Account/Logout";
import RestorePassword from "../components/Account/RestorePassword.vue";
import Welcome from "../components/Account/Welcome/Welcome";
import Dashboard from "../components/Admin/Dashboard/Dashboard";
import Drinks from "../components/Admin/Drinks/Drinks";
import History from "../components/Admin/History/History";
import Persons from "../components/Admin/Persons/Persons";
import Purchases from "../components/Admin/Purchases/Purchases";
import AdminSettings from "../components/Admin/Settings/AdminSettings";
import GDPR from "../components/GDPR/GDPR.vue";
import Landing from "../components/Landing/Landing";
import LandingForClients from "../components/LandingForClients/LandingForClients.vue";
import p404 from "../components/p404.vue";
import Payment from "../components/Payment.vue";
import SupportPortal from "../components/SupportPortal/Main.vue";
import Order from "../components/Terminal/Order";
import TerminalLogin from "../components/Terminal/TerminalLogin";

export default [
	{
		path: "/",
		component:
			process.env.VUE_APP_TITLE === "Goods2Gether" ? LandingForClients : Landing
	},
	{ path: "/login", name: "login", component: Login },
	{
		path: "/login/:groupId",
		name: "LoginTransferToPayment",
		component: Login
	},
	{
		path: "/login/:email",
		name: "LoginTransferEmail",
		component: Login
	},
	{
		path: "/pay/:groupId",
		name: "pay",
		component: Payment
	},
	{ path: "/terminal", component: TerminalLogin },
	{
		path: "/order",
		component: Order
	},
	{ path: "/logout/:cancel?", component: Logout },
	{
		path: "/admin_settings",
		component: AdminSettings,
		meta: {
			requiresAdmin: true
		}
	},
	{
		path: "/persons/:expandChipRequests?",
		component: Persons,
		meta: {
			requiresAdmin: true
		}
	},
	{
		path: "/drinks",
		component: Drinks,
		meta: {
			requiresAdmin: true
		}
	},
	{
		path: "/history",
		component: History,
		meta: {
			requiresAdmin: true
		}
	},
	{
		path: "/Purchases",
		component: Purchases,
		meta: {
			requiresAdmin: true
		}
	},
	{
		path: "/account",
		component: Account,
		name: "account",
		meta: {
			requiresLogin: true
		}
	},
	{
		path: "/dashboard",
		component: Dashboard,
		name: "dashboard",
		meta: {
			requiresAdmin: true
		}
	},
	{
		path: "/landing/:signUp?",
		component:
			process.env.VUE_APP_TITLE === "Goods2Gether" ? LandingForClients : Landing
	},
	{
		path: "/create_account/invite_email/:token",
		component: Welcome
	},
	{
		path: "/create_account/invite_link/:token",
		component: Welcome,
		meta: {
			isInviteLink: true
		}
	},
	{
		path: "/confirm_mail/:token",
		component: ConfirmEmail
	},
	{
		path: "/restore_password/:token",
		component: RestorePassword
	},
	{
		path: "/support",
		component: SupportPortal
	},
	{
		path: "/support/:article?",
		name: "support",
		component: SupportPortal
	},
	{
		path: "*",
		component: p404
	},
	{
		path: "/gdpr",
		name: "gdpr",
		component: GDPR
	},
	{
		path: "/gdpr/terms-and-conditions",
		name: "gdpr-terms-and-conditions",
		component: GDPR
	},
	{
		path: "/gdpr/privacy-policy",
		name: "gdpr-privacy-policy",
		component: GDPR
	},
	{
		path: "/gdpr/legal-notice",
		name: "gdpr-legal-notice",
		component: GDPR
	}
];
