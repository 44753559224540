<template lang="pug">
div
	div#background
	v-container(
		v-if='!$store.getters.user_logged_in'
		fluid
		fill-height
		).d-flex.justify-center.content
		div.mt-n16.mt-sm-0
			v-card(
				color="primary"
				max-height="300px"
				elevation="5"
				rounded
				z-index='999999'
				).pt-3.mt-n16.mt-sm-0.zz
				v-row
					v-col
						div(
							:style="{ fontSize : $vuetify.breakpoint.smAndUp ? '34px' : '24px' }"
							).ml-sm-6.mb-6.text-sm-left.text-center
							a(
								href='/'
								).brand-text-logo.primary-light--text.text-decoration-none
								template(v-if="$isBiederDrinks")
									| {{ $appTitle }}
								div(v-else).ml-2.pa-2
									v-img(
										src="../../../public/logos/png/logo-white-transparent.png"
										width="250px")
						v-card(
							color="complementary lighten-1"
							max-width="480px"
							elevation="5"
							).ml-sm-16
							v-row
								v-col.pl-8
									.d-flex.justify-space-between.align-center
										div(
											:class="$vuetify.breakpoint.smAndUp ? 'text-h5' : 'text-h6'"
											).complementary--text.text--lighten-4.mt-sm-2.mt-1
											| Login
										div
											v-btn(
												to="/landing/true"
												:x-small="$vuetify.breakpoint.xs"
												:small="$vuetify.breakpoint.smAndUp"
												outlined
												color="complementary lighten-5"
												).complementary--text.text--lighten-4.mb-0.mr-4.text-decoration-none
												| Get Account
									alert(
										:content='content'
										backgroundColor='complementary lighten-1'
										ref='alert'
										:type='type')
							v-row.mt-sm-2
								v-col(
									cols='10'
										:sm='$isBiederDrinks ? 5 : 10').ml-8.primary-light--text
									v-form(v-model='formIsValid' @submit="onSubmit")
										v-row.mt-1
											v-col.pa-0.pr-1
												v-text-field(
													v-on:keyup.enter="onSubmit"
													v-model="email"
													:rules="[rules.required, rules.email]"
													label="Email"
													placeholder="Email"
													filled
													rounded
													solo)
										v-row
											v-col.pa-0.pr-1
												v-text-field(
													v-on:keyup.enter="onSubmit"
													v-model="password"
													:rules="[rules.required]"
													label="Password"
													:type="password_visible ? 'text' : 'password'"
													placeholder="Password"
													filled
													rounded
													solo
													:append-icon="!password_visible ? 'visibility' : 'visibility_off'"
													@click:append="() => (password_visible = !password_visible)")
								v-col(
									v-if='$vuetify.breakpoint.smAndUp && $isBiederDrinks'
									cols='1'
									).my-8.d-flex.justify-center
									v-divider(vertical)
								v-col(
									v-if='$vuetify.breakpoint.smAndUp && $isBiederDrinks'
									cols='4'
									).d-flex.align-center.mb-4.pa-0.pt-0
									v-img(
										src="../../../public/img/biederstein-logo-transparent.png"
										position='top'
										fluid)
							v-row
								v-col(cols='2')
								v-col(cols='2').pa-0.d-flex.justify-center
									v-btn(
										@click="onSubmit"
										:disabled='loading || !formIsValid'
										icon
										elevation="15")
										v-icon(
											v-if="!loading"
											color="complementary lighten-5"
											size="64"
											) arrow_circle_right
										v-progress-circular(
											v-else
											indeterminate
											color="primary"
											).pa-7
								v-col(cols='7').pa-0.d-flex.justify-end.align-center
									a(
										@click='showRestorePasswordCard = !showRestorePasswordCard;\
														restorePasswordEmail = email;\
														reset()'
										).subtitle-2.complementary--text.text--darken-2.text-right
										| Forgot password?
						v-card(
							v-if='showRestorePasswordCard'
							color='complementary lighten-1'
							).pt-2.px-4.ml-sm-16.mt-8.complementary--text.text--lighten-5
							h6.mb-2 You can restore access using your email
							v-form(v-model='restorePasswordEmailValid').d-sm-flex.mt-2
								v-text-field(
									v-model='restorePasswordEmail'
									v-on:keyup='reset'
									:error-messages='restorePasswordErrorMessages'
									:rules='[rules.email, rules.required]'
									solo
								).mr-2.restore-password-input
								div.d-flex.justify-center
									v-btn(
										:disabled='noEmailFound'
										@click='submitRestorePasswordRequest'
										:loading='sendingRestoreEmail'
										color='complementary lighten-5'
										large
										).mt-1.mb-3 Restore
							div(
								v-if='restoreEmailSent'
								).d-flex.pb-2.subtitle-2
								v-icon(
									size='18'
									color='complementary lighten-5'
									).mr-1 mark_email_read
								div Check your mailbox for further instructions
</template>

<script>
import Alert from "../Alert";
import { catchError } from "../../mixins/catchError";
import { validators } from "../../mixins/validators";
import { mapGetters } from "vuex";
import { setTheme } from "../../mixins/themes";

export default {
	mixins: [catchError, validators, setTheme],
	data() {
		return {
			formIsValid: false,
			email: "",
			password: "",
			password_visible: false,
			loading: false,
			sendingRestoreEmail: false,
			restoreEmailSent: false,
			showRestorePasswordCard: false,
			restorePasswordEmailValid: false,
			restorePasswordEmail: "",
			restorePasswordErrorMessages: null,
			noEmailFound: false
		};
	},
	components: { Alert },
	mounted() {
		if (this.$route.params.email) this.email = this.$route.params.email;
	},
	computed: {
		...mapGetters(["user_is_admin"])
	},
	methods: {
		reset() {
			this.noEmailFound = false;
			this.restorePasswordErrorMessages = null;
			this.restoreEmailSent = false;
		},
		onSubmit(evt) {
			evt.preventDefault();
			const vm = this;
			this.loading = true;
			this.$axios
				.get("/api/login_password", {
					params: {
						email: this.email,
						password: this.password
					}
				})
				.then(response => {
					var user = response.data.user ?? {};
					var adminGroups = response.data.admin_groups ?? [];
					var settings = response.data.settings ?? {};
					var balances = response.data.balances ?? [];
					this.$store.commit("setBalances", balances);
					this.$store.commit("setLoginMethod", "password");
					this.$store.commit("setUser", user);
					this.$store.commit("setAdminGroups", adminGroups);
					this.setTheme(settings.theme);
					delete settings.theme;
					this.$store.commit("setMailSettings", settings);
					vm.loading = false;

					if (!this.$route.params.groupId)
						if (this.user_is_admin) window.location.href = "/#/dashboard";
						else window.location.href = "/#/account";
					else
						this.$router.push({
							name: `pay`,
							params: { groupId: this.$route.params.groupId }
						});
				})
				.catch(error => {
					vm.catchError(error);
				});
		},
		submitRestorePasswordRequest() {
			this.sendingRestoreEmail = true;
			const vm = this;
			this.$axios
				.get(`api/reset_password/${this.restorePasswordEmail}`)
				.then(() => {
					vm.restoreEmailSent = true;
				})
				.catch(error => {
					if (error.response.data.error == "No user with given mail found") {
						vm.restorePasswordErrorMessages =
							"We don't have this email in our system";
						vm.noEmailFound = true;
					} else vm.catchError(error);
				})
				.finally(() => (vm.sendingRestoreEmail = false));
		}
	}
};
</script>

<style scoped>
#background {
	background-image: url("../../../public/img/biederstein-login-background-old.jpg");
	background-size: cover;
	filter: blur(1px);
	transform: scale(1.02);
	position: fixed;
	left: 0;
	right: 0;
	z-index: 1;
	display: block;
	height: 100vh;
	width: 100vw;
}

.zz {
	z-index: 999999;
}

.content {
	z-index: 22;
}

.logo-font-size {
	font-size: 34px;
}

.restore-password-input ::v-deep .v-messages__message {
	color: var(--v-error-darken3) !important;
	font-size: 14px;
}
</style>
