<template lang="pug">
v-btn(
	:to="`/${page}`"
	color="primary"
	x-large
	text).text-decoration-none
	span {{text}}&nbsp;
	v-icon(size='16') arrow_forward
</template>

<script>
export default {
	props: {
		text: String,
		page: String
	}
};
</script>
