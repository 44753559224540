<template lang="pug">
v-container(fluid fill-height).pa-0#background
	v-row(no-gutters)
		v-col.d-flex.justify-center.align-centers
			v-card(
				color='primary'
				:min-width='$vuetify.breakpoint.smAndUp ? 550 : 345'
				).px-10.pt-3.pb-6
				alert(:content='content' ref='alert' :type='type')
				div(
					v-if='status === statuses.verifyingToken || status === statuses.tokenVerifiedRequestingEmailChange'
					).px-6.px-sm-10.pt-16
					div.d-flex.justify-center
						v-progress-circular(
							indeterminate
							color="primary lighten-4")
					h3.primary--text.text--lighten-4.mt-4 {{ status }}
				div(
					v-else-if='status === statuses.tokenVerificationFailure || status === statuses.emailChangeFailure'
					).primary--text.text--lighten-4.mt-4
					h3
						| {{ status }}
						br
						| Sorry for the invonvenience
					v-btn.mt-5(
						to='/landing'
						color='primary-light'
					).text-decoration-none Go to homepage
				div(
					v-else-if='status === statuses.success'
					).primary--text.text--lighten-4.mt-4
					h3 {{ status }}
					v-btn.mt-5(
						to='/account'
						color='primary-light'
					).text-decoration-none Go to account
	div.footer-logo
		h5.text-center.primary-light--text.brand-text-logo {{ $appTitle }}
</template>
<script>
import { validators } from "../../mixins/validators";
import { catchError } from "../../mixins/catchError";
import Alert from "../Alert.vue";

export default {
	mixins: [validators, catchError],
	components: { Alert },
	name: "welcome-dialog",
	data() {
		return {
			status: "",
			statuses: {
				idle: "",
				verifyingToken: "Verifying token...",
				tokenVerifiedRequestingEmailChange:
					"Token verified. Requesting email change...",
				success: "Email successfully changed!",
				tokenVerificationFailure: "Wrong token. Email could not be changed",
				emailChangeFailure: "Email could not be changed. Contact support"
			}
		};
	},
	mounted() {
		this.status = this.statuses.verifyingToken;
		const vm = this;
		this.$axios
			.get(`/api/verify_token/confirm_email/${this.$route.params.token}`)
			.then(() => {
				vm.status = vm.statuses.tokenVerifiedRequestingEmailChange;
				this.sendChangeEmailRequest();
			})
			.catch(error => {
				vm.status = vm.statuses.tokenVerificationFailure;
				vm.catchError(error);
			});
	},
	methods: {
		sendChangeEmailRequest() {
			const vm = this;
			this.$axios
				.post(`/api/confirm_email/${this.$route.params.token}`)
				.then(() => {
					vm.status = vm.statuses.success;
					this.$store.commit("setUser", {
						...this.$store.state.user,
						email: this.$store.state.user.emailCandidate,
						emailCandidate: null
					});
				})
				.catch(error => {
					vm.status = vm.statuses.emailChangeFailure;
					vm.catchError(error);
				});
		}
	}
};
</script>
<style lang="scss" scoped>
#background {
	background-image: url("../../../public/img/biederstein-login-background.jpg");
	background-size: cover;
}
</style>
