import Today from "./Today";
import Trends from "./Trends/Trends";
import Demand from "./Demand";
import Chart from "./Chart/Chart.vue";
import RequestWidget from "./RequestWidget.vue";
import ChipRequests from "./ChipRequests.vue";

export default {
	Today,
	Trends,
	Demand,
	Chart,
	RequestWidget,
	ChipRequests
};
