export const catchError = {
	data() {
		return {
			content: "",
			type: "error"
		};
	},
	methods: {
		catchError(error) {
			const response = error.response ? error.response : { data: error };
			this.content = response.data.error ? response.data.error : response.data;
			this.$refs.alert.showAlert();
			this.loading = false;
		}
	}
};
