<template lang="pug">
v-sheet(
	color="rgb(0, 0, 0, 0)"
	:class='`text-${align}`')
	div(
		:class="{'complementary-gradient': useCompementary}"
		).gradient-title
		h2(:class="squeeze ? 'mb-0' : 'mb-2'")
			span(
				v-if='textTop'
				:class='textTopClass ? textTopClass : ""')
				span(
					v-if='$vuetify.breakpoint.smAndUp'
					).top-font-size-desktop {{ textTop }}
				span(
					v-else
					).top-font-size-mobile {{ textTop }}
			br(v-if='$vuetify.breakpoint.smAndUp')
			br(v-if='spread')
		h2(:class="squeeze ? 'mt-0' : 'mt-4'").pb-1.px-3
			span(
				v-if='textBottom'
				:class='textBottomClass ? textBottomClass : ""')
				span(
					v-if='$vuetify.breakpoint.smAndUp'
					).bottom-font-size-desktop {{ textBottom }}
				span(
					v-else
					).bottom-font-size-mobile {{ textBottom }}
</template>

<script>
export default {
	props: [
		"textTop",
		"textBottom",
		"textBottomClass",
		"textTopClass",
		"spread",
		"align",
		"useCompementary",
		"squeeze"
	]
};
</script>

<style scoped>
.top-font-size-desktop {
	font-size: 60px;
}

.top-font-size-mobile {
	font-size: 30px;
}

.bottom-font-size-desktop {
	font-size: 74px;
}

.bottom-font-size-mobile {
	font-size: 38px;
}

.gradient-title {
	font-weight: 400;
	background: linear-gradient(
		180deg,
		rgba(108, 14, 141, 0) -33.51%,
		var(--v-primary-base) 162.68%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	background-blend-mode: color-burn;
	mix-blend-mode: normal;
}

.complementary-gradient {
	font-weight: 400;
	background: linear-gradient(
		180deg,
		rgba(86, 181, 85, 0) -33.51%,
		var(--v-complementary-darken2) 162.68%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	background-blend-mode: color-burn;
	mix-blend-mode: normal;
}
</style>
