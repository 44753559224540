<template lang="pug">
div(
	:class="$vuetify.breakpoint.smAndUp ? 'subtitle-1' : 'subtitle-2'"
	fluid
	).primary-light.py-4.py-sm-6.primary--text
	div(v-for='index in currentStepIndex').text-center.mt-1
		template(v-if="steps[index - 1].completionText")
			v-icon(
				color="primary"
				size="16"
				).mr-1 task_alt
			span {{ steps[index - 1].completionText }}
</template>
<script>
export default {
	props: ["currentStepIndex", "steps"]
};
</script>
