<template lang="pug">
div
	Paragraph(:paragraph='paragraphs[0]')
	Paragraph(:paragraph='paragraphs[1]')
	Paragraph(:paragraph='paragraphs[2]')
	Paragraph(:paragraph='paragraphs[3]')
	div.mb-3.text-body-2.text-sm-body-1 Sure. Here is a real life example:
	div.d-sm-flex
		PendingBalanceStep(:step='steps[0]').flex-grow-1
		PendingBalanceStep(:step='steps[1]').flex-grow-1
	PendingBalanceStep(:step='steps[2]')
	div.pt-3.body-1 Your friend...
	div.d-lg-flex.mt-2
		PendingBalanceStep(:step='steps[3]')
		PendingBalanceStep(:step='steps[4]')
		PendingBalanceStep(:step='steps[5]')
</template>
<script>
import Paragraph from "../Elements/Paragraph.vue";
import PendingBalanceStep from "../Elements/PendingBalanceStep.vue";
import InstantRefundBadge from "../../Elements/InstantRefundBadge.vue";
export default {
	data() {
		return {
			steps: [
				{
					icon: "paid",
					text: "Imagine you have",
					accountBalance: 10,
					pendingBalance: 0
				},
				{
					icon: "receipt_long",
					text: "You buy 5 beers, 1€ each",
					accountBalance: 5,
					pendingBalance: 0,
					value: 5
				},
				{
					icon: "people",
					text:
						"You split 3 of those beers with a friend<br />\
						We immediately refund you 3€. Your Pending is now −3€",
					accountBalance: "8",
					pendingBalance: "−3"
				},
				{
					icon: "hourglass_empty",
					title: "pays for all 3 beers",
					text: "Perfect! Exactly how you've planned",
					accountBalance: "8",
					pendingBalance: "0"
				},
				{
					icon: "paid",
					title: "only pays for 2 beers",
					text: "We charge you 1€ for the unpaid beer",
					accountBalance: "7",
					pendingBalance: "0"
				},
				{
					icon: "paid",
					title: "ignores or declines",
					text: "We charge you 3€ for the unpaid beers",
					accountBalance: "5",
					pendingBalance: "0"
				}
			],
			paragraphs: [
				{
					title: "What does Pending mean?",
					text:
						"You can look at it in two ways:<br /><br />\
						<ul>\
						<li>Total sum of money <b>your friends</b> are to pay for\
							Splits and PayMe Requests from you</li>\
						<li>What you would have to pay if nothing is claimed\
						 	before the <i>deadline</i>.</li></ul>\
							The <i>deadline</i> is always <b>2 weeks</b> from the moment of\
							 initiating a request (same for Split and PayMe)<br /><br />"
				},
				{
					title: "What's the point?",
					text:
						"<div style='margin-top: 24px; margin-bottom: 20px; font-size: 20px;'><b class='instant-refund--text'>Instant Refunds!</b></div>\
						<InstantRefundBadge />\
						Keeping Split and PayMe requests in a different book than your Account Balance lets us\
						issue an <b>immediate refund</b> as soon as you initiate a Split or PayMe<br /><br />\
						Your Balance will go up before a single person responds!\
						So you can keep shopping without the worry of getting into a hefty debt\
						(taken, you trust your friends to eventually pay for their stuff)<br /><br />Cool, right?<br /><br />"
				},
				{
					title: "How does Pending change?",
					text:
						"Let's say you go to your <a href='/#/account' style='text-decoration: underline'>Account</a> page\
							and see −2.00€ pending.<br /> It means you are waiting for your friends\
						 to contribute up to 2.00€<br /><br /> When somebody claims a drink that\
						 costs 1.00€, you'll see your pending balance going up from −2.00€ to  −1.00€<br /><br />\
						 If, on the other hand, your friend declines your\
						 request (or fails to respond within 2 weeks),\
						 we'll charge <b>you</b> 1.00€ for this drink. Again, Pending will go from −2.00€ to −1.00€<br /><br />"
				},
				{
					title: "An example?"
				}
			]
		};
	},
	components: { Paragraph, PendingBalanceStep, InstantRefundBadge }
};
</script>

<style scoped></style>
