<template lang="pug">
div
	div.white--text.primary.pt-6.pb-4.mx-2.rounded-xl
		div.text-center
			h4 Sign up process
			h6.mx-2
				| Our project is still in the ideation stage. We would love
				| to know what your needs are
		v-timeline(
				align-top
				light
				:dense='$vuetify.breakpoint.xs'
				).mr-5.mx-sm-5
				v-timeline-item(
					v-for='(item, i) in items'
					:key='i'
					:icon='item.icon'
					fill-dot
					color="white")
					template(v-slot:icon)
						v-icon(color="primary") {{ item.icon }}
					v-card(color='white' dark).primary--text
						v-card-title(
							v-html='item.title'
							).body-1.text-sm-h6.break-word
						v-card-subtitle.pb-2.caption.text-sm-body-2.account-card-text--text
							div
								| {{ item.subtitle }}
						v-card-text.white.text--text
							p(
							v-html="item.text"
							).mb-1.body-2.text-sm-body-1
		.d-flex.justify-center.mt-4
			div
			v-btn(
				@click="$emit('showGetStartedDialog')"
				color="white"
				)
				span.primary--text get started
		div.caption.mt-2.white--text.text-center
			| Completely free. No strings attached
</template>
<script>
export default {
	components: {},
	props: [],
	data() {
		return {
			items: [
				{
					title: "You get in touch with us",
					subtitle: "Just click on Get Started",
					text: "We respond within 24 hours",
					icon: "alternate_email"
				},
				{
					title: "We create an account for you",
					subtitle: "It's free",
					text: "We assist you with our product (if needed)",
					icon: "person_add"
				},
				{
					title: "We keep in touch",
					subtitle: "Very sparingly",
					text: "Once in a while we'll send you some relevant information",
					icon: "forum"
				}
			]
		};
	},
	methods: {},
	computed: {}
};
</script>
<style lang="scss"></style>
