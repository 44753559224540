<template lang="pug">
div.background.d-flex.justify-center
	v-sheet(
		fill-height
		max-width='1400'
		).background.pa-10
		v-container(fill-height fluid)
			alert(:content='content' ref='alert'  :type='type')
			v-row.text-center.d-flex.align-center
				v-col(cols='12').d-flex.align-center.justify-center
					div
						Today(
							:loading='todayLoading'
							:loadingFailed='todayLoadingFailed'
							:stats='today'
							:chipRequestCount='pendingUsers.data && pendingUsers.data.length > 0 ? pendingUsers.data.length : 0')
			v-row
				v-col(cols='12' md='6' lg='6')
					div
						div(:class='{"d-flex":$vuetify.breakpoint.smAndDown }')
							Trends(
								:trends='trends'
								:loading='trendsLoading'
								).mx-auto
						RequestWidget(
							v-if='$vuetify.breakpoint.mdAndUp'
							:vertical='true'
							@requestWidget='requestWidget').mt-4
				v-col(cols='12' md='6' lg='6')
					Demand(
						:demand='demand'
						:loading='demandLoading'
						:demandLoadingFailed='demandLoadingFailed'
						@getDemand='fetchDemandSince($event)')
			v-row
				v-col(cols='12').d-flex.justify-center
					Chart(
						:chart='chart'
						:loading='chartLoading'
						@setChartParameter='setChartParameter($event)')
			v-row
				v-col
					RequestWidget(v-if='$vuetify.breakpoint.smAndDown' @requestWidget='requestWidget')
			//- v-row
			//- 	v-col(cols='12')
			//- 		ThemePicker
</template>

<script>
import Vue from "vue";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
import Alert from "../../Alert.vue";
import ThemePicker from "../../Helpers/ThemePicker.vue";
import Widgets from "./Widgets";
import { dataLoader } from "../../../mixins/dataLoader";
import { catchError } from "../../../mixins/catchError";

export default {
	mixins: [dataLoader, catchError],
	components: { ...Widgets, Alert, ThemePicker },
	data() {
		return {
			dataToLoad: {
				url: "persons/pending",
				saveTo: "pendingUsers"
			},
			pendingUsers: {},
			demandLoading: false,
			demandLoadingFailed: false,
			todayLoading: false,
			todayLoadingFailed: false,
			trendsLoading: false,
			todayIcons: [
				{ caption: "purchases", icon: "storefront" },
				{ caption: "revenue", icon: "monetization_on" },
				{ caption: "balance", icon: "account_balance" }
			],
			today: [],
			demand: [],
			trends: [],
			trendsResponse: [],
			chart: {
				parameters: {
					type: {
						selectedType: "cumulative",
						items: ["cumulative", "daily"]
					},
					metric: {
						selectedMetric: "purchases",
						items: ["purchases", "revenue"]
					},
					timePeriod: {
						selectedTimePeriod: { title: "week", numberOfDays: 6 },
						items: [
							{ title: "week", numberOfDays: 6 },
							{ title: "month", numberOfDays: 29 }
						]
					}
				},
				data: {
					labels: [],
					datasets: []
				},
				options: {
					layout: {
						padding: { top: 0, left: 10, bottom: 3, right: 25 }
					},
					plugins: {
						title: {
							text: "Bloo"
						}
					}
				}
			}
		};
	},
	computed: {
		chartLoading() {
			return this.chart.data.datasets.length < 2;
		}
	},
	methods: {
		setChartParameter(parameter) {
			this.chart.parameters[
				parameter.name[0].toLowerCase() + parameter.name.substring(1)
			][`selected${parameter.name}`] = parameter.value;
			this.drawChart();
			this.$store.commit(
				`setChart${parameter.name}`,
				parameter.value.title ?? parameter.value
			);
		},
		requestWidget() {
			this.$emit("openFeedbackDialog", "widget_suggestion");
		},
		getWeekDay(day) {
			switch (day) {
				case 0:
					return "Sunday";
				case 1:
					return "Monday";
				case 2:
					return "Tuesday";
				case 3:
					return "Wednesday";
				case 4:
					return "Thursday";
				case 5:
					return "Friday";
				case 6:
					return "Saturday";
			}
		},
		fetchToday() {
			this.getDataSince("stats", 1, "today").then(response => {
				if (response === "Success")
					this.today.forEach(stat =>
						Vue.set(
							stat,
							"icon",
							this.todayIcons.find(element => element.caption === stat.name)
								.icon
						)
					);
				else this.todayLoadingFailed = true;
			});
		},
		fetchDemandSince(numberOfDays) {
			this.getDataSince("demand", numberOfDays, "demand");
		},
		fetchTrendsSince(numbersOfDays, trendNames) {
			this.trendsLoading = true;
			var output = [];
			numbersOfDays.forEach(numberOfDays => {
				var metrics = [];
				this.getDataSince("stats", numberOfDays, "trendsResponse").then(
					response => {
						if (response === "Success") {
							trendNames.forEach(trend => {
								let metric = this.trendsResponse.find(
									trendResponse => trendResponse.name === trend
								);
								if (metric)
									metrics.push({
										name: trend,
										value: metric.value,
										change: metric.change
									});
							});
							output.push({
								name: numberOfDays === 29 ? "month" : "week",
								metrics: metrics
							});
							// ToDo: change to something more meaningful
							if (output.length === trendNames.length) {
								Vue.set(this, "trends", output);
								this.trendsLoading = false;
							}
						}
					}
				);
			});
		},
		addDataset(data, title, color) {
			var dataset = {
				label: title ?? `Dataset ${this.chart.data.datasets.length + 1}`,
				data: data,
				backgroundColor: color,
				borderColor: color,
				borderWidth: 1
			};

			Vue.set(this.chart.data, "datasets", [
				...this.chart.data.datasets,
				dataset
			]);
		},
		fetchChartData(from, to) {
			const promise = this.$axios.get(
				`/api/dashboard/history/${this.chart.parameters.metric.selectedMetric}/${this.chart.parameters.type.selectedType}?from=${from}&to=${to}`
			);

			const dataPromise = promise.then(response => {
				var chartLabels = [];
				var chartDataset = [];

				response.data.forEach(point => {
					if (
						this.chart.parameters.timePeriod.selectedTimePeriod.title === "week"
					)
						chartLabels.push(this.getWeekDay(dayjs(point.label).day()));
					else if (
						this.chart.parameters.timePeriod.selectedTimePeriod.title ===
						"month"
					)
						chartLabels.push(dayjs(point.label).format("DD."));
					chartDataset.push(point.value);
				});

				return { data: chartDataset, labels: chartLabels };
			});

			return dataPromise;
		},
		getDateDaysAgo(numberOfDays) {
			return dayjs()
				.subtract(numberOfDays, "day")
				.format("YYYY-MM-DDTHH:mm:ss");
		},
		getDateDaysAgoRoundedDown(numberOfDays) {
			return dayjs()
				.subtract(numberOfDays, "day")
				.format("YYYY-MM-DDT00:00:00");
		},
		resetChart() {
			this.chart.data = {
				labels: [],
				datasets: []
			};
		},
		drawChart() {
			this.resetChart();
			var numberOfDays = this.chart.parameters.timePeriod.selectedTimePeriod
				.numberOfDays;
			let dataSet1StartDate = this.getDateDaysAgoRoundedDown(numberOfDays);
			let dataSet1EndDate = this.getDateDaysAgo(0);
			this.fetchChartData(dataSet1StartDate, dataSet1EndDate)
				.then(dataAndLabels => {
					Vue.set(this.chart.data, "labels", dataAndLabels.labels);
					this.addDataset(
						dataAndLabels.data,
						`This ${this.chart.parameters.timePeriod.selectedTimePeriod.title}`,
						this.$vuetify.theme.themes.light.primary
					);
				})
				.catch(error => {
					this.catchError(error);
				});

			let dataSet2StartDate = this.getDateDaysAgoRoundedDown(
				numberOfDays * 2 + 1
			);
			let dataSet2EndDate = dataSet1StartDate;
			this.fetchChartData(dataSet2StartDate, dataSet2EndDate)
				.then(dataAndLabels => {
					this.addDataset(
						dataAndLabels.data,
						`Last ${this.chart.parameters.timePeriod.selectedTimePeriod.title}`,
						this.$vuetify.theme.themes.light.complementary
					);
				})
				.catch(error => {
					this.catchError(error);
				});
		},
		getDataSince(dataType, numberOfDays, variableName) {
			return new Promise((resolve, reject) => {
				this[`${variableName}Loading`] = true;
				this[`${variableName}LoadingFailed`] = false;
				this.$axios
					.get(
						`/api/dashboard/${dataType}?from=${this.getDateDaysAgoRoundedDown(
							numberOfDays
						)}&to=${this.getDateDaysAgo(0)}`
					)
					.then(response => {
						this[variableName] = response.data;
						resolve("Success");
					})
					.catch(() => {
						//this.catchError(error);
						reject(Error("Error"));
						this[`${variableName}LoadingFailed`] = true;
					})
					.finally(() => (this[`${variableName}Loading`] = false));
			});
		},
		getChartParametersFromStore() {
			Vue.set(
				this.chart.parameters.type,
				"selectedType",
				this.$store.state.settings.chart.type
			);
			Vue.set(
				this.chart.parameters.metric,
				"selectedMetric",
				this.$store.state.settings.chart.metric
			);
			Vue.set(
				this.chart.parameters.timePeriod,
				"selectedTimePeriod",
				this.chart.parameters.timePeriod.items.find(
					item => item.title === this.$store.state.settings.chart.timePeriod
				)
			);
		}
	},
	mounted() {
		this.getChartParametersFromStore();
		this.fetchTrendsSince([6, 29], ["purchases", "revenue"]);
		this.fetchDemandSince(6);
		this.fetchToday();
		this.drawChart();
	}
};
</script>
