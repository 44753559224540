<template lang="pug">
v-card.mt-7.py-4.px-4.text--text
	div.subtitle-1.text-start.font-weight-bold Ideas? Suggestions?
	div.subtitle-2 We'd love your feedback!
	div.d-flex.justify-center.mt-2.mb-1
		v-btn(
			@click="$emit('openFeedbackDialog')"
			color='primary'
			small
			outlined).mt-3 Share here
	.caption.text-center.mt-2 without leaving this page
</template>
<script>
export default {
	components: {},
	props: [],
	data() {
		return {};
	},
	methods: {},
	computed: {}
};
</script>
<style lang="scss"></style>
