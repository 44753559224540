<template lang="pug">
	v-card(
		:rounded="$vuetify.breakpoint.sm ? 'xl' : null"
		color='background'
		flat).d-flex.justify-center
		v-img(
			:src="require('../../../../public/img/support-article-not-found.webp')"
			position='center bottom'
			max-height='700'
			:max-width='$vuetify.breakpoint.smAndDown ? "100%" : 640'
			:class="{'border': $vuetify.breakpoint.smAndDown}")
			div.ma-4.mx-sm-8.mt-10
				.text-md-h3.text-sm-h4.primary--text.text-h5
					| Nothing here...
				.text-sm-h6.text--text.text--darken-2.mt-3.body-1.text-shadow
					| The article you are trying to access was likely renamed, deleted or never existed
				div.mt-8
						div.text-center
							v-btn(
								@click='$emit("openFeedbackDialog", "404", null, "I landed here while trying to access ")'
								:large="$vuetify.breakpoint.smAndUp"
								color='primary-light'
								).primary--text get in touch
</template>

<script>
export default {};
</script>

<style scoped>
.text-shadow {
	text-shadow: 1px 1px 1px #ffffff;
}

.border {
	border: solid var(--v-account-card-divider-base) 8px !important;
}
</style>
