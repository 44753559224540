<template lang="pug">
div.white.d-flex.justify-center
	v-sheet(max-width='1200')
		v-container(fluid).ma-0.pa-10
			v-row.pl-md-8.ma-0.pa-0.pl-0
				v-col(
					cols='12' md='6'
					).d-flex.justify-center.align-center
					Title(
						:textTop='"Any place"'
						:textBottom='"Any device"'
						:textTopClass="'text-h3'"
						:textBottomClass="'text-h3'"
						:align='"center"')
				v-col(
					cols='12' md='6'
					).pa-0.pl-md-8
					div.d-flex.justify-center.justify-md-end
						v-sheet(
							rounded="l"
							).d-lg-flex.rounded-r-0.my-10.pa-0
							img(
								:src='require("../../../../public/img/WhatsNew/devices.png")').mw-100
</template>

<script>
import Title from "./Title.vue";
import CheckItOutButton from "../Elements/CheckItOutButton.vue";
export default {
	data() {
		return {};
	},
	created() {},
	methods: {},
	computed: {},
	components: { Title, CheckItOutButton }
};
</script>

<style scoped></style>
