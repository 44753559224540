-<template lang="pug">
	v-container(fluid fill-height)#background.background
		v-row.text-center
			v-col(v-if='$vuetify.breakpoint.width >= smallestBreakpoint' cols='2')
			v-col.text-left.d-flex.justify-center
				div.primary--text.hue
					div.text-h2.mb-1 It's dry here…
					h5 The page you are trying to access does not exist
					h6.text-secondary Error 404
					div(:class="{'d-flex': $vuetify.breakpoint.width >= smallestBreakpoint,\
												'mt-12 ml-12': $vuetify.breakpoint.width <= smallestBreakpoint}"
						).mt-6
						div
							v-btn(
								v-if='$store.getters.user_logged_in'
								:large="$vuetify.breakpoint.width <= smallestBreakpoint"
								to='account'
								color='primary').mr-4.text-decoration-none go to account
							v-btn(
								v-else
								:large="$vuetify.breakpoint.width <= smallestBreakpoint"
								to='landing'
								color='primary').mr-4.text-decoration-none go to main page
							v-btn(
								@click='$emit("openFeedbackDialog", "404", null, "I landed here while trying to access ")'
								:class="{'mt-10': $vuetify.breakpoint.width <= smallestBreakpoint}"
								:large="$vuetify.breakpoint.width <= smallestBreakpoint"
								text
								outlined
								color='primary'
								) report problem
		div.footer-logo
			h5.text-center.primary-light--text.brand-text-logo {{ $appTitle }}
</template>
<script>
export default {
	data() {
		return {
			smallestBreakpoint: 394
		};
	}
};
</script>

<style scoped>
:root {
	--hue: 410deg;
}

#background {
	filter: hue-rotate(var(--hue));
	height: 100vh;
	background-image: url("../../public/img/404.jpg");
	background-size: cover;
	background-position: center;
}
.hue {
	filter: hue-rotate(calc(var(--hue) * -1));
}
</style>
