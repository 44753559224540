import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const state = {
	landingRef: null,
	balance: 0.0,
	balances: [],
	login_method: "",
	user: {},
	group_name: null,
	admin_groups: [],
	maxDebtValue: 0,
	maxDebtSwitch: "",
	maxDebtSelectedOption: "",
	newPassword: "",
	newPasswordIsValid: false,
	settings: {
		theme: {
			name: "Exquisite Magenta",
			primary: "#8b103d",
			"primary-light": "#fbf9dc",
			complementary: "#428da6",
			background: "#d5deec",
			"terminal-footer-top": "#ff5319",
			"terminal-footer-bottom": "#ffe6aa"
		},
		chart: {
			type: "cumulative",
			metric: "purchases",
			timePeriod: "week"
		},
		mails: {
			mail_sub_debt_reminder: true,
			mail_sub_newsletter: true,
			mail_sub_split_finish: true,
			mail_sub_split_request: true,
			mail_sub_user_registration: true
		}
	}
};

const mutations = {
	setSettings: (state, value) => (state.settings = value),
	setMailSettings: (state, value) => (state.settings.mails = value),
	setMailSetting: (state, { setting, value }) =>
		(state.settings.mails[setting] = value),
	setTheme: (state, value) => (state.settings.theme = value),
	setUser: (state, value) => (state.user = value),
	setGroupName: (state, value) => (state.group_name = value),
	setLoginMethod: (state, value) => (state.login_method = value),
	setBalance: (state, value) => (state.balance = value),
	setBalances: (state, value) => (state.balances = value),
	setMaxDebtValue: (state, value) => (state.maxDebtValue = value),
	setMaxDebtSwitch: (state, value) => (state.maxDebtSwitch = value),
	setMaxDebtSelectedOption: (state, value) =>
		(state.maxDebtSelectedOption = value),
	setAdminGroups: (state, value) => (state.admin_groups = value),
	setNewPassword: (state, value) => (state.newPassword = value),
	setNewPasswordIsValid: (state, value) => (state.newPasswordIsValid = value),
	setChartType: (state, value) => (state.settings.chart.type = value),
	setChartMetric: (state, value) => (state.settings.chart.metric = value),
	setChartTimePeriod: (state, value) =>
		(state.settings.chart.timePeriod = value),
	setLandingRef: (state, value) => (state.landingRef = value),

	reset: state => {
		(state.user = {}),
			(state.login_method = ""),
			(state.admin_groups = []),
			(state.balance = 0.0),
			(state.balances = []),
			(state.newPassword = ""),
			(state.newPasswordIsValid = false);
	}
};

const getters = {
	user_logged_in(state) {
		return state.user.name ? true : false;
	},
	user_logged_in_from_order_terminal(state) {
		return state.login_method === "rfid";
	},
	logged_in_to_personal_area(state) {
		return state.login_method === "password";
	},
	user_is_admin(state) {
		return state.admin_groups.length > 0;
	}
};

export default new Vuex.Store({
	state,
	getters,
	mutations,
	plugins: [createPersistedState()]
});
