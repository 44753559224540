<template lang="pug">
.scan-instuction
	v-row
		v-col.text-center.mb-4
			div.text-h2.mb-6.primary--text
				| Hi {{ this.$store.state.user.name }}!
			div.text-h4.primary--text.text--lighten-2 Balance: {{ formatMonetaryValue(this.$store.state.balance) }}
	v-row.mt-8
		v-col(cols="4")
			v-img(src='../../../public/img/scanner.png')
		v-col.pr-2
			div.text-h5.mt-10
				div.pt-5.mb-6.pt-10
					span The
					span.complementary--text.font-weight-medium &nbsp;scanner is on your right
					br
					span and the drinks are all around!
			v-row.mt-8
				v-col(cols='2').pa-0
					v-icon(
						color='complementary'
						size="52px"
						right) mdi-barcode-scan
				v-col(cols='10').pa-0.pl-3.pr-8
					div.text-h6.complementary--text.text-left Scan your drinks just like you would in a supermarket
	v-row
		v-col(cols="3")
		v-col.mt-16(cols='6')
			v-btn.mt-16.hide-cursor(height="70px" elevation="4" width="100%" color="primary" @click='cancelOrder')
				span.h5.mb-0 Cancel order
</template>
<script>
export default {
	name: "ScanInstruction",
	methods: {
		cancelOrder() {
			this.$emit("cancel");
		}
	}
};
</script>
<style scoped>
.scan-instuction {
	border-radius: 6px;
	font-size: 40px;
	padding: 180px 16px 0 16px;
}
</style>
