<template lang="pug">
v-dialog(
	v-model='showSignUpDialog'
	transition="dialog-bottom-transition"
	max-width="420"
	@click:outside='cancel'
	@keydown.esc='cancel'
	:fullscreen='$vuetify.breakpoint.xs')
	v-container(
		v-if='!requestStatus'
		fluid
		fill-height).pa-0.align-stretch
		v-card(color='primary-light').fill-width
			v-card-title.complementary--text.pt-2.pt-sm-4
				div Sign Up
				v-spacer
				v-btn(@click='cancel()' icon)
					v-icon close
			SignUpForm(v-on="on")
			div.d-flex.justify-center
				div
					v-btn(
						color='primary'
						:disabled='!formData'
						:loading='isLoading && formData && pattern.test(formData.email)'
						large
						@click='signUp') Order a chip
					div(
						:class='{"body-2" : $vuetify.breakpoint.xs}'
						).text-center.mt-1.primary--text.text--lighten-1
						span(v-if='isLoading && formData && pattern.test(formData.email)') Checking your email...
			.caption.text-center.px-8.py-3.primary-light--text.text--darken-2
				| By signing up you agree to our
				|
				router-link(to="/gdpr/terms-and-conditions").primary--text.text--lighten-3 Terms and Conditions
				|, and
				|
				router-link(to="/gdpr/privacy-policy").primary--text.text--lighten-3 Privacy Policy
	RequestSender(
		v-else
		:status='requestStatus'
		:statuses="requestStatuses"
		@cancel='cancel')
</template>
<script>
import { dataLoader } from "@/mixins/dataLoader";
import { catchError } from "@/mixins/catchError";
import { validators } from "@/mixins/validators";
import RequestSender from "../../Helpers/RequestSender.vue";
import NameAndRoomBadge from "../../Elements/NameAndRoomBadge.vue";
import SignUpForm from "./SignUpForm.vue";

export default {
	mixins: [validators, catchError, dataLoader],
	components: { RequestSender, NameAndRoomBadge, SignUpForm },
	name: "sign-up-dialog",
	data() {
		return {
			formData: null,
			isLoading: false,
			on: {
				input: formData => (this.formData = formData),
				"is-loading": isLoading => (this.isLoading = isLoading)
			},
			showSignUpDialog: true,
			requestStatus: null,
			requestStatuses: {
				sendingRequest: {
					title: "Hold tight",
					text: "Requesting a chip for you..."
				},
				requestCompleted: {
					title: "Done!",
					text: "You'll get a chip in your mailbox soon"
				},
				requestFailed: {
					title: "Oups...",
					text: "Error occured",
					error: null,
					supportInformation: {
						title: "You can always reach out to us at:",
						link: {
							text: `support@${this.$emailDomain}`,
							url: `mailto:support@${this.$emailDomain}?subject=Error%20with%20Sign%20Up`
						}
					}
				}
			},
			pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		};
	},
	methods: {
		signUp() {
			this.requestStatus = this.requestStatuses.sendingRequest;
			//this.$refs.form.validate();
			if (this.formData) {
				this.requestStatus = this.requestStatuses.sendingRequest;
				this.$axios
					.post("/api/register", {
						name: this.formData.name,
						room: this.formData.room,
						email: this.formData.email,
						mails_enabled: this.emailConsent
					})
					.then(() => {
						this.requestStatus = this.requestStatuses.requestCompleted;
					})
					.catch(error => {
						this.requestStatuses.requestFailed.error =
							error.response.data.error;
						this.requestStatus = this.requestStatuses.requestFailed;
					});
			}
		},
		cancel() {
			this.$emit("cancel");
		}
	}
};
</script>
<style lang="scss" scoped></style>
