<template lang="pug">
	v-layout.fill-height.background.fill-width.pa-0.ma-0
		v-container(fluid).background.pt-0
			v-row(v-if="selectedPerson")
			alert(:content='content' ref='alert'  :type='type')
			v-row
				v-col.d-flex.justify-center.px-0
					v-data-table(
						v-if='purchases.tableOptions'
						:headers="headers ? headers : allHeaders"
						:items="purchases.data"
						:options.sync="purchases.tableOptions"
						:loading="!purchases || purchases.status === statuses.loading"
						:loading-text="messages.loadingText"
						item-key="id"
						:search="purchases.tableOptions.search"
						:server-items-length="purchases.totalItems"
						show-expand
						@click:row="(item, slot) => slot.expand(!slot.isExpanded)"
						:expanded.sync="expanded"
						).pt-1.maxWidth.elevation-5
						template(v-slot:top)
							v-toolbar(flat).mb-6
								v-container
									v-row.mt-3
										v-col(cols='6' v-if="!selectedPerson").d-flex.align-center
											v-btn(
												@click='addPurchaseDialog.show = true'
												color="primary"
												rounded
												big)
												v-icon mdi-plus
												span Add purchase
										v-col(cols='6' v-else).d-flex.align-center
											span.subtitle
												span Purchases
												span.mx-1 of {{ selectedPerson.name }}
												span ({{ formatRoomOrWGNumber(selectedPerson.room, true) }})
										v-col(cols='3').pt-6.mt-1
											v-btn(
												@click="expandOrCollapse"
												x-small
												plain).ml-4
												| {{ expandCollapseButtonText }}
										v-col(cols='3')
											v-text-field(
												v-model='purchases.tableOptions.search'
												v-if="!selectedPerson"
												:append-icon='purchases.tableOptions.search ? "mdi-magnify" : undefined'
												:append-outer-icon='purchases.tableOptions.search ? "mdi-close-circle-outline" : undefined'
												@click:append-outer='purchases.tableOptions.search = ""; updateEntityAndLoadData(purchases, "purchases")'
												@click:append='updateEntityAndLoadData(purchases, "purchases")'
												@keyup='startSearchTimer(purchases, "purchases")'
												label='Search'
												single-line
												flat
												solo
												hide-details).shrink
						template(v-slot:item.timestamp='item')
							div {{ item.value }}
							div(
								v-if='item.item.added_by'
								).caption.text--text
								| Added by {{ item.item.added_by.name }}
						template(v-slot:item.amount='item')
							span {{ formatMonetaryValue(item.value * -1) }}
						template(v-slot:expanded-item='{ headers, item }')
							td(:colspan="selectedPerson ? 1 : 2")
							td(:colspan="selectedPerson ? 4 : 3").pa-4
								PurchaseDetails(
									:items="item.details"
									:messages="messages")
			v-row(v-if="selectedPerson").pt-4
		addPurchaseDialog(
			v-if="addPurchaseDialog.show"
			:showDialog.sync='addPurchaseDialog.show'
			hide-overlay
			@cancel='addPurchaseDialog.show = false'
			@save='adminAddOrderSaved($event)')
</template>

<script>
import Alert from "@/components/Alert.vue";
import AddPurchaseDialog from "./AddPurchaseDialog.vue";
import PurchaseDetails from "./PurchaseDetails.vue";
import { catchError } from "@/mixins/catchError";
import { dataLoader } from "@/mixins/dataLoader";

export default {
	components: { Alert, AddPurchaseDialog, PurchaseDetails },
	props: ["selectedPerson", "headers"],
	mixins: [catchError, dataLoader],
	data() {
		return {
			dataToLoad: [
				{
					url: "transactions",
					saveTo: "purchases",
					table: true,
					tableOptions: {
						itemsPerPage: 10,
						selectedPerson: this.selectedPerson,
						sortBy: ["timestamp"],
						sortDesc: [true]
					}
				}
			],
			purchases: {},
			addPurchaseDialog: {
				show: false
			},
			expandCollapseButtonText: "Expand all",
			expanded: [],
			messages: {
				loadingText: "Loading purchases... Please wait",
				nothingFound: "There are no purchases yet",
				loadingError: "We couldn't load purchases"
			},
			allHeaders: [
				{
					text: "ID",
					align: "start",
					value: "id",
					sortable: true
				},
				{
					text: "Person",
					align: "center",
					value: "person",
					sortable: true
				},
				{
					text: "Room (WG)",
					align: "center",
					value: "room (WG)",
					sortable: true
				},
				{
					text: "Time of purchase",
					align: "center",
					value: "timestamp",
					sortable: true
				},
				{
					text: "Amount",
					align: "center",
					value: "amount",
					sortable: true
				}
			]
		};
	},
	methods: {
		expandOrCollapse() {
			this.expandCollapseButtonText == "Expand all"
				? this.expandAll()
				: this.collapseAll();
		},
		expandAll() {
			this.expanded = this.purchases.data;
			this.expandCollapseButtonText = "Collapse all";
		},
		collapseAll() {
			this.expanded = [];
			this.expandCollapseButtonText = "Expand all";
		},
		adminAddOrderSaved(message) {
			this.addPurchaseDialog.show = false;
			this.content = message.content;
			this.type = message.type;
			this.$refs.alert.showAlert();
			this.loadEntitiesAndSave(this.dataToLoad);
		}
	}
};
</script>
<style lang="scss"></style>
