<template lang="pug">
v-row.mx-4.mt-n8
	v-col
		v-sheet(max-width='400' color='background').mx-auto
			div(v-if='!balances.status || balances.status===statuses.loading')
				v-card(
					color='primary-light'
					min-height='105').px-16
					v-skeleton-loader(type="text").pt-4.mx-6
					v-skeleton-loader(type="heading").pt-2.d-flex.justify-center
			v-card(
				v-else-if='balancesReady'
				).d-flex.align-center.justify-space-between.pb-6
				div
					v-btn(
						@click='prevBalance'
						:disabled="this.selectedBalanceIndex === 0"
						text
						color='primary')
						v-icon chevron_left
				div
					v-window(
						v-model='selectedBalanceIndex')
						v-window-item(
						v-for="(balance, index) in balances.data"
						:key='index').pb-3
							div.subtitle-2.text-center.pt-3.text-secondary.mx-n4
								| {{ balance.groupName }}
							div.mt-3
								h2(
									:class="[balance.balance < 0 ? 'error--text' : 'primary--text']"
									).text-center.mt-1
									| {{ formatMonetaryValue(balance.amount) }}
							div.text-secondary
								div.d-flex.align-center.justify-center
									span.body-2.text-right.pr-2 Pending
									div
										span(
											v-if='balance.pending_amount < 0'
											) −{{ formatMonetaryValue(balance.pending_amount * -1) }}
										span(
											v-else='balance.pending_amount < 0'
											) {{ formatMonetaryValue(balance.pending_amount) }}
									a(href='#/support/PendingBalance').grey--text.text--darken-1
										v-icon(
											color='grey'
											size='15').ml-2.mt-n1 help
					div(:class="{'justify-end' : !balances.data[selectedBalanceIndex].disabled,\
					 						'justify-center' : balances.data[selectedBalanceIndex].disabled}"
											).d-flex.fill-width.mt-2.text-center.justify-center
						v-btn(
							color="primary"
							:href="`${host}/api/payment_page/${balances.data[selectedBalanceIndex].groupId}`"
							target="_blank"
							absolute
							:right="!balances.data[selectedBalanceIndex].disabled"
							center
							rounded
							).text-decoration-none
							v-icon.mr-1(size="22" color='primary-light')
								| add_circle
							span.primary-light--text Top Up
					div(
						v-if="balances.data[selectedBalanceIndex].disabled"
						).mt-14.px-2.mr-1.d-flex.lighten-2.rounded.rounded-t-0.mx-n12.mb-n2
						div.d-flex.caption.complementary--text.text--darken-3
							v-icon.mr-2(size="22" color="complementary darken-2") production_quantity_limits
							div You can't make purchases in
								br
								span {{ balances.data[selectedBalanceIndex].groupName }}
				div
					v-btn(
						@click='nextBalance'
						:disabled='this.selectedBalanceIndex === this.balances.data.length - 1'
						text
						color='primary')
						v-icon chevron_right
			v-card.d-flex.justify-center.mt-10.pa-4.pt-5
				div
					.d-flex.justify-center
						v-btn(
							color="primary"
							large
							@click="showMobileOrder = true")
							v-icon.pr-1 shopping_cart
							span New Purchase
					.caption.text-center.mt-2.background-text-dark--text
						| If somebody else is bringing you a drink they didn't pay for. Or if you took a drink from G-Raum without paying
			v-bottom-sheet(
				v-model='showMobileOrder'
				:fullscreen="true"
				transition="dialog-bottom-transition"
				@click:outside='showInstruction=false'
				scrollable)
				MobileOrder(
					@close='showMobileOrder = false;'
					:groupId="groupId"
					:balances="balances"
					@reloadData='$emit("reloadData")'
				)
			LeaveFeedback(@openFeedbackDialog="openFeedbackDialog").mb-16
</template>
<script>
import LeaveFeedback from "./LeaveFeedback.vue";
import MobileOrder from "../MobileOrder/MobileOrder";
export default {
	components: { LeaveFeedback, MobileOrder },
	props: ["balances", "statuses"],
	data() {
		return {
			showMobileOrder: false,
			selectedBalanceIndex: 0
		};
	},
	methods: {
		openFeedbackDialog() {
			this.$emit("openFeedbackDialog");
		},
		prevBalance() {
			if (this.selectedBalanceIndex > 0) this.selectedBalanceIndex--;
		},
		nextBalance() {
			if (this.selectedBalanceIndex < this.balances.data.length - 1)
				this.selectedBalanceIndex++;
		}
	},
	computed: {
		groupId() {
			if (!this.balances?.data) {
				return 0;
			}
			return this.balances.data[this.selectedBalanceIndex]?.groupId ?? 0;
		},
		balancesReady() {
			if (!this.balances.data) return false;
			return (
				this.balances.status === this.statuses.success &&
				this.balances.data.length > 0
			);
		},
		host() {
			return window.location.protocol + "//" + window.location.host;
		}
	}
};
</script>
<style lang="scss"></style>
