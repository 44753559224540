<template lang="pug">
div.primary-light
	v-container.pa-0
		v-row(no-gutters)
			v-col(cols="12")
				v-card(
					:outlined='residencyConsent'
					:rounded='$vuetify.breakpoint.smAndUp'
					outlined
					).mb-5.mx-3.py-1
					div(v-if='!residencyConsent').subtitle-2.d-flex.pt-2
						div(style='width: 25px').ml-3.mr-1.d-flex.align-center
							v-icon(color='complementary' size='22') home
						div.complementary--text Please confirm that you live in the dorm
					div.d-flex.justify-center
						v-checkbox(
							:dense='$vuetify.breakpoint.xs'
							v-model='residencyConsent'
							color='primary'
							hide-details).mt-0
							template(v-slot:label)
								div(
									:class="{'my-4' : residencyConsent}"
								).text-center.pt-2.primary--text.subtitle-2
									| Yes, I live in Biederstein
				div.mx-3
					v-form(
						ref='form'
						v-model='formIsValid'
						@keyup.enter="enterPressed")
						div.d-sm-flex
							div.mr-sm-3
								div(style={ 'min-width': '240px' })
									v-text-field(
										v-model='formData.name'
										:dense='$vuetify.breakpoint.xs'
										:rules='[rules.required, rules.maxNameLength]'
										:disabled='!residencyConsent'
										filled
										color='complementary darken-1'
										label='Name')
							div.fill-width
								v-text-field(
									v-model='formData.room'
									:dense='$vuetify.breakpoint.xs'
									:disabled='!residencyConsent'
									filled
									color='complementary darken-1'
									label='Room')
						div
							div.d-flex
								v-text-field(
									v-model='formData.email'
									:dense='$vuetify.breakpoint.xs'
									:rules='[rules.email, !registrationStatus || registrationStatusText]'
									@keyup="checkRegistrationStatus"
									@keyup.enter="enterPressed"
									:disabled='!residencyConsent'
									filled
									color='complementary darken-1'
									label='Email')
								v-btn(
									v-if='registrationStatus === "registered"'
									:to="{ name: 'LoginTransferEmail', params: { email: formData.email } }"
									dark
									color='primary').text-decoration-none.ml-4.mt-2
									v-icon.mr-1(size='18' dark) person
									span Log in
								div(v-if='registrationStatus === "admins_reviewing" && admins.data && admins.data.length > 0').px-4
									div.subtitle-2.primary--text
										| Contact Admins
									div.mr-1.px-4
									span(v-for='(admin, index) in admins.data')
										NameAndRoomBadge(
											:name='admin.name'
											:room='admin.room').ma-1
							div.d-flex.justify-center
								div
									v-checkbox(
										v-model='formData.emailConsent'
										:disabled='!residencyConsent'
										color='primary'
										dense
										hide-details)
										template(v-slot:label)
											div.pt-2.primary--text Send me occasional emails
									div.caption.mb-6.font-weight-thin.text-center.primary-light--text.text--darken-4
										b No spam. Only relevant information
</template>
<script>
import { dataLoader } from "@/mixins/dataLoader";
import { catchError } from "@/mixins/catchError";
import { validators } from "@/mixins/validators";
import RequestSender from "../../Helpers/RequestSender.vue";
import NameAndRoomBadge from "../../Elements/NameAndRoomBadge.vue";

export default {
	mixins: [validators, catchError, dataLoader],
	components: { RequestSender, NameAndRoomBadge },
	name: "sign-up-form",
	data() {
		return {
			dataToLoad: {
				url: "show_admins",
				saveTo: "admins"
			},
			admins: [],
			buttonHasBeenPressed: false,
			checkForRegistrationStatusTimerActive: true,
			loading: false,
			registrationStatus: null,
			residencyConsent: false,
			formIsValid: false,
			formData: {
				name: "",
				room: "",
				email: "",
				emailConsent: true
			},
			checkRegistrationStatusTimeout: null,
			emailChecked: false,
			pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		};
	},
	watch: {
		formIsValid: {
			handler: function(value) {
				if (!value) this.$emit("input", null);
				else this.$emit("input", this.formData);
			}
		},
		loading: {
			handler: function(value) {
				this.$emit("is-loading", value);
			}
		}
	},
	methods: {
		enterPressed() {
			this.$emit("enterPressed");
		},
		checkRegistrationStatus() {
			this.registrationStatus = null;
			this.loading = true;
			this.emailChecked = false;
			if (this.checkRegistrationStatusTimeout)
				clearTimeout(this.checkRegistrationStatusTimeout);
			this.checkRegistrationStatusTimeout = setTimeout(() => {
				this.checkForRegistrationStatus();
			}, 1000);
		},
		// TODo: move pattern somewhere
		checkForRegistrationStatus() {
			const vm = this;
			if (this.pattern.test(this.formData.email)) {
				this.loading = true;
				this.$axios
					.get(`/api/registration_status/${this.formData.email}`)
					.then(response => {
						vm.registrationStatus = response.data.status;
						vm.emailChecked = true;
						vm.loading = false;
					});
			}
		}
	},
	computed: {
		registrationStatusText() {
			if (this.registrationStatus === "registered")
				return "You already have an account.";
			else if (this.registrationStatus === "admins_reviewing")
				return "Admins are still reviewing your request.";
			else if (this.registrationStatus === "user_confirmation_pending")
				return "You have to confirm your email.";
			else {
				return null;
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.fill-width {
	width: 100%;
}
</style>
