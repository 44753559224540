<template lang="pug">
v-dialog(v-model='showAddPurchaseDialog'
				transition="dialog-bottom-transition"
				max-width="750"
				@click:outside='cancel'
				@keydown.esc='cancel'
				)
	v-card
		v-card-title.d-flex.justify-space-between
			span.text-h6.font-weight-normal Add a new purchase
		v-card-text
			v-container
				v-row
					v-col
						alert(:content='content' ref='alert'  :type='type')
						v-autocomplete(v-if="persons.data"
													ref="userName"
													v-model="selectedPersonId"
													item-value="id"
													:rules="[rules.required]"
													:items="persons.data"
													:filter="filterUser"
													:loading='persons.status === statuses.loading'
													prepend-inner-icon="person"
													item-text="name"
													label="Buyer"
													placeholder="Type name, room or WG number"
													outlined
													required
													no-data-text="Loading users..."
													)
							template(v-slot:item="{item}")
								span {{ item.name }}
								span.text-secondary.font-weight-medium.pl-1 {{ formatRoomOrWGNumber(item.room, true) }}
							template(v-slot:selection="{item}")
								span {{ item.name }}
								span.text-secondary.font-weight-medium.pl-1 {{ formatRoomOrWGNumber(item.room, true) }}
						v-card(flat outlined)
							v-card-text.pb-0
								v-form(v-model="purchaseIsValid")
									v-simple-table(dense fixed-header height="260px")
										template(v-slot:default)
											thead
												tr
													th.text-left
														| Name
													th.text-left
														| Price
													th.text-center
														| Quantity
													th.text-left
														| Sum
													th.text-center
											tbody
												tr(v-for='drink in purchase' :key='drink.barcode')
													td {{ drink.name }}
													td {{ formatMonetaryValue(drink.price) }}
													td(width="160px")
														v-container
															v-row
																v-col.d-flex.pa-0.justify-right.align-center
																	v-btn.mx-auto(icon @click="drink.quantity-- " :disabled="drink.quantity < 2")
																		v-icon mdi-minus
																v-col.d-flex.pa-0
																	v-text-field.quantity-input(v-model.number="drink.quantity" :rules="[rules.monetaryValue]" type="number" hide-details single-line filled)
																v-col.d-flex.pa-0.align-center
																	v-btn.mx-auto(icon @click="drink.quantity++")
																		v-icon(left) mdi-plus
													td {{ formatMonetaryValue(drink.price * drink.quantity) }}
													td
														v-btn(icon small @click="premoveFromPurchase(drink)")
															v-icon(size="20") cancel
								v-container
									v-row
										v-col(cols="2")
										v-col(cols="8").d-flex.align-center.pb-0
											v-autocomplete(v-if="drinks"
																		@change="addToPurchase"
																		ref="drink"
																		v-model="drinkInput"
																		:items="drinks.data"
																		:filter="filterDrink"
																		:loading='drinks.status === statuses.loading'
																		item-text="drink"
																		prepend-inner-icon="mdi-plus"
																		label="Drink name"
																		placeholder="Type name of a drink"
																		outlined
																		required
																		no-data-text="Loading drinks..."
																		)
												template(v-slot:item="{item}")
													span {{ item.name }}
												template(v-slot:selection="{item}")
													span {{ item.name }}
										v-col(cols="2").d-flex
		v-card-actions
			v-container
				v-row
					v-col.d-flex.justify-content-center.pt-0
						v-btn(v-if="!loading" @click="submitPurchase" color='primary'  :disabled="!selectedPersonId || purchase.length == 0 || !purchaseIsValid")
							span.pr-1 Charge
							template(v-if="purchase.length > 0 && selectedPersonId")
								span.pr-1.font-weight-black {{ selectedPerson.name }}
								span(v-if="purchaseIsValid").font-weight-black.pr-1 {{ formatMonetaryValue(purchaseSum) }}
								span for this purchase
						template(v-else)
							v-progress-circular.mr-2(indeterminate color="complementary" size="24")
							span Creating purchase
						v-btn(v-else @click="submitPurchase" color='primary'  :disabled="loading || !selectedPersonId || purchase.length === 0")
							span.pr-1 Charge
							template(v-if="selectedPersonId")
								span.pr-1.font-weight-black {{ selectedPerson.name }} {{ formatMonetaryValue(purchaseSum) }}
								span for this purchase

				v-row
					v-col.d-flex.justify-content-center.pb-1.pt-0
						v-btn(@click="cancel" color='grey darken-1' text)
							| Cancel
</template>
<script>
import { catchError } from "../../../mixins/catchError";
import { dataLoader } from "../../../mixins/dataLoader";
import { validators } from "../../../mixins/validators";
import Alert from "../../Alert";

export default {
	mixins: [validators, dataLoader, catchError],
	components: { Alert },
	name: "add-purchase-dialog",
	props: [],
	data() {
		return {
			dataToLoad: [
				{
					url: "persons/list",
					saveTo: "persons"
				},
				{
					url: "drinks"
				}
			],
			persons: {},
			drinks: {},
			purchase: [],
			showAddPurchaseDialog: true,
			selectedPersonId: "",
			drinkInput: "",
			messages: {
				addSuccess: "Transaction was successfully added"
			},
			purchaseIsValid: false
		};
	},
	methods: {
		roundedpurchaseSum(purchaseSum) {
			return this.addZeroes(
				Math.round((purchaseSum + Number.EPSILON) * 100) / 100
			);
		},
		filterUser(item, queryText) {
			return (
				item.name.toLocaleLowerCase().includes(queryText.toLocaleLowerCase()) ||
				item.room.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
			);
		},
		filterDrink(item, queryText) {
			return item.name
				.toLocaleLowerCase()
				.includes(queryText.toLocaleLowerCase());
		},
		cancel() {
			this.$emit("cancel");
		},
		reset() {
			while (this.purchase.length > 0) {
				this.premoveFromPurchase(this.purchase[0]);
			}
			this.selectedPersonId = "";
		},
		addToPurchase() {
			this.purchase.push({ ...this.drinkInput, quantity: "1" });
			this.drinks.data.splice(
				this.drinks.data.findIndex(drink => drink.id == this.drinkInput.id),
				1
			);
		},
		premoveFromPurchase(drink) {
			delete drink.quantity;
			this.drinks.data.push(drink);
			this.purchase.splice(
				this.purchase.findIndex(
					drinkInPurchase => drinkInPurchase.id == drink.id
				),
				1
			);
			this.drinkInput = "";
		},
		submitPurchase() {
			var purchaseToSend = {
				userId: this.selectedPersonId,
				drinks: [],
				terminal: false
			};
			this.purchase.forEach(drink =>
				purchaseToSend.drinks.push({
					drinkId: drink.id,
					quantity: drink.quantity
				})
			);
			const vm = this;
			this.$axios
				.post("api/order", purchaseToSend)
				.then(() => {
					vm.content = vm.messages.addSuccess;
					vm.type = "success";
					this.reset();
					this.$emit("save", { content: vm.content, type: vm.type });
				})
				.catch(error => {
					vm.catchError(error);
				});
		}
	},
	computed: {
		loading() {
			return (
				this.drinks.status === this.statuses.loading ||
				this.persons.status === this.statuses.loading
			);
		},
		selectedPerson() {
			return this.persons.data.find(
				person => person.id == this.selectedPersonId
			);
		},
		purchaseSum() {
			return this.roundedpurchaseSum(
				Object.values(this.purchase).reduce(
					(total, currentValue) =>
						total +
						parseFloat(currentValue.price) * parseInt(currentValue.quantity),
					0
				)
			);
		}
	}
};
</script>
<style lang="scss" scoped>
.quantity-input {
	text-align: center !important;
}
</style>
