<template lang="pug">
div.d-flex.align-center.justify-center.pa-6
	v-btn(
		color='primary'
		to='/'
		:small="$vuetify.breakpoint.smAndDown"
		).text-decoration-none
		| Go to homepage
	div.px-3
	v-btn(
		color='primary'
		:to='user_logged_in ? "/account" : "/login"'
		:small="$vuetify.breakpoint.smAndDown"
		).text-decoration-none
		| {{ user_logged_in ? "go to account" : "go to login" }}
</template>
<script>
import { mapGetters } from "vuex";
export default {
	computed: {
		...mapGetters(["user_logged_in"])
	}
};
</script>
