<template lang="pug">
v-card(
	rounded='xl'
	width='400'
	:height='height'
	).primary-light.text--darken-4
	v-card-subtitle.text-left.rounded-t.pt-3.pb-3.account-card-background-comp
		div.d-flex.justify-space-between.align-center
			div
				div.d-flex.justify-start.align-center.body-1
					div.account-card-text-comp--text.d-flex.align-center
						div.mr-2 From
						NameAndRoomBadge(
							v-if='request.author'
							:name='request.author.name'
							:room='request.author.room'
							size="s"
							preventExpansion='true')
			//v-divider(vertical dark).account-card-divider
			div
				v-tooltip(
					top
					nudge-left="122"
					nudge-top="15"
					open-delay="200"
					color="account-card-text darken-1")
					template(v-slot:activator="{ on, attrs }")
						span(
							v-bind="attrs"
							v-on="on"
							).account-card-text--text
							div.d-flex
								v-icon(
										size='17'
										color='account-card-text'
										style='margin-bottom: 1px'
										).mr-1 schedule
								div {{ request.timeLeftToPay.bottomLine }}
					template
						span You have 2 weeks to respond to a {{ request.askedToPay ? "PayMe" : "Split" }}
						br
						span This {{ request.askedToPay ? "PayMe" : "Split" }} was initiated on {{ convertTimestampToReadableDayOfTheWeekDateTime(request.splitInitiatedTimestamp) }}
	v-sheet(color='account-card-divider' height='2')
	v-card-text.pt-2.pb-1
		template(v-if="request.friends.paid.length > 0")
			div.subtitle-2.account-card-text-comp--text.pt-2 Already responded
			div.pt-1
				span(
					v-for="(friend, index) in request.friends.paid.slice(0, 2)"
					:key='index')
					NameAndRoomBadge(
						v-if='request.author'
						:name='friend.person.name'
						:room='friend.person.room'
						size='s').ma-1
						span {{friend.person.name}}
				span.ma-1(
					v-if="request.friends.paid.length > 2"
				).body-2 +{{ request.friends.paid.length - 2 }} more
		div(v-else).mb-3.account-card-text--text.text-center.pt-4
			div.body-1 {{ captions.title }}
			div.caption {{ request.author.name }} {{ captions.subtitle }}
	v-card-actions.pr-0.pb-0.pt-4
		div.mx-auto
			v-btn(
				@click="$emit('showRequestResponseDialog', request)"
				color="primary"
				elevation='4'
				).rounded-lg
				v-icon(size='18').mr-1 edit_square
				span Respond

</template>
<script>
import NameAndRoomBadge from "@/components/Elements/NameAndRoomBadge.vue";
import Badges from "@/components/Elements/Badges/payMeAndSplitBadges.js";
import SplitStatus from "@/components/Account/Desktop/Purchase/SplitStatus.vue";

export default {
	props: ["request", "captions", "height"],
	components: { ...Badges, NameAndRoomBadge, SplitStatus }
};
</script>
