<template lang="pug">
div.scroll-down-text.pb-5.cursor-default
	div(:class="{'lightened' : isLightened}").demo.section#section04.mt-16.cursor-default
		a.primary--text.text--lighten-3.cursor-default
			span
</template>

<script>
export default {
	props: ["isLightened"],
	data() {
		return {};
	},
	methods: {}
};
</script>

<style scoped>
.cursor-default {
	cursor: default !important;
}
#section04 a {
	padding-top: 60px;
}
#section04 a span {
	position: absolute;
	top: 0;
	/* left: 50%; */
	width: 24px;
	height: 24px;
	margin-left: -12px;
	border-left: 3px solid var(--v-primary-base);
	border-bottom: 3px solid var(--v-primary-base);
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	-webkit-animation: sdb04 2s infinite;
	animation: sdb04 2s infinite;
	box-sizing: border-box;
}

.lightened a span {
	border-left: 3px solid var(--v-primary-lighten4) !important;
	border-bottom: 3px solid var(--v-primary-lighten4) !important;
}

.demo a {
	position: absolute;
	bottom: 20px;
	left: 50%;
	z-index: 2;
	display: inline-block;
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	color: #fff;
	/* font: normal 400 20px/1 "Josefin Sans", sans-serif; */
	letter-spacing: 0.1em;
	text-decoration: none;
	transition: opacity 0.3s;
}

.section::after {
	position: absolute;
	bottom: 0;
	left: 0;
	content: "";
	width: 100%;
	height: 80%;
}
@-webkit-keyframes sdb04 {
	0% {
		-webkit-transform: rotate(-45deg) translate(0, 0);
	}
	20% {
		-webkit-transform: rotate(-45deg) translate(-10px, 10px);
	}
	40% {
		-webkit-transform: rotate(-45deg) translate(0, 0);
	}
}
@keyframes sdb04 {
	0% {
		transform: rotate(-45deg) translate(0, 0);
	}
	20% {
		transform: rotate(-45deg) translate(-10px, 10px);
	}
	40% {
		transform: rotate(-45deg) translate(0, 0);
	}
}
</style>
