<!--
There are 4 types of feedback dialog:
	1) Standard, where a user can specify if it's a bug, suggestion or sth else
			feedbackType: null
	2) Problem with a purchase
			feedbackType: "purchase_problem"
	3) Dashboard widget suggestion
			feedbackType: "widget_suggestion"
	4) Page 404
			feedbackType: "404"
-->
<template lang="pug">
	component(
		:is='$vuetify.breakpoint.smAndUp ? "v-dialog" : "v-bottom-sheet"'
		v-model='showFeedbackDialog'
		transition="dialog-bottom-transition"
		@click:outside='cancel'
		:max-width='$vuetify.breakpoint.smAndUp ? 500 : ""'
		@keydown.esc='cancel')
		v-card.pa-3
			div.ml-2
				h4.mb-2.mt-3.primary--text {{ dialogTitle }}
				h6.mb-7.primary--text.text--lighten-2 {{ dialogSubtitle }}
			v-row
					v-col.d-flex.justify-center
							v-btn-toggle(
								v-if="!data.type || feedbackTypeOptions.some(option => option.name === data.type)"
								v-model='selectedFeedbackType'
								@change='setType(selectedFeedbackType)'
								background-color="primary lighten-4"
								color="primary"
								borderless
								mandatory
								rounded).d-flex.flex-row
								v-btn(v-for="(option, index) in feedbackTypeOptions" :key='index' :value="option.name")
									v-icon(color="primary" size="20")
										| {{option.icon}}
									span.pl-1.primary--text.text--darken-2 {{option.name}}
			v-form(v-model="formIsValid")
				v-textarea.mt-3(
					v-model='text'
					:rules="[rules.required]"
					label='Description'
					rows='6'
					outlined)
			div.text-center
				v-btn(color="primary" @click='sendFeedback' :disabled='!formIsValid') Send
			div.text-center.mt-3
				v-btn(color='primary' @click='cancel' text) Cancel
</template>

<script>
import Alert from "./Alert.vue";
import { validators } from "../mixins/validators";
import { VDialog, VBottomSheet } from "vuetify/lib";
export default {
	name: "feedback",
	props: ["data"],
	components: { Alert, VDialog, VBottomSheet },
	mixins: [validators],
	data() {
		return {
			dialogTitle: "",
			dialogSubtitle: "",
			text: "",
			showFeedbackDialog: true,
			formIsValid: false,
			selectedFeedbackType: this.data.type ?? "Tip",
			feedbackTypeOptions: [
				{ name: "Tip", icon: "lightbulb" },
				{ name: "Bug", icon: "bug_report" },
				{ name: "Other", icon: "live_help" }
			]
		};
	},
	mounted() {
		switch (this.data.type) {
			case "purchase_problem":
				this.dialogTitle = "Tell us what went wrong";
				this.dialogSubtitle =
					"You don't need to specify order date or contents";
				return;
			case "widget_suggestion":
				this.dialogTitle = "Share your widget idea with us";
				this.dialogSubtitle = "Anything you find useful";
				return;
			case "404":
				this.dialogTitle = "What page were you trying to reach?";
				this.dialogSubtitle = "";
				return;
			default:
				this.dialogTitle = "Tell us what you think";
				this.dialogSubtitle = "Don't be shy";
				return;
		}
	},
	methods: {
		setType(type) {
			this.$emit("setType", type);
		},
		sendFeedback() {
			this.$emit("sendFeedback", this.text);
		},
		cancel() {
			this.$emit("cancel");
		}
	}
};
</script>

<style scoped></style>
