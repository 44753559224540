<template lang="pug">
div
	Paragraph(:paragraph='paragraphs[0]')
	Paragraph(:paragraph='paragraphs[1]')
	Paragraph(:paragraph='paragraphs[2]')
	Paragraph(:paragraph='paragraphs[3]')
	div.mb-3 Very easy – we'll show you:
	div.d-sm-flex.justify-space-between.mb-6
		div(
			v-for='(step,i) in steps'
			:index='i'
			).mt-4.mt-sm-0
			ProcessStep(:step='step')
	Paragraph(:paragraph='paragraphs[4]')
</template>
<script>
import Paragraph from "../Elements/Paragraph.vue";
import ProcessStep from "../Elements/ProcessStep.vue";
export default {
	data() {
		return {
			steps: [
				{
					icon: "account_circle",
					text: "to your Account page",
					title: "Go",
					links: [{ text: "Go to Account", link: "/#/account" }]
				},
				{
					icon: "person_add",
					text: "Click ADD FRIENDS on the Purchase you need. Choose PayMe",
					title: "Initiate",
					links: []
				},
				{
					icon: "credit_score",
					text: "Follow instructions. We'll issue an Instant Refund",
					title: "Done",
					links: []
				}
			],
			paragraphs: [
				{
					title: "What is PayMe?",
					text:
						"PayMe lets you ask your friend(s) to pay for the drinks you bought\
						 with your chip. It's very convenient for when you buy drinks for someone"
				},
				{
					title: "When to PayMe?",
					text:
						"Whenever you want someone to pay for your <b>entire</b> purchase"
				},
				{
					title: "How to respond to PayMe?",
					text:
						"Go to your <a href='/#/account' style='text-decoration: underline'>Account</a>.\
						<ul style='padding-top: 4px'><li>On the desktop version, PayMe requests are listed at the top of the page</li>\
						<li>On mobile, click on Friends button in the bottom menu</li></ul> Click RESPOND and follow instructions"
				},
				{
					title: "How to initiate a PayMe?"
				},
				{
					title: `Can I PayMe with friends outside of ${
						this.isBiederDrinks ? "Biederstein" : "my dorm"
					}?`,
					text: "No. A " + this.$appTitle + " account is required for PayMe"
				}
			]
		};
	},
	components: { ProcessStep, Paragraph }
};
</script>

<style scoped>
.step {
	position: absolute;
	top: 5px;
	left: 5px;
}
</style>
