<template lang="pug">
div
	v-card.pa-6.pa-sm-10
		div.text-center.background-text--text.text--darken-2
			div.text-h6 We only show our {{ entity }} to Stones.
			div.mt-2.subtitle-1
				| If you live in Biederstein, Log in or Sign Up to
				| {{ benefit }}
		div.d-flex.justify-center.mt-4.mt-sm-10.flex-wrap.mt-10.mt-sm-5
			div.d-flex.justify-center
				v-btn(
					to='login'
					x-large
					color='primary').text-decoration-none
					span Log in
			div(v-if='$vuetify.breakpoint.width > 382').mx-3
			div(v-if='$vuetify.breakpoint.width <= 382').my-10
			div.d-flex.justify-center.mt-sm-0
				SignUpButton(@click='signUpDialog.show = true' :isDark='true')
	sign-up-dialog(
		v-if='signUpDialog.show'
		:showDialog.sync='signUpDialog.show'
		@cancel='signUpDialog.show = false'
		hide-overlay)
</template>
<script>
import SignUpButton from "./SignUpButton.vue";
import SignUpDialog from "../SignUp/SignUpDialog.vue";
export default {
	components: { SignUpButton, SignUpDialog },
	props: { entity: String, benefit: String },
	data() {
		return {
			signUpDialog: {
				show: false
			}
		};
	}
};
</script>
