<template lang="pug">
v-app(:class="{'hide-cursor': user_logged_in_from_order_terminal}")
	template(v-if="user_is_admin && showNavBar($route.fullPath)")
		navbar
		alert(:content='content' ref='alert' :type='type')
		router-view(:key='$route.fullPath' @openFeedbackDialog='openFeedbackDialog').pt-1
	template(v-else)
		alert(:content='content' ref='alert'  :type='type')
		v-card
			v-app-bar(
				v-if="showNavbar2($route.fullPath)"
				style='z-index: 99'
				absolute
				:color='hexToRgbA($vuetify.theme.themes.light.primary, 0.8)'
				dark
				inverted-scroll
				scroll-target='#scrolling-techniques-8'
				).rounded-t-0
				v-toolbar-title(
					v-if='$isBiederDrinks'
					:style="{'font-size': `${$vuetify.breakpoint.xs ? 20 : 26}px`}"
					).brand-text-logo {{ $appTitle }}
				div(v-else)
					v-img(
						height="30"
						width="200"
						contain
						src="../public/logos/png/logo-white-transparent.png")
				v-spacer
				div(
					v-if='$isBiederDrinks && $vuetify.breakpoint.smAndUp'
					).d-flex.ml-4.subtitle-1.pt-1
					div.mr-6
						a(
							@click='scrollTo("whats-new-first")'
							) What's new?
					div.blue
				v-btn(
					:to="user_logged_in ? '/account' : '/login'"
					light
					color='primary-light').text-decoration-none
					span.primary--text {{ user_logged_in ? 'account' : 'login' }}
			v-sheet(
				).overflow-y-auto.ma-0#scrolling-techniques-8.pa-0.ma-0.outline.white
				//v-container(fluid style='width: 100vw !important').pa-0.ma-0
				router-view(
					:key='$route.fullPath'
					@openFeedbackDialog='openFeedbackDialog').router-view
	feedback(v-if="feedbackDialog.show"
					:showDialog.sync='feedbackDialog.show'
					:data='feedbackDialog.data'
					@setType='setFeedbackType($event)'
					@cancel='clearFeedbackAndCloseDialog'
					@sendFeedback='sendFeedback($event)')
	v-footer(v-if="showFooter($route.fullPath)" bottom padless)
		v-card.pa-0.ma-0.pb-1.text-center(flat tile color="background" width="100%")
			v-card-text.white--text.pt-8.mb-2
				v-row.pt-1
					v-col.pa-0(cols='3')
					v-col.pa-0(cols="6")
						v-container
							v-row.align-center
								v-col.pa-0.pl-1.text-center.background--text.text--darken-3
									div.h5.mb-0
										span.brand-text-logo {{ $appTitle }}
									div(v-if='user_is_admin').mt-1.subtitle-2.text--lighten-1 {{ this.$store.state.admin_groups[0].group_name }}
					v-col.pa-0(cols='3').d-flex.justify-end
						v-btn.mr-4(fab color="primary" @click='feedbackDialog.show = true')
							v-icon(color='primary-light') comment
			GDPRLinks.mt-n6
</template>

<script>
import Vue from "vue";
import Vuetify from "vuetify/lib";
import { mapGetters } from "vuex";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { catchError } from "../src/mixins/catchError";
import VueVirtualScroller from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

import Navbar from "./components/Navbar.vue";
import Feedback from "./components/Feedback.vue";
import Alert from "./components/Alert.vue";
import GDPRLinks from "./components/GDPRLinks.vue";

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(Vuetify);

Vue.use(VueVirtualScroller);

export default {
	name: "App",
	mixins: [catchError],
	components: { Navbar, Feedback, Alert, GDPRLinks },
	data() {
		return {
			feedbackDialog: {
				show: false,
				data: {
					type: "Tip",
					text: null,
					purchaseId: null
				}
			}
		};
	},
	methods: {
		scrollTo(target) {
			document.getElementById(target).scrollIntoView({
				behavior: "smooth"
			});
		},
		setFeedbackType(type) {
			this.feedbackDialog.data.type = type;
		},
		trimUrl(url) {
			return `/${url.split("/")[1]}`;
		},
		showNavBar(page) {
			let trimmedUrl = this.trimUrl(page);
			switch (trimmedUrl) {
				case "/dashboard":
				case "/persons":
				case "/drinks":
				case "/purchases":
				case "/admin_settings":
				case "/history":
					return true;
				default:
					return false;
			}
		},
		showFooter(page) {
			let trimmedUrl = this.trimUrl(page);
			switch (trimmedUrl) {
				case "/dashboard":
				case "/persons":
				case "/drinks":
				case "/purchases":
				case "/admin_settings":
				case "/history":
					return true;
				default:
					return false;
			}
		},
		showNavbar2(page) {
			let trimmedUrl = this.trimUrl(page);
			switch (trimmedUrl) {
				case "/":
				case "/landing":
				case "/landing/true":
					return true;
				default:
					return false;
			}
		},
		openFeedbackDialog(feedbackType, purchaseId, text) {
			if (text) this.feedbackDialog.data.text = text;
			if (feedbackType) this.feedbackDialog.data.type = feedbackType;
			if (feedbackType === "purchase_problem")
				this.feedbackDialog.data.purchaseId = purchaseId;
			this.feedbackDialog.show = true;
		},
		sendFeedback(text) {
			if (this.feedbackDialog.data.type === "purchase_problem") {
				this.feedbackDialog.data.text =
					"Purchase id: " + this.feedbackDialog.data.purchaseId + ". " + text;
			} else this.feedbackDialog.data.text = text;
			this.feedbackDialog.data.page = this.$route.path;
			this.feedbackDialog.data.userAgent = window.navigator.userAgent;
			const vm = this;
			console.log(JSON.stringify(this.feedbackDialog.data));
			this.$axios
				.put("/api/feedback", this.feedbackDialog.data)
				.then(() => {
					vm.type = "success";
					vm.content = "Feedback sent. Thank you!";
					this.$refs.alert.showAlert();
				})
				.catch(error => catchError(error));
			this.clearFeedbackAndCloseDialog();
		},
		resetDialog() {
			this.feedbackDialog.data.type = "Tip";
			this.feedbackDialog.data.text = null;
			this.feedbackDialog.data.purchaseId = null;
			this.feedbackDialog.data.userAgent = null;
		},
		clearFeedbackAndCloseDialog() {
			this.resetDialog();
			this.feedbackDialog.show = false;
		}
	},
	computed: {
		...mapGetters([
			"user_logged_in_from_order_terminal",
			"logged_in_to_personal_area",
			"user_is_admin",
			"user_logged_in"
		])
	},
	mounted() {
		document.title = this.$appTitle;
	}
};
</script>

<style lang="scss">
.router-view {
	width: 100% !important;
	height: 100vh;
}

.outline {
	outline: solid 1px blue;
}

.font-primary,
.v-application,
body,
.v-application .text-h1,
.v-application .text-h2,
.v-application .text-h3,
.v-application .text-h4,
.v-application .text-h5,
.v-application .text-h6,
.v-application .subtitle-1,
.v-application .subtitle-2,
.v-application .text-subtitle-1,
.v-application .text-subtitle-2,
.v-application .body-1,
.v-application .body-2,
.v-application .text-body-1,
.v-application .text-body-2,
.v-application .text-button {
	font-family: "Metropolis-Regular" !important;
}

@font-face {
	font-family: "RouttageRegular";
	src: url("../public/fonts/routtage/RouttageRegular.eot");
	src: url("../public/fonts/routtage/RouttageRegular.eot?#iefix")
			format("embedded-opentype"),
		url("../public/fonts/routtage/RouttageRegular.svg#RouttageRegular")
			format("svg"),
		url("../public/fonts/routtage/RouttageRegular.ttf") format("truetype"),
		url("../public/fonts/routtage/RouttageRegular.woff") format("woff"),
		url("../public/fonts/routtage/RouttageRegular.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Metropolis-Regular";
	src: url("../public/fonts/metropolis/Metropolis-Regular.eot");
	src: url("../public/fonts/metropolis/Metropolis-Regular.eot?#iefix")
			format("embedded-opentype"),
		url("../public/fonts/metropolis/Metropolis-Regular.svg#Metropolis-Regular")
			format("svg"),
		url("../public/fonts/metropolis/Metropolis-Regular.ttf") format("truetype"),
		url("../public/fonts/metropolis/Metropolis-Regular.woff") format("woff"),
		url("../public/fonts/metropolis/Metropolis-Regular.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

input[type="number"] {
	-moz-appearance: textfield !important;
	-webkit-appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
	margin: 0 !important;
}

.maxWidth {
	max-width: 900px;
}

.brand-text-logo {
	font-family: RouttageRegular;
}

.cursor-pointer {
	cursor: pointer;
}

.hide-cursor {
	cursor: none !important;
}

body {
	background-color: var(--v-background-base) !important;
}

#app {
	height: 100%;
}

.hide-scrollbar-y::-webkit-scrollbar {
	display: none;
}

.hide-scrollbar-y {
	scrollbar-width: none;
}

.break-word {
	word-break: break-word;
}

.background-transparent {
	background: rgba(0, 0, 0, 0) !important;
}

.body-3 {
	font-size: 0.75rem;
}

.footer-logo {
	position: absolute;
	bottom: 20px;
	width: 130px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
}
</style>
