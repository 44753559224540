<template lang="pug">
div
	alert(
		:content='content'
		ref='alert'
		:type='type'
		:backgroundColor="alertBackgroundColor").mb-4
	v-form(
		v-if='requireCurrentPassword'
		v-model="currentPassword.valid"
	).d-flex.justify-center
		v-text-field(
			:solo='!accountSettingsDialog'
			:outlined='accountSettingsDialog'
			v-model="currentPassword.value"
			:rules="[rules.required]"
			:type="currentPassword.visible ? 'text' : 'password'"
			:append-icon="!currentPassword.visible ? 'visibility' : 'visibility_off'"
			@click:append="() => (currentPassword.visible = !currentPassword.visible)"
			label="Current password"
		).text-field-size
	v-form(v-model="newPassword.valid").d-flex.justify-center
		v-text-field(
			hide-details
			:solo='!accountSettingsDialog'
			:outlined='accountSettingsDialog'
			v-model="newPassword.value"
			v-on:keyup="savePasswordToStore"
			v-on:input="savePasswordToStore"
			v-on:change="savePasswordToStore"
			:rules="[rules.required, rules.password]"
			:type="newPassword.visible ? 'text' : 'password'"
			:append-icon="!newPassword.visible ? 'visibility' : 'visibility_off'"
			@click:append="() => (newPassword.visible = !newPassword.visible)"
			@keyup.enter="$emit('enterPressed')"
			label="New password"
			:color="showPasswordIconColor"
			:dark="colorScheme == '!dark'"
		).bright-error-message.text-field-size
	div(:style="{color: listTextColor}").mt-6.text-center
		div.mb-1.mt-3.ml-3.ml-sm-6
			span(v-if='!newPassword.valid') Your password needs:
			span(v-else='!newPassword.valid') Great password!
		div(
			:class='{ "px-sm-4" : !accountSettingsDialog }'
			).d-flex.flex-wrap.justify-center
			v-card(
				v-for="(check, index) in passwordChecks"
				:key='index'
				outlined
				color='complementary lighten-3'
				).ma-1.py-sm-2.py-1.px-2.align-center.d-flex
				v-overlay(
					:value='check.condition(newPassword.value)'
					absolute
					opacity='0.85'
					color='green')
					v-icon(
						:size="$vuetify.breakpoint.xs ? 20 : 30"
						:color="passwordCheckIconColor"
						) check_circle
				div.complementary--text.text--darken-3.text-center.text-sm-body-1.caption
					span(
						v-if='!accountSettingsDialog && $vuetify.breakpoint.smAndUp'
						v-html="check.textWrap")
					span(
						v-else
						v-html="check.text")
		v-card-actions(v-if='requireCurrentPassword').pt-0.mt-8
			v-spacer
			v-btn(
				@click="changePassword"
				color="primary"
				:loading='loading'
				:disabled='!currentPassword.valid || !newPassword.valid')
				span Update password
			v-spacer
</template>
<script>
import { validators } from "../../mixins/validators";
import { catchError } from "../../mixins/catchError";
import Alert from "../Alert.vue";
export default {
	components: {
		Alert
	},
	mixins: [validators, catchError],
	name: "password-input",
	props: [
		"colorScheme",
		"accountSettingsDialog",
		"requireCurrentPassword",
		"alertBackgroundColor"
	],
	data() {
		return {
			loading: false,
			currentPassword: {
				value: "",
				valid: false,
				visible: false
			},
			newPassword: {
				value: "",
				valid: false,
				visible: false
			},
			passwordChecks: [
				{
					condition: password => /[A-Z]/.test(password),
					text: "CAPITAL letter",
					textWrap: "CAPITAL<br />letter"
				},
				{
					condition: password => /[a-z]/.test(password),
					text: "small letter",
					textWrap: "small<br />letter"
				},
				{
					condition: password => /[0-9]/.test(password),
					text: "digit",
					textWrap: "digit"
				},
				{
					condition: password => /[!@#$%^&*]/.test(password),
					text:
						"symbol<br /><div class='font-weight-bold'> ! @ # $ % ^ & *</div>",
					textWrap:
						"symbol<br /><div class='font-weight-bold'> ! @ # $ % ^ & *</div>"
				},

				{
					condition: password => password.length >= 6,
					text: "≥ 6 characters<br />",
					textWrap: "≥ 6 characters<br />"
				}
			]
		};
	},
	mounted() {
		this.savePasswordToStore();
	},
	methods: {
		savePasswordToStore() {
			if (this.newPassword.valid) {
				this.$store.commit("setNewPassword", this.newPassword.value);
				this.$store.commit("setNewPasswordIsValid", true);
			} else this.$store.commit("setNewPasswordIsValid", false);
		},
		changePassword() {
			this.loading = true;
			const vm = this;
			this.$axios
				.put(
					`/api/change_password?old_password=${this.currentPassword.value}&new_password=${this.newPassword.value}`
				)
				.then(() => {
					vm.content = "Password was successfully updated!";
					vm.type = "success";
					this.$refs.alert.showAlert();
				})
				.catch(error => {
					vm.catchError(error);
				})
				.finally(() => (vm.loading = false));
		}
	},
	computed: {
		showPasswordIconColor() {
			return this.colorScheme == "dark" ? "primary lighten-1" : "primary";
		},
		passwordCheckIconColor() {
			return "primary-light";
		},
		listTextColor() {
			return this.colorScheme == "dark"
				? "var(--v-primary-light)"
				: "var(--v-complementary-base)";
		},
		expansionColor() {
			return this.colorScheme == "dark" ? "primary darken-1" : "";
		},
		expansionTextColor() {
			return this.colorScheme == "dark"
				? "var(--v-primary-light-darken1)"
				: "var(--v-primary-base)";
		}
	}
};
</script>

<style lang="scss" scoped>
.password-rules-explanation {
	border-color: var(--v-primary-lighten3);
}

.text-field-size {
	max-width: 250px;
}
</style>
