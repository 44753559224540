<template lang="pug">
v-dialog(
	v-model='showEditDialog'
	transition="dialog-bottom-transition"
	max-width="600"
	@click:outside='cancel'
	@keydown.esc='cancel')
	v-card
		v-card-title.font-weight-normal.primary--text
			h3
				span(v-if='type === "approval"') Approve a new user
				span(v-else-if="isAddDialog") Add a new user
				span(v-else) Edit information for
					span.pl-2(v-if='!isAddDialog') {{ selectedPerson.name }}
					span.text-secondary.font-weight-medium.h5.pl-2
						| ({{ formatRoomOrWGNumber(selectedPerson.room, true) }})
		v-card-text.pb-0
			v-alert(
				v-if='selectedPerson.confirmed === "No" && !rfidOwner && type !== "approval"'
				type='info'
				outlined
				text
				dense)
				div.subtitle-1 {{ selectedPerson.name }} hasn't completed registration yet
				div.subtitle-2.mb-2
					| They have to follow the link in the email we sent
				div(v-if='!confirmationEmailSent').d-flex.subtitle-2.align-center
					div.mr-2 If our message is missing, you can
					v-btn(
						@click='sendConfirmationEmail'
						:loading='confirmationEmailLoading'
						color='primary'
						x-small) send it again
				div(v-else).d-flex.subtitle-2
					v-icon(color='info' size='20').mr-1 done
					div
						span.mr-1 We've sent another confirmation email to
						span.font-weight-bold {{ selectedPerson.email }}
			v-container
				v-form(v-model='formIsValid').mt-4
					v-row
						v-col(cols='12').pa-0
							v-container
								v-row(v-if="!rfidOwner")
									v-col(cols='3').py-1
										v-text-field(
											v-model='formData.name'
											:rules="[rules.required, rules.maxNameLength]"
											label='Name'
											required
											outlined)
									v-col(cols='3').py-1
										v-text-field(
											v-model='formData.room'
											:rules="[rules.required]"
											label='Room'
											hint='Or WG'
											required
											outlined)
									v-col(cols='6').py-1
										v-text-field(
											v-model='formData.email'
											@keyup='emailFound = false'
											:rules="[rules.required, rules.email]"
											:background-color='emailFound ? "primary-light" : ""'
											label='Email'
											required
											outlined)
								v-row(v-if='emailFound' no-gutters)
									v-col(cols='6')
									v-col(cols='6').d-flex.pl-5
										v-icon(size='20' color='warning').mr-1 warning
										div.warning--text This email is already taken
								v-row
									v-col(cols='12').pa-0
										v-container
											v-row
												v-col(cols='4' v-if="terminalEnabled").py-0
													v-text-field(
													v-model='formData["rfid-code"]'
													v-mask="rfidMask"
													:rules="[rules.required, rules.rfid]"
													label='Chip Code'
													required
													outlined
													:background-color='rfidOwner ? "primary-light" : ""')
												v-col(v-if='!isAddDialog && !type && !rfidOwner').py-0
													div.grey--text.text--darken-3.body-1.mt-5
														span.mr-1 Account balance:
														span.font-weight-bold {{ formData.hasOwnProperty("balance") ? formatMonetaryValue(formData.balance) : "loading" }}
											v-row
												v-col(cols='12').py-1
													v-text-field(
														v-model='formData.comment'
														label='Comment'
														outlined)
			v-divider(v-if="!rfidOwner")
			v-container(v-if="!rfidOwner")
				v-row.align-center
					v-col(cols='4').d-flex.justify-center
						v-checkbox.mb-0(
							v-model='formData.active'
							true-value='Yes'
							false-value='No'
							required)
							template(v-slot:label)
								span.mt-3 Active
					v-col(cols='8').pa-0
						p.pt-4.body-1 Deactivated users can't enter the room or make purchases. If they try to use their chip, they will be asked to contact you
				v-row
					v-col(cols='4')
						v-checkbox.mb-0(
							v-model='formData.is_admin_key'
							true-value='Yes'
							false-value='No'
							required)
							template(v-slot:label)
								span.mt-3 Admins' Chip
					v-col(cols='8')
						p.pt-4.body-1 Balances of those chips are not included in the "Combined Balance"
		div(v-if="!rfidOwner && (isAddDialog || selectedPerson.email != formData.email)").mx-6
			v-divider(v-if="!rfidOwner")
			v-alert(
				type='info'
				text
				outlined)
				span(v-if='isAddDialog') We'll send an invitation to the email you specified
				span(v-else) You changed email. We'll send a confirmation message to it
		v-card-actions(v-if="!rfidOwner")
			small.text-secondary(v-if='!isAddDialog')
				span.mr-1 Added
				span.mr-1(v-if='formData.created_by') by {{ formData.created_by }}
				span on {{ formData.timestamp | timestampToDDMMYY }}
			v-spacer
			v-btn(color='grey darken-1' text @click='cancel')
				| cancel
			v-btn(
				@click='checkDataAndSave'
				:loading='loading'
				:disabled='(!formIsValid && formData.active === "Yes") || (!formIsValid && isAddDialog)  || rfidOwner || emailFound'
				color='primary')
				span(v-if='type === "approval"') Approve user
				span(v-else) {{ isAddDialog ? "send invitation" : "save changes" }}
		v-card-text(v-if="rfidOwner")
			v-row.mt-1
				v-col
					v-card(color="primary-light")
						v-card-title.complementary--text This code already belongs to {{ rfidOwner.name }} ({{ formatRoomOrWGNumber(rfidOwner.room, true) }})
						v-card-text
							v-row
								v-col
									div.text-start.body-1 Are you giving {{ rfidOwner.name }}'s chip to {{ formData.name }}?
							v-row
								v-col(cols='6')
									v-card(outlined)
										v-card-actions.d-flex.justify-center
											v-btn.mt-3(@click="backToEditing" color="primary") no, back to editing
										v-card-text.text-center
											div.mb-10 No action will be taken
								v-col(cols='6').text-left
									v-card(outlined)
										v-card-actions.d-flex.justify-center
											v-btn.mt-3(@click="save" color="primary") Yes, reassign the chip
										v-card-text.pa-0.pt-3
											ul.dash-list
												li
													span(v-if="selectedPerson.name") {{ selectedPerson.name }}
													span(v-else).pr-1 New user
													span &nbsp;will get the chip
												li Account of {{ rfidOwner.name }} will be deactivated
</template>
<script>
import { validators } from "../../../mixins/validators";

export default {
	mixins: [validators],
	name: "edit-person-dialog",
	props: ["selectedPerson", "type", "terminalEnabled"],
	data() {
		return {
			loading: false,
			showEditDialog: true,
			formIsValid: false,
			emailFound: false,
			rfidOwner: null,
			confirmationEmailLoading: false,
			confirmationEmailSent: false,
			rfidMask: {
				mask: "FF FF FF FF",
				tokens: {
					F: {
						pattern: /[0-9a-fA-F]/,
						transform: v => v.toLocaleUpperCase()
					}
				}
			},
			formData: {},
			pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		};
	},
	methods: {
		sendConfirmationEmail() {
			this.confirmationEmailLoading = true;
			const vm = this;
			this.$axios
				.post(`/api/send_confirmation_mail/${this.formData.id}`)
				.then(() => {
					this.confirmationEmailLoading = false;
					this.confirmationEmailSent = true;
				})
				.catch(error => {
					vm.catchError(error);
				});
		},
		async checkDataAndSave() {
			this.loading = true;
			this.emailFound =
				(await this.emailExists()) &&
				this.formData.email !== this.selectedPerson.email;
			if (!this.emailFound) this.rfidOwner = await this.findRfidOwner();
			if (!this.emailFound && !this.rfidOwner) this.save();
			this.loading = false;
		},
		async findRfidOwner() {
			let owner = null;
			if (this.formData["rfid-code"] !== this.selectedPerson["rfid-code"])
				await this.$axios
					.get(`/api/rfid_owner/${this.formData["rfid-code"]}`)
					.then(response => {
						if (response.data.person) owner = response.data.person;
					});

			return owner;
		},
		async emailExists() {
			let emailFound = false;
			if (this.pattern.test(this.formData.email)) {
				await this.$axios
					.get(`/api/email_exists/${this.formData.email}`)
					.then(response => {
						emailFound = response.data.response;
					});
			}
			return emailFound;
		},
		save() {
			const formDataCopy = { ...this.formData };
			this.formData = {};
			if (this.type === "approval") {
				formDataCopy.approve = true;
				this.$emit("approve", formDataCopy);
			} else this.$emit("save", formDataCopy);
		},
		cancel() {
			this.$emit("cancel");
		},
		reset(selectedPerson) {
			this.formData = Object.assign({}, this.formData, selectedPerson);
		},
		backToEditing() {
			this.rfidOwner = null;
			this.rfidOwner = false;
		}
	},
	mounted() {
		this.reset(this.selectedPerson);
		if (this.isAddDialog) this.$set(this.formData, "active", "Yes");
	},
	computed: {
		isAddDialog() {
			return Object.keys(this.selectedPerson).length === 0;
		}
	},
	watch: {
		selectedPerson: {
			deep: true,
			handler: function(val) {
				this.formData = Object.assign({}, this.formData, val);
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.dash-list > li {
	list-style: none;
}

.dash-list ul {
	margin-left: 0;
	padding-left: 0;
}

.dash-list li {
	padding-left: 1em;
	text-indent: -1em;
}

.dash-list li:before {
	content: "–";
	padding-right: 5px;
}
</style>
