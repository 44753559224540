<template lang="pug">
v-card(flat)
	.px-4.complementary--text.text-sm-h6.subtitle-1.text-center
		| Specify when we should contact you
	v-card-text.pb-0
		div(
			v-for='(category, categoryIndex) in emailOptions'
			:index='categoryIndex')
			div(v-if='(category.adminOnly && user_is_admin) || !category.adminOnly')
				.subtitle-2 {{ category.categoryName }}
				div.mb-6
					div(
							v-for='(setting, settingIndex) in category.settings'
							:index='settingIndex').d-flex.align-center
							v-checkbox(
								v-if='!category.adminOnly || (category.adminOnly && user_is_admin)'
								v-model='selectedMailOptions'
								@click='saveSettings(categoryIndex, settingIndex)'
								:label='setting.name'
								:value='setting.url'
								color='primary'
								hide-details).mr-10.mt-2
								template(v-slot:label)
									span.mt-2.text-sm-body-1.body-2 {{ setting.name }}
							UpdateContactStatus(
								v-if="setting.status"
								title='setting'
								:status='setting.status'
								:statuses='updateStatuses'
								successMessage='Saved')
		v-alert(
			outlined
			icon='info'
			color='complementary'
			:dense='$vuetify.breakpoint.xs')
			div.text-sm-body-1.body-2
				| We will still send necessary emails related
				| to your account
</template>
<script>
import { mapGetters } from "vuex";
import UpdateContactStatus from "../Desktop/Elements/UpdateContactStatus.vue";
export default {
	data() {
		return {
			selectedMailOptions: [],
			emailOptions: [
				{
					categoryName: "Admin",
					adminOnly: true,
					settings: [
						{
							name: "A new user registers",
							url: "mail_sub_user_registration",
							status: null
						}
					]
				},
				{
					categoryName: "Friends' Activity",
					adminOnly: false,
					settings: [
						{
							name: "A Split or PayMe request from a friend",
							url: "mail_sub_split_request",
							status: null
						},
						{
							name: "Your Split or PayMe request has finished",
							url: "mail_sub_split_finish",
							status: null
						}
					]
				},
				{
					categoryName: "Balance",
					adminOnly: false,
					settings: [
						{
							name: "Your balance is negative",
							url: "mail_sub_debt_reminder",
							status: null
						}
					]
				},
				{
					categoryName: "Misc",
					adminOnly: false,
					settings: [
						{
							name: "Significant updates to the service",
							url: "mail_sub_newsletter",
							status: "null"
						}
					]
				}
			],
			updateStatuses: {
				loading: "loading",
				error: "error",
				success: "success"
			},
			isLoading: false
		};
	},
	mounted() {
		this.initializeSettings();
	},
	methods: {
		saveSettings(categoryIndex, settingIndex) {
			this.emailOptions[categoryIndex].settings[
				settingIndex
			].status = this.updateStatuses.loading;
			let newSettings = {};
			for (const [key] of Object.entries(this.$store.state.settings.mails))
				newSettings[key] = this.selectedMailOptions.includes(key)
					? true
					: false;
			this.$axios
				.post(`/api/user_settings`, newSettings)
				.then(() => {
					this.emailOptions[categoryIndex].settings[
						settingIndex
					].status = this.updateStatuses.success;
					this.$store.commit("setMailSettings", newSettings);
				})
				.catch(() => {
					this.emailOptions[categoryIndex].settings[
						settingIndex
					].status = this.updateStatuses.error;
				})
				.finally(() =>
					setTimeout(
						() =>
							(this.emailOptions[categoryIndex].settings[
								settingIndex
							].status = null),
						5500
					)
				);
		},
		initializeSettings() {
			this.selectedMailOptions = [];
			for (const [key, value] of Object.entries(
				this.$store.state.settings.mails
			)) {
				if (value) this.selectedMailOptions.push(key);
			}
		}
	},
	computed: {
		...mapGetters(["user_is_admin"])
	},
	components: { UpdateContactStatus }
};
</script>

<style lang="scss" scoped></style>
