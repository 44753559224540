<template lang="pug">
v-container(fluid).primary.primary-light--text.pa-0
	div.title.text-center.mt-3 Please, create a password
	div.mt-3.primary.primary-light--text
		PasswordInput(
			ref='createPassword'
			colorScheme="dark"
			@enterPressed='$emit("enterPressed")')
		v-card(
			color="primary"
			flat
		).my-2.mt-md-6.caption.pa-2.primary-light--text.d-flex.justify-center
			v-icon(
				size='26'
				color="primary-light"
			).mr-2 lock
			div
				span We keep your password securely encrypted.
				br
				span Nobody (including us) can see it.
</template>

<script>
import { validators } from "@/mixins/validators";
import PasswordInput from "../../../Helpers/PasswordInput.vue";

export default {
	mixins: [validators],
	components: { PasswordInput },
	name: "welcome-dialog",
	data() {
		return {
			loading: false,
			currentStep: 1
		};
	}
};
</script>

<style lang="scss" scoped></style>
