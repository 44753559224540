<template lang="pug">
v-card(
	:height='entityName==="purchases" ? 248 : 196'
	:rounded='entityName==="requests" && $vuetify.breakpoint.smAndUp ? "xl" : ""'
	:class='entityName==="purchases" ? "purchase-card" : "requestCard"'
	outlined
	style='border:  1px dashed var(--v-primary-light-darken2); background-color: var(--v-primary-light-base);'
	).pa-4.ml-lg-4.d-flex.justify-center.align-center
	div(v-if='status === statuses.loading').d-flex
		v-progress-circular(
			:size='$vuetify.breakpoint.xs ? 20 : 28'
			indeterminate
			width='2'
			color="primary").mr-4
		.text-sm-h6.subtitle-1.primary--text Loading {{ entityName }}...
	div(v-else-if='status === statuses.error').text-center
		v-icon(color='primary' size='38').mr-1.mb-4 wifi_off
		div.text-secondary.text-h6 Loading didn't work
		div.pt-2.primary--text
			a(@click='$emit("loadMore")') Try again
	v-btn(
		v-else
		@click='$emit("loadMore")'
		color='primary'
		text)
		//v-icon(color='primary').mr-1 add_circle_outline
		span(style='padding-top: 1px') load more
</template>
<script>
export default {
	props: ["status", "statuses", "entityName"],
	mixins: [],
	data() {
		return {};
	},
	methods: {},
	computed: {},
	components: {}
};
</script>

<style lang="scss" scoped></style>
